import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HOST } from "../../config/main.json";
import { Box, Button, Chip, Fade, IconButton, Modal, Tooltip } from "@mui/material";
import CustomTable from "../../common/CustomTable/CustomTable";
import utils from "../../utils/utils";
import { Close, Preview } from "@mui/icons-material";
import UserLogsFilter from "../../components/userLogs/UserLogsFilter";
import Loader from "../../pages/account/Loader";
import moment from "moment";
import { getUserLogsHeaders } from "../../utils/tableHeaders";

function UserLogs() {
  const [isLoaded, setIsLoaded] = useState(true);
  const [logs, setLogs] = useState([]);
  const [visibleLogs, setVisibleLogs] = useState([]);
  const [next, setNext] = useState("");
  const [pagesLoaded, setPagesLoaded] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [start, setStart] = useState(moment().subtract(6, "month").startOf("month").toDate());
  const [end, setEnd] = useState(moment().toDate());
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});

  useEffect(() => {
    getLogs(start, end, selectedUsers, selectedActions, rowsPerPage, order, "");
  }, [rowsPerPage, order]);

  useEffect(() => {
    if (logs.length === 0 && next !== "") {
      getLogs(start, end, selectedUsers, selectedActions, rowsPerPage, order, next);
    }
  }, [next]);

  const getTagColors = (value) => {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      let v = (hash >> (i * 8)) & 0xff;
      v = Math.floor(v * 0.7); 
      color += ("00" + v.toString(16)).substr(-2);
    }


    const style = {
      backgroundColor: color,
      color: "white",
      borderRadius: "8px",
    };

    return style;
  };

  const getLogs = (start, end, users, actions, rowsPerPage, order, next) => {
    setIsLoaded(false);
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const data = {
      startDate: moment(start).format("YYYY-MM-DD"),
      endDate: moment(end).format("YYYY-MM-DD"),
      users: users,
      actions: actions,
      rows: rowsPerPage,
      order: order,
      nextToken: next,
      platform: "oryx", //platform
    };

    axios.post(`${API_HOST}/logs/all`, data, optionAxios).then((res) => {
      if (typeof res.data !== "string") {
        let logs = res.data.rows || [];
        logs = logs.map((row) => {
          let log = { ...row };
          log.time = log.time.split(".")[0];
          const entity = utils.capitalizeFirstLetter(log.entity.split("_").join(" "));
          log.entity = <Chip label={entity} style={getTagColors(entity)} />;
          log.action = utils.capitalizeFirstLetter(log.action.split("-")[1].split("_").join(" "));
          try {
            const payload = JSON.parse(log.payload);
            row.additionalRows = Object.keys(payload)
              .filter((key) => key !== "request")
              .map((key) => {
                return {
                  key: key,
                  payload: payload[key],
                };
              });
            const requestAttribute = Object.keys(payload).filter((key) => key === "request");
            if (payload[requestAttribute]) {
              const requestKeys = Object.keys(payload[requestAttribute]);

              row.additionalInformation = requestKeys
                .filter((key) => key !== "notificationMetadata")
                .map((key) => {
                  return {
                    key: key,
                    payload: String(payload[requestAttribute][key]),
                  };
                });
            }
          } catch (error) {
            console.error(error);
          }

          log.options = (
            <IconButton>
              <Tooltip title={"Detailed Information"} onClick={() => handleView(row)}>
                <Preview />
              </Tooltip>
            </IconButton>
          );
          return log;
        });
        setVisibleLogs(logs);
        setLogs((prev) => [...prev, ...logs]);

        setNext(res.data.next);

        if (next === "") {
          setTotal(res.data.total);
        }

        if (logs.length > 0 || (logs.length === 0 && res.data.next === "")) {
          setIsLoaded(true);
        }
      }
    });
  };

  const handleInformationChange = (event) => {
    if (page !== event.page) {
      setPage(event.page);
      if (pagesLoaded < event.page) {
        setPagesLoaded(event.page);
        getLogs(start, end, selectedUsers, selectedActions, rowsPerPage, order, next);
      } else {
        setVisibleLogs(logs.slice(rowsPerPage * event.page, rowsPerPage * (event.page + 1)));
      }
    } else if (order !== event.order) {
      setIsLoaded(false);
      setLogs([]);
      setNext("");
      setPage(0);
      setPagesLoaded(0);
      setOrder(event.order);
    } else if (rowsPerPage !== event.rowsPerPage) {
      setIsLoaded(false);
      setLogs([]);
      setNext("");
      setPage(0);
      setPagesLoaded(0);
      setRowsPerPage(event.rowsPerPage);
    }
  };

  const handleFilter = (start, end, selectedUsers, selectedActions) => {
    setIsLoaded(false);
    setLogs([]);
    setNext("");
    setPage(0);
    setPagesLoaded(0);
    setStart(start);
    setEnd(end);
    setSelectedUsers(selectedUsers);
    setSelectedActions(selectedActions);
    getLogs(start, end, selectedUsers, selectedActions, rowsPerPage, order, "");
  };

  const handleView = (row) => {
    setSelectedLog(row);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const userLogsHeaders = getUserLogsHeaders;

  return (
    <>
      <div id="filter">
        <UserLogsFilter
          start={start}
          end={end}
          selectedActions={selectedActions}
          selectedUsers={selectedUsers}
          handleFilter={handleFilter}
        />
      </div>
      <div id="table">
        {isLoaded ? (
          logs.length > 0 ? (
            <>
              <CustomTable
                tableHeaders={userLogsHeaders}
                bodyColumns={visibleLogs}
                sendInformation={handleInformationChange}
                totalRows={total}
                pageNo={page}
                rowsPerPageNo={rowsPerPage}
                sortOrder={order}
                sortOrderBy={"time"}
              />
              <Modal
                className="styles_modal"
                aria-labelledby="modal-details-label"
                aria-describedby="modal-details-description"
                open={openModal}
                onClose={closeModal}
                closeAfterTransition
              >
                <Fade in={openModal}>
                  <div className="syles_paper" style={{ width: "50%" }}>
                    <div id="modal-details-heading" className="styles_modal_heading">
                      <h2 id="modal-details-heading-text" style={{ padding: "10px" }}>
                        Detailed Information
                      </h2>
                      <IconButton onClick={closeModal}>
                        <Close />
                      </IconButton>
                    </div>
                    <hr />
                    <div id="modal-details-body" style={{ padding: "10px", fontSize: "1.2rem" }}>
                      {selectedLog?.entity && (
                        <table>
                          <tr style={{ width: "100%" }}>
                            <td style={{ fontWeight: "bold", padding: "5px" }}>Entity</td>
                            <td style={{ padding: "5px 20px" }}>{utils.capitalizeFirstLetter(selectedLog?.entity)}</td>
                          </tr>
                          <tr style={{ width: "100%" }}>
                            <td style={{ fontWeight: "bold", padding: "5px" }}>Action</td>
                            <td style={{ padding: "5px 20px" }}>
                              {utils.capitalizeFirstLetter(selectedLog?.action.split("-")[1].split("_").join(" "))}
                            </td>
                          </tr>
                          <tr style={{ width: "100%" }}>
                            <td style={{ fontWeight: "bold", padding: "5px" }}>Time</td>
                            <td style={{ padding: "5px 20px" }}>
                              {moment(selectedLog?.time).format("MMMM DD YYYY - hh:mm:ss [GMT]")}
                            </td>
                          </tr>
                          <tr style={{ width: "100%" }}>
                            <td style={{ fontWeight: "bold", padding: "5px" }}>User</td>
                            <td style={{ padding: "5px 20px" }}>{selectedLog?.email}</td>
                          </tr>
                          {selectedLog.additionalRows &&
                            selectedLog.additionalRows.map((row) => (
                              <tr style={{ width: "100%" }}>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                >
                                  {row.key}
                                </td>
                                <td style={{ padding: "5px 20px" }}>{row.payload}</td>
                              </tr>
                            ))}
                          {selectedLog.additionalInformation &&
                            selectedLog.additionalInformation.map((row) => (
                              <tr style={{ width: "100%" }}>
                                <td
                                  style={{
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                >
                                  {row.key}
                                </td>
                                <td style={{ padding: "5px 20px" }}>{row.payload}</td>
                              </tr>
                            ))}
                          <tr style={{ width: "100%" }}>
                            <td style={{ fontWeight: "bold", padding: "5px" }}>Status</td>
                            <td style={{ padding: "5px 20px" }}>{utils.capitalizeFirstLetter(selectedLog?.status)}</td>
                          </tr>
                        </table>
                      )}
                    </div>
                    <div
                      style={{
                        float: "right",
                        margin: "15px 20px 15px 0",
                      }}
                    >
                      <Button variant="outlined" onClick={closeModal} style={{ marginRight: "10px" }}>
                        Close
                      </Button>
                    </div>
                  </div>
                </Fade>
              </Modal>
            </>
          ) : (
            <Box
              sx={{
                p: "20px",
                transform: "translateY(10px)",
                textAlign: "center",
                bgcolor: "#fff",
              }}
            >
              Table is empty
            </Box>
          )
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}

export default UserLogs;
