import React from 'react'
import SecondaryStyledMenu from './common/SecondaryStyledMenu';
import { Box, Button, Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../../redux/features/snackbar/snackbarSlice';
import LoadingButton from '@mui/lab/LoadingButton';
import { getDataByFilters } from './service/fetchDataByFilter';

const ExportData = (props) => {
  const { selectedData, tableRowsPerPage, payload, sortCol, selectedPlatform, tablePageNo, customCols } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportCount, setExportCount] = React.useState(tableRowsPerPage);
  const [error, setError] = React.useState(null);
  const [includeContactDetails, setIncludeContactDetails] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  //set default export count to tableRowsPerPage
  React.useEffect(() => {
    setExportCount(tableRowsPerPage);
  }, [tableRowsPerPage])

  const open = Boolean(anchorEl);

  const user = useSelector(state => state.user);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // update export count on change
  const countChangeHandler = (e) => {
    const { value } = e.target;
    const totalRows = selectedData['data']['total'];
    if (value > totalRows) {
      setError(`Please enter a number less than ${totalRows}`);
      setExportCount(totalRows);
    } else if (value < 1) {
      setError(`Please enter a number greater than 0`);
      setExportCount(1);
    }
    else {
      setError(null);
      setExportCount(value);
    }
  }

  // export data handler
  const exportDataHandler = async () => {
    try {
      setIsLoading(true);
      payload.userId = JSON.parse(user.attributes.identities)[0].userId;
      payload.generatedBy = user.attributes.name
      const { data, status } = await getDataByFilters(
        payload,
        sortCol,
        selectedPlatform,
        tablePageNo,
        tableRowsPerPage,
        customCols,
        {
          isExport: 1,
          email: user?.attributes?.email,
          exportContact: includeContactDetails ? 1 : 0,
        },
        exportCount,
        
      );
      if (status === 200 && data?.status === "success") {
        dispatch(openSnackbar({ message: "Exporting data. Please check your email for the file.", severity: "success" }));
        handleClose();
      }
    } catch (error) {
      dispatch(openSnackbar({ message: "Unable to export data , please try again later.", severity: "error" }));
    }
    setIsLoading(false);
  }

  return (
    <>
      <Button
        sx={{
          color: "black",
          background: "white",
          border: "1px solid lightgrey",
          borderRadius: "12px",
          "&:hover": { bgcolor: "lightgray" },
          whiteSpace: "nowrap",
          px: "12px",
          textTransform: "none",
          justifyContent: "space-between",
        }}
        id="styled-menu"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={<FileDownloadIcon />}
      >
        Export Search Results
      </Button>
      <SecondaryStyledMenu
        id="styled-menu"
        MenuListProps={{
          "aria-labelledby": "styled-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box p={2}>
          <Typography fontSize={"1rem"} fontWeight={"bold"}>
            {selectedData['data']['total']} influencers found
          </Typography>
          <Box my={2}>
            <TextField
              label="Amount of influencers needed"
              id="outlined-export-discovery-data"
              type="number"
              defaultValue={tableRowsPerPage}
              value={exportCount}
              onChange={countChangeHandler}
              size="small"
            />
            {error && <Typography color='red'>{error}</Typography>}
          </Box>
          <Box display={"flex"} alignItems={"center"} my={1}>
            <FormControlLabel control={<Checkbox />} checked={includeContactDetails} label="Include contacts details and demographic data" onChange={() => setIncludeContactDetails(!includeContactDetails)} />
            <Tooltip
              title="Access to additional information on an influencer but not their audience including gender, location (country, state, and city), language, interests and brand affinities along with an influencers contact details. Please note, the contact details might not be available for some influencers as we only display public contacts that can be found on their Influencer profile."
              sx={{ ml: "2px" }}>
              <IconButton>
                <InfoIcon htmlColor='lightgrey' />
              </IconButton>
            </Tooltip>
          </Box>
          {/* <Box display={"flex"} alignItems={"center"}>
            <FormControlLabel control={<Checkbox disabled />} label="Exclude previously exported" />
            <Tooltip
              title="Show influencers that have been previously exported to CSV, JSON or API."
              sx={{ ml: "2px" }}>
              <IconButton>
                <InfoIcon htmlColor='lightgrey' />
              </IconButton>
            </Tooltip>
          </Box> */}
          <LoadingButton my={2} color='primary' variant='contained' onClick={exportDataHandler} loading={isLoading} >
            Export ({exportCount})
          </LoadingButton>
        </Box>
      </SecondaryStyledMenu>
    </>
  )
}

export default ExportData
