import React, { useState } from "react";
import utils from "../../../../utils/utils.js";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import StyledMenu from "../../StyledMenu";
import CustomSelectedFilter from "./CustomSelectedFilter";
import { getLocationData } from "../service/fetchDataByFilter";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteSideMenu from "./AutocompleteSideMenu";
import { Box } from "@mui/system";

const CustomFilter = ({
  showFilter,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  isChanged,
  setIsChanged,
  audFilterKey = "aud_location",
  infFilterKey = "inf_location",
  filterType = "location",
  fetchDataByFilterHandler = getLocationData,
  showAnyOption = false,
  showOnlyOneField = false,
  allFiltersData,
  setAllFiltersData,
  audReqKey = "",
  infReqKey = "",
  weight = "0.05",
  isMulti = true,
  selectedPlatform,
  audTooltipMsg,
  infTooltipMsg,
  isInfFilter = false,
  customCols,
  isRange,
  label = "",
}) => {
  const [audData, setAudData] = useState([]);
  const [infData, setInfData] = useState([]);
  const [range, setRange] = useState(0.05);
  const [isAny, setIsAny] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const filterOptions = (options, state) => {
    sleep(500);
    return options;
  };

  const handleAudienceSelection = (selectedValues) => {
    if (filterType === "lookalikes") {
      let username = selectedValues["username"];
      if (username !== undefined) {
        let newPayload = payload;
        let audpayload = { value: username };
        newPayload["aud_audience_type"] = "any";
        newPayload["sort"] = "audience_relevance";
        newPayload[audFilterKey] = audpayload;
        allFiltersData = utils.addObj(allFiltersData, audFilterKey);
        allFiltersData[0][audFilterKey][0] = audpayload;
        setPayload(newPayload);
        setAllFiltersData(allFiltersData);
      }
    } else {
      let latestLoc = "";
      let latestId = "";
      if (!isMulti) {
        latestLoc = selectedValues["name"];
        latestId = selectedValues["id"];
      } else {
        latestLoc = selectedValues[selectedValues.length - 1]["name"];
        latestId = selectedValues[selectedValues.length - 1]["id"];
      }
      customCols = utils.addObj(customCols, audFilterKey);
      let filterSet = { id: latestId, name: latestLoc };
      let filtersData = utils.addObj(allFiltersData, audFilterKey);
      if (
        latestLoc !== undefined &&
        !filtersData[0][audFilterKey].includes(latestLoc)
      ) {
        let newPayload = payload;
        let locationPayload = [];
        if (!isMulti) {
          let payload = {};
          payload[audReqKey] = selectedValues["id"];
          payload["weight"] = weight;
          newPayload[audFilterKey] = payload;
          filtersData[0][audFilterKey][0] = filterSet;
          customCols[0][audFilterKey][0] = latestLoc;
        } else {
          selectedValues.map((loc, index) => {
            let payload = {};
            payload[audReqKey] = loc["id"];
            payload["weight"] = weight;
            locationPayload.push(payload);
            // filtersData[0][audFilterKey].push(filterSet)
            if (
              !filtersData[0][audFilterKey].some(
                (existingFilter) =>
                  existingFilter.id === filterSet.id &&
                  existingFilter.name === filterSet.name
              )
            ) {
              filtersData[0][audFilterKey].push(filterSet);
            }
          });
          newPayload[audFilterKey] = locationPayload;
          customCols[0][audFilterKey].push(latestLoc);
        }
        setAllFiltersData(filtersData);
        setSelectedCol(customCols);
        setCustomCols(customCols);
        setPayload(newPayload);
      }
    }
  };

  const handleInfSelection = (selectedValues) => {
    if (filterType === "lookalikes") {
      let username = selectedValues["username"];
      if (username !== undefined) {
        let newPayload = payload;
        let audpayload = { value: username };
        newPayload["aud_audience_type"] = "any";
        newPayload["sort"] = "relevance";
        audpayload = { weight: range, value: "@" + username };
        newPayload[infFilterKey] = audpayload;
        allFiltersData = utils.addObj(allFiltersData, infFilterKey);
        allFiltersData[0][infFilterKey][0] = audpayload;
        setPayload(newPayload);
        setAllFiltersData(allFiltersData);
      }
    } else {
      let latestLoc = "";
      let latestId = "";
      if (!isMulti) {
        latestLoc = selectedValues["name"];
        latestId = selectedValues["id"];
      } else {
        latestLoc = selectedValues[selectedValues.length - 1]["name"];
        latestId = selectedValues[selectedValues.length - 1]["id"];
      }

      let filterSet = { id: latestId, name: latestLoc };
      let filtersData = utils.addObj(allFiltersData, infFilterKey);
      filtersData[0][infFilterKey].push(filterSet);
      setAllFiltersData(filtersData);
      if (
        latestLoc !== undefined &&
        !filtersData[0][infFilterKey].includes(latestLoc)
      ) {
        let dataPayload = [];
        let newPayload = payload;
        if (!isMulti) {
          let payload = {};
          payload[infReqKey] = selectedValues["id"];
          newPayload[infFilterKey] = payload;
        } else {
          selectedValues.map((loc, index) => {
            let payload = {};
            if (infReqKey !== "") {
              payload[infReqKey] = loc["id"];
            } else {
              payload = loc["id"];
            }
            dataPayload.push(payload);
          });
          newPayload[infFilterKey] = dataPayload;
        }

        setPayload(newPayload);
      }
    }
  };
  const cancelAudHandler = (selectedValues, name) => {
    if (filterType === "lookalikes") {
      let index = allFiltersData[0][audFilterKey]
        .map((item) => item.value)
        .indexOf(name);
      if (index !== -1) {
        let newPayload = payload;
        const filteredLookalikes = allFiltersData[0][audFilterKey].filter(
          (_, idx) => idx !== index
        );
        let newFilters = allFiltersData;
        if (allFiltersData[0]["inf_lookalikes"] !== undefined) {
          newPayload["sort"] = "relevance";
        } else {
          newPayload["sort"] = "engagements";
        }
        newFilters[0][audFilterKey] = filteredLookalikes;
        setAllFiltersData(newFilters);
        newPayload[audFilterKey] = filteredLookalikes;
        setPayload(newPayload);
        setIsChanged(!isChanged);
      }
    } else {
      let index = allFiltersData[0][audFilterKey]
        .map((item) => item.id)
        .indexOf(selectedValues);
      if (index !== -1) {
        let newPayload = payload;
        const filteredLocations = allFiltersData[0][audFilterKey].filter(
          (_, idx) => idx !== index
        );
        newPayload[audFilterKey] = filteredLocations;
        setPayload(newPayload);
        let newcustCol = customCols;
        newcustCol[0][audFilterKey] = utils.removeElement(
          customCols[0][audFilterKey],
          name
        );
        setCustomCols(newcustCol);
        setSelectedCol(newcustCol);
        // document.getElementById(name).remove()
        let newFilters = allFiltersData;
        if (filteredLocations.length > 0) {
          newFilters[0][audFilterKey] = filteredLocations;
        } else {
          delete newFilters[0][audFilterKey];
        }
        setAllFiltersData(newFilters);
        setIsChanged(!isChanged);
      }
    }
  };

  const cancelInfHandler = (selectedValues, name) => {
    if (filterType === "lookalikes") {
      let index = allFiltersData[0][infFilterKey]
        .map((item) => item.value)
        .indexOf(name);
      if (index !== -1) {
        let newPayload = payload;
        const filteredLookalikes = allFiltersData[0][infFilterKey].filter(
          (_, idx) => idx !== index
        );
        let newFilters = allFiltersData;
        if (allFiltersData[0]["aud_lookalikes"] !== undefined) {
          newPayload["sort"] = "audience_relevance";
        } else {
          newPayload["sort"] = "engagements";
        }
        newFilters[0][infFilterKey] = filteredLookalikes;
        setAllFiltersData(newFilters);
        newPayload[infFilterKey] = filteredLookalikes;
        setPayload(newPayload);
        setIsChanged(!isChanged);
      }
    } else {
      let index = allFiltersData[0][infFilterKey]
        .map((item) => item.id)
        .indexOf(selectedValues);
      if (index !== -1) {
        const filteredInfLocation = allFiltersData[0][infFilterKey].filter(
          (_, idx) => idx !== index
        );
        let newPayload = payload;
        newPayload[infFilterKey] = filteredInfLocation;
        setPayload(newPayload);
        let newFilters = allFiltersData;
        if (filteredInfLocation.length > 0) {
          newFilters[0][infFilterKey] = filteredInfLocation;
        } else {
          delete newFilters[0][infFilterKey];
        }
        setAllFiltersData(newFilters);
        setIsChanged(!isChanged);
        // document.getElementById(selectedValues + "-inf").remove()
      }
    }
  };

  const handlerAudLocation = async (event) => {
    if (event.target.value !== "" && filterType === "lookalikes") {
      const data = await fetchDataByFilterHandler(
        event.target.value,
        selectedPlatform,
        "lookalike"
      );
      setAudData(data);
    } else if (event.target.value !== "" && fetchDataByFilterHandler) {
      const data = await fetchDataByFilterHandler(event.target.value);
      setAudData(data);
    }
  };
  const handlerInfLocation = async (event) => {
    if (event.target.value !== "" && filterType === "lookalikes") {
      const data = await fetchDataByFilterHandler(
        event.target.value,
        selectedPlatform,
        "topic-tags"
      );
      setInfData(data);
    } else if (event.target.value !== "" && fetchDataByFilterHandler) {
      const data = await fetchDataByFilterHandler(event.target.value);
      setInfData(data);
    }
  };

  const handleRange = (event, locId) => {
    let newPayload = payload;
    if (!isMulti) {
      newPayload[audFilterKey].weight = event.target.value;
    } else {
      newPayload[audFilterKey].map((loc, index) => {
        if (loc["id"] === locId) {
          newPayload[audFilterKey][index]["weight"] = event.target.value;
        }
      });
    }
    setPayload(newPayload);
    setRange(event.target.value);
  };

  const handleIsAny = (checkbox) => {
    setIsAny(checkbox);
    let newPayload = payload;
    newPayload[audFilterKey] = [];
    setPayload(newPayload);
  };

  const sideMenuAttachment = () => {
    if (allFiltersData !== undefined) {
      if (filterType === "lookalikes") {
        if (
          allFiltersData[0][isInfFilter ? infFilterKey : audFilterKey] !==
            undefined &&
          allFiltersData[0][isInfFilter ? infFilterKey : audFilterKey].length >
            0
        ) {
          return (
            <AutocompleteSideMenu
              options={Object.keys(
                allFiltersData[0][isInfFilter ? infFilterKey : audFilterKey]
              ).map((index) => {
                let item =
                  allFiltersData[0][isInfFilter ? infFilterKey : audFilterKey][
                    index
                  ];
                item.name = item.value;
                item.id = index;
                return (
                  <CustomSelectedFilter
                    item={item}
                    chipClickHandler={() =>
                      isInfFilter
                        ? cancelInfHandler(null, item.name)
                        : cancelAudHandler(null, item.name)
                    }
                    isRange={false}
                  />
                );
              })}
            />
          );
        }
      } else {
        if (
          allFiltersData[0][audFilterKey] !== undefined &&
          allFiltersData[0][audFilterKey].length > 0 &&
          !isInfFilter
        ) {
          return (
            <AutocompleteSideMenu
              options={Object.keys(allFiltersData[0][audFilterKey]).map(
                (index) => {
                  let item = allFiltersData[0][audFilterKey][index];
                  return (
                    <CustomSelectedFilter
                      item={item}
                      chipClickHandler={() =>
                        cancelAudHandler(item.id, item.name)
                      }
                      range={range}
                      rangeHandler={handleRange}
                      isRange={true}
                    />
                  );
                }
              )}
            />
          );
        } else if (
          allFiltersData[0][infFilterKey] !== undefined &&
          allFiltersData[0][infFilterKey].length > 0 &&
          isInfFilter
        ) {
          return (
            <AutocompleteSideMenu
              options={Object.keys(allFiltersData[0][infFilterKey]).map(
                (index) => {
                  let item = allFiltersData[0][infFilterKey][index];
                  return (
                    <CustomSelectedFilter
                      item={item}
                      chipClickHandler={() =>
                        cancelInfHandler(item.id, item.name)
                      }
                      range={range}
                      rangeHandler={handleRange}
                      isRange={false}
                    />
                  );
                }
              )}
            />
          );
        }
      }
    }
    return null;
  };

  return (
    showFilter && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple={isMulti}
                disableClearable
                forcePopupIcon={false}
                limitTags={0}
                id={`filter-${audFilterKey}`}
                size="small"
                popupIcon={""}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option?.name}
                renderTags={() => null}
                renderOption={(props, option) => {
                  if (filterType === "lookalikes") {
                    console.log(option);
                    return (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="40"
                          src={option.pic}
                          srcSet={option.pic}
                          alt=""
                        />
                        {option.username} {option.name} {option.followers}
                      </Box>
                    );
                  } else {
                    return (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.name}
                      </Box>
                    );
                  }
                }}
                options={isInfFilter ? infData : audData}
                value={
                  typeof allFiltersData === "undefined"
                    ? ""
                    : isInfFilter
                    ? isMulti
                      ? allFiltersData[0][infFilterKey]
                      : null
                    : isMulti
                    ? allFiltersData[0][audFilterKey]
                    : null
                }
                onChange={(_e, v) =>
                  isInfFilter
                    ? handleInfSelection(v)
                    : handleAudienceSelection(v)
                }
                // onKeyPress={(e) => handlerAudLocation(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={utils.capitalizeFirstLetter(
                      label === "" ? filterType : label
                    )}
                    placeholder={utils.capitalizeFirstLetter(
                      label === "" ? filterType : label
                    )}
                    variant="outlined"
                    onChange={
                      isInfFilter ? handlerInfLocation : handlerAudLocation
                    }
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: sideMenuAttachment(),
                    }}
                  />
                )}
                style={{
                  borderRadius: ".775rem",
                }}
              />
              {/* <div id={`${audFilterKey}_tags`}>
                {allFiltersData !== undefined &&
                  allFiltersData[0][audFilterKey] !== undefined &&
                  Object.keys(allFiltersData[0][audFilterKey]).map((index) => {
                    let item = allFiltersData[0][audFilterKey][index];
                    return (
                      <CustomSelectedFilter
                        item={item}
                        chipClickHandler={() =>
                          cancelAudHandler(item.id, item.name)
                        }
                        range={range}
                        rangeHandler={handleRange}
                        isRange={true}
                      />
                    );
                  })}
              </div> */}
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  );
};

CustomFilter.propTypes = {
  showFilter: PropTypes.bool.isRequired,
  setCustomCols: PropTypes.func.isRequired,
  setSelectedCol: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  setIsChanged: PropTypes.func.isRequired,
  audFilterKey: PropTypes.string.isRequired,
  infFilterKey: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  fetchDataByFilterHandler: PropTypes.func.isRequired,
  showAnyOption: PropTypes.bool,
  showOnlyOneField: PropTypes.bool,
};

export default CustomFilter;
