import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useScroll from "../../../../hooks/useScroll";
import { Container, Box, Typography, Skeleton, Grid } from "@mui/material";
import BasicDetails from "./components/BasicDetails/BasicDetails";
import AudienceInfo from "./components/AudienceInfo/AudienceInfo";
import InfluencerInfo from "./components/InfluencerInfo/InfluencerInfo";
import ContentInfo from "./components/ContentInfo/ContentInfo";
import FallbackWrapper from "./components/common/FallbackWrapper/FallbackWrapper";
import CustomToggleButtonGroup from "./components/common/CustomToggleButtonGroup/CustomToggleButtonGroup";
import ScrollToTop from "../../../general/ScrollToTop";
import { getProfileData } from "../../../../services/imai/getProfileData";
import utils from "../../../../utils/utils";
import { PLATFORM_NAME } from "../../../../config/main.json";
import { useSelector } from "react-redux";

const tabOptions = [
  {
    key: "influencer",
    value: "Influencer",
  },
  {
    key: "audience",
    value: "Audience",
  },
  {
    key: "content",
    value: "Content",
  },
];

const Common = ({ id, platform }) => {
  const [currentSection, setCurrentSection] = useState("influencer");
  const [scrollOntoInfluencer, influencerRef] = useScroll();
  const [scrollOntoAudience, audienceRef] = useScroll();
  const [scrollOntoContent, contentRef] = useScroll();
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const user = useSelector((state) => state.user);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let fromNotifcations = params.get("fromNotifcation");

  const scrollHandler = () => {
    const yPos = window.scrollY;
    if (yPos < 540) setCurrentSection("influencer");
  };

  const fetchData = async () => {
    try {
      let data;
      if (fromNotifcations === null) {
        data = {
          scid: id,
          platform,
          notificationMetadata: {
            userId: JSON.parse(user.attributes.identities)[0].userId,
            sourcePlatform: PLATFORM_NAME,
            notificationTypeId: "4",
            notificationActionId: "2",
            notificationCategoryId: "2",
            generatedBy: user.attributes.name,
            priority: "high",
            consumedBy: "oryx",
            navigateTo: `/advance/analyze/${platform}/${id}?fromNotifcation=true`,
          },
        };
      } else {
        data = {
          scid: id,
          platform,
        };
      }
      const res = await getProfileData(data);
      if (res && !res?.message) {
        setProfileData(res);
        setIsLoading(false);
      } else {
        if (res?.message.includes("We are updating data for this account")) {
          setIsLoading(false);
          setProfileData(null);
        } else {
          setIsLoading(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false); // Set loading to false on error to prevent infinite API calls.
    }
  };

  useEffect(() => {
    setProfileData(null);
    if (id !== "" && platform !== null) {
      fetchData();
      window.addEventListener("scroll", scrollHandler);
      return () => {
        window.removeEventListener("scroll", scrollHandler);
      };
    }
  }, [id, platform]); // Effect runs only on component mount

  useEffect(() => {
    if (profileData == null) {
      const interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter + 1);
      }, 60000); // 2 minutes in milliseconds

      window.addEventListener("scroll", scrollHandler); // Add scroll event listener

      return () => {
        clearInterval(interval);
        window.removeEventListener("scroll", scrollHandler); // Clean up the scroll event listener on unmount
      };
    }
  }, []); // Effect runs only on component mount

  useEffect(() => {
    if (counter > 0 && !isLoading && profileData === null) {
      console.log(Date.now())
      fetchData();
    }
  }, [counter, isLoading]);

  const handleChange = (_, newSection) => {
    setCurrentSection(newSection ? newSection : currentSection);
    switch (newSection) {
      case "influencer":
        return scrollOntoInfluencer();
      case "audience":
        return scrollOntoAudience();
      case "content":
        return scrollOntoContent();
      default:
        return scrollOntoInfluencer();
    }
  };

  const renderContentHandler = () => {
    let content = <></>;
    if (!isLoading && profileData == null)
      content = (
        <>
          <img src="/static/images/progress_loader.svg" />
          <br />
          We are creating influencer profile for you.
          <br /> It may take approximately 3-4 minutes.
          <br /> Please note that the page will auto-refresh, so refrain from closing it.
        </>
      );
    else if (profileData != null)
      content = (
        <>
          <Box>
            <BasicDetails data={profileData?.["profile_info"]} platform={profileData?.["platform"]} />
          </Box>
          <Box>
            <CustomToggleButtonGroup options={tabOptions} selectedOption={currentSection} handleChange={handleChange} />
          </Box>
          <Box component={"section"} ref={influencerRef} sx={{ my: "2rem" }}>
            <FallbackWrapper isError={utils.checkIsObjectEmpty(profileData["influencer_data"])}>
              <InfluencerInfo data={profileData?.["influencer_data"]} platform={profileData?.["platform"]} />
            </FallbackWrapper>
          </Box>
          <Box component={"section"} ref={audienceRef} sx={{ my: "2rem" }}>
            <Typography variant="h3" component={"h3"}>
              AUDIENCE DATA
            </Typography>
            <FallbackWrapper isError={utils.checkIsObjectEmpty(profileData["audience_data"])}>
              <AudienceInfo data={profileData?.["audience_data"]} platform={profileData?.["platform"]} />
            </FallbackWrapper>
          </Box>
          <Box component={"section"} ref={contentRef} sx={{ my: "2rem" }}>
            <Typography variant="h3" component={"h3"} sx={{ mb: "2rem" }}>
              CONTENT
            </Typography>
            <FallbackWrapper isError={!profileData["contentData"]?.length}>
              <ContentInfo data={profileData?.["contentData"]} platform={profileData?.["platform"]} />
            </FallbackWrapper>
          </Box>
          <ScrollToTop />
        </>
      );
    else content = <LoaderSkeleton />;
    return content;
  };

  return (
    <>
      <Container sx={{ mt: "75px" }} style={{ textAlign: "center" }}>
        {renderContentHandler()}
      </Container>
    </>
  );
};

export default Common;

//loading screen layout
const LoaderSkeleton = () => (
  <>
    <Typography sx={{ my: "1rem" }}>Fetching Influencer's Information</Typography>
    <Skeleton variant="rectangular" height={300} sx={{ mb: "1rem", borderRadius: "6px" }} />
    <Skeleton variant="rectangular" height={100} sx={{ mb: "1rem", borderRadius: "6px" }} />
    <Grid container spacing={3}>
      {Array(3)
        .fill(1)
        .map((_, idx) => (
          <Grid xs item key={`skel-${idx}`} sx={{ mb: "1rem", borderRadius: "6px" }}>
            <Skeleton variant="rectangular" height={100} />
          </Grid>
        ))}
    </Grid>
    <Grid container spacing={3}>
      {Array(3)
        .fill(1)
        .map((_, idx) => (
          <Grid xs item key={`skel-${idx}`}>
            <Skeleton variant="rectangular" height={298} sx={{ mb: "1rem", borderRadius: "6px" }} />
          </Grid>
        ))}
    </Grid>
    <Skeleton variant="rectangular" height={100} sx={{ mb: "1rem", borderRadius: "6px" }} />
    <Grid container spacing={3}>
      {Array(2)
        .fill(1)
        .map((_, idx) => (
          <Grid xs item key={`skel-${idx}`}>
            <Skeleton variant="rectangular" height={298} sx={{ mb: "1rem", borderRadius: "6px" }} />
          </Grid>
        ))}
    </Grid>
    <Skeleton variant="rectangular" height={200} sx={{ mb: "1rem", borderRadius: "6px" }} />
    <Grid container spacing={3}>
      {Array(2)
        .fill(1)
        .map((_, idx) => (
          <Grid xs item key={`skel-${idx}`}>
            <Skeleton variant="rectangular" height={298} sx={{ mb: "1rem", borderRadius: "6px" }} />
          </Grid>
        ))}
    </Grid>
    <Skeleton variant="rectangular" height={300} sx={{ mb: "1rem", borderRadius: "6px" }} />
  </>
);
