import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import utils from '../../../utils/utils';

import { FormControl, Grid, Autocomplete } from "@mui/material";
import AutocompleteSideMenu from "./common/AutocompleteSideMenu";
import CustomSelectedFilter from "./common/CustomSelectedFilter";

const Contact = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  isChanged,
  setIsChanged,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const [allContact] = useState([
    { value: "email", name: "Has Email" },
    { value: "phone", name: "Has Phone" },
    { value: "snapshot", name: "Has Snapshot" },
    { value: "facebook", name: "Has Facebook" },
    { value: "youtube", name: "Has Youtube" },
    { value: "tiktok", name: "Has TikTok" },
    { value: "twitter", name: "Has Twitter" },
    { value: "telegram", name: "Has Telegram" },
    { value: "whatsapp", name: "Has WhatsApp" },
    { value: "linkedin", name: "Has Linkedin" },
    { value: "vk", name: "Has VK" },
    { value: "bbm", name: "Has BBM" },
    { value: "kik", name: "Has Kik" },
    { value: "wechat", name: "Has WeChat" },
    { value: "viber", name: "Has Viber" },
    { value: "skype", name: "Has Skype" },
    { value: "tumblr", name: "Has Tumblr" },
    { value: "twitch", name: "Has Twitch" },
    { value: "kakao", name: "Has Kakao" },
    { value: "pinterest", name: "Has Pinterest" },
    { value: "linktree", name: "Has LinkTree" },
    { value: "sarahah", name: "Has Sarahah" },
    { value: "sayat", name: "Has Sayat.me" },
    { value: "itunes", name: "Has Apple Music Account" },
    { value: "weibo", name: "Has Weibo" },
  ]);
  const [contactSelected, setContactSelected] = useState([]);
  const [isAny, setIsAny] = useState(false);
  const handleInfContactelection = (selectedValues) => {
    if (!isAny) {
      let latestContact = selectedValues[selectedValues.length - 1]["name"];
      let latestValue = selectedValues[selectedValues.length - 1]["value"];
      let filterSet = { name: latestContact, value: latestValue };
      allFiltersData = utils.addObj(allFiltersData, "aud_contact");
      allFiltersData[0]["aud_contact"].push(filterSet);
      if (
        latestContact !== undefined &&
        !contactSelected.includes(latestContact)
      ) {
        let contactPayload = [];
        let newPayload = payload;
        selectedValues.map((contact, index) => {
          contact = { type: contact["value"], action: "should" };
          contactPayload.push(contact);
        });
        newPayload["aud_contact"] = contactPayload;
        setPayload(newPayload);
        setAllFiltersData(allFiltersData);
      } else if (
        latestContact !== undefined &&
        contactSelected.includes(latestContact)
      ) {
        handleCancleContact(latestContact);
      }
      setContactSelected(selectedValues);
    }
  };

  const handleCancleContact = (selectedValues) => {
    let index = allFiltersData[0]["aud_contact"]
      .map((item) => item.name)
      .indexOf(selectedValues);
    if (index !== -1) {
      const filteredInfPartner = allFiltersData[0]["aud_contact"].filter(
        (_, idx) => idx !== index
      );
      let newPayload = payload;
      newPayload["aud_contact"] = filteredInfPartner;
      setPayload(newPayload);
      let newFilters = allFiltersData;
      newFilters[0]["aud_contact"] = filteredInfPartner;
      setAllFiltersData(newFilters);
      setIsChanged(!isChanged);
    }
  };

  const sideMenuAttachment = () => {
    if (allFiltersData !== undefined) {
      if (
        allFiltersData[0]["aud_contact"] !== undefined &&
        allFiltersData[0]["aud_contact"].length > 0
      ) {
        return (
          <AutocompleteSideMenu
            options={Object.keys(allFiltersData[0]["aud_contact"]).map(
              (index) => {
                let item = allFiltersData[0]["aud_contact"][index];
                return (
                  <CustomSelectedFilter
                    item={item}
                    chipClickHandler={() => handleCancleContact(item.name)}
                    isRange={false}
                  />
                );
              }
            )}
          />
        );
      }
    }
    return null;
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              disableClearable
              forcePopupIcon={false}
              limitTags={0}
              id="filter-contact"
              size="small"
              popupIcon={""}
              options={allContact}
              getOptionLabel={(option) => option.name}
              renderTags={() => null}
              onChange={(_e, v) => handleInfContactelection(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contact"
                  placeholder="Contact"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: sideMenuAttachment(),
                  }}
                />
              )}
              style={{
                borderRadius: ".775rem",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Contact.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Contact;
