import React from "react";
import ReactEcharts from "echarts-for-react";
import { useLocation } from "react-router-dom";
import { getTopThreeGroups } from "../../services/list/ListDetails";
import { textColor } from "./snapshot";
import { Card, Grid, Typography } from "@mui/material";
import utils from "../../utils/utils";
import { BarChart } from "../../common/chart";

const EngByListWise = (props) => {
  const { startDate, endDate, filterBy, selectedPlatformName, platforms, selectedListIds, selectedInfluencersIds, parentStyle } =
    props;
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = params.get("id");

  const [dataFound, setDataFound] = React.useState(false);
  const [listEngagementTime, setListEngagementTime] = React.useState([]);
  const [lists, setLists] = React.useState([]);
  const [listEngagement, setListEngagement] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, [startDate, endDate, filterBy, selectedPlatformName, platforms, selectedListIds, selectedInfluencersIds]);

  const fetchData = async () => {
    const platformIds =
      selectedPlatformName === "all"
        ? platforms.map((value) => value.id)
        : [platforms.find((obj) => obj.name === selectedPlatformName).id];
    const response = await utils.getEngByListwise(
      campaignId,
      startDate,
      endDate,
      filterBy,
      platformIds,
      [-1],
      selectedListIds.length > 0 ? selectedListIds : [-1],
      selectedInfluencersIds.length > 0 ? selectedInfluencersIds : [-1]
    );
    if (Object.keys(response.ListEngagement).length > 0) {
      createListWisengagementSummaryArray(response.ListEngagement);
      setDataFound(true);
    } else {
      createListWisengagementSummaryArray({});
    }
  };

  const createListWisengagementSummaryArray = (summarySplit) => {
    if (summarySplit) {
      const groups = Object.keys(summarySplit);
      let event = createPlatformSplitArray(groups, summarySplit, false);
      setListEngagementTime(event.finalDateArray);
      setLists(groups);
      setListEngagement(event.seriesArray);
    }
  };

  const createPlatformSplitArray = (summaryObject, summarySplit, color) => {
    let dateArray = [];
    let seriesArray = [];
    summaryObject.forEach((platform) => {
      dateArray.push(...Object.keys(summarySplit[platform]));
    });

    summaryObject.forEach((platform) => {
      const object = {
        name: platform,
        type: "bar",
        showBackground: false,
        color: color ? [textColor[platform].color] : [],
        data: [],
        itemStyle: {
          borderRadius: [5, 5, 0, 0]
        },
      };
      dateArray.forEach((date) => {
        if (summarySplit[platform][date]) {
          object.data.push(summarySplit[platform][date]);
        } else {
          object.data.push(0);
        }
      });
      seriesArray.push(object);
    });
    const finalDateArray = [...new Set(dateArray)];
    finalDateArray.sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
    return { finalDateArray, seriesArray };
  };

  return (
    <Card style={{ padding: "20px", marginTop: "20px", boxShadow: 'none' }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Typography variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#1E2022', marginBottom: "20px" }}>
           Engagement by List
          </Typography>
        </Grid>
      </Grid>
      {listEngagementTime.length > 0 && listEngagement.length > 0 ? (
        <>
          <ReactEcharts
            option={BarChart(listEngagementTime, true, listEngagement, lists, ["line", "bar", "stack"])}
            style={{ height: "400px", width: "100%" }}
          />
          <i style={{ position: "absolute", right: 100 }}>*Engagement is cumulative</i>
          <br />
        </>
      ) : (
        <p style={parentStyle.noData}>
          No Graph found
        </p>
      )}
    </Card>
  );
};

export default EngByListWise;
