import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ExportFiles = ({ sendExportSelected }) => {
  const [exportType, setExportType] = React.useState("");
  const createExport = (event) => {
    const {
      target: { value },
    } = event;
    setExportType("");
    sendExportSelected(value);
  };

  return (
    <FormControl size="small">
      <InputLabel id="demo-simple-select-label"  sx={{
          fontSize: '0.875rem' 
        }}>Export As</InputLabel>

      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{
          minWidth: "9vw",
          marginLeft: "5px",
          marginRight: "20px",
          borderRadius: "10px",
          "& .MuiSelect-select": {
            fontSize: '0.875rem', 
          }
        }}
        value={exportType}
        onChange={createExport}
        label="Export As"
      >
        <MenuItem value={"csv"}>CSV</MenuItem>
      </Select>
    </FormControl>
  );
};

ExportFiles.propTypes = {
  sendExportSelected: PropTypes.func.isRequired,
};

export default ExportFiles;
