import { number, node, string } from 'prop-types';


//his component dynamically shows and hides its children based on the currently selected tab, providing the 
//behavior expected of a tab panel component.
const TabPanel = ({
  children, value, index, name, ...other
}) => (
  <div
    role="tabpanel"
      //This attribute indicates the role of the div as a tab panel for accessibility purposes.
    hidden={value !== index}
      //This conditionally hides the panel based on whether its index matches the currently selected tab index. 
      //If value is not equal to index, the panel is hidden.
    id={`${name}-${index}`}
    aria-labelledby={`${name}-${index}`}
      // This associates the tab panel with its corresponding tab for accessibility.
    {...other}
  >
    {value === index && (
      children
    )}
  </div>
);

TabPanel.propTypes = {
  children: node,
  name: string.isRequired,
  index: number.isRequired,
  value: number.isRequired
};

export default TabPanel;
