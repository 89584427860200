import React, { Component } from "react";

import WorldMap from "react-svg-worldmap";

/* external files */
import MyPaper from "../general/MyPaper";

/* mui */
import Datatables from "mui-datatables";
import {
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from "@material-ui/icons/Close";

class LinkLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTableModal: false,
    }
  }

  expandTable = () => {
    this.setState({ showTableModal: true });
  }

  handleTableModalClose = () => {
    this.setState({ showTableModal: false });
  }

  render() {
    const color = (this.props.color)?(this.props.color):"green";
    const title = (this.props.title)?(this.props.title):"World Map";
    const valueSuffix = (this.props.valueSuffix)?(this.props.valueSuffix):"";
    const size = (this.props.size)?(this.props.size):"xl";
    return(
      <MyPaper sx={{ marginTop: "20px" }}>
        <Stack direction="row" justifyContent="end">
          <Typography variant="h4" sx={this.props.parentStyle.typography}>
            {title}
          </Typography>
        </Stack>
        {
          (!this.props.data || this.props.data.length == 0) ?
          (
          <div style={this.props.parentStyle.noData}>
            No records found
          </div>
          ):(
            <>
              <div id="location">
                <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
                  <Grid item xs={4} md={6}>
                    <WorldMap
                      color={color}
                      value-suffix={valueSuffix}
                      size={size}
                      data={this.props.data}
                      borderColor={"black"}
                      strokeOpacity={0.5}
                    />
                  </Grid>
                  <Grid item xs={4} md={6} style={{ display: "flex", flexFlow: "column wrap", justifyContent: "space-between" }}>
                    <div id="location_table">
                      <Datatables 
                        data={this.props.countryTableData.slice(0,4)}
                        columns={this.props.countryTableCols}
                        options={this.props.tableOpts}
                      />
                    </div>
                    <div id="location_table_expand_button">
                      <Stack direction="row" justifyContent="end">
                        <Button
                          variant="contained"
                          onClick={this.expandTable}
                          endIcon={<OpenWithIcon />}
                        >
                          Expand
                        </Button>
                      </Stack>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Modal
                className="styles_modal"
                aria-labelledby="modal-expanded_link_locations_table-label"
                aria-describedby="modal-expanded_link_locations_table-description"
                open={this.state.showTableModal}
                onClose={this.handleTableModalClose.bind(this)}
                closeAfterTransition
              >
                <Fade in={this.state.showTableModal}>
                  <div className="syles_paper" style={{ width: "50%" }}>
                    <div id="modal-expanded_link_locations_table-heading" className="styles_modal_heading">
                      <h2 id="modal-expanded_link_locations_table-heading-text" style={{ padding: "10px" }}>City Level Breakdown</h2>
                      <IconButton onClick={this.handleTableModalClose}><CloseIcon /></IconButton>
                    </div>
                    <hr />
                    <div id="modal-expanded_link_locations_table-body" style={{ padding: "10px" }}>
                      <Datatables data={this.props.cityTableData} columns={this.props.cityTableCols} options={{...this.props.tableOpts, tableBodyMaxHeight: "500px" }} />
                    </div>
                  </div>
                </Fade>
              </Modal>
            </>
          )
        }
      </MyPaper>
    )
  }
}

export default LinkLocations;