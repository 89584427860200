import {
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datatables from "mui-datatables";
import { Close, ThumbUp, Timer } from "@mui/icons-material";
import { addInfluencerToDB, getInfluencerData } from "../../services/influencer/Influencer";
import utils from "../../utils/utils";
import { snapshotHeaderIcons, textColor } from "../../components/campaignAnalysis/snapshot";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";

function ProfileAnalyze() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [backdropShow, setBackdropShow] = useState(true);
  const [data, setData] = useState([]);
  const [jobModal, setJobModal] = useState(false);
  const [fields, setFields] = useState({});
  const listOfPlatformsSupported = ["instagram", "facebook"];
  const dispatch = useDispatch();

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = async () => {
    const data = await getInfluencerData();
    setData(data);
    setIsLoaded(true);
    setBackdropShow(false);
  };

  const openJobModal = () => {
    setJobModal(true);
  };

  const closeJobModal = () => {
    setJobModal(false);
  };

  const handleChange = (field, value) => {
    const newFields = { ...fields, [field]: value };
    setFields(newFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setJobModal(false);
    setIsLoaded(false);
    const found = listOfPlatformsSupported.find((v) => fields.handle.includes(v));
    if (found) {
      const response = addInfluencerToDB(fields.handle, found, true);
      setBackdropShow(true);
      if (response) {
        //to check : remove setTimeout and check for realtime update
        setTimeout(() => {
          getTableData();
          dispatch(
            openSnackbar({
              message: "Profile Added Successfully",
              severity: "success",
            })
          );
        }, 1000);
      } else {
        dispatch(
          openSnackbar({
            message: "Error while adding the profile",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          message: "We support instagram and facebook only at the moment",
          severity: "info",
        })
      );
    }
  };

  let tableData = [];
  tableData = data?.map((row) => {
    const rowData = { ...row };
    rowData["profileDetails"] = {};
    rowData["profileDetails"]["name"] = rowData["name"];
    rowData["profileDetails"]["platform"] = rowData["platform"];
    if (rowData["profileDetails"]["name"] === "") {
      rowData["profileDetails"]["name"] = rowData["first_name"] + rowData["last_name"];
    }
    rowData["profileDetails"]["username"] = rowData["username"];
    rowData["profileDetails"]["profile_picture"] = rowData["url"];
    rowData["profileDetails"]["profile_url"] = rowData["profile_url"];
    return rowData;
  });

  const columns = [
    {
      label: "Social Handle",
      name: "profileDetails",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <div>
                    <img
                      alt="profile"
                      style={{
                        borderRadius: "50%",
                        maxHeight: "50px",
                        maxWidth: "50px",
                        marginRight: "15px",
                        border: "1px solid #d0d6dd",
                        overflow: "hidden",
                      }}
                      src={value["profile_picture"]}
                      onError={utils.addDefaultSrc}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <a href={value.profile_url} target="_blank" rel="noreferrer">
                    {value.name}
                  </a>
                  <br />@{value.username}
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={textColor[value["platform"]]} style={{ paddingTop: "10px" }}>
                    {snapshotHeaderIcons[value["platform"]]}
                  </Typography>
                </Grid>
              </Grid>
            </>
          );
        },
        filter: false,
        download: false,
      },
    },
    {
      label: "Total Followers",
      name: "total_followers",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <p style={{ paddingLeft: "30px" }}> {utils.convertToInternationalNumberSystem(value)}</p>
            </>
          );
        },
        filter: false,
        download: false,
      },
    },
    {
      label: "Interests",
      name: "tags",
      options: {
        customBodyRender: (value) => {
          var arrayOFTags = value.split(",");
          return (
            <Grid container spacing={1}>
              {arrayOFTags.length > 1 &&
                arrayOFTags.map((tag) => (
                  <Grid item key={tag}>
                    <Chip label={tag} variant="outlined" />
                  </Grid>
                ))}
            </Grid>
          );
        },
        filter: false,
        download: false,
      },
    },
    {
      label: "Added On",
      name: "createdAt",
      options: {
        customBodyRender: (value) => {
          return <span>{new Date(value).toDateString()}</span>;
        },
        filter: false,
        download: false,
      },
    },
    {
      label: "Status",
      name: "tags",
      options: {
        customBodyRender: (value) => {
          return (
            <Grid container>
              <Grid item xs={3}>
                {getIcon(value)}
              </Grid>
            </Grid>
          );
        },
        filter: false,
        download: false,
      },
    },
  ];

  const getIcon = (value) => {
    if (value === "") {
      return <Timer style={{ color: "#fada0c", width: "50%" }} />;
    } else {
      return <ThumbUp style={{ color: "#04cf55", width: "50%" }} />;
    }
  };
  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
  };

  return isLoaded ? (
    <>
      <Box m={3}>
        <Tabs value={0} style={{ width: "200px", float: "left" }} variant="scrollable" scrollButtons="auto">
          <Tab label="All Profiles" id="prev-jobs-tab-0" to={"/tools/sentiments"} component={Link} />
        </Tabs>
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" onClick={openJobModal}>
            + New Profile
          </Button>
        </Stack>
      </Box>
      <Datatables data={tableData} columns={columns} options={options} />
      <Modal
        className="styles_modal"
        aria-labelledby="modal-new_job-label"
        aria-describedby="modal-new_job-description"
        open={jobModal}
        onClose={closeJobModal}
        closeAfterTransition
      >
        <Fade in={jobModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-new_job-heading" className="styles_modal_heading">
              <h2 id="modal-new_job-heading-text" style={{ padding: "10px" }}>
                Analyize a profile
              </h2>
              <IconButton onClick={closeJobModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-add_brand_to_account-body" style={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Box>
                  <form id="addBrandForm" onSubmit={handleSubmit}>
                    <div>
                      <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <TextField
                            onChange={(event) => handleChange("handle", event.target.value)}
                            variant="outlined"
                            label="Social handle url"
                            required={true}
                            helperText="* Currently we support Instagram and Facebook urls only."
                          />
                        </FormControl>
                      </Grid>
                    </div>
                    <button
                      variant="contained"
                      className="btn btn-success float-right"
                      style={{
                        float: "right",
                        marginBottom: "15px",
                        marginRight: "20px",
                      }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
      open={backdropShow}
    >
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
}

export default ProfileAnalyze;
