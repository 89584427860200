import React from 'react'
import { Favorite } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { object } from 'prop-types';
import utils from "../../../../../../../../utils/utils";

const CountWithIcon = (props) => {
  const { icon, count, countText, helperText } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography fontSize="40px">
        {icon}
      </Typography>
      <Box ml="6px">
        <Typography component={"span"} fontSize={"18px"}>
          {utils.convertToInternationalNumberSystem(count, 1)}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography component={"span"} fontSize={"12px"} whiteSpace="nowrap">
            {countText}
          </Typography>
          {helperText &&
            <Tooltip title={helperText} sx={{ fontSize: '12px' }}>
              <IconButton>
                <HelpOutlineIcon fontSize='12px' />
              </IconButton>
            </Tooltip>
          }
        </Box>
      </Box>
    </Box>
  )
}

const InsightNumbers = (props) => {
  const { data } = props;
  const {
    avg_comments,
    avg_likes,
    avg_views,
    total_posts
  } = data;
  return (
    <>
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", columnGap: "18px" }}>
        <CountWithIcon icon={<Favorite fontSize='inherit' />} count={avg_likes} countText={"Avg Likes"} helperText="The average sum of likes on the last 30 posts" />
        <CountWithIcon icon={<ChatIcon fontSize='inherit' />} count={avg_comments} countText={"Avg Comments"} helperText="The average sum of comments on the last 30 posts" />
      </Box>
      <hr />
      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)", columnGap: "24px" }}>
        <CountWithIcon icon={<VisibilityIcon fontSize='inherit' />} count={avg_views} countText={"Avg Views"} helperText="The average sum of views on the last 30 posts" />
        <CountWithIcon icon={<PhotoSizeSelectActualIcon fontSize='inherit' />} count={total_posts} countText={"Total Posts"} />
      </Box>
    </>
  )
}

InsightNumbers.propTypes = {
  data: object.isRequired
}

export default InsightNumbers