const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name) => {
  if (typeof name !== "undefined" && name !== "") {
    const [firstName, lastName] = name.split(" ");

    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 56,
        height: 56,
        textAlign: "center"
      },
      children: `${firstName ? firstName[0].toUpperCase() : ""}`,
    };
  }

  return null;
};

export default stringAvatar;
