import { Cancel, Search } from "@mui/icons-material";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const SearchItem = ({ sendSearchedValue, searchBy }) => {
  const [search, setSearch] = React.useState("");
  const handleSearch = (event) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
    sendSearchedValue(value);
  };

  React.useEffect(() => {}, [search]);
  
  const clearInput = () => {
    setSearch("");
    sendSearchedValue("");
  };
  return (
    <Autocomplete
      size="small"
      freeSolo
      disableClearable
      options={[]}
      value={search}
      renderInput={(params) => (
        <TextField
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              minWidth: "19vw",
              marginRight: "5px",
              borderRadius: "10px",
              fontSize: '0.875rem',
            },
            "& .MuiInputLabel-root": {
              fontSize: '0.875rem', 
            }
          }}
          {...params}
          label={"Search by " + searchBy}
          value={search}
          placeholder={"Search by " + searchBy}
          onInput={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {search.length === 0 ? (
                  <Search htmlColor="#B5B5C3" />
                ) : (
                  <Cancel
                    fontSize="small"
                    htmlColor="#B5B5C3"
                    onClick={clearInput}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

// This means that wherever SearchItem component is used, it must receive a prop named sendSearchedValue, and it must be a function, 
// otherwise React will issue a warning in development mode.
SearchItem.propTypes = {
  sendSearchedValue: PropTypes.func.isRequired,
};

export default SearchItem;
