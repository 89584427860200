import React from 'react';

const LinkedinRepost = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="24"
    height="24"
    focusable="false"
  >
    <path d="M3 13v-3a9 9 0 0118 0v1h-2v-1a7 7 0 00-14 0v3h4l-5 5-5-5h4zm18-2v3a9 9 0 01-18 0v-1h2v1a7 7 0 0014 0v-3h-4l5-5 5 5h-4z" />
  </svg>
);

export default LinkedinRepost;
