import { TextField } from '@mui/material';
import { func } from 'prop-types';
import { usePlacesWidget } from 'react-google-autocomplete';
import { REACT_APP_GOOGLE_API_KEY } from "../../config/main.json"

const LocationInput = ({ placeSelectedHandler, ...props }) => {
    const { ref } = usePlacesWidget({
        apiKey: REACT_APP_GOOGLE_API_KEY,
        onPlaceSelected: placeSelectedHandler
    });

    return <TextField {...props} inputRef={ref} />;
};

LocationInput.propTypes = {
    placeSelectedHandler: func
};

export default LocationInput;
