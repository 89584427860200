import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import StyledMenu from "../StyledMenu";
import utils from '../../../utils/utils';

import {
  FormControl,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Chip,
  IconButton,
} from "@mui/material";
import { getBrandData } from "./service/fetchDataByFilter";
import { useDispatch, useSelector } from "react-redux";
import { FilterTooltips } from "./static/FiltersTooltips";
import AutocompleteSideMenu from "./common/AutocompleteSideMenu";

const Partnerships = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
  isChanged,
  setIsChanged,
}) => {
  const [allPartnerships, setAllPartnerships] = useState([]);
  const [isAny, setIsAny] = useState(false);
  const handleInfPartnershipselection = (selectedValues) => {
    if (!isAny) {
      let newPayload = payload;
      let latestLoc = selectedValues[selectedValues.length - 1]["name"];
      let latestId = selectedValues[selectedValues.length - 1]["id"];
      let filterSet = { id: latestId, name: latestLoc };
      allFiltersData = utils.addObj(allFiltersData, "inf_ads_brands");
      allFiltersData[0]["inf_ads_brands"].push(filterSet);
      if (
        latestLoc !== undefined &&
        !allFiltersData[0]["inf_ads_brands"].includes(latestLoc)
      ) {
        let partnerPayload = [];
        selectedValues.forEach((loc, index) => {
          partnerPayload.push(loc["id"]);
        });
        newPayload["inf_ads_brands"] = partnerPayload;
      }
      setAllFiltersData(allFiltersData);
      setPayload(newPayload);
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancleInfPartner = (selectedValues, name) => {
    let index = allFiltersData[0]["inf_ads_brands"]
      .map((item) => item.id)
      .indexOf(selectedValues);
    if (index !== -1) {
      const filteredInfPartner = allFiltersData[0]["inf_ads_brands"].filter(
        (_, idx) => idx !== index
      );
      let newPayload = payload;
      newPayload["inf_ads_brands"] = filteredInfPartner;
      setPayload(newPayload);
      let newFilters = allFiltersData;
      newFilters[0]["inf_ads_brands"] = filteredInfPartner;
      setAllFiltersData(newFilters);
      setIsChanged(!isChanged);
    }
  };

  const getPartnerData = async (searchKey) => {
    try {
      const response = await getBrandData(searchKey);
      if (response?.length > 0) {
        setAllPartnerships(response);
      } else setAllPartnerships([]);
    } catch (err) {
      console.log(err);
    }
  };

  const handlerAudBrand = (event) => {
    if (event.target.value !== "") {
      getPartnerData(event.target.value);
    }
  };

  const handleIsAny = (checkbox) => {
    setIsAny(checkbox);
    let newPayload = payload;
    newPayload["inf_ads_brands"] = [];
    setPayload(newPayload);
    let newFilters = allFiltersData;
    delete newFilters[0]["inf_ads_brands"];
    setAllFiltersData(newFilters);
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              disableClearable
              limitTags={0}
              id="filter-partnership"
              size="small"
              forcePopupIcon={false}
              popupIcon={""}
              options={allPartnerships}
              getOptionLabel={(option) => option.name}
              value={allFiltersData[0]["inf_ads_brands"]}
              onChange={(_e, v) => handleInfPartnershipselection(v)}
              renderTags={() => null}
              // onKeyPress={(e) => handlerAudBrand(e)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Partnerships"
                  placeholder="Partnerships"
                  variant="outlined"
                  onChange={handlerAudBrand}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: allFiltersData[0]["inf_ads_brands"] !==
                      undefined &&
                      Object.keys(allFiltersData[0]["inf_ads_brands"])?.length >
                        0 && (
                        <AutocompleteSideMenu
                          options={Object.keys(
                            allFiltersData[0]["inf_ads_brands"]
                          ).map((index) => {
                            let item =
                              allFiltersData[0]["inf_ads_brands"][index];
                            return (
                              <Grid
                                container
                                id={item.name}
                                spacing={1}
                                style={{ marginTop: "10px" }}
                              >
                                <Grid item>
                                  <Chip
                                    key={item.id}
                                    icon={
                                      <IconButton
                                        onClick={() =>
                                          handleCancleInfPartner(
                                            item.id,
                                            item.name
                                          )
                                        }
                                      >
                                        <CloseRoundedIcon
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </IconButton>
                                    }
                                    label={item.name}
                                    style={{
                                      backgroundColor: "white",
                                      marginBottom: "10px",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                        />
                      ),
                  }}
                />
              )}
              style={{
                borderRadius: ".775rem",
              }}
            />
            {/* <div id="infPartnerTags">
              {allFiltersData[0]["inf_ads_brands"] !== undefined && Object.keys(allFiltersData[0]["inf_ads_brands"]).map((index) => {
                let item = allFiltersData[0]["inf_ads_brands"][index]
                return (
                  <Grid container id={item.name} spacing={1} style={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={6}>
                      <Chip
                        key={item.id}
                        icon={
                          <IconButton onClick={() => handleCancleInfPartner(item.id, item.name)}>
                            <CloseRoundedIcon style={{ width: "20px", height: "20px" }} />
                          </IconButton>
                        }
                        label={item.name}
                        style={{ backgroundColor: "white", marginRight: "10px", marginBottom: "10px" }}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </div> */}
          </FormControl>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl sx={{ m: 1 }}>Influencer</FormControl>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip title={FilterTooltips.parntership}>
                  <HelpRoundedIcon fontSize="small" style={{ opacity: 0.3 }} />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl sx={{ m: 1 }}>
                  <Autocomplete
                    multiple
                    disableClearable
                    limitTags={0}
                    id="filter-partnership"
                    size="small"
                    popupIcon={""}
                    options={allPartnerships}
                    getOptionLabel={(option) => option.name}
                    value={allFiltersData[0]["inf_ads_brands"]}
                    onChange={(_e, v) => handleInfPartnershipselection(v)}
                    renderTags={() => null}
                    // onKeyPress={(e) => handlerAudBrand(e)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Partnerships"
                        placeholder="Partnerships"
                        variant="outlined"
                        onChange={handlerAudBrand}
                      />
                    )}
                    style={{
                      minWidth: 200,
                      borderRadius: ".775rem",
                    }}
                  />
                  <div id="infPartnerTags">
                    {allFiltersData[0]["inf_ads_brands"] !== undefined &&
                      Object.keys(allFiltersData[0]["inf_ads_brands"]).map(
                        (index) => {
                          let item = allFiltersData[0]["inf_ads_brands"][index];
                          return (
                            <Grid
                              container
                              id={item.name}
                              spacing={1}
                              style={{ marginTop: "10px" }}
                            >
                              <Grid item xs={12} sm={6}>
                                <Chip
                                  key={item.id}
                                  icon={
                                    <IconButton
                                      onClick={() =>
                                        handleCancleInfPartner(
                                          item.id,
                                          item.name
                                        )
                                      }
                                    >
                                      <CloseRoundedIcon
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                    </IconButton>
                                  }
                                  label={item.name}
                                  style={{
                                    backgroundColor: "white",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl sx={{ m: 1 }}>
                  <FormControlLabel
                    control={<Checkbox false />}
                    label="Any"
                    onChange={(_e, v) => handleIsAny(v)}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  title={
                    "Refine search results with only influencers with at least one sponsored post for any brand."
                  }
                >
                  <HelpRoundedIcon fontSize="small" style={{ opacity: 0.3 }} />
                </Tooltip>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Partnerships.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Partnerships;
