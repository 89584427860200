import { CircularProgress, Grid, Typography, Box, Modal, Fade, IconButton, Button } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";

import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import { PROFILE_IMG } from "../../config/main.json";

import ListTable from "./ListTable";
import ListWidgetBar from "./ListWidgetBar";
import { getListInfluencers } from "../../services/list/ListInfluencerMap";
import InviteByEmail from "../influencerInvite/InviteByEmail";
import SearchInfluencers from "./SearchInfluencers";
import moment from "moment";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EmailIcon from "@mui/icons-material/Email";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SearchItem from "../../pages/account/SearchItem";

const ListDetails = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const platform = params.get("platform");
  const listId = params.get("id");
  const listName = params.get("name");
  const campaignId = params.get("cid");
  const campaignName = params.get("cname");
  const companyName = params.get("aname");
  const [influencerAccount, setInfluencerAccount] = useState([]);
  const [total, setTotal] = useState(0);
  const [filtered, setFiltered] = useState(0);
  const [engagement, setEngagement] = useState(0);
  const [reach, setReach] = useState(0);
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [isNull, setIsNull] = useState(false);
  const [removeInfluencer, setRemoveInfluencer] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [filter, setFilter] = useState("");
  const [reload, setReload] = useState(false);
  const [searchBy] = useState("Name and Username");

  useEffect(() => {
    getInfluencersData(true, 0, 10, "name", "asc", "");
  }, []);

  const modalHandler = (type) => {
    setModalType(type);
    setIsModalOpen(!isModalOpen);
  };

  const callbackHandler = () => {
    setLoading(true);
    setIsNull(false);
    getInfluencersData(true, 0, 10, "name", "asc", "");
    modalHandler();
  };

  const getInfluencersData = (refresh, page, rows, sortCol, sortOrder, filter) => {
    getListInfluencers(+listId, platform, refresh, page, rows, sortCol, sortOrder, filter).then((response) => {
      setLoading(false);
      if (response.total > 0) {
        setTotal(response.total);
        setFiltered(response.filtered);
        setEngagement(response.engagement);
        setReach(response.reach);
        setLocation(response.location);
        createInfluencerList(response.rows ? response.rows : []);
      } else {
        setIsNull(true);
      }
    });
  };

  const setInfluencerList = (influencerList) => {
    setInfluencerAccount(influencerList);
    setReload(true);
  };

  const createInfluencerList = (influencerArray) => {
    const dataArray = [];
    let profileUrl = "";
    influencerArray.forEach((influencer) => {
      if (influencer.name !== "" || influencer.email !== "" || influencer.username !== "") {
        if (influencer.profile_url != "") {
          profileUrl = influencer.profile_url;
        }
        const id = influencer.iid;
        const influencerObject = {
          id: id,
          name: influencer.name ? influencer.name : influencer.email,
          username: influencer.username ? influencer.username : "",
          followers: influencer.total_followers ? influencer.total_followers : "0",
          engagement: influencer.engagement ? influencer.engagement : 0,
          engagementrate: influencer.engagementRate ? influencer.engagementRate : "0",
          location: influencer.location ? influencer.location : "0",
          score: influencer.score ? influencer.score : "0",
          photoUrl: influencer.photo_url && influencer.photo_url?.length > 0 ? influencer.photo_url : PROFILE_IMG,
          type: influencer.onboared_type ? influencer.onboared_type : "direct",
          isLeader: influencer.isLeader ? influencer.isLeader : false,
          age_range: influencer?.age_range || "",
          gender: influencer?.gender || "",
          note: influencer?.note,
          profile_url: profileUrl,
          email: influencer.email ? influencer.email : "",
          phone: influencer.phone ? influencer.phone : "",
          certificateLink: influencer.certificateLink ? influencer.certificateLink : "",
          status: influencer.status ? influencer.status : 0,
          addedOn: moment(influencer.createdAt, "YYYY-MM-DD h:mm:ss").format("MMM DD YYYY"),
        };
        dataArray.push(influencerObject);
      }
    });
    setLoading(false);
    setInfluencerAccount(dataArray);
  };

  const removeInfluencers = () => {
    setRemoveInfluencer(true);
  };

  const influencerRemoved = () => {
    setRemoveInfluencer(false);
  };

  const sendSearchedValue = (value) => {
    setFilter(value);
  };

  return loading ? (
    <>
      <CircularProgress color="success" className={styles.loaderElement} />
    </>
  ) : (
    <>
        <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12} md={7}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <h3 className={styles.heading}>
            {campaignName?.length > 30
              ? campaignName.substring(0, 30) + '...'
              : campaignName || <span style={{ color: 'red' }}>No Campaign found</span>}
            &nbsp;/&nbsp;&nbsp;
          </h3>
          <Typography sx={textColor[platform?.toLowerCase()]}>
            {snapshotHeaderIcons[platform?.toLowerCase()]}
          </Typography>
          &nbsp;&nbsp;
          <h3 className={styles.heading}>{listName}</h3>
        </Box>
      </Grid>
      <Grid item xs={12} md={5} container spacing={1} justifyContent={{ xs: 'center', md: 'flex-end' }}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => modalHandler('inviteByEmail')}
            startIcon={<EmailIcon />}
            style={{ width: '100%' }}
          >
            Invite Influencers (email)
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => modalHandler('discovery')}
            startIcon={<PersonAddAltIcon />}
            style={{ width: '100%' }}
          >
            Add Influencers
          </Button>
        </Grid>
      </Grid>
    </Grid>
      <br />
      {isNull === true ? (
        <>
          <h5>This list has no influencers at the moment</h5>
        </>
      ) : (
        <>
          <ListWidgetBar total={total} engagement={engagement} reach={reach} location={location} />
          <br />
          <Grid container>
            <Grid item xs={3}>
              <SearchItem sendSearchedValue={sendSearchedValue} searchBy={searchBy} />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                type="submit"
                size="small"
                sx={{
                  color: "white",
                  backgroundColor: disableBtn ? "#fff" : "#51A6FF",
                }}
                className={styles.button}
                onClick={removeInfluencers}
                disabled={disableBtn}
                startIcon={<PersonRemoveIcon />}
              >
                Remove Influencers
              </Button>
            </Grid>
          </Grid>
          <br />
          <ListTable
            influencerAccount={influencerAccount}
            platform={platform}
            listId={listId}
            setInfluencerList={setInfluencerList}
            removeInfluencer={removeInfluencer}
            influencerRemoved={influencerRemoved}
            disableRemoveBtn={setDisableBtn}
            total={filtered}
            getInfluencersData={getInfluencersData}
            filter={filter}
            reload={reload}
            setReload={setReload}
          />
          <br />
          <h6 className={styles.searchHeading}>Note: "*" symbolizes that it's a direct influencer</h6>
        </>
      )}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-add-inf-to-list-label"
        aria-describedby="modal-add-brand-to-account-description"
        open={isModalOpen}
        onClose={modalHandler}
        id
        closeAfterTransition
      >
        <Fade in={isModalOpen}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-add-inf-to-list-heading" className="styles_modal_heading">
              <h2 id="modal-add-inf-to-list-heading-text" style={{ paddingBlock: "5px", paddingInline: "10px" }}>
                {modalType === "inviteByEmail" ? "Invite Influencer" : "Add Influencer"}
              </h2>
              <IconButton onClick={modalHandler}>
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            {modalType === "inviteByEmail" ? (
              <Box sx={{ p: "1rem" }}>
                <InviteByEmail
                  setNavigateBack={null}
                  presetData={{
                    campaignId,
                    campaignName,
                    listId,
                    listName,
                    companyName,
                    platform,
                  }}
                  callbackHandler={callbackHandler}
                />
              </Box>
            ) : (
              <SearchInfluencers
                listInfo={{
                  campaignId,
                  campaignName,
                  listId,
                  listName,
                  companyName,
                  platform,
                  alreadyIncludedInfluencers: influencerAccount,
                }}
                callbackHandler={callbackHandler}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ListDetails;
