// AddBrand is used to create a brand from several components too
// it is called from NesBrands.js of "create brand" page
// if this.prpos.type = Campaign, it means it is bening called from "add Campaign" page or "edit Campaign" page

import React, { Component } from "react";
import { API_HOST } from "../../../config/main.json";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Autocomplete,
  OutlinedInput,
  Snackbar,
  Alert,
} from "@mui/material";
import { BusinessCenterTwoTone } from "@mui/icons-material";
import MyPaper from "../../general/MyPaper";
import axios from "axios";
import utils from "../../../utils/utils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

let backdropShow = false;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class AddBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      backdropShow: backdropShow,
      attributesList: [],
      primaryList: [],
      secondaryList: [],
      accountData: [],
      notification: true,
      message: "",
      type: "",
    };
  }

  getPrimaryList() {
    utils.getPcatData().then((data) => {
      const primaryList = data;
      this.setState({ primaryList });
    });
  }

  getSecondaryList() {
    utils.getScatData().then((data) => {
      const secondaryList = data;
      this.setState({ secondaryList });
    });
  }

  loadData = () => {
    this.getAttributesList();
    this.getPrimaryList();
    this.getSecondaryList();
    this.getAccountData();
    this.setState({ backdropShow: false });
  };

  getAttributesList() {
    utils.getAttrData().then((data) => {
      const attributesList = data;
      this.setState({ attributesList });
    });
  }

  componentDidMount() {
    this.loadData();
    this.setState({
      fields: {
        accountName: this.props.accountName,
        id: this.props.accountId,
        brandName: this.props.brandName || "",
      },
    });
  }

  postData(data) {
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const { attributes, brandName, primaryCategory, secondaryCategory, id } = data;

    //brand addition payload
    const brandPayload = {
      attributes,
      name: brandName,
      primaryCat: primaryCategory,
      secondaryCat: secondaryCategory,
      addedFrom: "oryx",
      id,
    };
    this.setState({ backdropShow: true });

    //after account creation , create brand
    axios.post(`${API_HOST}/brand/add`, brandPayload, optionAxios).then(() => {
      this.setState({
        notification: true,
        message: "Brand created successfully",
        type: "success",
      });

      if (this.props.type === "Campaign") {
        this.setState({ backdropShow: false });
        this.props.saveTheAddedBrand();
      } else {
        // No redirection to accounts on successful response
        setTimeout(() => {
          this.setState({ backdropShow: false });
          this.props.saveTheAddedBrand();
        }, 3000);
      }
    });
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  postAccount(e) {
    backdropShow = true;
    this.setState({ backdropShow });
    e.preventDefault();
    var body = this.state.fields;
    this.postData(body);
  }

  handleChangeAuto(field, e, values) {
    let fields = this.state.fields;
    fields[field] = "";
    if (values !== null) {
      if (values.length > 0) {
        var data = [];
        values.forEach(function (item, index) {
          data.push(item["id"]);
        });
        fields[field] = data.join(",");
      } else if (typeof values["id"] !== "undefined") {
        fields[field] = values["id"];
      }
    }
    this.setState({ fields });
  }

  closeNotification = () => {
    this.setState({ notification: false });
  };

  getAccountData = () => {
    axios.get(`${API_HOST}/account/list/type/1/all`).then((res) => {
      let accountData = [];
      if (res.data) {
        accountData = res.data;
      }
      this.setState({ accountData });
    });
  };

  handleChangeAccount(values, field) {
    let fields = this.state.fields;
    fields[field] = undefined;
    fields["accountName"] = "";
    if (values !== null) {
      fields[field] = values.id;
      fields["accountName"] = values.name;
    }
  }

  render() {

    const { accountName, accountId, type } = this.props;

    return (
      <>
        <Snackbar
          open={this.state.notification}
          autoHideDuration={3000}
          onClose={this.closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={this.state.type} variant="filled" sx={{ width: "100%" }}>
            {this.state.message}
          </Alert>
        </Snackbar>
        <form onSubmit={this.postAccount.bind(this)}>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              flexDirection: "column",
            }}
            open={this.state.backdropShow}
          >
            <CircularProgress color="inherit" />
            <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
          </Backdrop>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MyPaper>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }}>
                      <BusinessCenterTwoTone sx={{ mr: 2 }} />
                      <Typography variant="h5">Brand Details</Typography>
                    </Box>
                    <Divider />
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      {type == "Campaign" ? (
                         <>
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              options={this.state.accountData}
                              getOptionLabel={(cname) => cname.name || this.state.fields["accountName"]}
                              value={String(this.state.fields["accountName"])}
                              onChange={(event, value) => this.handleChangeAccount(value, "id")}
                              refs="companyId"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Account Name"
                                  placeholder="Choose account for campaign"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <TextField
                              variant="outlined"
                              value={accountName}
                              disabled
                              label="Account Name"
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            variant="outlined"
                            required={true}
                            type="text"
                            id="brandName"
                            refs="brandName"
                            name="brandName"
                            label="Brand Name"
                            value={this.state.fields["brandName"]}
                            onChange={this.handleChange.bind(this, "brandName")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            id="checkboxes-tags-demo"
                            options={this.state.attributesList}
                            onChange={this.handleChangeAuto.bind(this, "attributes")}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                                {option.name}
                              </li>
                            )}
                            renderInput={(params) => <TextField {...params} label="Brand Attributes" />}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                        <FormControl fullWidth>
                          <InputLabel id="primary-category-label">Brand Primary Category *</InputLabel>
                          <Select
                            required={true}
                            label={"Brand Primary Category *"}
                            id="primaryCategory"
                            onChange={this.handleChange.bind(this, "primaryCategory")}
                            input={<OutlinedInput label={"Brand Primary Category *"} id="demo-dialog-native" />}
                          >
                            {this.state.primaryList.map((primaryCategory) => (
                              <MenuItem value={primaryCategory.id} key={primaryCategory.id}>
                                {primaryCategory.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="secondary-category-label">Brand Secondary Category *</InputLabel>
                          <Select
                            required={true}
                            label={"Brand Secondary Category *"}
                            id="secondaryCategory"
                            onChange={this.handleChange.bind(this, "secondaryCategory")}
                            input={<OutlinedInput label={"Brand Secondary Category *"} id="demo-dialog-native" />}
                          >
                            {this.state.secondaryList.map((secondaryCategory) => (
                              <MenuItem value={secondaryCategory.id} key={secondaryCategory.id}>
                                {secondaryCategory.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Divider />
              </MyPaper>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Box sx={{ mt: 4 }}>
                <Button variant="contained" size="large" type="submit">
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

export default AddBrand;
