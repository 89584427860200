import { Box } from '@mui/material';
import { node } from 'prop-types';

const RandomPicBanner = ({ children }) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      position: 'relative',
      background: 'black'
    }}
  >
    <Box
      sx={{
        backgroundImage: 'url(https://source.unsplash.com/featured/?people)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: (theme) => theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        opacity: 0.5
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
      }}
    >
      {children}
    </Box>
  </Box>
);

RandomPicBanner.propTypes = {
  children: node
};

export default RandomPicBanner;
