import React, { useState } from "react";
import { Avatar, Button } from "@mui/material";
import { ModeCommentOutlined, ThumbUpOutlined } from "@mui/icons-material";
import { object, number } from "prop-types";
import Carousel from "react-material-ui-carousel";
import stringAvatar from "../../utils/avatar";
import YouTubeShare from "../../styles/icons/youtubeshare";
import PostActionButton from "../../pages/account/posts/PostActionButton";
import { GetPostText, RenderCardMedia } from "../../utils/mediacontent";
import { renderData } from "../../utils/platform-asset-calculator";
import statusMapping from "../../models/notification";
import { format } from "date-fns";

const showIcon = (platform) => {
  switch (platform) {
    case "facebook":
      return <i className="fab fa-facebook" />;
    case "twitter":
      return <i className="fab fa-twitter" />;
    case "instagram":
      return <i className="fab fa-instagram" />;
    default:
      return null;
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "MMMM d, yyyy");
};

const FacebookPost = ({ formData, cid }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const renderContent = () => {
    if (isExpanded || formData.postContent.length <= 100) {
      return (
        <p
          style={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          {renderData(formData.postContent, formData.preview)}
        </p>
      );
    } else {
      return (
        <>
          <p>
            {renderData(formData.postContent.slice(0, 100), formData.preview)}
            ...{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleReadMore}
            >
              Read more
            </span>
          </p>
        </>
      );
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
        <Avatar
          src={formData.avatarUrl}
          alt={formData.influencerName}
          style={{ width: 40, height: 40 }}
        />
        <div style={{ marginLeft: "10px" }}>
          <span style={{ fontWeight: "bold" }}>
            {formData.influencerName}
          </span>
          <div style={{ color: "gray", fontSize: "12px" }}>
            {formatDate(formData.createdAt)}
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button>...</Button>
        </div>
      </div>
      <div style={{ padding: "10px" }}>{renderContent()}</div>
      <div>
        <Carousel fullHeightHover={false} style={{ marginBottom: "10px" }}>
          {formData?.Attachments?.map((attachment) => (
            <img
              key={attachment.id}
              src={attachment.url}
              alt="Post content"
              style={{ width: "100%", display: "block", borderRadius: "0px" }}
            />
          ))}
        </Carousel>
      </div>
      {formData.preview && (
        <Button
          style={{
            backgroundColor: statusMapping.filter(
              (value) => value.id === formData.currentStatus
            )[0]?.color,
            width: "100%",
            fontSize: "12px",
            borderRadius: "10px",
          }}
          variant="contained"
        >
          {GetPostText(formData)}
        </Button>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #e0e0e0",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span role="img" aria-label="like" style={{ marginRight: "5px" }}>
            👍❤️😮
          </span>
        </div>
        <div style={{ marginLeft: "auto", color: "gray", fontSize: "12px" }}>
          2 Comments 14.6k Shares
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderTop: "1px solid #e0e0e0",
          padding: "10px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <ThumbUpOutlined />
          <span style={{ marginLeft: "5px" }}>Like</span>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <ModeCommentOutlined />
          <span style={{ marginLeft: "5px" }}>Comment</span>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <YouTubeShare />
          <span style={{ marginLeft: "5px" }}>Share</span>
        </div>
      </div>
    </>
  );
};

FacebookPost.propTypes = {
  formData: object.isRequired,
  cid: number.isRequired,
};

export default FacebookPost;
