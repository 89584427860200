import { Auth } from '@aws-amplify/auth';
import { Box } from '@mui/material';
import { node } from 'prop-types';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/features/user/userSlice';

const Logout = ({ children }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    try {
      Auth.signOut();
      dispatch(logout());
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <Box onClick={handleLogout}>
      {children}
    </Box>
  );
};

Logout.propTypes = {
  children: node
};

export default Logout;
