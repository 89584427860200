import React, { useState, useEffect, useRef } from 'react';
import { Stack, Button } from "@mui/material";
import { CircularProgress, Box, Tab, Tabs, Typography, FormControl, Tooltip, Snackbar, Grid } from "@mui/material";
import {
  AddCircle,
  Analytics,
  Archive,
  Article,
  Assessment,
  Circle,
  Delete,
  Edit,
  MoreVertOutlined,
  Pause,
  PlayArrow,
} from "@mui/icons-material";
import { getTimeZone } from "./../../utils/exportData";
import { getAccountCampaignList } from "../../services/campaign/CampaignDetails";
import { getAccountCampignsTableHeaders } from '../../utils/tableHeaders';
import { convertToUserTimezone, getFormattedDate } from "../../utils/momentManipulations";
import { campaignStatusColors, snapshotHeaderIcons, textColor, statusToNum } from "../../components/campaignAnalysis/snapshot";
import CustomTable from "../../common/CustomTable/CustomTable";
import Loader from '../../pages/account/Loader';
import MyPaper from '../general/MyPaper';


const AccountCampigns = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [account, setAccount] = useState(null);
  const [type] = useState(1);
  const [isExport] = useState(0);
  const [tablePageNo] = useState(0);
  const [tableRowsPerPage] = useState(5);
  const [sortCol, setSortCol] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filter] = useState("");
  const [mimeType] = useState("csv");
  const [campaignPlatform] = useState("oryx");
  const [selectedStatus] = useState("");
  const [campaignData, setCampaignData] = useState([]);
  const [dataFound, setDataFound] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const timeZone = getTimeZone();


  const fetchData = () => {
    setIsLoaded(() => false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const account = Number(params.get("id"));
    setAccount(account)
    getCampaignData(
      account,
      type,
      isExport,
      tablePageNo,
      tableRowsPerPage,
      sortCol,
      sortOrder,
      filter,
      mimeType,
      campaignPlatform,
      timeZone,
      selectedStatus
    );
  };

  useEffect(() => {
    fetchData();
  }, [
    account,
    sortOrder,
  ]);

  const getCampaignData = async (
    account,
    type,
    isExport,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    filter,
    mimeType,
    campaignPlatform,
    timeZone,
    selectedStatus
  ) => {
    setIsLoaded(false);
    const res = await getAccountCampaignList(
      account,
      type,
      isExport,
      tablePageNo + 1,
      tableRowsPerPage,
      sortCol,
      sortOrder,
      filter,
      mimeType,
      campaignPlatform,
      timeZone,
      selectedStatus
    );

    const { total_records, campaignList, active, inactive, deleted } = res;


    if (total_records > 0 && campaignList) {
      setCampaignData(campaignList);
      setDataFound(true);
      setTotalRecords(total_records);
    } else {
      setDataFound(false);
    }

    setIsLoaded(true);
  };

  const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
    setIsChanged(!isChanged);
    setSortCol(newPage.orderBy);
    setSortOrder(newPage.order);
  };

  useEffect(() => {
    const data = [];
    if (campaignData) {
      campaignData.forEach((item, index) => {
        const {
          id,
          universal,
          name,
          companyId,
          companyName,
          platform,
          postCount,
          weekEngg,
          influencerCount,
          daysDiff,
          endDate,
          current_status,
          listCount,
          startDate,
          brandName,
          gdriveId,
          slackId,
        } = item;
        const formattedEndDate = daysDiff > 0 ? `${daysDiff} Days` : <p style={{ color: "#f08995" }}>Finished</p>;
        const formattedStartDate = getFormattedDate(startDate, "MMMM Do YYYY");
        const endDateText =
          daysDiff > 0
            ? `${getFormattedDate(convertToUserTimezone(endDate), "MMMM Do YYYY")}`
            : `${getFormattedDate(endDate, "MMMM Do YYYY")}`;

        const circleColor = campaignStatusColors[current_status];
        const status = (
          <Grid container alignItems="center" justifyContent="flex-start" spacing={1}>
            <Grid>
              <Circle htmlColor={circleColor} sx={{ width: "20px", height: "20px" }} />
            </Grid>
            <Grid sx={{ marginLeft: 0.5 }}>{current_status}</Grid>
            {/* </Box> */}
          </Grid>
        );


        const platformIcons = platform?.map((plat) => (
          <Grid item xs={1} md={1}>
            <Typography sx={{ ...textColor[plat.name.toLowerCase()] }}>
              {React.cloneElement(snapshotHeaderIcons[plat.name.toLowerCase()], { style: { fontSize: 'calc(1.3rem + 0.1vw' } })}
            </Typography>
          </Grid>
        ));

        const object = {
          id: index + 1,
          name: (
            <>
              <Grid container spacing={1} sx={{ justifyContent: "flex-start" }}>
                <Grid item md={8} sm={5}>
                  <Box
                    component={"a"}
                    sx={{
                      cursor: "pointer",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 800,
                      fontSize: "1.1rem",
                      lineHeight: "1rem",
                      color: "#1461cc",
                    }}
                    href={`../campaigns/analysis?id=${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {name}
                  </Box>
                </Grid>
                <Grid item md={4} sm={2} sx={{ marginBlock: "auto" }}>
                  <Grid container spacing={3}>
                    {platformIcons}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ),
          endDate: (
            <Grid container>
              <Grid item xs={12} style={{ fontSize: "0.95em", fontStyle: "italic" }}>
                {endDateText}
              </Grid>
            </Grid>
          ),
          creatorAccounts: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingLeft: "20px" }}>
                {influencerCount}
              </Grid>
            </Grid>
          ),
          totalPublications: (
            <Grid container sx={{ width: "70%" }}>
              <Grid item xs={12} sx={{ paddingTop: "10px", paddingBottom: "10PX", backgroundColor: "#E0FFFF", textAlign: "center", color: "#26C0E2", borderRadius: "10px" }}>
                {postCount}
              </Grid>
            </Grid>
          ),
          current_status: status,
        };

        data.push(object);
      });
      setTableData(data);
    }
  }, [campaignData]);

  const tableHeaders = getAccountCampignsTableHeaders;

  const renderContentHandler = () => {
    let content = null;
    if (isLoaded) {
      if (dataFound) {
        content = (
          <>
            <div id="table">
              {/* This is a custom made component, take care while changing field names */}
              <CustomTable
                tableHeaders={tableHeaders}
                bodyColumns={tableData}
                sendInformation={handleInformationChange}
                sortOrder={sortOrder}
                sortOrderBy={sortCol}
                Pagination={false}
              />
            </div>
          </>
        );
      } else {
        content = (
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <p style={{ padding: "40px", textAlign: "center" }}> You don't have any campaigns at the moment.</p>
            </Grid>
          </Grid>
        );
      }
    } else content = <Loader />;

    return content;
  };

  return (
    <>
      <MyPaper sx={{ marginTop: "20px" }}>
        <Stack direction="row" justifyContent="start">
          {/* <Typography variant="h4" sx={props.parentStyle.typography}> */}
          <Typography variant="h3">
            Recent Campaigns
          </Typography>
        </Stack>

        <Box my={1}>{renderContentHandler()}</Box>

        {totalRecords > 5 && (
          <div style={{ textAlign: "center" }}>
            <a href={`/campaigns?accId=${account}`} style={{ textDecoration: "none" }}>
              <button
                style={{ width: "160px", height: "40px", color: "#757575", backgroundColor: "#FFFFFF", border: "1px solid #474747", borderRadius: "10px", fontSize: "20px", fontWeight: "bold" }}
              > View All </button>
            </a>
          </div>
        )}
      </MyPaper>
    </>
  )
}

export default AccountCampigns;