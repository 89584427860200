import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import utils from "../../utils/utils";
import { API_HOST } from "../../config/main.json";
import Datatables from "mui-datatables";
import { Analytics, Close, Delete, Edit, ManageAccounts, PersonAdd, Preview } from "@mui/icons-material";
import { getAllLists } from "../../services/list/ListDetails";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import CampaignActions from "../../pages/account/CampaignActions";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

function LinkManageUrls() {
  const [campId, setCampId] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [urlsData, setUrlsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalURLId, setModalURLId] = useState(0);
  const [modalPlatform, setModalPlatform] = useState("all");
  const [modalList, setModalList] = useState("");
  const [modalURLText, setModalURLText] = useState("");
  const [modalURL, setModalURL] = useState("");
  const [modalForAll, setModalForAll] = useState(false);
  const [platformData, setPlatformData] = useState([]);
  const [listData, setListData] = useState([]);
  const [openPrevModal, setOpenPrevModal] = useState(false);
  const [shortUrlsData, setShortUrlsData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteUrlId, setDeleteUrlId] = useState(0);
  const [deleteUrl, setDeleteUrl] = useState("");
  const [groupName, setGroupName] = useState("");
  const [tabArray, setTabArray] = useState([]);
  const dispatch = useDispatch();

  const getCampaignInfo = (campId) => {
    utils.getCampaignData(campId).then((data) => {
      setCampaignName(data.name);
    });
  };

  const getCampaignUrls = (campId) => {
    axios.get(API_HOST + "/link/campaignUrls/" + campId).then((res) => {
      let urlsData = [];
      if (res.data) {
        urlsData = res.data;
      }
      setUrlsData(urlsData);
    });
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    const TbArry = campaignTabsArray(campId);

    setCampId(campId);
    setTabArray(TbArry)
    getCampaignInfo(campId);
    getCampaignUrls(campId);
  }, []);

  const getAllListsFunc = async (platform) => {
    const data = await getAllLists(campId, platform);
    setListData(data ? data : []);
    setModalList("");
  };

  const handleOpenAddModal = () => {
    setOpenModal(true);
    setModalType("add");
    setModalPlatform("all");
    setModalList("");
    setModalURLText("");
    setModalURL("");
    setModalForAll(false);

    utils.getPlatforms().then((data) => {
      const platformData = data;
      setPlatformData(platformData);
    });

    getAllListsFunc("all");
  };

  const handleOpenEditModal = (id, lid) => {
    for (let i = 0; i < urlsData.length; i++) {
      if (urlsData[i].id === id) {
        setModalURLId(id);
        setModalList(lid);
        setModalURLText(urlsData[i].urlText);
        setModalURL(urlsData[i].url);
        setModalForAll(urlsData[i].forAll);
        break;
      }
    }

    setOpenModal(true);
    setModalType("edit");
  };

  const closeModal = () => {
    setOpenModal(false);
    setOpenPrevModal(false);
    setOpenDeleteModal(false);
  };

  const handleModalPlatformChange = (event) => {
    const platform = event.target.value;
    setModalPlatform(platform);
    getAllListsFunc(platform);
  };

  const handleModalListChange = (event, listData) => {
    const selectedLid = event.target.value;
    const selectedList = listData.find((list) => list.id === selectedLid);
    setGroupName(selectedList["name"]);
    setModalList(selectedLid);
  };

  const handleModalURLTextChange = (event) => {
    const urlText = event.target.value;
    setModalURLText(urlText);
  };

  const handleModalURLChange = (event) => {
    const url = event.target.value;
    setModalURL(url);
  };

  const handleModalForAllChange = (event) => {
    setModalForAll(event.target.checked);
  };

  const isUrl = (string) => {
    try {
      return Boolean(new URL(string));
    } catch (e) {
      return false;
    }
  };

  const handleModalSubmit = (event) => {
    event.preventDefault();
    dispatch(
      openSnackbar({
        message: "Please wait... We are adding the destination url",
        severity: "info",
      })
    );
    if (!isUrl(modalURL)) {
      dispatch(
        openSnackbar({
          message: "Invalid URL",
          severity: "error",
        })
      );
      return;
    }
    if (modalType === "add") {
      const data = {
        lid: modalList,
        urlText: modalURLText.trim(),
        url: modalURL.trim(),
        forAll: modalForAll,
        group: groupName,
      };
      axios.post(API_HOST + "/link/list/addUrl", data, optionAxios).then((res) => {
        dispatch(
          openSnackbar({
            message: `${modalURL.trim()} added successfully`,
            severity: "success",
          })
        );
        setOpenModal(false);
        getCampaignUrls(campId);
      });
    } else if (modalType === "edit") {
      const data = {
        id: modalURLId,
        lid: modalList,
        urlText: modalURLText.trim(),
        url: modalURL.trim(),
        forAll: modalForAll,
        group: groupName,
      };

      axios.post(API_HOST + "/link/list/updateUrl", data, optionAxios).then((res) => {
        dispatch(
          openSnackbar({
            message: `${modalURL.trim()} edited successfully`,
            severity: "success",
          })
        );
        setOpenModal(false);
        getCampaignUrls(campId);
      });
    }
  };

  const handleShortURLs = (urlId) => {
    axios.get(API_HOST + "/link/listurl/shortlinks/" + urlId).then((res) => {
      let shortUrlsData = res.data ? res.data : [];
      shortUrlsData = shortUrlsData.map((row) => {
        return {
          ...row,
          influencerNameAndUsername: `${row.influencerName} ${row.userName ? "(@" + row.userName + ")" : ""}`,
        };
      });
      setShortUrlsData(shortUrlsData);
      setOpenPrevModal(true);
    });
  };

  const handleOpenDeleteModal = (urlId, url) => {
    setDeleteUrlId(urlId);
    setDeleteUrl(url);
    setOpenDeleteModal(true);
  };

  const handleDeleteURL = () => {
    dispatch(
      openSnackbar({
        message: "Please wait.. we are deleting the url",
        severity: "info",
      })
    );
    axios.get(API_HOST + "/link/listurl/remove/" + deleteUrlId).then((res) => {
      if (res.data === "success") {
        dispatch(
          openSnackbar({
            message: `${deleteUrl} deleted successfully!`,
            severity: "success",
          })
        );
        getCampaignUrls(campId);
      }
    });
    closeModal();
  };

  let tableData = [];
  if (urlsData.length > 0) {
    tableData = urlsData.map((url) => {
      const row = {};
      row.urlText = url.urlText;
      row.url = url.url;
      row.forAll = url.forAll;
      row.platform = url.platform;
      row.group = url.listName;
      row.id = {
        urlId: url.id,
        lid: url.lid,
        url: url.url,
      };

      return row;
    });
  }

  const columns = [
    {
      label: "URL Information",
      name: "urlText",
    },
    {
      label: "URL",
      name: "url",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={value} rel="noreferrer" target="_blank">
              {value}
            </a>
          );
        },
      },
    },
    {
      label: "Platform",
      name: "platform",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const platform = utils.capitalizeFirstLetter(value);
          return platform;
        },
      },
    },
    {
      label: "Group",
      name: "group",
    },
    {
      label: "For All",
      name: "forAll",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? "Yes" : "No";
        },
      },
    },
    {
      label: "Action",
      name: "id",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const forAll = tableMeta.rowData[4];
          const urlId = value.urlId;
          const url = value.url;
          const lid = value.lid;
          return (
            <div>
              <IconButton onClick={() => handleOpenEditModal(urlId, lid)}>
                <Tooltip title={"Edit"}>
                  <Edit fontSize="small" />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteModal(urlId, url)}>
                <Tooltip title={"Delete URL"}>
                  <Delete fontSize="small" />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => handleShortURLs(urlId)}>
                <Tooltip title={"Short URLs"}>
                  <Preview fontSize="small" />
                </Tooltip>
              </IconButton>
              {!forAll && (
                <IconButton
                  component={Link}
                  to={
                    "/campaigns/linkAnalysis/urls/create?id=" + campId + "&lid=" + lid + "&urlId=" + urlId + "&group=" + groupName
                  }
                >
                  <Tooltip title={"Create New Short URLs"}>
                    <PersonAdd fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
            </div>
          );
        },
        download: false,
      },
    },
  ];

  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
  };

  const shortUrlsColumn = [
    {
      label: "Influencer",
      name: "influencerNameAndUsername",
    },
    {
      label: "URL",
      name: "shortUrl",
      options: {
        customBodyRender: (value, tableMeta, _updateValue) => {
          return (
            <a href={"https://" + value} rel="noreferrer" target="_blank">
              {value}
            </a>
          );
        },
      },
    },
    {
      label: "Created On",
      name: "createdAt",
    },
  ];

  const shortUrlsOptions = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    responsive: "simple",
    pagination: false,
    tableBodyMaxHeight: "500px",
    selectableRows: "none",
  };

  const tabArray2 = [
    {
      label: "Metrics",
      id: "Metrics-tab-0",
      to: `/campaigns/linkAnalysis?id=${campId}`,
      component: Link,
      icon: <Analytics />,
      iconPosition: "start"
    },
    {
      label: "Manage Destination URLs",
      id: "manage-destination-tab-0",
      to: `/campaigns/linkAnalysis/urls?id=${campId}`,
      component: Link,
      icon: <ManageAccounts />,
      iconPosition: "start"
    },
  ]

  return (
    <>
      <CampaignActions name={campaignName} campId={campId} />
      <br />
      <CustomTabs tabArray={tabArray} selectedTab={"4"} />

      <div id="link-manage-urls-component">
        <Box m={3} style={{ marginTop: "10px" }}>
          <CustomTabs tabArray={tabArray2} selectedTab={"1"} />
          <br />
          <Stack direction="row" justifyContent="end">
            <Button variant="contained" onClick={handleOpenAddModal}>
              Add New URL
            </Button>
          </Stack>
        </Box>
        <Datatables data={tableData} columns={columns} options={options} />
        <Modal
          className="styles_modal"
          aria-labelledby="modal-add_update_url-label"
          aria-describedby="modal-add_update_url-description"
          open={openModal}
          onClose={closeModal}
          closeAfterTransition
        >
          <Fade in={openModal}>
            <div className="syles_paper" style={{ width: "50%" }}>
              <div id="modal-add_update_url-heading" className="styles_modal_heading">
                <h2 id="modal-add_update_url-heading-text" style={{ padding: "10px" }}>
                  {modalType === "add" && "Add Destination URL"}
                  {modalType === "edit" && "Edit Destination URL"}
                </h2>
                <IconButton onClick={closeModal}>
                  <Close />
                </IconButton>
              </div>
              <hr />
              <div id="modal-add_update_url-body" style={{ padding: "10px" }}>
                <form onSubmit={handleModalSubmit}>
                  <Grid container spacing={2}>
                    {modalType === "add" && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Platform</InputLabel>
                            <Select
                              onChange={handleModalPlatformChange}
                              value={modalPlatform}
                              variant="outlined"
                              label="Platform"
                            >
                              <MenuItem key={"all"} value={"all"}>
                                All Platforms
                              </MenuItem>
                              {platformData.map((platform) => (
                                <MenuItem value={platform.name} key={platform.name}>
                                  {utils.capitalizeFirstLetter(`${platform.name}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">List *</InputLabel>
                            <Select
                              onChange={(event) => handleModalListChange(event, listData)}
                              variant="outlined"
                              label="List *"
                              required={true}
                              value={modalList}
                            >
                              {listData.map((list) => (
                                <MenuItem value={list.id} key={list.id}>
                                  <Typography sx={textColor[list.platform]}>{snapshotHeaderIcons[list.platform]}</Typography>
                                  {utils.capitalizeFirstLetter(`${list.name}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <TextField
                          onChange={handleModalURLTextChange}
                          variant="outlined"
                          label="URL Information"
                          required={true}
                          value={modalURLText}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <TextField
                          onChange={handleModalURLChange}
                          variant="outlined"
                          label="URL"
                          required={true}
                          value={modalURL}
                        />
                      </FormControl>
                    </Grid>
                    {modalType === "add" && (
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                          <FormControlLabel
                            label="Create short link for all influencers in list"
                            control={<Checkbox checked={modalForAll} onChange={handleModalForAllChange} />}
                          />
                        </FormControl>
                        <FormHelperText>* This option cannot be changed later</FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      float: "right",
                      marginBottom: "15px",
                      marginTop: "15px",
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </Fade>
        </Modal>
        <Modal
          className="styles_modal"
          aria-labelledby="modal-short_urls-label"
          aria-describedby="modal-short_urls-description"
          open={openPrevModal}
          onClose={closeModal}
          closeAfterTransition
        >
          <Fade in={openPrevModal}>
            <div className="syles_paper" style={{ width: "50%" }}>
              <div id="modal-short_urls-heading" className="styles_modal_heading">
                <h2 id="modal-short_urls-heading-text" style={{ padding: "10px" }}>
                  Short URLs
                </h2>
                <IconButton onClick={closeModal}>
                  <Close />
                </IconButton>
              </div>
              <hr />
              <div id="modal-short_urls-body" style={{ padding: "10px" }}>
                <Datatables data={shortUrlsData} columns={shortUrlsColumn} options={shortUrlsOptions} />
              </div>
            </div>
          </Fade>
        </Modal>
        <Modal
          className="styles_modal"
          aria-labelledby="modal-delete_url-label"
          aria-describedby="modal-delete_url-description"
          open={openDeleteModal}
          onClose={closeModal}
          closeAfterTransition
        >
          <Fade in={openDeleteModal}>
            <div className="syles_paper" style={{ width: "50%" }}>
              <div id="modal-delete_url-heading" className="styles_modal_heading">
                <h2 id="modal-delete_url-heading-text" style={{ padding: "10px" }}>
                  Delete this URL?
                </h2>
                <IconButton onClick={closeModal}>
                  <Close />
                </IconButton>
              </div>
              <hr />
              <div id="modal-delete_url-body" style={{ padding: "10px" }}>
                Are you sure you want to permanently delete this destination URL -{" "}
                <a href={deleteUrl} rel="noreferrer" target="_blank">
                  {deleteUrl}
                </a>
                ?
              </div>
              <div
                style={{
                  float: "right",
                  margin: "15px 20px 15px 0",
                }}
              >
                <Button variant="outlined" onClick={closeModal} style={{ marginRight: "15px" }}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleDeleteURL}>
                  Delete
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

export default LinkManageUrls;
