import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { InstagramEmbed, TwitterEmbed, YouTubeEmbed, TikTokEmbed, LinkedInEmbed, FacebookEmbed } from "react-social-media-embed";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "2em",
          backgroundColor: "unset",
        },
      },
    },
  },
});

const InfoToolTip = ({ text }) => {
  return (
    <Tooltip
      title={
        <>
          <h2 style={{ fontSize: 13 }}>{text}</h2>
        </>
      }
      placement="top-start"
    >
      <InfoOutlined fontSize="small" />
    </Tooltip>
  );
};

const InfoToolTipPreview = ({ postUrl, platform, postId }) => {
  const renderPlatformContent = () => {
    switch (platform) {
      case "instagram":
        return <InstagramEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "twitter":
        return <TwitterEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "youtube":
        return <YouTubeEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "tiktok":
        return <TikTokEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      // case "linkedin":
      //   return <LinkedInEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      case "facebook":
        return <FacebookEmbed url={postUrl} width={400} height={400} style={{ overflow: "overlay" }} />;
      default:
        return null;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Tooltip placement="right" title={<div style={{ background: "transparent", padding: "0" }}>{renderPlatformContent()}</div>}>
        <a href={postUrl} target="_blank" rel="noreferrer">
          <p style={{ marginTop: 5 }}>{postId?.substring(0, 15)}...</p>
        </a>
      </Tooltip>
    </ThemeProvider>
  );
};

InfoToolTip.propTypes = {
  text: PropTypes.string,
};
InfoToolTipPreview.propTypes = {
  postUrl: PropTypes.string,
};
export { InfoToolTip, InfoToolTipPreview };
