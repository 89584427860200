import React from 'react';
import { Backdrop, Box, Button, Grid, Menu, MenuItem, Modal, Paper, Tooltip, Typography, styled } from '@mui/material';
import {
  LocationOn as LocationOnIcon,
  Language as LanguageIcon,
  Favorite as FavoriteIcon,
  RecentActors as RecentActorsIcon,
  AlternateEmail as AlternateEmailIcon,
  Add as AddIcon,
  NoteAlt as NoteAltIcon,
  MoreVert as MoreVertIcon,
  InfoOutlined as InfoOutlinedIcon,
  Send as SendIcon,
  Email as EmailIcon,
  CloudDownload as CloudDownloadIcon,
  Done as DoneIcon,
  VerifiedUser as VerifiedUserIcon,
  WorkspacePremium as WorkspacePremiumIcon,
  AccountCircle as AccountCircleIcon
} from '@mui/icons-material';

import { snapshotHeaderIcons, textColor } from '../../../../../campaignAnalysis/snapshot';
import FallbackWrapper from '../common/FallbackWrapper/FallbackWrapper';
import { object, string } from "prop-types";
import ContactCard from '../ContactCard/ContactCard';


const StyledMenu = styled((props) => (
  <Menu
    elevation={2}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: "#94A2B3",
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const IconWithText = (props) => {
  const { icon, text, wrapperSxProps } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", color: "#94A2B3", ...wrapperSxProps }}>
      {icon}
      {text}
    </Box>
  )
}

const ButtonWithIcon = (props) => {
  const { icon, text, btnSxProps, onClick } = props;
  return (
    <Button variant="outlined" startIcon={icon} sx={{ borderColor: "#94A2B3", color: "#94A2B3", borderRadius: "10px", ...btnSxProps }} onClick={onClick}>{text}</Button>
  )
}


const BasicDetails = (props) => {

  const { data, platform } = props;
  const {
    name,
    ageRange,
    bio,
    gender,
    languages,
    link: profileLink,
    locations,
    socialId,
    link,
    username,
    interests,
    profile_pic,
    country_code,
    is_verified,
    quality_score,
  } = data;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [seeMore, setSeeMore] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Paper elevation={4} sx={{ borderRadius: "8px", minHeight: "280px", position: 'relative', overflow: "hidden" }}>
        <FallbackWrapper isError={(!(Object.keys(data).length) > 0)}>
          {/* <IconButton sx={{ position: 'absolute', top: '2px', right: '2px', fontSize: "12px" }}>
            <Tooltip title="Profile last updated on: 30th Jan 2023">
              <InfoOutlinedIcon fontSize='12px' />
            </Tooltip>
          </IconButton> */}
          {quality_score &&
            <Box sx={{ position: 'absolute', bottom: '2%', right: '3%', display: "flex", alignItems: "center" }}>
              <WorkspacePremiumIcon sx={{ fill: "#E2A071", fontSize: "60px" }} />
              <Box>
                <Box display="flex" alignItems="center" justifyContent="center" p="3px" bgcolor="#219653" width="53px" height="18px" borderRadius="6px">
                  <Typography fontSize="12px" display="flex" whiteSpace="nowrap" color="white">
                    79 / 100
                  </Typography>
                </Box>
                <Typography color="#219653" fontSize="15px">
                  Excellent
                </Typography>
                <Typography display="flex" alignItems="center" justifyContent="center" fontSize="8px" color="#94A2B3">
                  Quality Sore
                  <InfoOutlinedIcon sx={{ fontSize: "12px", paddingLeft: "2px" }} />
                </Typography>
              </Box>
            </Box>
          }
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item xs={12} md={3} sx={{ position: "relative", pt: "0 !important", pl: "0 !important" }} >
              <Box
                component={"img"}
                src={profile_pic}
                sx={{ width: "100%", height: "100%" }}
              />
              {is_verified && <Box position="absolute" right="2%" top="9%">
                <VerifiedUserIcon fontSize='large' sx={{ fill: "#54A7FA" }} />
              </Box>}
            </Grid>
            <Grid xs={12} md={9} item py="1rem">
              <Grid container sx={{ mb: "12px" }}>
                <Grid item xs={5}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography component={"h2"} sx={{ fontSize: "40px", fontFamily: "Roboto", color: "#272D4E", fontWeight: "700" }}>
                      {name}
                    </Typography>
                    <Box ml="16px" position="relative">
                      <Typography sx={textColor[platform]}>
                        {snapshotHeaderIcons[platform]}
                      </Typography>
                      <Box position="absolute" width="15px" height="15px" borderRadius="50%" bgcolor="#f2f3f5" top="-5px" right="-5px" display="flex" alignItems="center" justifyContent="center" fontSize="12px">
                        <DoneIcon fontSize='inherit' fill="#007DFF" sx={{ stroke: "4px", fill: "#007DFF" }} />
                      </Box>
                    </Box>
                  </Box>
                  {platform !== "youtube" && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {username &&
                      <a href={profileLink} target="_blank" rel="noreferrer">
                        <IconWithText icon={<AlternateEmailIcon fontSize="14px" />} text={<Typography component={"span"} sx={{ fontSize: "14px", display: "inline-block" }}>{username}</Typography>} wrapperSxProps={{ alignItems: "flex-end" }} />
                      </a>
                    }
                    {gender && ageRange &&
                      <IconWithText icon={<RecentActorsIcon fontSize="18px" />} text={<Typography component={"span"} sx={{ fontSize: "14px", display: "inline-block", pl: "0.3rem" }}>{gender}, {ageRange} years old</Typography>} />
                    }
                  </Box>}
                  {
                    link && platform === "youtube" &&
                    <>
                      <br />
                      <a href={link} target="_blank" rel="noreferrer">{link}</a>
                    </>

                  }
                  {interests &&
                    <Box sx={{ display: "flex", color: "#94A2B3", fontWeight: "400", pt: "16px" }}>
                      <FavoriteIcon fontSize="14px" sx={{ mt: "4px" }} />
                      <Typography p fontSize="16px" paddingTop={0}>{interests}</Typography>
                    </Box>
                  }
                </Grid>
                <Grid item xs={4} sx={{ pl: "1.5rem", pt: "12px" }}>
                  <Box display="flex" alignItems="center" >
                    {locations && <IconWithText icon={<LocationOnIcon fontSize="15px" />} text={<Typography p fontSize="16px">{locations}</Typography>} />}
                    {country_code && <Box component="img" src={`https://flagcdn.com/w20/${country_code.toLowercase()}.png`} srcSet={`https://flagcdn.com/w40/${country_code.toLowercase()}.png`} width="20" alt={country_code.toLowercase()} />}
                  </Box>
                  {languages && <IconWithText icon={<LanguageIcon fontSize="15px" />} text={<Typography p fontSize="14px">{languages}</Typography>} wrapperSxProps={{ maxWidth: "20px", textOverflow: "ellipsis", whiteSpace: "nowrap" }} />}
                </Grid>
              </Grid>
              <Grid item xs={8}>
                {bio && <Box sx={{ display: "flex", color: "#94A2B3", fontWeight: "400" }}>
                  <RecentActorsIcon fontSize="12px" sx={{ mt: "4px" }} />
                  <Typography p fontSize="16px" paddingTop={0}>{bio.slice(0, seeMore ? bio.length : 150)} {bio.length > 150 && <Box component={"span"} sx={{ cursor: "pointer", color: "#000000" }} onClick={() => setSeeMore(!seeMore)}>{seeMore ? "show less" : " ...show more"}</Box>}</Typography>
                </Box>
                }
              </Grid>
              <Grid item container>
                <Grid item xs={8} sx={{ display: "flex", alignItems: "center", pl: "1.5rem" }}>
                  <ButtonWithIcon icon={<AccountCircleIcon />} text={"Contact Info"} onClick={handleModalOpen} />
                  {/* <ButtonWithIcon icon={<AddIcon />} text={"Add To Campaign"} />
                  <ButtonWithIcon icon={<NoteAltIcon />} text={"Note"} btnSxProps={{ ml: "8px" }} />
                  <IconButton sx={{ p: "4px", border: "2px solid #94A2B3", borderRadius: "50%", ml: "1rem" }} onClick={handleClick}><MoreVertIcon color="#94A2B3" /></IconButton> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FallbackWrapper>
      </Paper>
      <StyledMenu
        id="profile-options"
        MenuListProps={{
          'aria-labelledby': 'profile-options',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <SendIcon />
          Invite To Campaign
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <EmailIcon />
          Send Email
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <CloudDownloadIcon />
          Download Report
        </MenuItem>
      </StyledMenu>

      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
        className="styles_modal"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <ContactCard avatarInfo={{ picUrl: profile_pic, name, userId:socialId }} platform={platform} handleModalClose={handleModalClose} />
      </Modal>

    </>
  )
}

BasicDetails.propTypes = {
  data: object.isRequired,
  platform: string.isRequired
}


export default BasicDetails;