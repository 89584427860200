import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "@aws-amplify/auth";
import { getDetails } from "../../../services/account/information";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    initialized: false,
    loggedIn: false,
    username: null,
    userPoolId: null,
    attributes: {},
    details: null,
    detailsLoaded: false,
    group: null,
  },
  reducers: {
    login: (state, action) => {
      state.loggedIn = true;
      state.userPoolId = action.payload.pool.userPoolId;
      state.username = action.payload.username;
      state.attributes = action.payload.attributes;
      state.initialized = true;
      state.group = action.payload.group;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.attributes = {};
      state.username = null;
      state.userPoolId = null;
      state.initialized = true;
      state.details = null;
      state.detailsLoaded = false;
    },
    setDetails: (state, action) => {
      state.details = action.payload;
      state.detailsLoaded = true;
    },
    setDbId: (state, action) => {
      state.attributes["custom:db_id"] = action.payload.id;
    },
  },
});

export const { login, logout, setDbId, setDetails } = userSlice.actions;

export default userSlice.reducer;

export const getUserData = async (dispatch, getState) => {
  const state = getState();
  if (typeof state.user.attributes?.["custom:db_id"] !== "undefined") {
    const details = await getDetails(state.user.attributes?.["custom:db_id"]);

    if (dispatch.error !== false) {
      dispatch(setDetails(details.data));
    }
  }
};

export const initUser = async (dispatch) => {
  let user = null;
  try {
    user = await Auth.currentAuthenticatedUser({ bypassCache: true });
  } catch (error) {
    console.error(error);
  }
  if (user) {
    let group = null;
    if (user.signInUserSession.idToken.payload["cognito:groups"].length > 0) {
      group = user.signInUserSession.idToken.payload["cognito:groups"][0];
    }
    const payload = {
      username: user.username,
      attributes: user.attributes,
      pool: {
        userPoolId: user.pool.userPoolId,
      },
      group: group,
    };
    dispatch(login(payload));

    dispatch(getUserData);
  } else {
    dispatch(logout());
  }
};
