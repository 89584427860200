  import React, { useState } from 'react';
  import { Avatar, Button, IconButton } from '@mui/material';
  import GlobeIcon from '@mui/icons-material/Public';
  import Carousel from 'react-material-ui-carousel';
  import { number, object } from 'prop-types';
  import stringAvatar from '../../utils/avatar';
  import LinkedinLike from '../../styles/icons/linkedinlike';
  import LinkedinComment from '../../styles/icons/linkedincomment';
  import LinkedinRepost from '../../styles/icons/linkedinRepost'; 
  import LinkedinShare from '../../styles/icons/LinkedinShare'; 
  import showIcon from '../../utils/icons';
  import { GetPostText, RenderCardMedia } from '../../utils/mediacontent';
  import PostActionButton from '../../pages/account/posts/PostActionButton';
  import { renderData } from '../../utils/platform-asset-calculator';
  import statusMapping from '../../models/notification';
  import { format } from "date-fns";

  const linkedStyle = {
    postContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      overflow: 'hidden', // Changed from 'auto' to 'hidden' to remove scrolling here
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      alignItems: 'center'
    },
    nameSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: '6rem'
    },
    content: {
      padding: '0 10px',
      overflowY: 'auto',
      maxHeight: '300px',
      '::-webkit-scrollbar': {
        width: '8px' ,
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '10px'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',  
        borderRadius: '10px',
        '&:hover': {
          background: '#555' 
        }
      },
      scrollbarWidth: 'thin', 
      scrollbarColor: '#888 #f1f1f1'  
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: '10px 0'
    },
    seeMoreButton: {
      color: 'grey',
      padding: '10px 0',
      alignSelf: 'flex-end'
    },
    button: {
      fontSize: '12px',
      borderRadius: '10px'
    }
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "MMMM d, yyyy");
  };

  const LinkedinPost = ({ formData, cid }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div style={linkedStyle.postContainer}>
        <div style={linkedStyle.header}>
          <Avatar sx={{ width: 32, height: 32 }} {...stringAvatar(formData.influencerName)} />
          <div style={linkedStyle.nameSection}>
            <strong>{formData.influencerName}</strong>
            <div>
              <span>{formatDate(formData.createdAt)}</span>
              <GlobeIcon fontSize="small" />
            </div>
          </div>
          <div>
            {showIcon(formData.platform)}
            {formData.preview && <PostActionButton id={formData.id} cid={cid} />}
          </div>
        </div>
        <div style={linkedStyle.content}>
          <p>{renderData(formData.postContent, !isExpanded)}</p>
        </div>
        {formData.postContent.length > 100 && (
          <Button onClick={toggleExpand} style={linkedStyle.seeMoreButton}>
            {isExpanded ? 'See Less' : 'See More'}
          </Button>
        )}
        <Carousel fullHeightHover={false} style={{ minHeight: '200px', width: '100%' }}>
    {RenderCardMedia(formData)}
  </Carousel>

        <div style={linkedStyle.actions}>
          <IconButton aria-label="like">
            <LinkedinLike />
          </IconButton>
          <IconButton aria-label="comment">
            <LinkedinComment />
          </IconButton>
          <IconButton aria-label="repost">
            <LinkedinRepost />
          </IconButton>
          <IconButton aria-label="share">
            <LinkedinShare />
          </IconButton>
        </div>
        {formData.preview && (
          <Button style={{
            ...linkedStyle.button,
            backgroundColor: statusMapping.filter(value => value.id === formData.currentStatus)[0]?.color,
            marginTop: '10px'
          }} variant="contained">
            {GetPostText(formData)}
          </Button>
        )}
      </div>
    );
  };

  LinkedinPost.propTypes = {
    formData: object.isRequired,
    cid: number.isRequired,
  };

  export default LinkedinPost;
