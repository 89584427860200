const statusMapping = [
  { id: 0, status: 'Under Review', color: '#2874f7' },
  { id: 1, status: 'Under Review', color: '#2874f7' },
  { id: 2, status: 'Approved', color: '#50b153' },
  { id: 3, status: 'Rejected', color: '#ff0000' },
  { id: 4, status: 'Feedback Provided', color: '#ff9800' },
  { id: 5, status: 'Deleted', color: 'black' },
  { id: 6, status: 'Published', color: '#4BB543' }
];

export default statusMapping;
