import React, { Component } from "react";

import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

/* external files */
import MyPaper from "../general/MyPaper";

/* mui */
import {
  FormControl,
  Stack,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";

class LinkUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUrlId: -1,
      "startDate": moment().subtract(29, "days").toDate(),
      "endDate": moment().toDate(),
    }
  }

  componentDidMount() {
    if(this.props.urls && this.props.urls.length>0) {
      const selectedUrlId = this.props.urls[0].id;
      this.setState({ selectedUrlId });
    }
  }

  addDestinationURL = () => {
    alert("under construction!!");
  }

  sendDataToParent = (selectedUrlId, startDate, endDate) => {
    if(selectedUrlId === "") {
      selectedUrlId = this.state.selectedUrlId;
    } else if (startDate === "") {
      startDate = this.state.startDate;
      endDate = this.state.endDate;
    }
    let urlIds = [];
    if(selectedUrlId !== -1) {
      urlIds.push(selectedUrlId);
    } else {
      urlIds = this.props.urls.map((url) => url.id);
    }

    const payload = {
      "urlIds": urlIds,
      "startDate": startDate,
      "endDate": endDate
    }

    this.props.handleUrlChange(payload);
  }

  handleUrlChange = (event) => {
    const selectedUrlId = event.target.value;
    this.setState({ selectedUrlId });
    this.sendDataToParent(selectedUrlId, "", "");
  }

  handleCalendarChange = (start, end) => {
    const startDate = moment(start);
    const endDate = moment(end);
    this.setState({ startDate, endDate });
    this.sendDataToParent("", startDate, endDate);
  }

  render() {
    return(
      <>
        <MyPaper sx={{ marginBottom: "20px" }}>
          <Stack direction="row" justifyContent="space-between">
            <FormControl size="small" sx={{ marginRight: "5px" }}>
              <InputLabel id="list-select-label">
                Short URL
              </InputLabel>
              <Select
                sx={{ height: "40px", minWidth: "10vw" }}
                style={{ color: "black" }}
                labelId="list-select-label"
                id="list-select"
                value={this.state.selectedUrlId}
                label="Short URL"
                onChange={this.handleUrlChange}
                variant="outlined"
              >
                {
                  (this.props.urls && this.props.urls.length > 0)?
                  (
                    <MenuItem key={-1} value={-1}>
                      All Short URLs
                    </MenuItem>
                  ):(
                    <MenuItem key={-2} value={-1} disabled>
                      No URLs available
                    </MenuItem>
                  )
                }
                {this.props.urls.map((url) => {
                  return(
                    <MenuItem key={url.id} value={url.id}>{url.shortUrl}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <Stack spacing={3} sx={{ marginRight: "5px" }}>
            <DateRangePicker
              initialSettings={{
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                ranges: {
                  "Today": [moment().toDate(), moment().toDate()],
                  "Yesterday": [
                    moment().subtract(1, "days").toDate(),
                    moment().subtract(1, "days").toDate(),
                  ],
                  "This Week": [
                    moment().startOf("week").toDate(),
                    moment().toDate(),
                  ],
                  "Last Week": [
                    moment()
                      .subtract(1, "weeks")
                      .startOf("week")
                      .toDate(),
                    moment().subtract(1, "weeks").endOf("week").toDate(),
                  ],
                  "Last 7 Days": [
                    moment().subtract(6, "days").toDate(),
                    moment().toDate(),
                  ],
                  "Last 30 Days": [
                    moment().subtract(29, "days").toDate(),
                    moment().toDate(),
                  ],
                  "This Month": [
                    moment().startOf("month").toDate(),
                    moment().endOf("month").toDate(),
                  ],
                  "Last Month": [
                    moment()
                      .subtract(1, "month")
                      .startOf("month")
                      .toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                  ],
                  "Campaign Duration": [
                    moment(this.props.start).toDate(),
                    moment(this.props.end).toDate(),
                  ]
                },
              }}
              onCallback={this.handleCalendarChange}
            >
              <input
                style={{ lineHeight: 1.6 }}
                type="text"
                className="form-control"
              />
            </DateRangePicker>
          </Stack>
            {/* <Button
              variant="contained"
              onClick={this.addDestinationURL}
            >
              Add destination URL
            </Button> */}
          </Stack>
        </MyPaper>
      </>
    );
  }
}

export default LinkUrl;