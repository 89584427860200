import React from "react";
import BrandTableTable from "./BrandTable";
import { Link, useLocation, useNavigate } from "react-router-dom";


const DeletededBrand = () => {
    const location = useLocation();
    const platform = new URLSearchParams(location.search).get("platform")

    return <BrandTableTable type={2} platfrom={platform} />;
}

export default DeletededBrand;