import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Alert,
  Avatar,
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { INF_API_HOST, API_HOST } from "../../config/main.json";
import { func, object } from "prop-types";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import { makeStyles } from "@mui/styles";
import stringAvatar from "../../utils/avatar";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import { CheckBox } from "@material-ui/icons";
import utils from "../../utils/utils";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const useStyles = makeStyles((theme) => ({
  option: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));
const SearchInfluencers = (props) => {
  const classes = useStyles();
  const { listInfo, callbackHandler } = props;
  const { listId, platform, alreadyIncludedInfluencers, listName, campaignName } = listInfo;
  const [selectedPlatform, setSelectedPlatform] = useState(platform);
  const [selectedListId, setSelectedListId] = useState(listId);
  const [selectedListName, setSelectedListName] = useState(listName);
  const [audienceList, setAudienceList] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getLists();
    getInfluencerData();
  }, [selectedPlatform]);

  const getInfluencerData = () => {
    setIsLoading(true);
    axios.get(`${INF_API_HOST}/influencer/list/profiles/${selectedPlatform.toLowerCase()}`).then((res) => {
      //filter out data based on specific platform
      const platformInfluencers = res.data.filter((item) => item.platform === selectedPlatform?.toLowerCase());
      // get ids of already added influencers in the list
      const includedIds = alreadyIncludedInfluencers?.map((item) => item.id);
      // filter out data by removing already added influencers
      const data =
        includedIds?.length > 0
          ? platformInfluencers.filter((item) => !includedIds.includes(item?.influencer_id))
          : platformInfluencers;
      //set the state value
      setOptions(data);
    });
    setIsLoading(false);
  };

  const getLists = () => {
    axios.get(`${API_HOST}/lists/type/2/${selectedPlatform.toLowerCase()}`).then((res) => {
      setAudienceList(res.data.filter((value) => value.cname === campaignName));
    });
  };

  const addToList = async (idsArray) => {
    const axiosOptions = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    try {
      const { status, data } = await axios.post(
        API_HOST + "/list/influencers/add",
        { iid: idsArray, lid: +selectedListId, group: selectedListName },
        axiosOptions
      );
      if (status === 200) {
        if (data?.status !== "success") {
          dispatch(
            openSnackbar({
              message: "Error adding influencer(s)",
              severity: "error",
            })
          );
        } else {
          dispatch(
            openSnackbar({
              message: "Influencers added successfully",
              severity: "success",
            })
          );
          if (callbackHandler) {
            setTimeout(() => callbackHandler(), 500);
          }
        }
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: "Error, please try again later",
          severity: "error",
        })
      );
    }
  };

  const onChangeHandler = (_, value) => {
    setSelectedOptions(value);
  };

  const addInfluencerToListHandler = async () => {
    // get ids of selected influencers
    setIsLoading(true);
    const selectedIds = selectedOptions.map((item) => +item.influencer_id);
    await addToList(selectedIds);
    setIsLoading(false);
  };

  const selectedAudienceList = (event) => {
    setSelectedListId(audienceList.filter((item) => item.name === event.target.innerHTML)[0].id);
    setSelectedListName(event.target.innerHTML);
  };

  return (
    <>
      {listName !== "" && (
        <Box sx={{ display: "flex", alignItems: "center", my: "1rem" }}>
          <Typography variant="h5" sx={{ display: "flex", alignItems: "center", mr: "1rem" }}>
            <b>Platform:</b>&nbsp;
            <Typography sx={textColor[selectedPlatform?.toLowerCase()]} variant="">
              {snapshotHeaderIcons[selectedPlatform?.toLowerCase()]}
            </Typography>
          </Typography>
          <Typography variant="h5" sx={{ mr: "1rem" }}>
            <b>Campaign:</b> {campaignName}
          </Typography>
          <Typography variant="h5">
            <b>List:</b> {listName}
          </Typography>
        </Box>
      )}
      {listName === "" && (
        <Grid container spacing={2}>
          <Grid item md={6} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-standard-label">Select the platform*</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="platform"
                value={selectedPlatform}
                label="Select the platform"
                placeholder="Select the platform for which you want to invite the influencer"
                onChange={(e) => setSelectedPlatform(e.target.value)}
                variant="outlined"
                required={true}
              >
                <MenuItem disabled value=" ">
                  <em>Platform</em>
                </MenuItem>
                <MenuItem value={"instagram"} selected>
                  Instagram
                </MenuItem>
                <MenuItem value={"tiktok"} selected>
                  TikTok
                </MenuItem>
                <MenuItem value={"youtube"} selected>
                  YouTube
                </MenuItem>
                <MenuItem value={"linkedin"} selected>
                  LinkedIn
                </MenuItem>
                <MenuItem value={"facebook"} selected>
                  Facebook
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} sm={6}>
            <FormControl fullWidth>
              <Autocomplete
                refs="audienceId"
                id="audienceId"
                variant="outlined"
                sx={{ width: "100%" }}
                options={audienceList}
                getOptionLabel={(option) => option.name}
                onChange={(option) => selectedAudienceList(option)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <>
                    <TextField {...params} label="Which list do you want to add influencer in?" variant="outlined" />
                  </>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
      <br />
      {options.length > 0 && (
        <Box sx={{ display: "grid", placeItems: "center" }}>
          <Autocomplete
            disablePortal
            options={options}
            autoComplete
            autoHighlight
            multiple
            loading={isLoading}
            loadingText={"Loading Influencers..."}
            value={selectedOptions}
            isOptionEqualToValue={(option, value) => option.influencer_id === value.influencer_id}
            onChange={onChangeHandler}
            getOptionLabel={(option) => `${option.name} (@${option.username})`}
            renderOption={(props, option, { selected }) => (
              <div {...props}>
                <Grid container spacing={3} sx={{ padding: "10px" }}>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item>
                        {option.photo_url !== "" && option.photo_url.includes("gazelle") ? (
                          <Avatar sx={{ width: 56, height: 56 }} src={option.photo_url} alt={option.name} />
                        ) : (
                          <Avatar {...stringAvatar(option.name)} />
                        )}
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item xs={12}>
                            {option.profile_url ? (
                              <a href={option.profile_url} target="_blank" rel="noopener noreferrer">
                                {option.name}
                              </a>
                            ) : (
                              <span>{option.name}</span>
                            )}
                          </Grid>
                          <Grid item xs={12} sx={{ fontSize: "13px", color: "gray" }}>
                            <i>@{option.username}</i>
                          </Grid>
                          <Grid item xs={12} sx={{ fontSize: "13px", color: "gray" }}>
                            <i>{option.email || "No email found"}</i>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={1} sx={{ fontSize: "13px", color: "gray" }}>
                    <i>{option.onboared_type === "organic" ? "Registered" : utils.capitalizeFirstLetter(option.onboared_type)}</i>
                  </Grid>
                </Grid>
              </div>
            )}
            renderInput={(params) => <TextField {...params} label="Influencer" placeholder="Search by username, name..." />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={`${option.name} (@${option.username})`} {...getTagProps({ index })} />
              ))
            }
            closeOnSelect={false} // Set closeOnSelect to false
            sx={{ width: "100%" }}
          />
        </Box>
      )}

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", my: "1rem" }}>
        <LoadingButton
          variant="contained"
          size="small"
          onClick={addInfluencerToListHandler}
          loading={isLoading}
          sx={{ p: "8px", color: "white", backgroundColor: "rgb(51, 204, 153)" }}
          disabled={selectedOptions?.length === 0}
        >
          Add Influencers
        </LoadingButton>
      </Box>
    </>
  );
};

SearchInfluencers.propTypes = {
  object: object.isRequired,
  callbackHandler: func,
};

export default SearchInfluencers;
