import { Box, Button, Divider, FormControl, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CampaignTabs from "../../pages/account/CampaignTabs";
import { Link, useNavigate } from "react-router-dom";
import utils from "../../utils/utils";
import TabPanel from "../general/TabPanel";
import MyPaper from "../general/MyPaper";
import { Analytics, BadgeTwoTone, ManageAccounts } from "@mui/icons-material";
import TransferList from "../general/TransferList";
import axios from "axios";
import { API_HOST } from "../../config/main.json";
import CampaignActions from "../../pages/account/CampaignActions";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

function LinkCreateShortUrls() {
  const [campId, setCampId] = useState(0);
  const [urlId, setUrlId] = useState(0);
  const [lid, setLid] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [groupName, setGroupName] = useState("");

  const navigate = useNavigate();

  const getCampaignInfo = (campId) => {
    utils.getCampaignData(campId).then((data) => {
      setCampaignName(data.name);
    });
  };

  const getInfluencerStatus = (urlId, lid) => {
    axios.get(API_HOST + "/link/listurl/shortlinksStatus/" + urlId + "/" + lid).then((res) => {
      const infStatus = res.data;
      const left = [];
      const right = [];
      infStatus.forEach((influencer) => {
        const inf = {};
        inf["id"] = influencer.iid;
        inf["label"] = influencer.influencerName;
        inf["disabled"] = false;
        if (!influencer.status) {
          left.push(inf);
        } else {
          inf["label"] += " (Short URL Exists)";
          inf["disabled"] = true;
          right.push(inf);
        }
      });

      setLeft(left);
      setRight(right);
    });
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    const lid = params.get("lid");
    const urlId = params.get("urlId");
    const groupName = params.get("group");

    setCampId(campId);
    setUrlId(+urlId);
    setLid(+lid);
    getCampaignInfo(campId);
    getInfluencerStatus(urlId, lid);
    setGroupName(groupName);
  }, []);

  useEffect(() => {
    setSelectedCount(right.filter((inf) => !inf.disabled).map((inf) => inf.id).length);
  }, [right]);

  const createShortUrls = (event) => {
    event.preventDefault();

    const iids = right.filter((inf) => !inf.disabled).map((inf) => inf.id);
    if (iids.length === 0) {
      return;
    }

    const data = {
      iids: iids,
      urlId: urlId,
      lid: lid,
      group: groupName,
    };

    axios.post(API_HOST + "/link/list/createshorturls", data, optionAxios).then((res) => {
      navigate("/campaigns/linkAnalysis/urls?id=" + campId);
    });
  };

  return (
    <>
      <CampaignActions id={campId} name={campaignName} />
      <br />
      <CampaignTabs campId={campId} selectedTab={"5"} />

      <div id="link-manage-urls-component">
        <Box m={3}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">{campaignName}</Typography>
          </Stack>
        </Box>

        <Box m={3}>
          <Tabs style={{ width: "200px", float: "left" }} variant="scrollable" scrollButtons="auto">
            <Tab
              label="Metrics"
              icon={<Analytics />}
              iconPosition="start"
              id="Metrics-tab-0"
              to={"/campaigns/linkAnalysis?id=" + campId}
              component={Link}
            />
          </Tabs>
          <Tabs value={0} style={{ width: "300px", float: "left" }} variant="scrollable" scrollButtons="auto">
            <Tab
              label="Manage Destination URLs"
              id="manage-destination-tab-0"
              to={"/campaigns/linkAnalysis/urls?id=" + campId}
              icon={<ManageAccounts />}
              iconPosition="start"
              component={Link}
            />
          </Tabs>
          <br />

          <Stack direction="row" justifyContent="end">
            <Button variant="contained" component={Link} to={"/campaigns/linkAnalysis/urls?id=" + campId}>
              Manage List
            </Button>
          </Stack>
        </Box>
        <TabPanel index={0} name="template-imformation-tab" value={0}>
          <form onSubmit={createShortUrls}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MyPaper>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                    Select influencers from left box
                  </Typography>
                  <Divider />

                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12} style={{ marginTop: "1rem" }}>
                      <FormControl fullWidth>
                        <TransferList left={left} setLeft={setLeft} right={right} setRight={setRight} />
                      </FormControl>
                    </Grid>
                  </Grid>
                </MyPaper>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <Box sx={{ mt: 4 }}>
                  <Button variant="contained" size="large" type="submit">
                    Create ({selectedCount})
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </TabPanel>
      </div>
    </>
  );
}

export default LinkCreateShortUrls;
