import { getColor, getIcon } from "../services/social-media/platforms";
import enums from "./enums";

const showIcon = (value) => {
  let platform;
  if (typeof value === "number") {
    platform = enums.platformIdMapping[value];
  } else {
    platform = value.toLowerCase();
  }
  const IconComponent = getIcon(platform);
  const IconColor = getColor(platform);
  if (IconComponent !== undefined) {
    return (
      <IconComponent
        key={value}
        ctitle={value}
        sx={{ color: IconColor, mr: 1 }}
      />
    );
  }
  return null;
};

export default showIcon;
