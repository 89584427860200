import React, { useState } from 'react';
import { Avatar } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { object } from "prop-types";
import {
  BookmarkBorderOutlined,
  FavoriteBorderOutlined,
} from "@mui/icons-material";
import stringAvatar from "../../utils/avatar";
import CommentIcon from "../../styles/icons/comment";
import ShareIcon from "../../styles/icons/share";
import showIcon from "../../utils/icons";
import { GetPostText, RenderCardMedia } from "../../utils/mediacontent";
import { renderData } from "../../utils/platform-asset-calculator";
import statusMapping from "../../models/notification";
import moment from 'moment';

const InstagramPost = ({ formData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getTimeSincePost = (dateString) => {
    const postDate = moment(dateString);
    const now = moment();
    return now.diff(postDate, 'days') < 1 ? now.diff(postDate, 'hours') + 'h' : now.diff(postDate, 'days') + 'd';
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderContent = () => {
    if (isExpanded) {
      return (
        <p>
          {renderData(formData.postContent, formData.preview)}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={handleToggleExpand}
          >
            See less
          </span>
        </p>
      );
    } else if (formData.postContent.length > 100) {
      return (
        <>
          <p>
            {renderData(formData.postContent.slice(0, 100), formData.preview)}
            ...{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleToggleExpand}
            >
              Read more
            </span>
          </p>
        </>
      );
    } else {
      return <p>{renderData(formData.postContent, formData.preview)}</p>;
    }
  };

  return (
    <div style={{ borderRadius: "8px", padding: "10px", marginBottom: "10px", maxHeight: "500px", overflowY: "auto"}}>
      <div className="d-flex flex-nowrap example-parent" style={{ width: "100%", marginBottom: "10px" }}>
        <Avatar
          sx={{ width: 22, height: 22 }}
          {...stringAvatar(formData.influencerName)}
        />
        <span className="order-2 p-2 col-example" style={{ width: "100%" }}>
          <div className="d-flex justify-content-between" style={{ marginBottom: "10px" }}>
            <span className="header">{formData.influencerName}</span>
            {showIcon(formData.platform)}
          </div>
        </span>
      </div>
      <Carousel fullHeightHover={false} style={{ marginBottom: "10px", width: "100%", }}>
        {RenderCardMedia(formData)}
      </Carousel>
      <br />
      <div>
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: "10px" }}
        >
          <span className="col-example text-left">
            <FavoriteBorderOutlined style={{ color: "black", marginRight: 10 }} />
            <CommentIcon style={{ marginRight: 10 }} />
            <ShareIcon />
          </span>
          <span className="col-example text-left">
            <BookmarkBorderOutlined style={{ color: "black" }} />
          </span>
        </div>
        <div className="d-flex" style={{ marginBottom: "10px" }}>
          <b>{formData.influencerName.split(" ")[0]} - {getTimeSincePost(formData.createdAt)}</b>
          <span>&nbsp;&nbsp;</span>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

InstagramPost.propTypes = {
  formData: object.isRequired,
};

export default InstagramPost;
