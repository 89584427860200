import { AddPhotoAlternate, InsertPhoto } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

function TableRows({ rowsData, deleteTableRows, handleChange, handleUpload }) {
  return rowsData.map((data, index) => {
    const { url, likes, comments, shares, views, image } = data;
    return (
      <tr key={index}>
        <td>
          <input
            style={{ width: "500px" }}
            type="text"
            value={url}
            onChange={(evnt) => handleChange(index, evnt)}
            name="url"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="number"
            value={likes}
            onChange={(evnt) => handleChange(index, evnt)}
            name="likes"
            className="form-control"
          />{" "}
        </td>
        <td>
          <input
            type="number"
            value={comments}
            onChange={(evnt) => handleChange(index, evnt)}
            name="comments"
            className="form-control"
          />{" "}
        </td>
        <td>
          <input
            type="number"
            value={shares}
            onChange={(evnt) => handleChange(index, evnt)}
            name="shares"
            className="form-control"
          />{" "}
        </td>
        <td>
          <input
            type="number"
            value={views}
            onChange={(evnt) => handleChange(index, evnt)}
            name="views"
            className="form-control"
          />{" "}
        </td>
        <td>
          <div style={{ display: "flex" }}>
            <input
              style={{
                width: "1px",
                border: "1px solid white",
                maxHeight: "1px",
              }}
              accept="image/png, image/jpeg"
              type="file"
              onChange={(evnt) => handleUpload(index, evnt)}
              id="upload-image"
              hidden
            />
            <button
              className="btn"
              style={{ display: "flex" }}
              onClick={(evnt) => {
                evnt.preventDefault();
                document.getElementById("upload-image").click();
              }}
              disabled={image !== null}
            >
              {image === null && <AddPhotoAlternate color="secondary" />}
              {image !== null && <InsertPhoto color="secondary" />}
            </button>
            <button className="btn" onClick={() => deleteTableRows(index)}>
              <DeleteIcon color="secondary" />
            </button>
          </div>
        </td>
      </tr>
    );
  });
}

export default TableRows;
