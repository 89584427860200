import React from "react";
import BrandTableTable from "./BrandTable";
import { Link, useLocation, useNavigate } from "react-router-dom";


const ArchivedBrand = () => {
  const location = useLocation();
  const platform = new URLSearchParams(location.search).get("platform")

  return <BrandTableTable type={0} platfrom={platform} />;
}

export default ArchivedBrand;