import { Work } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { object, string } from "prop-types";
import styles from "../../../styles/theme/pageStyling/ProfileDetails.module.css";

const WorkInformation = ({ influencerAccount, platform, employmentInfo }) => {
  const createEmploymentDetails = () => {
    let employmentLine = "";
    employmentInfo.length > 0 &&
      employmentInfo.forEach((school) => {
        employmentLine += `${school.domain}, ${school.name}`;
        employmentLine += "\r\n";
      });
    return employmentLine.length > 0 ? employmentLine : "-";
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={styles.aboutMeHeader}>
        Employment Details
      </Grid>
      <Grid item xs={12} className={styles.aboutMeContent}>
        <Grid container spacing={1}>
          <Grid item xs={1} className={styles}>
            <Work />
          </Grid>
          <Grid item xs={11}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {employmentInfo ? createEmploymentDetails() : "-"}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
WorkInformation.propTypes = {
  influencerAccount: object.isRequired,
  platform: string.isRequired,
  employmentInfo: object.isRequired,
};
export default WorkInformation;
