import { Alert, Box, Button, Snackbar, Stack, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import UserAccessTable from "../../components/userAccess/UserAccessTable";
import NewUserModal from "../../components/userAccess/NewUserModal";
import SearchItem from "./SearchItem";
import { useDispatch, useSelector } from "react-redux";
import CustomTabs from "../../common/CustomTabs/CustomTabs";

function UserAccess() {
  const [newUserModal, setNewUserModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [search, setSearch] = useState("");
  const [clearPageNumber, setClearPageNumber] = useState(false);
  const [searchBy] = useState("Name");
  const user = useSelector((state) => state.user);

  const openNewUserModal = () => {
    setNewUserModal(true);
  };

  const handleSearch = (search) => {
    setSearch(search);
    setClearPageNumber(true);
  };

  const userAdded = (event) => {
    setRefreshTable(event);
  };

  const tabArray = [
    {
      label: "Active Users",
      id: "active-users-tab-0",
      to: "/userAccess/aifluence",
      component: Link,
    },
    {
      label: "Disabled Users",
      id: "disabled-users-tab-1",
      to: "/userAccess/aifluence/disabled",
      component: Link,
    },
  ]

  return (
    <>
      <h5 style={{ padding: "10px" }}>
        <b>Aifluence Users</b>
      </h5>
      <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <CustomTabs tabArray={tabArray} selectedTab={"0"} />
        <Stack direction="row" justifyContent="space-between">
          <SearchItem sendSearchedValue={handleSearch} searchBy={searchBy} />
          {["super-user"].includes(user.group) && (
            <Button size="small" variant="contained" onClick={openNewUserModal} sx={{ ml: "10px", borderRadius: "10px" }}>
              + Invite New User
            </Button>
          )}
        </Stack>
      </Box>
      <UserAccessTable
        type={1}
        search={search}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        clearPageNumber={clearPageNumber}
        setClearPageNumber={setClearPageNumber}
      />
      <NewUserModal
        newUserModal={newUserModal}
        setNewUserModal={setNewUserModal}
        setRefreshTable={setRefreshTable}
        userAdded={userAdded}
      />
    </>
  );
}

export default UserAccess;
