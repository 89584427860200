import React, { Component } from "react";
import MyPaper from "../../general/MyPaper";
import { Typography, Stack, Grid } from "@mui/material";
import utils from "../../../utils/utils";
import ProfileCard from "../../general/ProfileCard";
import FetchingDetailsLoader from "../../general/FetchingDetailsLoader";

export class TopNInfluencers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topInfluencers: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    utils.getTopNInfluencers(this.props.campId).then((data) => {
      this.calculateN(data);
    });
  }

  calculateN(data) {
    if (data) {
      if (data.length >= 10) {
        this.setState({
          topInfluencers: data.slice(0, 10),
        });
      } else if (data.length < 10 && data.length >= 5) {
        this.setState({
          topInfluencers: data.slice(0, 5),
        });
      } else if (data.length < 5 && data.length > 0) {
        this.setState({
          topInfluencers: data.slice(0, 3),
        });
      } else {
        this.setState({
          topInfluencers: [],
        });
      }
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {
      return <FetchingDetailsLoader />;
    } else {
      return (
        <MyPaper sx={{ marginTop: "20px", height: "auto" ,display :this.state.topInfluencers.length > 0 ? "block":"none" }}>
          <Stack direction="row" justifyContent="start">
            <Typography variant="h4" sx={this.props.parentStyle.typography}>
              Top {this.state.topInfluencers.length} Influencers
            </Typography>
          </Stack>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 8 }}>
            {this.state.topInfluencers.map((inf) => {
              return (
                <Grid key={inf.iid} item xs={1} sm={2} md={2}>
                  <ProfileCard
                    photoUrl={inf.photoUrl}
                    name={inf.name}
                    city={inf.location}
                    followers={inf.followerCount}
                    engagement={inf.engagement}
                    engRate={inf.engRate}
                    platform={inf.platform}
                    linkToProfile={inf.profileUrl}
                  ></ProfileCard>
                </Grid>
              );
            })}
          </Grid>
        </MyPaper>
      );
    }
  }
}

export default TopNInfluencers;
