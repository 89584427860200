import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Menu, MenuItem, Divider, ListItemIcon, IconButton, Tooltip, Avatar, Typography } from "@mui/material";
import { Logout as LogoutIcon } from "@mui/icons-material";
import Logout from "../../general/Logout";
import stringAvatar from "../../../utils/avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Notification from "../../../pages/Notification";

const UserMenu = () => {
  const user = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    user.loggedIn && (
      <>
        <Notification />
        <Tooltip title="Account settings" placement="bottom-end">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            {/* <AccountCircleIcon /> */}
            <Avatar
              sx={{ width: 32, height: 32 }}
              {...stringAvatar(user?.attributes?.name)}
            />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box sx={{ padding: "0px 25px", margin: "12px 0px" }}>
            <Typography variant="subtitle1" color="primary">
              <b>{user.attributes.name}</b>
            </Typography>
            <Typography variant="caption">{user.attributes.email}</Typography>
          </Box>
          <Divider />
          <Logout>
            <MenuItem>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Logout>
        </Menu>
      </>
    )
  );
};

export default UserMenu;
