import axios from "axios";
import { API_HOST } from "../../config/main.json";

const getNotificationType = async () => {
  const response = await axios({
    url: `${API_HOST}/notification/type`,
    method: "GET",
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getNotificationCount = async (platform, id) => {
  const response = await axios({
    url: `${API_HOST}/notification/count/${platform}/${id}`,
    method: "GET",
  });
  if (response) {
    return response.data;
  }

  return false;
};

const listNotifications = async (platform, id, from, to) => {
  const response = await axios({
    url: `${API_HOST}/notification/list/${platform}/${id}/${from}/${to}`,
    method: "GET",
  });
  if (response) {
    return response.data;
  }

  return false;
};

const markAsRead = async (notificationID) => {
  const response = await axios({
    url: `${API_HOST}/notification/${notificationID}`,
    method: "GET",
  });
  if (response) {
    return response.data;
  }

  return false;
};

export { getNotificationType, getNotificationCount, listNotifications, markAsRead };
