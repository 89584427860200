import axios from 'axios';
import {
  Google
} from '@mui/icons-material';
import { Auth, CognitoUser } from '@aws-amplify/auth';
const authApi = process.env.REACT_APP_AUTH_API;
const awsEnv = process.env.REACT_APP_AWS_ENV;

const socialLoginButtons = {
  google: {
    icon: <Google style={{ fontSize: '30px' }} />,
    handler: () => Auth.federatedSignIn({ provider: 'Google' }),
    style: {
      backgroundColor: '#DB4437 !important'
    },
    text: 'Continue with Google'
  }
};

const createInfluencerInDb = async (user) => {
  const response = await axios({
    url: `${authApi}${awsEnv}/connect-to-db`,
    method: 'POST',
    data: {
      userName: user.username,
      userPoolId: user.userPoolId,
      request: {
        userAttributes: user.attributes
      }
    }
  });

  let influencerDBId = null;

  if (typeof response.data.success !== 'undefined') {
    influencerDBId = response.data.id;
    const authUser = await Auth.currentAuthenticatedUser();
    if (authUser instanceof CognitoUser) {
      await Auth.updateUserAttributes(authUser, {
        'custom:db_id': response.data.id.toString()
      });
    } else {
      const userInfo = JSON.parse(localStorage['aws-amplify-federatedInfo']);
      userInfo.user.attributes['custom:db_id'] = response.data.id;
      localStorage['aws-amplify-federatedInfo'] = JSON.stringify(userInfo);
    }
  }

  return influencerDBId;
};

export {
  socialLoginButtons,
  createInfluencerInDb
};
