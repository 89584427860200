import { Alert, AlertTitle, Autocomplete, Box, Button, Grid, Snackbar, Typography, TextField } from "@mui/material";
import { func } from "prop-types";
import { useEffect, useState } from "react";
import { getCampaignListData } from "../../services/campaign/CampaignDetails";
import { getListsByCampaign } from "../../services/list/ListDetails";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import { inviteInfluencerByEmail } from "../../services/influencer/InfluencerInvite";
import { PLATFORM_NAME } from "../../config/main.json";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
const InviteByEmail = (props) => {
  const { setNavigateBack, presetData, callbackHandler } = props;
  const [campaignData, setCampaignData] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(
    presetData?.campaignName && presetData?.campaignId
      ? { id: +presetData?.campaignId, name: presetData?.campaignName }
      : { name: "" }
  );
  const [disabled, setDisabled] = useState(true);
  const [selectedList, setSelectedList] = useState(
    presetData?.listId && presetData?.listName
      ? { id: +presetData?.listId, name: presetData?.listName, platform: presetData?.platform?.toLowerCase() ?? "" }
      : { name: "" }
  );
  const [listData, setListData] = useState([{ name: "Please select a campaign to get list information" }]);
  const [emailList, setEmailList] = useState("");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      getAllCampaigns();
    }
    init();
  }, []);

  const getAllCampaigns = async () => {
    const data = await getCampaignListData();
    setCampaignData(data);
  };

  const chooseCampaign = async (newValue) => {
    setSelectedCampaign(newValue);
    setDisabled(true);
    if (newValue && newValue.id) {
      const data = await getListsByCampaign(newValue.id);
      setSelectedList({ name: "" });
      setListData(data);
    }
  };

  const chooseList = async (newValue) => {
    if (newValue && newValue.id && newValue.platform) {
      setSelectedList(newValue);
    }
  };

  const handleChange = (event) => {
    setEmailList(event.target.value);
  };
  const goback = () => {
    setNavigateBack(true);
  };

  const rollOutInvitation = () => {
    const notificationMetadata = {
      userId: JSON.parse(user.attributes.identities)[0].userId,
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "5",
      generatedBy: user.attributes.name,
      priority: "medium",
      consumedBy: "gazelle",
    };
    //roll out invitation api integration pending
    inviteInfluencerByEmail(
      selectedCampaign.id,
      selectedList.id,
      emailList,
      selectedCampaign.name,
      selectedList.platform,
      notificationMetadata
    ).then((result) => {
      if (result) {
        setSelectedList({ name: "" });
        setSelectedCampaign({ name: "" });
        setEmailList("");

        dispatch(
          openSnackbar({
            message: "Email Sent to the influencers",
            severity: "success",
          })
        );
        if (callbackHandler) {
          setTimeout(() => {
            callbackHandler(); // call any callback prop if passed
          }, 800);
        }
      }
    });
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(option) =>
              presetData?.campaignName && presetData?.companyName
                ? `${presetData.campaignName} (${presetData.companyName})`
                : option.name !== ""
                ? `${option.name} (${option.companyName})`
                : ""
            }
            options={campaignData}
            value={selectedCampaign}
            onChange={(event, newValue) => chooseCampaign(newValue)}
            disabled={presetData?.campaignName}
            renderInput={(params) => <TextField {...params} label="Campaign for list (mandatory)" required />}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(option) => option.name}
            options={listData ? listData : []}
            value={selectedList}
            disabled={presetData?.listName}
            renderOption={(props, option) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                <Typography sx={textColor[option.platform]}>{snapshotHeaderIcons[option.platform]}</Typography>
                &nbsp;
                {option.name}
              </Box>
            )}
            onChange={(event, newValue) => {
              setSelectedList(newValue);
              chooseList(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="List which has all your influencers" required />}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label="Enter the emails of the influencer you want to send the invite to"
            placeholder="The email ids should be either comma-separated or seperated by new line"
            multiline
            onChange={handleChange}
            value={emailList}
            rows={4}
            required
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <Box sx={{ mt: 4 }}>
            {!presetData && (
              <>
                <Button variant="outlined" size="large" type="submit" onClick={goback}>
                  Go back
                </Button>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </>
            )}
            <Button
              variant="contained"
              size="large"
              type="submit"
              onClick={rollOutInvitation}
              disabled={!selectedCampaign?.id || !selectedList?.id || !emailList.length}
            >
              Invite
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
InviteByEmail.propTypes = {
  setNavigateBack: func.isRequired,
};
export default InviteByEmail;
