import React from 'react';
import { useParams } from 'react-router-dom';
import Common from "./Common";

const AnalyzeProfile = () => {
  const { id, platform } = useParams();
  return <Common id={id} platform={platform} />
}

export default AnalyzeProfile;

