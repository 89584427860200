import { Grid } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styles from "../../styles/theme/pageStyling/AddInvites.module.css";
import { useState } from "react";
import InviteSelection from "./InviteSelection";
import InviteLayout from "./InviteLayout";
import { object } from "prop-types";
import utils from "../../utils/utils";

const AddInvites = ({ presetData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [type, setType] = useState("");
  const steps = [
    "How do you want to invite the influencer?",
    "Send out those invites",
  ];

  const moveToNextStep = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };
  const selectedValue = (value) => {
    moveToNextStep();
    setType(value);
  };

  const goback = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!presetData && <div className={styles.heading}>Let's get this journey started</div>}
          <div className={utils.formatClassnames(styles.selectionBox,presetData?.campaignId && styles.removePadding)}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={styles.childBox}>
              {activeStep === 0 && (
                <InviteSelection selectedValue={selectedValue} />
              )}
              {activeStep === 1 && (
                <InviteLayout typeOfInvite={type} goback={goback}/>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

AddInvites.propTypes = {
  presetData: object
};

export default AddInvites;
