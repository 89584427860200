import {
  Alert,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Datatables from "mui-datatables";
import { Close, ContentCopy, Download, Email, Info, Preview } from "@mui/icons-material";
import { API_HOST, CAMPAIGN_BRIEF_LINK } from "../../config/main.json";
import axios from "axios";
import utils from "../../utils/utils";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import CustomTabs from "../../common/CustomTabs/CustomTabs";

function CampaignBrief() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [backdropShow, setBackdropShow] = useState(true);
  const [data, setData] = useState([]);
  const [requestModal, setRequestModal] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [account, setAccount] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [responseData, setResponseData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = () => {
    axios.get(API_HOST + "/campaignbrief/all").then((res) => {
      let data = [];
      if (res.data) {
        data = res.data;
      }
      setData(data);
      setIsLoaded(true);
      setBackdropShow(false);
    });
  };

  const getAccountData = () => {
    axios.get(`${API_HOST}/account/list/type/1/all`).then((res) => {
      let accountData = [];
      if (res.data) {
        accountData = res.data;
      }
      setAccountData(accountData);
    });
  };

  const resendEmail = (uuid) => {
    axios.get(API_HOST + "/campaignbrief/sendreminder/" + uuid).then((res) => {
      dispatch(
        openSnackbar({
          message: "Reminder of campaign brief sent",
          severity: "success",
        })
      );
    });
  };

  const copyLink = (uuid) => {
    const link = CAMPAIGN_BRIEF_LINK + uuid;
    navigator.clipboard.writeText(link);
    dispatch(
      openSnackbar({
        message: "Link has been copied to your clipboard",
        severity: "success",
      })
    );
  };

  const openRequestModal = () => {
    if (accountData.length === 0) {
      getAccountData();
    }
    setName("");
    setEmail("");
    setAccount("");
    setRequestModal(true);
  };

  const openPreviewModal = (uuid) => {
    axios.get(API_HOST + "/campaignbrief/response/" + uuid).then((res) => {
      const responseData = JSON.parse(res.data[0].response);
      setResponseData(responseData);
      setPreviewModal(true);
    });
  };

  const closeModal = () => {
    setRequestModal(false);
    setPreviewModal(false);
  };

  const downloadCampaignBriefResponse = async (uuid) => {
    const response = await axios({
      method: "GET",
      url: `${API_HOST}/campaignbrief/download/${uuid}`,
      responseType: "blob",
    });
    dispatch(
      openSnackbar({
        message: "Campaign brief's response downloaded successfully!",
        severity: "success",
      })
    );
    await FileSaver(response.data, `${uuid}.pdf`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRequestModal(false);

    const data = {
      name: name,
      email: email,
      account: account,
    };

    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios.post(API_HOST + "/campaignbrief/request", data, optionAxios).then(() => {
      dispatch(
        openSnackbar({
          message: "Campaign Brief Request Sent!",
          severity: "success",
        })
      );
      getTableData();
    });
  };

  let tableData = [];
  tableData = data.map((row) => {
    row.nameColumn = (
      <Grid container>
        <Grid item xs={2}>
          <Tooltip title={"Email sent to: " + row.email}>
            <Info fontSize="small" />
          </Tooltip>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={9}>
          {row.name}
        </Grid>
      </Grid>
    );
    row.typeColumn = (
      <div>
        <div>{row.requested ? "Requested" : "Inbound"}</div>
        <div>{row.requested ? `on ${utils.convertDate(row.requestedTime, "datetime")} GMT` : ""}</div>
      </div>
    );
    row.typeOfRequest = row.requested ? "Requested" : "Inbound";
    row.requestedTime = row.requested ? `${utils.convertDate(row.requestedTime, "datetime")} GMT` : "";

    row.status = row.submissionTime === "" ? "Pending" : "Submitted";
    row.statusTime = row.status === "Submitted" ? `${utils.convertDate(row.submissionTime, "withtime")} GMT` : "";

    row.statusField = (
      <div>
        <div>{row.status}</div>
        <div>{row.status === "Submitted" ? `on ${utils.convertDate(row.submissionTime, "withtime")} GMT` : ""}</div>
      </div>
    );
    row.submissionTimeColumn = row.submissionTime === "" ? "" : utils.convertDate(row.submissionTime, "withtime") + " GMT";
    row.actions =
      row.status === "Pending" ? (
        <>
          <IconButton onClick={() => resendEmail(row.uuid)}>
            <Tooltip title={"Resend Request Email"}>
              <Email fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => copyLink(row.uuid)}>
            <Tooltip title={"Copy Link"}>
              <ContentCopy fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      ) : (
        <>
          <IconButton onClick={() => openPreviewModal(row.uuid)}>
            <Tooltip title={"View Response"}>
              <Preview fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => downloadCampaignBriefResponse(row.uuid)}>
            <Tooltip title={"Download Response"}>
              <Download fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      );
    return row;
  });

  const columns = [
    {
      label: "Name",
      name: "nameColumn",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value) => {
          return value;
        },
      },
    },
    {
      label: "Name",
      name: "name",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      label: "Organisation",
      name: "organisation",
    },
    {
      label: "Type",
      name: "typeColumn",
      options: {
        download: false,
      },
    },
    {
      label: "Status",
      name: "statusField",
      options: {
        download: false,
      },
    },
    {
      label: "Type Of Request",
      name: "typeOfRequest",
      options: {
        display: false,
      },
    },
    {
      label: "Requested Time",
      name: "requestedTime",
      options: {
        display: false,
      },
    },
    {
      label: "Status Of Request",
      name: "status",
      options: {
        display: false,
      },
    },
    {
      label: "Status Updated On",
      name: "statusTime",
      options: {
        display: false,
      },
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        sort: false,
        download: false,
      },
    },
  ];

  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
  };

  const tabArray = [
    {
      label: "Campaign Briefs",
      id: "campaign-briefs-tab-0",
      to: "/bdTools/campaignBrief",
      component: Link,
    },
  ]

  return isLoaded ? (
    <>
      <Box m={3}>
        <CustomTabs tabArray={tabArray} selectedTab={"0"} />
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" onClick={openRequestModal}>
            + New Campaign Brief Request
          </Button>
        </Stack>
      </Box>
      <Datatables data={tableData} columns={columns} options={options} />
      <Modal
        className="styles_modal"
        aria-labelledby="modal-new_request-label"
        aria-describedby="modal-new_request-description"
        open={requestModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={requestModal}>
          <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-new_request-heading" className="styles_modal_heading">
              <h2 id="modal-new_request-heading-text" style={{ padding: "10px" }}>
                New Campaign Brief Request
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <h5 id="modal-new_request-sub-heading-text" style={{ paddingLeft: "10px" }}>
              Receiver's Details
            </h5>
            <div id="modal-new_request-body" style={{ padding: "10px" }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} style={{ width: "100%" }}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event) => setName(event.target.value)}
                      variant="outlined"
                      label="Name"
                      style={{ width: "100%" }}
                      required={true}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event) => setEmail(event.target.value)}
                      variant="outlined"
                      label="Email"
                      style={{ width: "100%" }}
                      type="email"
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel>Account</InputLabel>
                      <Select
                        onChange={(event) => setAccount(event.target.value)}
                        variant="outlined"
                        label="Account"
                        style={{ width: "100%" }}
                        unselectable
                        required={true}
                      >
                        <MenuItem value="" key="none">
                          None
                        </MenuItem>
                        {accountData.map((account) => (
                          <MenuItem value={account.name} key={account.id}>
                            {account.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <button
                  variant="contained"
                  className="btn btn-success"
                  style={{
                    float: "right",
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginRight: "15px",
                  }}
                  type="submit"
                >
                  Send Request Email
                </button>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className="styles_modal"
        aria-labelledby="modal-preview-label"
        aria-describedby="modal-preview-description"
        open={previewModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={previewModal}>
          <div className="syles_paper_list" style={{ height: "80vh", width: "50%" }}>
            <div id="modal-preview-heading" className="styles_modal_heading">
              <h2 id="modal-preview-heading-text" style={{ padding: "10px" }}>
                Response
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-preview-body" style={{ padding: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h4>Campaign Overview</h4>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Organisation"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.step1?.organisation}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Organisation Type"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.step1?.organisationType}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="When do you want to start?"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.step1?.start}
                  />
                </Grid>
                <Grid item xs={6}>
                  {responseData.step1?.startDate && (
                    <TextField
                      variant="outlined"
                      label="Date"
                      style={{ width: "100%" }}
                      inputProps={{
                        readOnly: true,
                      }}
                      value={responseData.step1?.startDate}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={3}
                    variant="outlined"
                    label="Campaign Summary"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.step1?.summary}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <h4>Campaign Brief</h4>
                </Grid>
                {["Product/Service or Brand(s)", "Marketing Agency"].includes(responseData.step1?.organisationType) && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        label="What is the main objective of your campaign?"
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={responseData.step2?.objective}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        label="Brand Name"
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={responseData.step2?.brandName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Brand Attributes</InputLabel>
                        <Select
                          variant="outlined"
                          label="Brand Attributes"
                          style={{ width: "100%" }}
                          value={responseData.step2?.attributes}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        label="Brand Category"
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={responseData.step2?.category}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        label="What is your target audience?"
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={responseData.step2?.target}
                      />
                    </Grid>
                  </>
                )}
                {["NGO/NPO, or a Donor"].includes(responseData.step1?.organisationType) && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        label="What is the main objective of your campaign?"
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={responseData.step2?.objective}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Area of Focus</InputLabel>
                        <Select
                          variant="outlined"
                          label="Area of Focus"
                          style={{ width: "100%" }}
                          value={responseData.step2?.focus}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={2}
                        variant="outlined"
                        label="Program/Organizational Goals"
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={responseData.step2?.goals}
                      />
                    </Grid>
                  </>
                )}

                {["Product/Service or Brand(s)", "Marketing Agency"].includes(responseData.step1?.organisationType) && (
                  <>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <h4>Audience/Consumer Targets</h4>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Age</InputLabel>
                        <Select
                          variant="outlined"
                          label="Age"
                          style={{ width: "100%" }}
                          value={responseData.step3?.age}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Gender</InputLabel>
                        <Select
                          variant="outlined"
                          label="Gender"
                          style={{ width: "100%" }}
                          value={responseData.step3?.gender}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Interests</InputLabel>
                        <Select
                          variant="outlined"
                          label="Interests"
                          style={{ width: "100%" }}
                          value={responseData.step3?.interests}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Industry</InputLabel>
                        <Select
                          variant="outlined"
                          label="Industry"
                          style={{ width: "100%" }}
                          value={responseData.step3?.industry}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Languages</InputLabel>
                        <Select
                          variant="outlined"
                          label="Languages"
                          style={{ width: "100%" }}
                          value={responseData.step3?.languages}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={12}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Locations</InputLabel>
                        <Select
                          variant="outlined"
                          label="Locations"
                          style={{ width: "100%" }}
                          value={responseData.step3?.locations}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {["NGO/NPO, or a Donor"].includes(responseData.step1?.organisationType) && (
                  <>
                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                      <h4>Target Demographic/Audience</h4>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Age</InputLabel>
                        <Select
                          variant="outlined"
                          label="Age"
                          style={{ width: "100%" }}
                          value={responseData.step3?.age}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Gender</InputLabel>
                        <Select
                          variant="outlined"
                          label="Gender"
                          style={{ width: "100%" }}
                          value={responseData.step3?.gender}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Industry</InputLabel>
                        <Select
                          variant="outlined"
                          label="Industry"
                          style={{ width: "100%" }}
                          value={responseData.step3?.industry}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Languages</InputLabel>
                        <Select
                          variant="outlined"
                          label="Languages"
                          style={{ width: "100%" }}
                          value={responseData.step3?.languages}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        label="Religion"
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={responseData.step3?.religion ? "Yes" : "No"}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Audience Touch Points</InputLabel>
                        <Select
                          variant="outlined"
                          label="Audience Touch Points"
                          style={{ width: "100%" }}
                          value={responseData.step3?.audience}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel>Locations</InputLabel>
                        <Select
                          variant="outlined"
                          label="Locations"
                          style={{ width: "100%" }}
                          value={responseData.step3?.locations}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </Box>
                          )}
                          inputProps={{
                            readOnly: true,
                          }}
                        ></Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        label="Social Challenges"
                        style={{ width: "100%" }}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={responseData.step3?.social}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <h4>About you</h4>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Name"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.step4?.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Email"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.step4?.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="What is your position in the company?"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.step4?.position}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Phone"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.step4?.phone ? "+" + responseData.step4?.phone : ""}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
      open={backdropShow}
    >
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
}

export default CampaignBrief;
