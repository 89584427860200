import React from "react";
import AccountTable from "./AccountTable";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ArchivedAccounts = () => {
  const location = useLocation();
  const platform = new URLSearchParams(location.search).get("platform");

  return <AccountTable type={0} platfrom = {platform} />;

}

export default ArchivedAccounts;
