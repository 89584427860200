import { Boy, Email, Face, Flag, Lightbulb, PermIdentity, Phone, School } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { object, string } from "prop-types";
import styles from "../../../styles/theme/pageStyling/ProfileDetails.module.css";

const EducationInformation = ({ influencerAccount, platform, educationInfo }) => {
  const createEducationInfo = () => {
    let schoolLine = "";
    educationInfo.length > 0 &&
      educationInfo.forEach((school) => {
        schoolLine += `${school.degree}, `;
        schoolLine += `${school.name} (${school.grad_year ? school.grad_year : ""})`;
        schoolLine += "\r\n";
      });
    return schoolLine.length > 0 ? schoolLine : "-";
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={styles.aboutMeHeader}>
        Education Details
      </Grid>
      <Grid item xs={12} className={styles.aboutMeContent}>
        <Grid container spacing={1}>
          <Grid item xs={1} className={styles}>
            <School />
          </Grid>
          <Grid item xs={11}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {educationInfo ? createEducationInfo() : "-"}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
EducationInformation.propTypes = {
  influencerAccount: object.isRequired,
  platform: string.isRequired,
  educationInfo: object.isRequired,
};
export default EducationInformation;
