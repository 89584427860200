import moment from "moment";

const getDaysLeft = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, "days");
};

const getTodaysDate = () => {
  return moment().format("MMMM Do YYYY");
};


//The function utilizes the moment library to parse the input date and then format it 
//according to the provided format string using the format() method.
const getFormattedDate = (date, format) => {
  return moment(date).format(format);
};

const getFutureDate = (date, amount, unit) => {
  return moment(date).add(amount, unit).format();
};

const getFullDate = (date) => {
  return moment(date).format();
};


// It creates a new Date object based on the provided UTC timestamp.
// It defines an options object specifying how the date and time should be formatted.
// It uses the toLocaleString method to convert the utcdate to a string representation in the user's timezone according to the specified options.
// It returns the formatted date string representing the date and time in the user's timezone.
const convertToUserTimezone = (utcTimestamp) => {
  const utcDate = new Date(utcTimestamp);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };
  const userTimezoneDate = utcDate.toLocaleString('en-US', options);
  return userTimezoneDate;
};

export { getDaysLeft, getTodaysDate, getFormattedDate, getFutureDate, getFullDate, convertToUserTimezone };
