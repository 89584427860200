import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { bool, func, object, string } from "prop-types";
import { useEffect, useRef, useState } from "react";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import { CardMedia, Divider, Grid, TextField, Typography } from "@mui/material";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import { LocationOn } from "@mui/icons-material";
import utils from "../../utils/utils";

const ViewNoteDialog = ({ functionality, influencerAccount, viewNoteDialog, closeViewNoteDialog, sendInformation }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const platform = params.get("platform");
  const [scroll, setScroll] = useState("paper");
  const [note, setNote] = useState("");
  const descriptionElementRef = useRef(null);
  const [isUpdate, setIsUpdate] = useState(false);
  useEffect(() => {
    console.log(influencerAccount.note)
    if (viewNoteDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [viewNoteDialog]);

  const handleClose = () => {
    closeViewNoteDialog(false);
  };

  const getLabel = () => {
    return !isUpdate
      ? `Update a ${functionality} for ${influencerAccount.name}`
      : `Add a ${functionality} for ${influencerAccount.name}`;
  };

  const handleChange = (event) => {
    setNote(event.target.value);
  };

  const updateInformation = () => {
    sendInformation({ id: influencerAccount.id, note, createdAt: new Date() });
  };

  return (
    <Dialog
      open={viewNoteDialog}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <CardMedia
              component="img"
              image={influencerAccount.photoUrl}
              alt={influencerAccount.name}
              onError={utils.addDefaultPostThumbnailImage}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={styles.profileHeading}>
                {influencerAccount.name}
              </Grid>
              <Grid item xs={12} className={styles.location}>
                <LocationOn /> {influencerAccount.location != 0 ? influencerAccount.location : "-"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Typography sx={textColor[platform?.toLowerCase()]}>{snapshotHeaderIcons[platform?.toLowerCase()]}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          {influencerAccount?.note && (
            <>
              <div className={styles.borderBox}>
                <h4 className={styles.cardHeading}>Previous Notes</h4>
              </div>

              {influencerAccount?.note?.map((note) => {
                return (
                  <span>
                    <span className={styles.cardData}> {note?.note}</span>{" "}
                    <i style={{ fontSize: "14px" }}> ({new Date(note?.createdAt).toDateString()})</i> <br />
                  </span>
                );
              })}
              <Divider />
              <br />
            </>
          )}

          <TextField
            style={{ width: "100%" }}
            label={getLabel()}
            multiline
            rows={10}
            variant="outlined"
            onChange={handleChange}
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={updateInformation}>
          Add {functionality}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ViewNoteDialog.propTypes = {
  functionality: string.isRequired,
  influencerAccount: object.isRequired,
  viewNoteDialog: bool.isRequired,
  closeViewNoteDialog: func.isRequired,
  sendInformation: func.isRequired,
};

export default ViewNoteDialog;
