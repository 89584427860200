// this contains the hard-coded values for now.
// helpful for backend, later on.
// remove once backend APIs are integrated.

import { Grid, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import utils from "../../utils/utils";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";

const enums = {
  defaultStyles: {
    indeterminateColor: {
      color: "#f50057",
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
    typography: {
      marginRight: "auto",
      display: "flex",
      alignItems: "center",
      color: "#33CC99",
      borderBottom: "2px solid #33CC99",
      marginBottom: "20px",
    },
    noData: {
      position: "relative",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      fontSize: "large",
      fontStyle: "italic",
    },
    centerElement: {
      position: "relative",
      left: "50%",
      top: "50%",
      textAlign: "center",
      transform: "translate(-50%, -50%)",
    },
    fetchingDetails: {
      marginTop: "20px",
      paddingLeft: "50px",
    },
  },
  linkInfo_tableOptions: {
    filterType: "dropdown",
    expandableRows: true,
    expandableRowsOnClick: true,
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    download: false,
    print: false,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
    renderExpandableRow: (rowData, _rowMeta) => {
      return (
        <>
          <TableRow style={{ backgroundColor: "#f6f1f1", width: "100%" }}>
            <TableCell colSpan={12}>
              <h6 style={{ paddingLeft: "30px" }}>
                <b className="MuiFormHelperText-root">Destination URL</b>
                <br />
                {rowData[7]}
              </h6>
            </TableCell>
          </TableRow>
        </>
      );
    },
  },
  subTableColumns: [
    {
      label: "Influencer Name",
      name: "influencerNameAndUsername",
    },
    {
      label: "URL",
      name: "links",
      options: {
        customBodyRender: (value, tableMeta, _updateValue) => {
          return (
            <Tooltip title={value.destination}>
              <a href={value.destination} rel="noreferrer" target="_blank">
                {value.short}
              </a>
            </Tooltip>
          );
        },
      },
    },
    {
      label: "Clicks",
      name: "clicks",
    },
  ],
  subTableOptions: {
    responsive: "simple",
    selectableRows: "none",
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    filter: false,
    pagination: false,
  },
  linkInfo_multiTableOptions: {
    filterType: "dropdown",
    expandableRows: true,
    expandableRowsOnClick: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    renderExpandableRow: (rowData, _rowMeta) => {
      return rowData[0];
    },
  },
  linkInfo_tableColumns: [
    {
      label: "Sr NO.",
      name: "sl",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      label: "Short Url Id",
      name: "shortUrlId",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      label: "Influencer",
      name: "influencerNameAndUsername",
    },
    {
      label: "Group",
      name: "listName",
    },
    {
      label: "URL",
      name: "shortUrl",
      options: {
        customBodyRender: (value, tableMeta, _updateValue) => {
          return (
            <a href={"https://" + value} rel="noreferrer" target="_blank">
              {value}
            </a>
          );
        },
      },
    },
    {
      label: "Created On",
      name: "createdAt",
    },
    {
      label: "Clicks",
      name: "clicks",
    },
    {
      label: "Destination URL",
      name: "destinationUrl",
      options: {
        display: false,
        filter: false,
      },
    },
  ],
  linkInfo_multiCableColumns: [
    {
      name: "expansion",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      label: "Destination URL",
      name: "destinationUrl",
      options: {
        customBodyRender: (value, tableMeta, _updateValue) => {
          return (
            <a href={value} rel="noreferrer" target="_blank">
              {value}
            </a>
          );
        },
      },
    },
    {
      label: "Group",
      name: "group",
      options: {
        customBodyRender: (value, tableMeta, _updateValue) => {
          return (
            <>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography
                    sx={{...textColor[value.platform], width: "24px", height: "24px" }}
                  >
                    {snapshotHeaderIcons[value.platform]}
                  </Typography>
                </Grid>
                <Grid item>{utils.capitalizeFirstLetter(value.list)}</Grid>
              </Grid>
            </>
          );
        },
      },
    },
    {
      label: "Total Clicks",
      name: "totalClicks",
    },
  ],
  chartMenu: [
    {
      key: "save_as",
      value: "Save As",
      isDisabled: true,
      submenu: {
        png: "png",
        jpeg: "jpeg",
        pdf: "pdf",
      },
    },
    {
      key: "view_chart_as",
      value: "View Chart As",
      isDisabled: true,
      submenu: {
        bar: "bar",
        line: "line",
      },
    },
  ],
  countryLocation_tableColumns: [
    {
      label: "Country",
      name: "country",
    },
    {
      label: "Clicks",
      name: "clicks",
    },
    {
      label: "Users",
      name: "percentage",
    },
  ],
  cityLocation_tableColumns: [
    {
      label: "City",
      name: "city",
    },
    {
      label: "Country",
      name: "country",
    },
    {
      label: "Clicks",
      name: "clicks",
    },
    {
      label: "Users",
      name: "percentage",
    },
  ],
  location_tableOptions: {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    responsive: "simple",
    pagination: false,
    tableBodyMaxHeight: "320px",
    selectableRows: "none",
  },
};

export default enums;
