import { useState, useEffect } from "react";

import { PropTypes } from "prop-types";

import { Avatar, Button, Grid, Stack, Tooltip, Paper, Skeleton, Modal, Backdrop } from "@mui/material";

// import enums_discovery from "../discovery/enums_discovery";

import StyledTable2 from "../../utils/styledTable2";
import utils from "../../utils/utils";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import ViewProfileDialog from "../list/ViewProfileDialog";
import AddInfToListDialog from "../discovery/AddInfToListDialog";
import AddInfToNewListDialog from "../discovery/AddInfToNewListDialog";
import MyPaper from "../general/MyPaper";
import QueryStatsTwoToneIcon from "@mui/icons-material/QueryStatsTwoTone";
import { Link } from "react-router-dom";
import CustomTable from "../../common/CustomTable/CustomTable";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ContactCard from "./components/AnalyzeProfile/components/ContactCard/ContactCard";
import DiscoveryActionBtn from "./DiscoveryActionBtn";
import FilterEnum from "./FilterEnum";

const tableColors = {
  header: {
    backgroundColor: "#F3F6F9",
    textColor: "#B5B5C3",
  },
};

const DiscoveryTable = ({
  platform,
  tableDataLoaded,
  tableData,
  tableDataUpdated,
  tablePageChanged,
  tableRowsPerPageChanged,
  sortOrderChanged,
  updatePageNo,
  tableRowHeaders,
  finalTableRowHeaders,
  selectedData,
  customCols,
  setTableRowHeaders,
  appliedFilters,
  setAppliedFilters,
  allFiltersData,
  tablePageNo,
  setAllFiltersData,
  tableRowsPerPage,
  sortCol,
  handleInformationChange,
}) => {
  const [bodyColumns, setBodyColumns] = useState(0);
  const [totalTableRows, setTotalTableRows] = useState(0);

  const [selectedRow, setSelectedRow] = useState({}); // for 3-dot menu action
  const [additionalDetail, setAdditionalDetail] = useState({});
  const [viewProfileDialog, setViewProfileDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedRows_iid, setSelectedRows_iid] = useState([]);

  const [addToListDialog, setAddToListDialog] = useState(false);
  const [addInfToNewListDialog, setAddInfToNewListDialog] = useState(false);
  const [basicHeader] = useState(FilterEnum.headers);

  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const menuOptions = (row) => [
    {
      id: "contact-info",
      name: "Contact Info",
      icon: <AccountCircleRoundedIcon />,
      action: () => {
        setActiveMenuItem(row?.account?.user_profile);
        handleModalOpen();
      },
    },
  ];

  useEffect(() => {
    if (!selectedData || !selectedData["data"]["accounts"] || selectedData["data"]["accounts"].length === 0) {
      setTotalTableRows(0);
      setBodyColumns([]);
      return;
    }
    const totalRows = selectedData["data"]["total"];
    setTotalTableRows(totalRows);
    setTableRowHeaders([]);
    let newHeaders = tableRowHeaders;
    let filteredArray = {};
    if (newHeaders.length === 4) {
      filteredArray = basicHeader;
    } else {
      filteredArray = basicHeader.filter((obj) => obj.field !== "action");
    }
    for (const [key, value] of Object.entries(customCols[0])) {
      let filters = appliedFilters;
      filters[key] = value;
      let allFilters = [];
      if (value.length > 0) {
        if (typeof value === "object") {
          value.forEach((row, index) => {
            let latestLoc = row;
            let newCol = { id: filteredArray.length + 1, label: latestLoc, sortParameter: latestLoc,headerAssignment: latestLoc, show: true };
            const existingElement = filteredArray.find((element) => element.label === latestLoc);
            if (!existingElement) {
              filteredArray.push(newCol);
              allFilters.push(latestLoc);
            }
          });
        } else {
          let latestLoc = value;
          let newCol = { id: filteredArray.length + 1, label: latestLoc, sortParameter: latestLoc,headerAssignment: latestLoc, show: true };
          const existingElement = filteredArray.find((element) => element.label === latestLoc);
          if (!existingElement) {
            filteredArray.push(newCol);
            allFilters.push(latestLoc);
          }
        }
      }
    }
    setAppliedFilters(allFiltersData);
    const analyzeCol = { id: filteredArray.length + 1, label: "", sortParameter: "action",headerAssignment: "action" ,show: true };
    filteredArray.push(analyzeCol);
    setTableRowHeaders(filteredArray);
    const bodyCols = [];
    const records = tableData["data"]["accounts"];
    records.forEach((row, index) => {
      let newRec = row["account"]["user_profile"];
      const iid = newRec["user_id"];
      let displayName = "";
      let name = "";
      let username = "";
      let displayUsername = "";
      if (newRec["fullname"] !== undefined) {
        name = newRec["fullname"];
        displayName = name;
        // if (displayName !== "undefined") {
        //   if (displayName.length > 18) {
        //     displayName = displayName.slice(0, 15) + "...";
        //   }
        // }
      }
      if (newRec["username"] !== undefined) {
        username = newRec["username"];
        displayUsername = username;
        // if (displayUsername.length > 10) {
        //   displayUsername = displayUsername.slice(0, 7) + "..."
        // }
      }

      let profileLink = newRec["url"];

      const photoUrl = newRec["picture"] ? newRec["picture"] : "";
      const bodyCol = {
        id: index + 1,
        iid,
        profile: (
          <Grid container columns={{ xs: 4, sm: 6, md: 10 }}>
            {/* Avatar */}
            <Grid item xs={4} sm={2} md={2}>
              <a href={profileLink} target="_blank" rel="noreferrer">
                <Avatar variant="circular" sx={{ width: "50px", height: "50px" }} src={photoUrl} />
              </a>
            </Grid>
            <Grid item xs={8}>
              <Grid container columns={{ xs: 4, sm: 10, md: 10 }}>
                <a href={profileLink} target="_blank" rel="noreferrer">
                  <Grid item xs={10} sx={{ fontSize: "16px" }}>
                    <Tooltip title={name}>
                      <span className="name containsTootlip">
                        <b>{displayName}</b>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sx={{ fontSize: "14px" }}>
                    <Tooltip title={username}>
                      <span className="subtext username containsTooltip">@{displayUsername}</span>
                    </Tooltip>
                  </Grid>
                </a>
              </Grid>
            </Grid>
          </Grid>
        ),
        following: (
          <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid item xs={4} sm={6} md={12}>
              {utils.convertToInternationalNumberSystem(newRec["followers"])}
            </Grid>
          </Grid>
        ),
        engagements: (
          <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid item xs={4} sm={6} md={12}>
              {utils.convertToInternationalNumberSystem(newRec["engagements"])}
            </Grid>
          </Grid>
        ),
        engagement_rate: (
          <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
            <Grid item xs={4} sm={6} md={12}>
              {newRec["engagement_rate"].toFixed(2) + "%"}
            </Grid>
          </Grid>
        ),
        action: (
          <Grid container columns={{ xs: 4, sm: 6, md: 8 }}>
            <Grid item xs={4} sm={6} md={8} display={"flex"} alignItems={"center"} justifyContent={"end"}>
              <>
                <DiscoveryActionBtn
                  menuOptions={menuOptions(row)}
                  setActiveMenuItem={setActiveMenuItem}
                  activeMenuItem={activeMenuItem}
                  handleModalOpen={handleModalOpen}
                />
                <Link to={`/advance/analyze/${platform?.toLowerCase()}/${iid}`} target="_blank" rel="noopener noreferrer">
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    sx={{ ml: "10px" }}
                    style={{ color: "white", backgroundColor: "#51A6FF", float: "right" }}
                    className={styles.button}
                  >
                    <span>
                      <QueryStatsTwoToneIcon />
                    </span>{" "}
                    Analyse
                  </Button>
                </Link>
              </>
            </Grid>
          </Grid>
        ),
      };
      for (const [key, value] of Object.entries(customCols[0])) {
        if (value.length > 0) {
          if (typeof value === "object") {
            value.forEach(function (item, index) {
              if (row["account"]["colData"] != null) {
                if (row["account"]["colData"][key] != null) {
                  bodyCol[item] = row["account"]["colData"][key][item] || "-";
                }
              }
            });
          } else {
            if (row["account"]["colData"] != null) {
              if (row["account"]["colData"][key] != null) {
                bodyCol[value] = row["account"]["colData"][key][value] || "-";
              }
            }
          }
        }
      }
      bodyCols.push(bodyCol);
    });
    setBodyColumns(bodyCols);
  }, [tableDataUpdated, allFiltersData]);

  const closeViewProfileDialog = () => {
    setAdditionalDetail({});
    setViewProfileDialog(false);
  };

  const pageChanged = (pageNo) => {
    tablePageChanged(pageNo, -1);
  };
  const rowsPerPageChanged = (rowsPerPage) => {
    tableRowsPerPageChanged(rowsPerPage);
  };

  const rowSelectionChanged = (rowIdsSelected) => {
    setSelectedRows(rowIdsSelected);
  };
  const closeAddInfToListDialog = (_successfullyAddedToList) => {
    setAddToListDialog(false);
  };

  const infToListCreation = () => {
    setAddToListDialog(false);
    setAddInfToNewListDialog(true);
  };
  const closeInfToNewListDialog = () => {
    setAddInfToNewListDialog(false);
  };

  return tableDataLoaded ? (
    <>
      <div id="table">
        {bodyColumns?.length > 0 ? (
          <CustomTable
            tableHeaders={finalTableRowHeaders}
            bodyColumns={bodyColumns}
            // expand={expand}
            selectedRow={selectedRow}
            sendInformation={handleInformationChange}
            totalRows={totalTableRows}
            pageNo={tablePageNo}
            rowsPerPageNo={tableRowsPerPage}
            sortOrder={"desc"}
            sortOrderBy={sortCol}
          />
        ) : (
          <div style={{ marginTop: "20px" }}>
            <MyPaper>No data available. Please expand the filters.</MyPaper>
          </div>
        )}
      </div>
      {viewProfileDialog && (
        <ViewProfileDialog
          influencerAccount={selectedRow}
          viewProfileDialog={viewProfileDialog}
          closeViewProfileDialog={closeViewProfileDialog}
          additionalDetail={additionalDetail}
          platform={platform}
          engagementDisplay={false}
        />
      )}
      {/* checkMe: later on, fetch next 2 components into a new component - duplicay in DiscoveryActionButton */}
      {addToListDialog && (
        <AddInfToListDialog
          addToListDialog={addToListDialog}
          platform={platform}
          iidsSelected={selectedRows_iid}
          modalClosed={closeAddInfToListDialog}
          newListCreation={infToListCreation}
        />
      )}
      {addInfToNewListDialog && (
        <AddInfToNewListDialog
          addInfToNewListDialog={addInfToNewListDialog}
          platform={platform}
          iidsSelected={selectedRows_iid}
          modalClosed={closeInfToNewListDialog}
        />
      )}
      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <ContactCard
          avatarInfo={{ picUrl: activeMenuItem?.picture, name: activeMenuItem?.username, userId: activeMenuItem?.user_id }}
          platform={platform}
          handleModalClose={handleModalClose}
        />
      </Modal>
    </>
  ) : (
    <>
      {new Array(10).fill(1).map((_, idx) => (
        <Skeleton variant="rectangular" height={50} sx={{ mb: "1rem", borderRadius: "6px" }} key={`skel-${idx}`} />
      ))}
    </>
  );
};

export default DiscoveryTable;
