import axios from "axios";
import { API_HOST } from "../../config/main.json";
import { getResponseType } from "../../utils/exportData";
import { Campaign } from "@mui/icons-material";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

/* 
  get details of all unfinished campaigns
*/
const getCampaignListData = async (type = 1) => {
  const response = await axios({
    url: `${API_HOST}/campaign/list/type/${type}`,
    method: "GET",
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getCampaignList = async (
  account,
  type,
  isExport,
  page,
  rows,
  SortCol,
  SortOrder,
  filter,
  mimeType,
  campaignPlatform,
  timeZone,
  selectedStatus
) => {

  const response = await axios({
    url: `${API_HOST}/campaigns`,
    method: "POST",
    data: {
      account,
      type,
      isExport,
      page,
      rows,
      SortCol,
      SortOrder,
      filter,
      mimeType,
      addedFrom: campaignPlatform,
      timeZone,
      status: selectedStatus
    },
    headers: optionAxios.headers,
    responseType: getResponseType(isExport),
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getAccountCampaignList = async (
  account,
  type,
  isExport,
  page,
  rows,
  SortCol,
  SortOrder,
  filter,
  mimeType,
  campaignPlatform,
  timeZone,
  selectedStatus
) => {

  const response = await axios({
    url: `${API_HOST}/campaigns`,
    method: "POST",
    data: {
      account,
      type,
      isExport,
      page,
      rows,
      SortCol,
      SortOrder,
      filter,
      mimeType,
      addedFrom: campaignPlatform,
      timeZone,
      status: selectedStatus,
      accountFilter: true 
    },
    headers: optionAxios.headers,
    responseType: getResponseType(isExport),
  });
  if (response) {
    return response.data;
  }

  return false;
};

const editOnDetailsChange = (payload) => {
  return axios.post(`${API_HOST}/campaign/influencer/edit`, payload).then((res) => {
    return res.data;
  });
};

const changeCampaignStatus = (CampaignId, current_status ) => {
  let body = {};
    body["id"] = CampaignId;
    body["current_status"] = current_status;
    let optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return axios.post(`${API_HOST}/campaign/status/update`, body, optionAxios).then((res) => {
      return res; // it returns only a success stsutus , implying that status is changed 

      // window.location = "/campaigns"; //This line of code will redirect you once the submission is succeed
    });
}

// const deleteForever = (id) => {
//   axios.get(`${API_HOST}/campaign/delete/` + id).then((res) => {
//     return res;
//   })
// }

// const deleteForever = (id) => {
//   return new Promise((resolve, reject) => {
//     axios.get(`${API_HOST}/campaign/delete/` + id)
//       .then((res) => {
//         resolve(res); // Resolve the Promise with the response data
//       })
//       .catch((error) => {
//         reject(error); // Reject the Promise with the error
//       });
//   });
// };

export { getCampaignListData, getCampaignList, editOnDetailsChange, changeCampaignStatus, getAccountCampaignList };
