import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    loaded: false,
    data: [],
    lastLoad: null
  },
  reducers: {
    load: (state, action) => {
      state.loaded = true;
      state.data = action.payload;
      state.lastLoad = moment.format();
    },
  }
});

export const {
  login, logout, initialize, setDbId
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
