import * as React from "react";
import { FacebookEmbed, InstagramEmbed, PlaceholderEmbed, TikTokEmbed, TwitterEmbed, YouTubeEmbed } from "react-social-media-embed";

export default function TopNPosts(props) {
  const platform = props.Platform?.toLowerCase();

  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
    margin: "20px 0",
  };

  const commonEmbedStyle = {
    border: "1px solid #ddd",
    padding: "10px",
    background: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    flex: "1 1 calc(33.333% - 40px)",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "30vw", // Base max height for larger screens
  };

  const scrollableStyle = {
    overflowY: "auto",
    width: "100%",
    maxHeight: "100%", // Allow the scrollable container to take up the full height of its parent
  };

  const platformStyles = {
    instagram: { width: "100%", height: "100%" },
    facebook: { width: "100%", height: "100%" },
    linkedin: { width: "100%", height: "100%" },
    tiktok: { width: "100%", height: "100%" },
    twitter: { width: "100%", height: "100%" },
    youtube: { width: "100%", height: "100%" },
  };

  const responsiveEmbedStyle = {
    ...commonEmbedStyle,
    '@media (max-width: 1200px)': {
      maxHeight: "40vw",
    },
    '@media (max-width: 900px)': {
      maxHeight: "50vw",
    },
    '@media (max-width: 600px)': {
      maxHeight: "60vw",
    },
  };

  return (
    <div style={containerStyle}>
      {platform === "instagram" && (
        <div style={responsiveEmbedStyle}>
          <div style={scrollableStyle}>
            <InstagramEmbed url={props.PostUrl} width="100%" height="100%" />
          </div>
        </div>
      )}
      {platform === "facebook" && (
        <div style={responsiveEmbedStyle}>
          <div style={scrollableStyle}>
            <FacebookEmbed url={props.PostUrl} width="100%" height="100%" />
          </div>
        </div>
      )}
      {platform === "linkedin" && (
        <div style={responsiveEmbedStyle}>
          <div style={scrollableStyle}>
            <PlaceholderEmbed
              linkText="View post on Linkedin"
              style={{ width: "100%", height: "100%" }}
              url={props.PostUrl}
            />
          </div>
        </div>
      )}
      {platform === "tiktok" && (
        <div style={responsiveEmbedStyle}>
          <div style={scrollableStyle}>
            <TikTokEmbed url={props.PostUrl} width="100%" height="100%" />
          </div>
        </div>
      )}
      {platform === "twitter" && (
        <div style={responsiveEmbedStyle}>
          <div style={scrollableStyle}>
            <TwitterEmbed url={props.PostUrl} width="100%" height="100%" />
          </div>
        </div>
      )}
      {platform === "youtube" && (
        <div style={responsiveEmbedStyle}>
          <div style={scrollableStyle}>
            <YouTubeEmbed url={props.PostUrl} width="100%" height="100%" />
          </div>
        </div>
      )}
    </div>
  );
}

