import { useState } from "react";
import TabPanel from '../../components/general/TabPanel';
import ViewInf from '../../components/account/details/ViewInfluencers';

export default function App() {
const [selectedTab] = useState(0);
  return (
    <>
    <TabPanel index={0} name="account-details-tab" value={selectedTab}>
            <ViewInf />
          </TabPanel>
    <div>
        </div>
      </>
  )
}
