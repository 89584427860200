import React, { useState } from "react";
import MyPaper from "../general/MyPaper";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { toDate } from "date-fns";

const AppLinksFilters = ({
  selectedStartDate,
  selectedEndDate,
  shortUrls,
  selectedShortUrls,
  setSelectedShortUrls,
  sendFilterChange,
  campaignStart,
  campaignEnd,
}) => {
  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);
  const [linkSelectAll, setLinkSelectAll] = useState(true);

  const sendSelectedDates = (start, end) => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
  };

  const selecturls = (value) => {
    let selectAllPresent = false;
    for (let i = 0; i < value.length; i++) {
      if (value[i].id === -1) {
        selectAllPresent = true;
        break;
      }
    }
    if (selectAllPresent) {
      if (linkSelectAll) {
        setLinkSelectAll(false);
        setSelectedShortUrls([]);
      } else {
        const values = shortUrls.filter((val) => val.id !== -1);
        setSelectedShortUrls(values);
        setLinkSelectAll(true);
      }
      return;
    }
    let selectAll = false;
    if (value.length === shortUrls.length) {
      selectAll = true;
    }
    setSelectedShortUrls(value);
    setLinkSelectAll(selectAll);
  };

  const applyFilters = () => {
    sendFilterChange({
      startDate,
      endDate,
      selectedShortUrls,
    });
  };

  return (
    <MyPaper sx={{ mb: "20px", mt: "80px" }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="h5"
          sx={{
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          Filters
        </Typography>
        <Stack direction="row" justifyContent="right">
          <Autocomplete
            sx={{ height: "40px", minWidth: "20vw", mr: "5px" }}
            id="free-solo-2-demo"
            disableCloseOnSelect
            multiple
            limitTags={0}
            disableClearable
            options={[{ id: -1, shortUrl: "Select All" }, ...shortUrls]}
            value={selectedShortUrls}
            disabled={shortUrls.length === 0}
            onChange={(_, newValue) => {
              selecturls(newValue);
            }}
            getOptionLabel={(option) => option.shortUrl}
            renderTags={(selected, _, state) =>
              state.popupOpen ? "" : `+${selected.length}`
            }
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={option.id === -1 ? linkSelectAll : selected}
                />
                {option.shortUrl}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selected Short Urls"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    minWidth: "7vw",
                    marginRight: "5px",
                  },
                }}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          <DateRangePicker
            style={{ marginRight: "5px" }}
            initialSettings={{
              startDate: moment(selectedStartDate).toDate(),
              endDate: moment(selectedEndDate).toDate(),
              ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment().subtract(1, "days").toDate(),
                  moment().subtract(1, "days").toDate(),
                ],
                "This Week": [
                  moment().startOf("week").toDate(),
                  moment().toDate(),
                ],
                "Last Week": [
                  moment().subtract(1, "weeks").startOf("week").toDate(),
                  moment().subtract(1, "weeks").endOf("week").toDate(),
                ],
                "Last 7 Days": [
                  moment().subtract(6, "days").toDate(),
                  moment().toDate(),
                ],
                "Last 30 Days": [
                  moment().subtract(29, "days").toDate(),
                  moment().toDate(),
                ],
                "This Month": [
                  moment().startOf("month").toDate(),
                  moment().endOf("month").toDate(),
                ],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month").toDate(),
                  moment().subtract(1, "month").endOf("month").toDate(),
                ],
                "Last 6 Months": [
                  moment().subtract(6, "months").toDate(),
                  moment().toDate(),
                ],
                "Campaign Duration": [
                  moment(campaignStart).toDate(),
                  moment(campaignEnd).toDate(),
                ],
              },
            }}
            onCallback={sendSelectedDates}
          >
            <input
              style={{ lineHeight: 1.6 }}
              type="text"
              className="form-control"
            />
          </DateRangePicker>
          <Button variant="contained" sx={{ ml: "5px" }} onClick={applyFilters}>
            Filter
          </Button>
        </Stack>
      </Stack>
    </MyPaper>
  );
};

export default AppLinksFilters;
