import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  FormGroup,
} from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import utils from "../../utils/utils";
import { API_HOST } from "../../config/main.json";
import Datatables from "mui-datatables";
import { Close, Preview, Analytics, ManageAccounts } from "@mui/icons-material";
import { getAllLists } from "../../services/list/ListDetails";
import CampaignActions from "./CampaignActions";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

function ManageAppLinks() {
  const [campId, setCampId] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [urlsData, setUrlsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalURLId, setModalURLId] = useState(0);
  const [modalPlatform, setModalPlatform] = useState("all");
  const [modalList, setModalList] = useState("");
  const [modalURLText, setModalURLText] = useState("");
  const [modalURL, setModalURL] = useState("");
  const [modalForAll, setModalForAll] = useState(false);
  const [platformData, setPlatformData] = useState([]);
  const [listData, setListData] = useState([]);
  const [openPrevModal, setOpenPrevModal] = useState(false);
  const [shortUrlsData, setShortUrlsData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteUrlId, setDeleteUrlId] = useState(0);
  const [deleteUrl, setDeleteUrl] = useState("");
  const [groupName, setGroupName] = useState("");
  const [domainUriPrefix, setDomainUriPrefix] = useState("");
  const [linkError, setLinkError] = useState("");
  const [domainValidation, setDomainValidation] = useState(true);
  const [linkValidation, setLinkValidation] = useState(false);
  const [urlInformation, setUrlInformation] = useState("");
  const [link, setLink] = useState("");
  const [packageName, setPackageName] = useState("");
  const [iosBundleId, setIosBundleId] = useState("");
  const [iosAppStoreId, setIosAppStoreId] = useState("");
  const [forAll, setForAll] = useState(false);
  const [skipPreview, SetSkipPreview] = useState(false);
  const [appsData, setAppsData] = useState([]);
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedAppIds, setSelectedAppIds] = useState("");
  const [tabArray, setTabArray] =  useState([]);

  const handleDomainUriPrefixChange = (event) => {
    const value = event.target.value;
    setDomainUriPrefix(value);
    if (validateUrl(value) === "") {
      setDomainValidation(true);
    } else {
      setDomainValidation(false);
    }
  };

  const handleLinkChange = (event) => {
    const value = event.target.value;
    setLink(value);
    setLinkError(validateUrl(value));
    if (validateUrl(value) === "") {
      setLinkValidation(true);
    } else {
      setLinkValidation(false);
    }
  };

  const handlepackageNameChange = (event) => {
    const value = event.target.value;
    setPackageName(value);
  };

  const handleiosBundleIdChange = (event) => {
    const value = event.target.value;
    setIosBundleId(value);
  };

  const validateUrl = (value) => {
    if (!value) {
      return "This field is required.";
    }

    const urlPattern = /^(http:\/\/|https:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
    if (!urlPattern.test(value)) {
      return "Invalid URL.";
    }

    return "";
  };
  const getCampaignInfo = (campId) => {
    utils.getCampaignData(campId).then((data) => {
      setCampaignName(data.name);
    });
  };

  const getCampaignUrls = (campId) => {
    axios.get(API_HOST + "/firebase/campaignUrls/" + campId).then((res) => {
      let urlsData = [];
      if (res.data) {
        urlsData = res.data;
      }
      setUrlsData(urlsData);
    });
  };

  const fetchApps = async (campId) => {
    var payload = { id: campId.toString() };
    axios.post(API_HOST + "/firebase/getApps", payload, optionAxios).then((result) => {
      console.log(result);
      if (result !== undefined && result.status === 200 && result.data != null) {
        setAppsData(result.data);
        setDomainUriPrefix(result.data[0].domain);
        setPackageName(result.data[0].package);
        setIosBundleId(result.data[0].iosBundle);
        setIosAppStoreId(result.data[0].iosAppStoreId);
      }
    });
  };

  const getDomains = async (ids) => {
    var payload = { id: ids };
    axios.post(API_HOST + "/firebase/domains", payload, optionAxios).then((result) => {
      if (result !== undefined && result.status === 200) {
        setDomainUriPrefix(result.data[0].domain);
        setPackageName(result.data[0].package);
        setIosBundleId(result.data[0].iosBundle);
        setIosAppStoreId(result.data[0].iosAppStoreId);
      }
    });
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    const tabArr = campaignTabsArray(campId);
    fetchApps(campId);
    setCampId(campId);
    setTabArray(tabArr);
    getCampaignInfo(campId);
    getCampaignUrls(campId);
  }, []);

  const getAllListsFunc = async (platform) => {
    const data = await getAllLists(campId, platform);
    setListData(data ? data : []);
    setModalList("");
  };

  const handleOpenAddModal = () => {
    setOpenModal(true);
    setModalType("add");
    setModalPlatform("all");
    setModalList("");
    setModalURLText("");
    setModalURL("");
    setModalForAll(false);

    utils.getPlatforms().then((data) => {
      const platformData = data;
      setPlatformData(platformData);
    });

    getAllListsFunc("all");
  };

  const handleOpenEditModal = (id, lid) => {
    for (let i = 0; i < urlsData.length; i++) {
      if (urlsData[i].id === id) {
        setModalURLId(id);
        setModalList(lid);
        setModalURLText(urlsData[i].urlText);
        setModalURL(urlsData[i].url);
        setModalForAll(urlsData[i].forAll);
        break;
      }
    }

    setOpenModal(true);
    setModalType("edit");
  };

  const closeModal = () => {
    setOpenModal(false);
    setOpenPrevModal(false);
    setOpenDeleteModal(false);
  };

  const handleModalPlatformChange = (event) => {
    const platform = event.target.value;
    setModalPlatform(platform);
    getAllListsFunc(platform);
  };

  const handleAppChange = (event) => {
    const value = event.target.value;
    setSelectedApp(value);
    if (value === "all") {
      setDomainUriPrefix("");
      setPackageName("");
      setIosBundleId("");
      setIosAppStoreId("");
      setDomainValidation(false);
      return;
    }
    const selectedAppId = appsData.find((app) => app.apps === value).id;
    setSelectedAppIds(selectedAppId);
    getDomains(selectedAppId);
  };

  const handleModalListChange = (event, listData) => {
    const selectedLid = event.target.value;
    const selectedList = listData.find((list) => list.id === selectedLid);
    setGroupName(selectedList["name"]);
    setModalList(selectedLid);
  };

  const handleModalURLTextChange = (event) => {
    const urlText = event.target.value;
    setModalURLText(urlText);
  };

  const handleModalURLChange = (event) => {
    const url = event.target.value;
    setModalURL(url);
  };

  const handleModalForAllChange = (event) => {
    setForAll(event.target.checked);
  };

  const isUrl = (string) => {
    try {
      return Boolean(new URL(string));
    } catch (e) {
      return false;
    }
  };

  const handleModalSubmit = (event) => {
    event.preventDefault();

    if (modalType === "add") {
      const data = {
        cid: campId,
        accountId: 0,
        domainPrefix: domainUriPrefix,
        link: link,
        packageName: packageName,
        iosBundleId: iosBundleId,
        iosAppStoreId: iosAppStoreId,
        forceRedirect: skipPreview,
        utmMedium: modalPlatform.replace(/ /g, "_").toLowerCase(),
        utmCampaign: campaignName.replace(/ /g, "_").toLowerCase(),
        utmTerm: groupName.replace(/ /g, "_").toLowerCase(),
        lid: modalList,
        forAll: forAll,
        urlText: urlInformation.trim(),
        appids: selectedAppIds,
      };
      axios.post(API_HOST + "/firebase/createLink", data, optionAxios).then((res) => {
        setOpenModal(false);
        getCampaignUrls(campId);
      });
    } else if (modalType === "edit") {
      const data = {
        id: modalURLId,
        lid: modalList,
        urlText: modalURLText.trim(),
        url: modalURL.trim(),
        forAll: modalForAll,
        group: groupName,
      };

      axios.post(API_HOST + "/link/list/updateUrl", data, optionAxios).then((res) => {
        setOpenModal(false);
        getCampaignUrls(campId);
      });
    }
  };

  const handleShortURLs = (urlId) => {
    axios.get(API_HOST + "/firebase/listurl/shortlinks/" + urlId).then((res) => {
      let shortUrlsData = res.data ? res.data : [];
      shortUrlsData = shortUrlsData.map((row) => {
        return {
          ...row,
          influencerNameAndUsername: `${row.influencerName} ${row.userName ? "(@" + row.userName + ")" : ""}`,
        };
      });
      setShortUrlsData(shortUrlsData);
      setOpenPrevModal(true);
    });
  };

  const handleOpenDeleteModal = (urlId, url) => {
    setDeleteUrlId(urlId);
    setDeleteUrl(url);
    setOpenDeleteModal(true);
  };

  const handleDeleteURL = () => {
    axios.get(API_HOST + "/link/listurl/remove/" + deleteUrlId).then((res) => {
      if (res.data === "success") {
        getCampaignUrls(campId);
      }
    });
    closeModal();
  };

  let tableData = [];
  if (urlsData.length > 0) {
    tableData = urlsData.map((url) => {
      const row = {};
      row.appName = url.appName;
      row.urlText = url.urlText;
      row.url = url.url;
      row.forAll = url.forAll;
      row.platform = url.platform;
      row.group = url.listName;
      row.id = {
        urlId: url.id,
        lid: url.lid,
        url: url.url,
      };

      return row;
    });
  }

  const columns = [
    {
      label: "App",
      name: "appName",
    },
    {
      label: "URL Description",
      name: "urlText",
    },
    {
      label: "URL",
      name: "url",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={value} rel="noreferrer" target="_blank">
              {value}
            </a>
          );
        },
      },
    },
    {
      label: "Platform",
      name: "platform",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const platform = utils.capitalizeFirstLetter(value);
          return platform;
        },
      },
    },
    {
      label: "Group",
      name: "group",
    },
    {
      label: "For All",
      name: "forAll",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? "Yes" : "No";
        },
      },
    },
    {
      label: "Action",
      name: "id",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const forAll = tableMeta.rowData[4];
          const urlId = value.urlId;
          const url = value.url;
          const lid = value.lid;
          return (
            <div>
              {/*<IconButton onClick={() => handleOpenEditModal(urlId, lid)}>
                <Tooltip title={"Edit"}>
                  <Edit fontSize="small" />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteModal(urlId, url)}>
                <Tooltip title={"Delete URL"}>
                  <Delete fontSize="small" />
                </Tooltip>
          </IconButton>*/}
              <IconButton onClick={() => handleShortURLs(urlId)}>
                <Tooltip title={"App Links"}>
                  <Preview fontSize="small" />
                </Tooltip>
              </IconButton>
              {/*!forAll && (
                <IconButton
                  component={Link}
                  to={
                    "/campaigns/linkAnalysis/urls/create?id=" +
                    campId +
                    "&lid=" +
                    lid +
                    "&urlId=" +
                    urlId +
                    "&group=" +
                    groupName
                  }
                >
                   <Tooltip title={"Create New Short URLs"}>
                    <PersonAdd fontSize="small" />
                  </Tooltip>
                </IconButton>
                )*/}
            </div>
          );
        },
        download: false,
      },
    },
  ];

  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
  };

  const shortUrlsColumn = [
    {
      label: "Influencer",
      name: "influencerNameAndUsername",
    },
    {
      label: "URL",
      name: "shortUrl",
      options: {
        customBodyRender: (value, tableMeta, _updateValue) => {
          return (
            <a href={"https://" + value} rel="noreferrer" target="_blank">
              {value}
            </a>
          );
        },
      },
    },
    {
      label: "Created On",
      name: "createdAt",
    },
  ];

  const shortUrlsOptions = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    responsive: "simple",
    pagination: false,
    tableBodyMaxHeight: "500px",
    selectableRows: "none",
  };

  const tabArray2 = [
    {
      label: "Metrics",
      id: "Metrics-tab-0",
      to: `/campaigns/Applinks?id=${campId}`,
      component: Link,
      icon: <Analytics />,
      iconPosition: "start"
    },
    {
      label: "Manage app URLs",
      id: "manage-app-tab-0",
      to: `/campaigns/Applinks/manage?id=${campId}`,
      component: Link,
      icon: <ManageAccounts />,
      iconPosition: "start"
    },
  ]


  return (
    <>
      <CampaignActions name={campaignName} id={campId} />
      <br />
      <CustomTabs tabArray={tabArray} selectedTab={"7"} />

      <div>
        <Box m={3} style={{ marginTop: "10px" }}>
        <CustomTabs tabArray={tabArray2} selectedTab={"1"} />
          <Stack direction="row" justifyContent="end">
            <Button variant="contained" onClick={handleOpenAddModal}>
              Add New URL
            </Button>
          </Stack>
        </Box>
        <Datatables data={tableData} columns={columns} options={options} />
        <Modal
          className="styles_modal"
          aria-labelledby="modal-add_update_url-label"
          aria-describedby="modal-add_update_url-description"
          open={openModal}
          onClose={closeModal}
          closeAfterTransition
        >
           <Fade in={openModal}>
    <div className="styles_paper" style={{ maxWidth: "600px", margin: "auto", padding: "20px", backgroundColor: "#fff", borderRadius: "8px" }}>
      <div id="modal-add_update_url-heading" className="styles_modal_heading" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" id="modal-add_update_url-heading-text" style={{ padding: "10px" }}>
          {modalType === "add" ? "Create App Link" : "Edit Destination URL"}
        </Typography>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </div>
      <hr />
      <form onSubmit={handleModalSubmit}>
        <Grid container spacing={2}>
          {modalType === "add" && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Application *</InputLabel>
                  <Select
                    onChange={handleAppChange}
                    value={selectedApp}
                    variant="outlined"
                    required
                  >
                    {appsData.map((app) => (
                      <MenuItem key={app.id} value={app.apps}>
                        {utils.capitalizeFirstLetter(app.apps)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Platform</InputLabel>
                  <Select
                    onChange={handleModalPlatformChange}
                    value={modalPlatform}
                    variant="outlined"
                  >
                    <MenuItem key={"all"} value={"all"}>
                      All Platforms
                    </MenuItem>
                    {platformData.map((platform) => (
                      <MenuItem value={platform.name} key={platform.name}>
                        {utils.capitalizeFirstLetter(platform.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>List *</InputLabel>
                  <Select
                    onChange={(event) => handleModalListChange(event, listData)}
                    variant="outlined"
                    required
                    value={modalList}
                  >
                    {listData.map((list) => (
                      <MenuItem value={list.id} key={list.id}>
                        {utils.capitalizeFirstLetter(list.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="URL Description"
                type="text"
                value={urlInformation}
                onChange={(e) => setUrlInformation(e.target.value)}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="DomainUriPrefix"
                type="url"
                value={domainUriPrefix}
                onChange={handleDomainUriPrefixChange}
                error={Boolean(linkError)}
                helperText={linkError}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Link"
                type="url"
                value={link}
                onChange={handleLinkChange}
                error={Boolean(linkError)}
                helperText={linkError}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                label="Android Package"
                type="text"
                value={packageName}
                onChange={handlepackageNameChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                label="iOS Bundle Id"
                type="text"
                value={iosBundleId}
                onChange={handleiosBundleIdChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={skipPreview} onChange={(e) => SetSkipPreview(e.target.checked)} />}
                label="Skip the app preview page (not recommended)"
              />
            </FormGroup>
          </Grid>
          {modalType === "add" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  label="Create short link for all influencers in list"
                  control={<Checkbox onChange={handleModalForAllChange} />}
                />
              </FormControl>
              <FormHelperText>* This option cannot be changed later</FormHelperText>
            </Grid>
          )}
        </Grid>
        <Button
          disabled={!domainValidation || !linkValidation || urlInformation == "" || !groupName == "" || !selectedApp === ""}
          variant="contained"
          type="submit"
          style={{ float: "right", marginTop: "15px" }}
        >
          Submit
        </Button>
      </form>
    </div>
  </Fade>
</Modal>
        <Modal
          className="styles_modal"
          aria-labelledby="modal-short_urls-label"
          aria-describedby="modal-short_urls-description"
          open={openPrevModal}
          onClose={closeModal}
          closeAfterTransition
        >
          <Fade in={openPrevModal}>
            <div className="syles_paper" style={{ width: "50%" }}>
              <div id="modal-short_urls-heading" className="styles_modal_heading">
                <h2 id="modal-short_urls-heading-text" style={{ padding: "10px" }}>
                  Firebase
                </h2>
                <IconButton onClick={closeModal}>
                  <Close />
                </IconButton>
              </div>
              <hr />
              <div id="modal-short_urls-body" style={{ padding: "10px" }}>
                <Datatables data={shortUrlsData} columns={shortUrlsColumn} options={shortUrlsOptions} />
              </div>
            </div>
          </Fade>
        </Modal>
        <Modal
          className="styles_modal"
          aria-labelledby="modal-delete_url-label"
          aria-describedby="modal-delete_url-description"
          open={openDeleteModal}
          onClose={closeModal}
          closeAfterTransition
        >
          <Fade in={openDeleteModal}>
            <div className="syles_paper" style={{ width: "50%" }}>
              <div id="modal-delete_url-heading" className="styles_modal_heading">
                <h2 id="modal-delete_url-heading-text" style={{ padding: "10px" }}>
                  Delete this URL?
                </h2>
                <IconButton onClick={closeModal}>
                  <Close />
                </IconButton>
              </div>
              <hr />
              <div id="modal-delete_url-body" style={{ padding: "10px" }}>
                Are you sure you want to permanently delete this destination URL -{" "}
                <a href={deleteUrl} rel="noreferrer" target="_blank">
                  {deleteUrl}
                </a>
                ?
              </div>
              <div
                style={{
                  float: "right",
                  margin: "15px 20px 15px 0",
                }}
              >
                <Button variant="outlined" onClick={closeModal} style={{ marginRight: "15px" }}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleDeleteURL}>
                  Delete
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

export default ManageAppLinks;
