const currencyData = [
    { name: "US Dollar ($)", value: "$" },
    { name: "British Pound (£)", value: "£" },
    { name: "Euro (€)", value: "€" },
    { name: "Kenyan Shilling (KSh)", value: "KSh" },
    { name: "South African Rand (R)", value: "R" },
    { name: "Nigerian Naira (₦)", value: "₦" },
    { name: "Indian Rupee (₹)", value: "₹" },
];

export default currencyData;