import { colors } from "@mui/material";

const Palette = {
  background: {
    default: "#F4F6F8",
    paper: colors.common.white,
  },
  primary: {
    contrastText: "#ffffff",
    main: "#33cc99",
    light: "#3af0b3",
    dark: "#289470",
  },
  secondary: {
    main: "#757575",
    light: "#949494",
    dark: "#5c5c5c",
  },
  text: {
    primary: '#4f4f4f',
    secondary: '#6b778c'
  },
  danger: {
    contrastText: '#ffffff',
    main: '#FF505F',
    light: '#FF303F',
    dark: '#FF303F'
  }
};
export default Palette;
