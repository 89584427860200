export const FilterTooltips = {
  location: {
    audTooltipMsg: "Identify influencers by their audience location. We determine audience location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several audience locations and specify minimum percentage for each location, your search results will be refined with influencers that have audience in ANY of the specified locations.",
    infTooltipMsg: "Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations."
  },
  language: {
    audTooltipMsg: "Identify influencers by their audience language. We determine language of audience by analyzing captions of recent posts.",
    infTooltipMsg: "Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations."
  },
  brand: {
    audTooltipMsg: "Identify influencers by their audience brand affinities. We determine brand affinities of audience by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and specify minimum percentage for each brand, your search results will be refined with influencers that have audience mentioning ANY of the specified brands.",
    infTooltipMsg: "Identify influencers by their brand affinities. We determine  brand affinities of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and your search results will be refined with influencers that have ANY of the specified brands."
  },
  parntership:  "Identify influencers by having sponsored posts with brands. We detect sponsored posts either by usage of commercial hashtags or official paid partnership tag. You can add several brands and your search results will be refined with influencers that have sponsored posts for ANY of the specified brands.",
  interest: {
    audTooltipMsg: "Identify influencers by their audience interests. We determine interest of audience by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and specify minimum percentage for each interest, your search results will be refined with influencers that have audience mentioning ANY of the specified interests.",
    infTooltipMsg: "Identify influencers by their interests. We determine  interest of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and your search results will be refined with influencers that have ANY of the specified interests."
  },
  gender: {
    audTooltipMsg: "Identify influencers by their audience gender. We determine gender of audience by analyzing profile photos, name and text in profile bio and selfies in recent posts.",
    infTooltipMsg: "Identify influencers by gender. We determine gender of influencers by analyzing profile photos, name and text in profile bio and selfies in recent posts."
  },
  ethinicity: "Identify influencers by their audience ethnicity. We determine ethnicity of audience by analyzing profile photos and selfies in recent posts.",
  age: {
    audTooltipMsg: "Identify influencers by their audience age. We determine age of audience by analyzing profile photos and selfies in recent posts.",
    infTooltipMsg: "Identify influencers by their age. We determine age by analyzing profile photos and selfies in recent posts.",
  },
  audienceType: "Switch audience filters to look only at the specific audience. Any by default.",
  lookalike: {
    audTooltipMsg: "Audience lookalikes help you find influencers that have similar audience.",
    infTooltipMsg: "Based on TopicTensor® technology, help you find influencers that post similar content."
  },
  bio: "Identify influencers by keywords within their bio description.",
  keyword: {
    keyword: "Identify influencers by the keywords they use in their posts texts.",
    mention: "Identify influencers by the mentions they use in their posts texts."
  },
  follower: "Identify influencers by number of their followers.",
  view: {

  },
  engagement: {
    audTooltipMsg: "Identify influencers by number of their engagements.",
    infTooltipMsg: "Identify influencers by their engagement rate. ER is average likes of their recent posts divided by their followers.",
  },
  reelPlay: "Identify influencers by average number of plays in their recent reels.",
  contact: "Refine search results with only influencers that have Email or other contact details.",
  lastPost: "Identify influencers based on when they posted last.",
  accountType: "Identify influencers by their Instagram account type - Business, Creator or regular (non business) Instagram account",
  views: "Identify influencers by their audience age. We determine audience views by analyzing views tags, language and caption of recent posts and text in profile bio. You can add several audience views and specify minimum percentage for each age, your search results will be refined with influencers that have audience in ANY of the specified age.",
  followersGrowth: {

  }

}