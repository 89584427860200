import React, { Component } from "react";
import { CircularProgress } from "@mui/material";
import MyPaper from "./MyPaper";
const styles = {
  fetchingDetails: {
    marginTop: "20px",
    paddingLeft: "50px",
    position: "relative",
    left: "50%",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
};
export class FetchingDetailsLoader extends Component {
  render() {
    return (
      <MyPaper sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <CircularProgress
          style={{
            position: "relative",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          color="success"
        />
        <div style={styles.fetchingDetails}>Fetching details...</div>
      </MyPaper>
    );
  }
}

export default FetchingDetailsLoader;
