import { Backdrop, Box, CircularProgress, Grid, IconButton, Modal, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { API_HOST } from "../../config/main.json";
import Datatables from "mui-datatables";
import { Fade } from "@mui/material";
import { Close, Info, Preview } from "@mui/icons-material";
import utils from "../../utils/utils";
import CustomTabs from "../../common/CustomTabs/CustomTabs";

function EmpowrQueriesResolved() {
  const [isLoaded, setIsLoaded] = useState(true);
  const [backdropShow, setBackdropShow] = useState(true);
  const [data, setData] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = () => {
    axios.get(API_HOST + "/contactus/resolved").then((res) => {
      let data = [];
      if (res.data) {
        data = res.data;
      }
      setData(data);
      setIsLoaded(true);
      setBackdropShow(false);
    });
  };

  const openPreviewModal = (id) => {
    const previewData = data.filter((row) => row.id === id);
    setPreviewData(previewData[0]);
    setPreviewModal(true);
  };

  const closeModal = () => {
    setPreviewModal(false);
  };

  let tableData = [];
  tableData = data.map((response) => {
    const row = { ...response };
    row.nameColumn = (
      <Grid container>
        <Grid item xs={2}>
          <Tooltip title={"Email: " + row.email}>
            <Info fontSize="small" />
          </Tooltip>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={9}>
          &nbsp;{row.name}
        </Grid>
      </Grid>
    );
    row.issueColumn = row.issue.length <= 50 ? row.issue : row.issue.substring(0, 50) + "...";
    row.resolutionTimeColumn = utils.convertDate(row.resolvedAt, "withtime") + " GMT";
    row.actions = (
      <>
        <IconButton onClick={() => openPreviewModal(row.id)}>
          <Tooltip title={"View Issue"}>
            <Preview fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
    return row;
  });

  const columns = [
    {
      label: "Name",
      name: "nameColumn",
      options: {
        filter: true, // Enable filtering for this column
        sort: true, // Enable sorting for this column
        download: false,
      },
    },
    {
      label: "Name",
      name: "name",
      options: {
        display: false,
        viewColumns: false,
        filter: true
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      label: "Issue",
      name: "issueColumn",
    },
    {
      label: "Resolved On",
      name: "resolutionTimeColumn",
    },
    {
      label: "Resolved By",
      name: "resolvedBy",
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        sort: false,
        download: false,
      },
    },
  ];

  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
  };

  const tabArray = [
    {
      label: "Pending Queries",
      id: "pending-queries-tab-0",
      to: "/bdTools/empowrQueries",
      component: Link,
    },
    {
      label: "Resolved Queries",
      id: "resolved-queries-tab-0",
      to: "/bdTools/empowrQueries/resolved",
      component: Link,
    },
  ]

  return isLoaded ? (
    <>
      <Box m={3} style={{ paddingBottom: "35px" }}>
        <CustomTabs tabArray={tabArray} selectedTab={"1"} />
      </Box>
      <Datatables data={tableData} columns={columns} options={options} />
      <Modal
        className="styles_modal"
        aria-labelledby="modal-preview-label"
        aria-describedby="modal-preview-description"
        open={previewModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={previewModal}>
          <div className="syles_paper" style={{ maxHeight: "80vh", width: "50%" }}>
            <div id="modal-preview-heading" className="styles_modal_heading">
              <h2 id="modal-preview-heading-text" style={{ padding: "10px" }}>
                Issue Raised
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-preview-body" style={{ padding: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Name"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={previewData.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Email"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={previewData.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    // rows={6}
                    variant="outlined"
                    label="Describe Your Issue"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={previewData.issue}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
      open={backdropShow}
    >
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
}

export default EmpowrQueriesResolved;
