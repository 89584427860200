import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CssBaseline,
  Backdrop,
  CircularProgress,
  Box,
  Toolbar
} from '@mui/material';
import { styled } from '@mui/system';
import { Outlet, useNavigate } from 'react-router-dom';
import AccountToolbar from '../../components/account/layout/AccountToolbar';
import AccountNav from '../../components/account/layout/AccountNav';
import { DRAWER_WIDTH } from '../../config/account.json';

const drawerWidth = DRAWER_WIDTH;

const Root = styled('div')({
  display: 'flex',
  width: '100%'
});

const AccountLayout = () => {
  const user = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [backdropShow] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };


 useEffect(async () => {
    if (user.initialized) {
      if (!user.loggedIn) {
        navigate('/');
      } 
      // else {
      //   var email = user.attributes['email'];
      //   var userName = user.attributes['name'];
      //   var userId = user['username'].replace("google_", "");
      // }
    }
  }, [user.initialized]);

  return (
    <Root sx={{ minHeight: '100%' }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <AccountToolbar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
          aria-label="mailbox folders"
        >
          <AccountNav drawerOpen={open} drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
        </Box>
        <Box component="main" sx={{ position: 'relative', flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, flexDirection: 'column' }}
        open={backdropShow}
      >
        <CircularProgress color="inherit" />
        <Box sx={{ marginTop: '20px' }}>
          Setting up your account
        </Box>
      </Backdrop>
    </Root>
  );


};

export default AccountLayout;
