import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./redux/store";
//import * as serviceWorker from './serviceWorker';
import { initUser } from "./redux/features/user/userSlice";
import "typeface-public-sans";
import axios from "axios";
import { Auth } from "aws-amplify";
import { API_HOST, HEIMDALL_HOST } from "./config/main.json";

store.dispatch(initUser);

axios.interceptors.request.use(async (config) => {
  if (
    config.url.startsWith(API_HOST) ||
    config.url.startsWith(HEIMDALL_HOST) ||
    config.url.startsWith("http://localhost")
  ) {
    const token = await Auth.currentSession();
    config.headers["Authorization-Token"] = token.getIdToken().getJwtToken();
  }

  return config;
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
