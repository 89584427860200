import axios from 'axios';

const accountApi = process.env.REACT_APP_ACCOUNT_API;
const awsEnv = process.env.REACT_APP_AWS_ENV;

const getDetails = async (id) => {
  const response = await axios({
    url: `${accountApi}${awsEnv}/get-user-data`,
    method: 'GET',
    params: { id }
  });

  return response.data;
};

const saveNewEmail = async (id, email, primary) => {
  const response = await axios({
    url: `${accountApi}${awsEnv}/save-email-address`,
    method: 'POST',
    data: { id, email, primary }
  });

  if (!response.data.error) {
    return response.data.contactId;
  }

  return false;
};

const saveNewPhone = async (id, dialCode, phoneNumber, primary) => {
  const response = await axios({
    url: `${accountApi}${awsEnv}/save-phone-number`,
    method: 'POST',
    data: {
      id, dialCode, phoneNumber, primary
    }
  });

  if (!response.data.error) {
    return response.data.contactId;
  }

  return false;
};

export { getDetails, saveNewEmail, saveNewPhone };
