import React, { useState } from "react";
import { Button, MenuItem, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import utils from "../../../utils/utils";
import { formItems } from "./Verified";
import SecondaryStyledMenu from "./common/SecondaryStyledMenu";
import KeyEnum from "../KeyEnum";
import FilterNameEnum from "../FilterNameEnum";

const sortColsArr = ["inf_followers", "inf_engagements", "inf_er"];

const SortByColumns = (props) => {
  const { columnValue, columnHandler, allFiltersData } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const excluded_fliters = formItems.map((item) => "inf_" + item.key);
  excluded_fliters.push("aud_contact");
  excluded_fliters.push("aud_lastPosted");
  excluded_fliters.push("aud_audience_type");

  const included_fliters = [];
  included_fliters.push("inf_reel_plays");

  const filters = [...Object.keys(allFiltersData[0]), ...sortColsArr];
  if (!filters.includes(columnValue.field)) {
    columnHandler({
      field: "inf_engagements",
      name: KeyEnum["inf_engagements"],
    });
  }

  return (
    <>
      <Button
        sx={{
          color: "black",
          background: "white",
          border: "1px solid lightgrey",
          borderRadius: "12px",
          "&:hover": { bgcolor: "lightgray" },
          whiteSpace: "nowrap",
          px: "12px",
          textTransform: "none",
          mr: "1rem",
          minWidth: "200px",
          justifyContent: "space-between",
        }}
        id="styled-menu"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      >
        Sort By: {utils.capitalizeFirstLetter(columnValue?.name)}
      </Button>
      <SecondaryStyledMenu
        id="styled-menu"
        MenuListProps={{
          "aria-labelledby": "styled-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {sortColsArr.map((item) => (
          <MenuItem selected={KeyEnum[item] === KeyEnum[columnValue?.field]}>
            <Typography
              sx={{
                p: "8px",
                cursor: "pointer",
                "&:hover": { bgcolor: "#f7f7f7" },
              }}
              key={KeyEnum[item]}
              onClick={() => {
                handleClose();
                columnHandler({ field: item, name: FilterNameEnum[item] });
              }}
            >
              {utils.capitalizeFirstLetter(FilterNameEnum[item])}
            </Typography>
          </MenuItem>
        ))}
        {allFiltersData?.length > 0 &&
          Object.keys(allFiltersData[0]).length > 0 &&
          Object.keys(allFiltersData[0]).map((key) => {
            if (
              (key.startsWith("inf_") || excluded_fliters.includes(key)) &&
              !included_fliters.includes(key)
            )
              return null;
            const item = allFiltersData[0][key];
            return item.map((subItem) => {
              let id, name, capitalizedName;
              if (Object.keys(subItem).includes("name")) {
                id = subItem?.id;
                name = subItem?.name;
                capitalizedName = utils.capitalizeFirstLetter(subItem?.name);
              } else if (Object.keys(subItem).includes("code")) {
                name = subItem?.code;
                capitalizedName = utils.capitalizeFirstLetter(subItem?.code);
              } else if (
                Object.keys(subItem).includes("left") &&
                Object.keys(subItem).includes("right")
              ) {
                if (key === "aud_age") {
                  name = subItem.left + "-" + subItem.right + " Y.O.";
                  capitalizedName =
                    subItem.left + "-" + subItem.right + " Y.O.";
                } else if (key === "inf_reel_plays") {
                  name = FilterNameEnum[key];
                  capitalizedName = FilterNameEnum[key];
                }
              } else if (Object.keys(subItem).includes("value")) {
                if (key === "aud_lookalikes") {
                  name = FilterNameEnum[key];
                  capitalizedName = FilterNameEnum[key];
                }
              } else {
                name = FilterNameEnum[key];
                capitalizedName = FilterNameEnum[key];
              }
              return (
                <MenuItem selected={name === columnValue?.name}>
                  <Typography
                    sx={{
                      p: "8px",
                      cursor: "pointer",
                      "&:hover": { bgcolor: "#f7f7f7" },
                    }}
                    key={id}
                    onClick={() => {
                      handleClose();
                      columnHandler({
                        field: key,
                        name: name,
                        id: id,
                      });
                    }}
                  >
                    {capitalizedName}
                  </Typography>
                </MenuItem>
              );
            });
          })}
      </SecondaryStyledMenu>
    </>
  );
};

export default SortByColumns;
