import React, { Component } from "react";

/* css */
import "./posts.css";

/* config */
import axios from "axios";
import { API_HOST } from "../../config/main.json";

/* material-ui */
import {
  Button,
  FormControl,
  Grid,
  Stack,
  TextareaAutosize,
  Card,
  IconButton,
  Typography,
  Box,
  Tabs,
  Tab,
  Snackbar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FacebookPost from "./FacebookPost";
import InstagramPost from "./InstagramPost";
import LinkedinPost from "./LinkedinPost";
import TiktokPost from "./TiktokPost";
import TwitterPost from "./TwitterPost";
import YoutubePost from "./YoutubePost";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import MessageIcon from "@mui/icons-material/Message";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HistoryIcon from "@mui/icons-material/History";
import PostHistoryTimeline from "./PostHistoryTimeline";
import PostTextEditor from "./PostTextEditor";
import enums from "../../utils/enums";
import PreviewIcon from "@mui/icons-material/Preview";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import MuiAlert from "@mui/material/Alert";
import utils from "../../utils/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class PostReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastCommentHidden: false,
      feedback: "",
      editContent: false,
      content: this.props.postDetail.postContent,
      contentLength: this.props.postDetail?.postContent?.length,
      maxLength: enums.charLengthSupported[this.props.postDetail.platform],
      disableSubmit: true,
      approvedDraft: this.props.approvedDraft,
      tabValue: 0,
      postHistory: [],
      postIdSelected: -1,
      postsIds: [...new Set(this.props.data?.map(item => item.id))],
      postDetail: this.props.postDetail,
      showMessage: false,
      message: "",
      feedbackError: false,
      unsavedChanges: false,
      isSubmitting: false,
    };
    this.ViewHistory(this.props.postDetail.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.contentLength !== this.state.contentLength ||
      prevState.feedback !== this.state.feedback
    ) {
      const feedback = document.getElementById("improvement_comment")?.value;
      if (
        this.state.contentLength > this.state.maxLength ||
        (this.state.editContent && feedback.trim() === "")
      ) {
        this.setState({ disableSubmit: true });
      } else {
        this.setState({ disableSubmit: false });
      }
    }
  }

  nextPost = () => {
    if (this.state.unsavedChanges) {
      if (!window.confirm("You have unsaved changes. Do you want to proceed without saving?")) {
        return;
      }
    }
    const { postDetail, postsIds } = this.state;
    const currentIndex = postsIds.indexOf(postDetail.id);
    const newIndex = currentIndex + 1;
    if (newIndex < postsIds.length) {
      const newPostId = postsIds[newIndex];
      this.updatePostDetails(newPostId);
    } else {
      this.setState({ showMessage: true, message: "This is the last post." });
    }
  };

  previousPost = () => {
    if (this.state.unsavedChanges) {
      if (!window.confirm("You have unsaved changes. Do you want to proceed without saving?")) {
        return;
      }
    }
    const { postDetail, postsIds } = this.state;
    const currentIndex = postsIds.indexOf(postDetail.id);
    const newIndex = currentIndex - 1;
    if (newIndex >= 0) {
      const newPostId = postsIds[newIndex];
      this.updatePostDetails(newPostId);
    } else {
      this.setState({ showMessage: true, message: "This is the first post." });
    }
  };

  updatePostDetails = (postId) => {
    this.setState(
      { postDetail: { ...this.state.postDetail, id: postId }, unsavedChanges: false },
      () => {
        this.ReviewPost(postId);
        this.ViewHistory(postId);
      }
    );
  };

  attachmentsInFullScreen = () => {
    this.props.attachmentsInFullScreen(true);
  };

  /* let parent know that user chose to close the modal */
  HandlePostReviewModalClose = () => {
    this.setState({ postDetail: [] });
    this.props.HandlePostReviewModalClose(true);
  };

  /* let parent know that user clicked one of the action buttons */
  HandleActionButtonClick = (action) => {
    const data = {
      action,
      feedback: this.state.feedback,
      postContent: this.state.editContent
        ? this.state.content
        : this.props.postDetail.postContent,
    };
    if (this.props.HandleActionButtonClick) {
      this.props.HandleActionButtonClick(data);
      if (action === "Edit With Suggestions" || action === "Approved") {
        this.setState({ editContent: false, feedback: "", feedbackError: false, unsavedChanges: false });
      }
    } else {
      console.error("HandleActionButtonClick function is not defined");
    }
  };

  ReviewPost = (postId) => {
    this.setState({ postIdSelected: postId });
    utils.getPostDetails(postId)
      .then((postDetail) => {
        if (postDetail != null) {
          this.setState({
            postDetail,
            content: postDetail.postContent,
            contentLength: postDetail.postContent.length,
          });
        }
      })
      .catch((error) => {
        console.error("Failed to fetch post details:", error);
      });
  };

  ViewHistory = (postId) => {
    this.setState({ postIdSelected: postId });
    utils.getPostHistory(postId)
      .then((postHistory) => {
        if (postHistory == null || postHistory === undefined) {
          postHistory = [];
        }
        this.setState({ postHistory });
      });
  };

  handlePostInput = (content) => {
    this.setState({ content, contentLength: content.length, unsavedChanges: true });
  };

  handleContentLength = (length) => {
    this.setState({ contentLength: length, unsavedChanges: true });
  };

  handleGiveFeedbackClick = () => {
    if (this.state.feedback.trim() === "") {
      this.setState({ feedbackError: true });
    } else {
      this.HandleActionButtonClick("Improvement Suggested");
      this.setState({ disableSubmit: true, editContent: false, unsavedChanges: false });
    }
  };

  /* display post */
  renderPost = () => {
    // base style
    const baseStyle = {
      margin: "1px auto",
      padding: "20px",
      borderRadius: "10px",
    };

    // Extend base style based on the platform
    const platformStyle = {
      instagram: { width: "100%", maxWidth: "450px", marginLeft: "30px" },
      linkedin: { width: "100%", maxWidth: "500px", marginLeft: "30px" },
      facebook: { width: "100%", maxWidth: "338px", marginLeft: "30px" },
      twitter: { width: "100%", maxWidth: "480px", marginLeft: "30px" },
      tiktok: { width: "100%", maxWidth: "500px", marginLeft: "30px" },
      youtube: { width: "100%", maxWidth: "600px", marginLeft: "30px" },
    };

    const currentPlatformStyle = platformStyle[this.state.postDetail.platform] || {};
    const statusValue = {
      1: "Submitted For Review",
      2: "Approved",
      3: "Rejected",
      4: "Improvement Suggested",
      5: "Deleted",
      6: "Edit With Suggestions",
      7: "Resubmitted",
      8: "Published",
      9: "Discard"
    };

    const statusText = statusValue[this.state.postDetail.currentStatus] || "Status Unknown";

    return (
      <>
        <Box
          style={{
            backgroundColor: "#007BFF",
            color: "#fff",
            textAlign: "center",
            borderRadius: "10px",
            boxSizing: "border-box",
            padding: "5px",
            marginBottom: "0",
            ...currentPlatformStyle,
          }}
        >
          <Typography variant="body2">{statusText}</Typography>
        </Box>
        <Card style={{ ...baseStyle, ...currentPlatformStyle }}>
          {this.state.postDetail.platform === "instagram" && (
            <InstagramPost
              formData={this.state.postDetail}
              cid={this.state.postDetail.cid}
            />
          )}
          {this.state.postDetail.platform === "linkedin" && (
            <LinkedinPost
              formData={this.state.postDetail}
              cid={this.state.postDetail.cid}
            />
          )}
          {this.state.postDetail.platform === "facebook" && (
            <FacebookPost
              formData={this.state.postDetail}
              cid={this.state.postDetail.cid}
            />
          )}
          {this.state.postDetail.platform === "twitter" && (
            <TwitterPost
              formData={this.state.postDetail}
              cid={this.state.postDetail.cid}
            />
          )}
          {this.state.postDetail.platform === "tiktok" && (
            <TiktokPost
              formData={this.state.postDetail}
              cid={this.state.postDetail.cid}
            />
          )}
          {this.state.postDetail.platform === "youtube" && (
            <YoutubePost
              formData={this.state.postDetail}
              cid={this.state.postDetail.cid}
            />
          )}
        </Card>
      </>
    );
  };

  editPost = () => {
    return (
      <>
        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <PostTextEditor
            isReadOnly={false}
            placeholder={"placeholder"}
            maxLength={this.state.maxLength}
            handlePostInput={(content) => {
              this.handlePostInput(content);
            }}
            postContent={this.state.content}
            charCount={this.state.contentLength}
            setCharCount={this.handleContentLength}
          />
        </FormControl>
        <Stack
          direction="row"
          justifyContent="end"
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Button
            sx={{ marginRight: "auto" }}
            variant="contained"
            size="large"
            startIcon={<CancelIcon />}
            className="postReview-btn"
            id="postReview-cancel-btn"
            color="secondary"
            onClick={() => {
              this.setState({ editContent: false, disableSubmit: false, unsavedChanges: false });
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            startIcon={<CheckCircleIcon />}
            className="postReview-btn"
            id="postReview-submit-btn"
            disabled={this.state.disableSubmit}
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to SUBMIT the edits with the following suggestions?"
                )
              ) {
                this.HandleActionButtonClick("Edit With Suggestions");
              }
            }}
          >
            Submit
          </Button>
        </Stack>
      </>
    );
  };

  toggleLastComment = () => {
    this.setState({ lastCommentHidden: !this.state.lastCommentHidden });
  };

  getLastCommentToggleIcon = () => {
    if (this.state.lastCommentHidden) {
      return (
        <IconButton
          id="last_comment-toggle_button"
          onClick={() => {
            this.toggleLastComment();
          }}
          style={{ color: "black" }}
        >
          <ExpandMoreIcon fontSize="small" />
        </IconButton>
      );
    }
    return (
      <IconButton
        id="last_comment-toggle_button"
        onClick={() => {
          this.toggleLastComment();
        }}
        style={{ color: "black" }}
      >
        <ExpandLessIcon fontSize="small" />
      </IconButton>
    );
  };

  getTopComments = (topComment) => {
    if (this.state.lastCommentHidden) {
      return;
    }

    return topComment.map((detail) => (
      <>
        <span id="last_comment">{detail.comment}</span> <br />
        {detail && (
          <span
            id="last_commented_at"
            style={{ color: "rgb(113, 118, 123)", fontSize: "10px" }}
          >
            <IconButton>
              <AccessTimeIcon sx={{ fontSize: 12, color: "black" }} />
            </IconButton>
            {new Date(detail.timestamp).toLocaleString()}
          </span>
        )}
        <br />
      </>
    ));
  };

  loadLastComment = () => {
    if (this.state.postDetail && this.state.postDetail.topComments) {
      const topComments = this.state.postDetail.topComments;
      if (topComments === "") {
        return;
      }
      /* show last comment and last comment at similar to "Attachments" */
      return (
        <div className="lastComment">
          <div className="styles_modal_footer">
            <b>Latest feedback</b>
            {this.getLastCommentToggleIcon()}
          </div>
          <div id="last_comment_div">{this.getTopComments(topComments)}</div>
        </div>
      );
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  closeSnackbar = () => {
    this.setState({ showMessage: false });
  };

  render() {
    const isApprovedOrDiscarded = this.state.postDetail.currentStatus === 2 || this.state.postDetail.currentStatus === 9;
    const currentIndex = this.state.postsIds?.indexOf(this.state.postDetail.id);
    const disableActions = this.state.editContent;

    const actionButtonStyle = (disabled) => ({
      backgroundColor: disabled ? "#E0E0E0" : "",
      color: disabled ? "#9E9E9E" : "",
      border: disabled ? "1px solid #D0D5DD" : "",
    });

    return (
      <>
        {this.state.postDetail && (
          <div
            className="modal-container syles_paper_list"
            style={{
              backgroundColor: "#E0E0E0",
              borderRadius: "10px",
              maxHeight: "90vh",
              height: "auto",
              padding: "20px",
              border: "1px solid #ccc",
              margin: "20px auto",
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                <PreviewIcon
                  style={{ color: "#464E5F", width: "4.8rem", height: "4.8rem" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    style={{ fontSize: "28px", color: "#000" }}
                  >
                    {this.state.postDetail.campaignName}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "#464E5F", fontSize: "20px" }}
                  >
                    Preview drafts, provide feedback or approve
                  </Typography>
                </Box>
              </Box>
              <IconButton onClick={this.HandlePostReviewModalClose}>
                <HighlightOffIcon
                  style={{ color: "#000", width: "2.2rem", height: "2.2rem" }}
                />
              </IconButton>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12} md={isApprovedOrDiscarded ? 12 : 6} style={isApprovedOrDiscarded ? { display: 'flex', justifyContent: 'center' } : {}}>
                {!this.state.editContent && (
                  <div className="post">
                    <Grid
                      container
                      spacing={2}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      id="post"
                    >
                      {this.renderPost()}
                    </Grid>
                  </div>
                )}
                {this.state.editContent && <div>{this.editPost()}</div>}
              </Grid>
              {!isApprovedOrDiscarded && (
                <Grid item xs={12} md={6}>
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleTabChange}
                    aria-label="post review tabs"
                  >
                    <Tab
                      icon={<CreditCardIcon style={{ color: "black" }} />}
                      iconPosition="start"
                      label={<span style={{ color: "#000" }}>Actions</span>}
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<HistoryIcon style={{ color: "black" }} />}
                      iconPosition="start"
                      label={<span style={{ color: "#000" }}>History</span>}
                      {...a11yProps(1)}
                    />
                  </Tabs>

                  <TabPanel value={this.state.tabValue} index={0}>
                    <span
                      style={{ color: "#344054", fontSize: "14px", fontWeight: "500" }}
                    >
                      Feedback*
                    </span>
                    <TextareaAutosize
                      id="improvement_comment"
                      placeholder="Type in your feedback here."
                      className={this.state.feedbackError ? "error" : ""}
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        padding: "12px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                      }}
                      minRows={5}
                      value={this.state.feedback}
                      onChange={(e) => {
                        this.setState({ feedback: e.target.value, feedbackError: false });
                      }}
                    />
                    <Stack spacing={2} style={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<MessageIcon />}
                        style={{
                          ...actionButtonStyle(disableActions),
                          backgroundColor: disableActions ? "#E0E0E0" : "#FFFFFF",
                          color: disableActions ? "#9E9E9E" : "#595959",
                        }}
                        onClick={this.handleGiveFeedbackClick}
                        disabled={disableActions}
                      >
                        Give Feedback
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<CheckCircleIcon />}
                        style={{
                          ...actionButtonStyle(disableActions),
                          backgroundColor: disableActions ? "#E0E0E0" : "#33CC99",
                          color: disableActions ? "#9E9E9E" : "#fff",
                        }}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to APPROVE this post?"
                            )
                          ) {
                            this.HandleActionButtonClick("Approved");
                          }
                        }}
                        disabled={disableActions}
                      >
                        Approve Draft
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<EditIcon />}
                        style={{
                          ...actionButtonStyle(disableActions),
                          backgroundColor: disableActions ? "#E0E0E0" : "#7F56D9",
                          color: disableActions ? "#9E9E9E" : "#fff",
                        }}
                        onClick={() => {
                          this.setState({ editContent: true });
                        }}
                        disabled={disableActions}
                      >
                        Edit Caption & Give Feedback
                      </Button>
                    </Stack>

                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                      <Grid item xs={6}>
                        <Box
                          style={{
                            padding: "10px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: "bold" }}
                          >
                            Submitted by
                          </Typography>
                          <Typography variant="body2">
                            {this.state.postDetail.influencerName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          style={{
                            padding: "10px",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            border: "1px solid #ccc",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: "bold" }}
                          >
                            List
                          </Typography>
                          <Typography variant="body2">
                            {this.state.postDetail.listName.length > 14
                              ? this.state.postDetail.listName.substring(0, 14) + "..."
                              : this.state.postDetail.listName}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={this.state.tabValue} index={1}>
                    <PostHistoryTimeline
                      HandlePostReviewModalClose={this.HandlePostReviewModalClose}
                      postHistory={this.state.postHistory}
                    />
                  </TabPanel>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Button
                variant="outlined"
                startIcon={<ReplyOutlinedIcon />}
                style={{
                  color: currentIndex === 0 ? "gray" : "#007DFF",
                  border: "2px solid",
                  backgroundColor: "#fff",
                  height: "44px",
                }}
                onClick={this.previousPost}
                disabled={currentIndex === 0}
              >
                Previous Draft
              </Button>
              <Button
                variant="outlined"
                endIcon={<ReplyOutlinedIcon style={{ transform: "scaleX(-1)" }} />}
                style={{
                  color:
                    currentIndex === this.state.postsIds.length - 1
                      ? "gray"
                      : "#007DFF",
                  border: "2px solid",
                  backgroundColor: "#fff",
                  height: "44px",
                }}
                onClick={this.nextPost}
                disabled={currentIndex === this.state.postsIds.length - 1}
              >
                Next Draft
              </Button>
            </Grid>
          </div>
        )}
        <Snackbar
          open={this.state.showMessage}
          autoHideDuration={3000}
          onClose={this.closeSnackbar}
        >
          <Alert onClose={this.closeSnackbar} severity="info">
            {this.state.message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}
