import axios from "axios";
import { API_HOST } from "../../config/main.json";
import { getResponseType } from "../../utils/exportData";

const optionAxios = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
};

const getBrandList = async (
    account,
    type,
    isExport,
    page,
    rows,
    SortCol,
    SortOrder,
    filter,
    mimeType,
    brandPlatform,
    timeZone,
) => {
    const response = await axios({
        url: `${API_HOST}/brands`,
        method: "POST",
        data: {
            account,
            type,
            isExport,
            page,
            rows,
            SortCol,
            SortOrder,
            filter,
            mimeType,
            addedFrom: brandPlatform,
            timeZone,
            status: "",
            platform: brandPlatform
        },
        headers: optionAxios.headers,
        responseType: getResponseType(isExport),
    });
    if (response) {
        return response.data;
    }

    return false;
};

const getAccountsBrandList = async (
    account,
    type,
    isExport,
    page,
    rows,
    SortCol,
    SortOrder,
    filter,
    mimeType,
    brandPlatform,
    timeZone,
) => {
    const response = await axios({
        url: `${API_HOST}/brands`,
        method: "POST",
        data: {
            account,
            type,
            isExport,
            page,
            rows,
            SortCol,
            SortOrder,
            filter,
            mimeType,
            addedFrom: brandPlatform,
            timeZone,
            status: "",
            platform: brandPlatform,
            accountFilter: true
        },
        headers: optionAxios.headers,
        responseType: getResponseType(isExport),
    });
    if (response) {
        return response.data;
    }

    return false;
};




export { getBrandList, getAccountsBrandList };
