import { Box, Grid, Paper } from "@mui/material";
import { number, string } from "prop-types";
import { useEffect, useState } from "react";
import Widget from "../../common/Widget";
import utils from "../../utils/utils";

const ListWidgetBar = ({ total, reach, engagement, location }) => {
  const [widgetTypes, setWidgetTypes] = useState([]);

  useEffect(() => {
    let widgetValues = [];
    const averageEngagement = ((engagement / reach) * 100).toFixed(2);

    widgetValues = [
      {
        name: "Influencers",
        count: total,
        completeString: "",
      },
      {
        name: "Total Reach",
        count: utils.convertToInternationalNumberSystem(reach),
        completeString: "",
      },
      {
        name: "Total Engagement",
        count: utils.convertToInternationalNumberSystem(engagement),
        completeString: "",
      },
      {
        name: "Top Location",
        count:
          location.length < 8 ? location : `${location.substring(0, 7)}...`,
        completeString: location.toString(),
      },
      {
        name: "Avg ER",
        count: `${averageEngagement >= 0 ? averageEngagement : "0.00"}%`,
        completeString: "",
      },
    ];
    setWidgetTypes(widgetValues);
  }, [total, reach, engagement, location]);
  return (
    <Box>
      <Paper
        style={{
          backgroundColor: "#E2EDFC",
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <Grid container spacing={1}>
          {widgetTypes.map((widget) => (
            <Grid item xs={2.4}>
              <Widget
                name={widget.name}
                count={widget.count}
                completeString={widget.completeString}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

ListWidgetBar.propTypes = {
  total: number.isRequired,
  reach: number.isRequired,
  engagement: number.isRequired,
  location: string.isRequired,
};

export default ListWidgetBar;
