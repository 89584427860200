const rolesData = [
  {
    value: "5",
    title: "Super User",
    description:
      "All Manager privileges, plus the ability to add and remove users",
  },
  {
    value: "1",
    title: "Manager",
    description: "Manager can additionally create campaigns and assign them to members.",
  },
  {
    value: "3",
    title: "Member",
    description: "Members can view the data and manage posts and influencers.",
  },
];

export { rolesData };
