import React, { useEffect } from "react";
import { Alert as MuiAlert, Snackbar, ThemeProvider } from "@mui/material";
import Amplify from "aws-amplify";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import awsconfig from "./aws-exports";
import GlobalStyles from "./styles/GlobalStyles";
import routes, {
  managerRestricted,
  memberRestricted,
} from "./navigation/routes";
import theme from "./styles/theme";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import ReactGA from "react-ga4";
import RouteChangeTracker from "./common/RouteChangeTracker";
import config from "./config/main.json";

import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "./redux/features/snackbar/snackbarSlice";
import { Auth } from "aws-amplify";
import { Chart, registerables } from "chart.js";

Amplify.configure(awsconfig);
Chart.register(...registerables);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" sx={{ color: "white" }} {...props} />;
});

const App = () => {
  const currentComponent = useRoutes(routes);
  const TRACKING_ID = config.GA_TRACKING_CODE;
  ReactGA.initialize([{trackingId: TRACKING_ID}]);
  
  const snackbar = useSelector((state) => state.snackbar);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  
  useEffect(() => {
    if (
      (user.group === "member" &&
        memberRestricted.includes(location.pathname)) ||
      (user.group === "manager" &&
      managerRestricted.includes(location.pathname))
    ) {
      navigate("/404");
    }
  }, [user.group, location, navigate]);

  setInterval(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
  }, 5 * 60 * 1000)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Snackbar
        open={snackbar?.open}
        autoHideDuration={3000}
        onClose={() => dispatch(closeSnackbar())}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => dispatch(closeSnackbar())} severity={snackbar?.severity} sx={{ width: '100%' }}>
          {snackbar?.message}
        </Alert>
      </Snackbar>
      {currentComponent}
      <RouteChangeTracker />
    </ThemeProvider>
  );
};

export default App;
