import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

/* config */
import { API_HOST, PLATFORM_NAME } from "../../config/main.json";
import utils from "../../utils/utils";
import enums from "../../utils/enums";

/* material-ui */
import Datatables from "mui-datatables";
import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  Divider
} from "@mui/material";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ApprovalIcon from "@mui/icons-material/Approval";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MuiAlert from "@mui/material/Alert";
import {
  Circle,
  MoreVertOutlined,
  Close,
  Visibility,
  Campaign,
  ChangeCircle,
  AddBusiness,
  PersonAdd,
  Unarchive,
  Info,
} from "@mui/icons-material";
import { PowerSettingsNewRounded, PublishedWithChanges } from "@mui/icons-material";

/* post history timeline modal */
import PostHistoryTimeline from "./PostHistoryTimeline";

/* post review modal */
import PostReview from "./PostReview";
import PostAttachmentsExpanded from "./PostAttachmentsExpanded";
import { connect } from "react-redux";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import { Delete, RemoveRedEye, History, Done, Restore } from "@material-ui/icons";

import { getDraftsToReviesPostTableHeaders, getApprovedPostsTableHeaders, getDiscardedPostTableHeaders } from "../../utils/tableHeaders";
import CustomTable from "../../common/CustomTable/CustomTable";
import Loader from "../../pages/account/Loader";
import tableStyle from "../../styles/theme/pageStyling/TableStyle.module.css";
import MyPaper from "../general/MyPaper";
import CampaignFilters from "../../pages/account/CampaignFilters";
import { getAllLists } from "../../services/list/ListDetails";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let campaignId;

const Posts = (props) => {
  const [type, setType] = useState(1);// 1 = DraftsToRevies, 2=ApprovedPosts, 3=DiscardedPosts
  const [status, setStatus] = useState([1, 4, 6, 7]);
  const [campId, setCampId] = useState(null);
  const [tablePageNo, setTablePageNo] = useState(0);
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const [sortCol, setSortCol] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [isLoaded, setIsLoaded] = useState(false);
  const [postsData, setPostsData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [dataFound, setDataFound] = useState(false);
  const [anchorEl, setAnchorEl] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [postIdSelected, setPostIdSelected] = useState(-1);
  const [postDetail, setPostDetail] = useState({});
  const [postReviewModalDisplay, setPostReviewModalDisplay] = useState(false);
  const [expandedAttachmentsModalDisplay, setExpandedAttachmentsModalDisplay] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [postHistory, setPostHistory] = useState([]);
  const [postHistoryModalDisplay, setPostHistoryModalDisplay] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [isChanged, setIsChanged] = useState(false);
  const [resetPageNo, setResetPageNo] = useState(false);
  const [fields, setFields] = useState({});

  const [selectedPlatform, setSelectedPlatform] = useState(-1);
  const [selectedPlatformName, setSelectedPlatformName] = useState("all");
  const [selectedListIds, setSelectedListIds] = useState([]);
  const [selectedInfluencersIds, setSelectedInfluencersIds] = useState([]);
  const [selectedTasksIds, setSelectedTasksIds] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [isSubmit, setIsSubmit] = useState(true);
  const [disableRefreshButton, setDisableRefreshButton] = useState(false);
  const [hideAdvancedFilters, setHideAdvancedFilters] = useState(false);

  const [filters, setFilters] = useState({ selectedListIds: [], selectedInfluencersIds: [] });
  const [optionsLists, setOptionsLists] = useState([]);
  const [optionsInfluencers, setOptionsInfluencers] = useState([]);
  const [optionsTasks, setOptionstasks] = useState([]);


  useEffect(async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    campaignId = parseInt(params.get("id"));
    setCampId(campaignId);
    getCampaignDetails(campaignId);
    getAllPlatforms();
    const InfId = await getAllList(campaignId.toString(), "all");
    utils.getAllInfluencers(InfId, "all", campaignId).then((data) => {
      if (data) {
        setOptionsInfluencers(data);
      }
    });
    getTaskList(campaignId);
  }, [])

  const getCampaignDetails = (campId) => {
    utils.getCampaignData(campId).then((data) => {
      setFields(data);
    })
  };

  const getTaskList = (campId) => {
    utils.getTaskListData(campId).then((data) => {
      if (data !== null) {
        setOptionstasks(data);
      }
    })
  };


  const getAllPlatforms = () => {
    utils.getPlatforms()
      .then((data) => {
        setPlatforms(data);
      })
  };

  const getAllList = async (campId, platform) => {
    const data = await getAllLists(campId, platform);
    if (data) {
      setOptionsLists(data);

      const idsArray = data.map(object => object.id);

      return idsArray;
    }
  };

  const fetchData = () => {
    if (isSubmit) {
      setIsSubmit(false)
    }
    setIsLoaded(() => false);
    let status = [1, 4, 6, 7];
    if (props.type === 2) {
      setType(2);
      status = [2];
    } else if (props.type === 3) {
      setType(3);
      status = [9];
    }
    getPostsList(
      campaignId,
      status,
      tablePageNo,
      tableRowsPerPage,
      sortCol,
      sortOrder,
      selectedPlatformName,
      selectedListIds,
      selectedInfluencersIds,
      selectedTasksIds,
    );
  };

  useEffect(() => {
    fetchData();
  }, [
    campId,
    status,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    isSubmit,
  ]);

  const getPostsList = async (
    campaignId,
    status,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    selectedPlatformName,
    selectedListIds,
    selectedInfluencersIds,
    selectedTasksIds,
  ) => {

    setIsLoaded(false);
    // API used to get the details of the accounts

    const res = await utils.getDraftList(
      campaignId,
      status,
      tablePageNo,
      tableRowsPerPage,
      sortCol,
      sortOrder,
      selectedPlatformName,
      selectedListIds,
      selectedInfluencersIds,
      selectedTasksIds,
    );

    const { total, draftPosts } = res;
    if (total > 0 && draftPosts) {
      setPostsData(draftPosts)
      setTotalRecords(total);
      setIsSubmit(false);
      setDataFound(true);
      setIsLoaded(true);
    }
    else {
      setIsSubmit(false);
      setDataFound(false);
      setIsLoaded(true);
    }
    // })
  };

  /* review this particular post */
  const ReviewPost = (postId) => {
    setPostIdSelected(postId);
    axios.get(API_HOST + "/post/details/" + postId).then((res) => {
      const postDetail = res.data;
      if (postDetail != null) {
        setPostDetail(postDetail);
        setPostReviewModalDisplay(true);
      }
    });
  };

  const HandlePostReviewModalClose = () => {
    setPostReviewModalDisplay(false);
  };

  const attachmentsInFullScreen = () => {
    setExpandedAttachmentsModalDisplay(true);
  };

  /* post review actions */
  const HandleActionButtonClick = (event) => {
    const status = enums.statusMapping[event.action];
    const comment = event.feedback;
    const postContent = event.postContent;
    AddPostReview(status, comment, postContent);
  };

  const AddPostReview = (status, comment, postContent) => {
    const specificObject = postsData.find((obj) => obj.id === postIdSelected);

    const notificationMetadata = {
      userId: specificObject.iid.toString(),
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "6",
      generatedBy: props.user.attributes.name,
      priority: "medium",
      consumedBy: "gazelle",
      navigateTo: `/account/posts/draft/${postIdSelected}?campaignId=${postDetail.cid}`,
    };
    const data = {
      postId: postIdSelected,
      status,
      comment,
      postContent,
      campaignName: postDetail.campaignName,
      platform: "oryx",
      email: props.user.attributes.email,
      notificationMetadata,
    };

    const optionAxios = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    let notificationMsg = "";
    axios
      .post(`${API_HOST}/posts/review`, data, optionAxios)
      .then((res) => {
        notificationMsg = res.data.Msg;
      })
      .catch((_res) => {
        notificationMsg = "Could not update post status";
      })
      .finally(() => {
        setNotificationMsg(notificationMsg);
        setShowNotification(true);
        setPostReviewModalDisplay(false);
        fetchData();
        // window.location = window.location.href;
      });
  };

  const HandleExpandedAttachmentsModalClose = () => {
    expandedAttachmentsModalDisplay(false);
  };

  const ViewHistory = (postId) => {
    setPostIdSelected(postId);
    axios.get(`${API_HOST}/post/history/${postId}`).then((res) => {
      let postHistory = res.data;
      if (postHistory == null || postHistory === undefined) {
        postHistory = [];
      }
      setPostHistory(postHistory);
      setPostHistoryModalDisplay(true);
    });
  };

  const HandlePostHistoryModalClose = (_event) => {
    setPostHistoryModalDisplay(false);
    setPostHistory([]);
  };

  const DiscardPost = (post) => {
    setNotificationMsg("Please wait... We are discarding the post");
    setSeverity("info");
    setShowNotification(true);
    setPostReviewModalDisplay(false);

    const { postContent, id, campaign, iid, cid } = post;

    const notificationMetadata = {
      userId: iid.toString(),
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "18",
      generatedBy: props.user.attributes.name,
      priority: "medium",
      consumedBy: "gazelle",
      navigateTo: `/account/posts/draft/${id}?campaignId=${cid}`,
    };

    const data = {
      postId: id,
      status: enums.statusMapping.Discard,
      comment: "",
      postContent: postContent,
      campaignName: campaign,
      platform: "oryx",
      email: props.user.attributes.email,
      notificationMetadata,
    };

    const optionAxios = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };

    let notificationMsg = "";
    axios
      .post(`${API_HOST}/posts/review`, data, optionAxios)
      .then((res) => {
        notificationMsg = res.data.Msg;
      })
      .catch((_res) => {
        notificationMsg = "Could not update post status";
      })
      .finally(() => {
        setNotificationMsg(notificationMsg);
        setSeverity("success");
        setShowNotification(true);
        setPostReviewModalDisplay(false);
        fetchData();
        // window.location = window.location.href;
      });
  };

  const PublishPost = (post) => {
    setNotificationMsg("Please wait... We are marking the post as published ");
    setSeverity("info");
    setShowNotification(true);
    setPostReviewModalDisplay(false);
    setPostIdSelected(PowerSettingsNewRounded);

    const { postContent, id, campaign, iid, cid } = post;

    const notificationMetadata = {
      userId: iid.toString(),
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "18",
      generatedBy: props.user.attributes.name,
      priority: "medium",
      consumedBy: "gazelle",
      navigateTo: `/account/posts/draft/${id}?campaignId=${cid}`,
    };

    const data = {
      postId: id,
      status: enums.statusMapping.Published,
      comment: "",
      postContent: postContent,
      campaignName: campaign,
      platform: "oryx",
      email: props.user.attributes.email,
      notificationMetadata,
    };

    const optionAxios = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };

    axios
      .post(`${API_HOST}/posts/review`, data, optionAxios)
      .then((res) => {
        setNotificationMsg(res.data.Msg);
        setSeverity("success");
      })
      .catch((_res) => {
        setNotificationMsg("Could not update post status");
        setSeverity("error");
      })
      .finally(() => {
        setShowNotification(true);
        setPostReviewModalDisplay(false);
        fetchData();
      });
  };

  const RestoreDraft = (post) => {
    setNotificationMsg("Please wait... We are restoring this draft ");
    setSeverity("info");
    setShowNotification(true);
    setPostReviewModalDisplay(false);
    setPostIdSelected(PowerSettingsNewRounded);

    const { postContent, id, campaign, iid, cid } = post;

    const notificationMetadata = {
      userId: iid.toString(),
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "18",
      generatedBy: props.user.attributes.name,
      priority: "medium",
      consumedBy: "gazelle",
      navigateTo: `/account/posts/draft/${id}?campaignId=${cid}`,
    };

    const data = {
      postId: id,
      status: enums.statusMapping["Submitted For Review"],
      comment: "",
      postContent: postContent,
      campaignName: campaign,
      platform: "oryx",
      email: props.user.attributes.email,
      notificationMetadata,
    };

    const optionAxios = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };

    axios
      .post(`${API_HOST}/posts/review`, data, optionAxios)
      .then((res) => {
        setNotificationMsg(res.data.Msg);
        setSeverity("success");
      })
      .catch((_res) => {
        setNotificationMsg("Could not update post status");
        setSeverity("error");
      })
      .finally(() => {
        setNotificationMsg("Draft Restored Successfully");
        setSeverity("success");
        setShowNotification(true);
        setPostReviewModalDisplay(false);
        fetchData();
      });
  };

  const menuItemClickHandler = (itemIdx, action) => {
    if (action === "approvePost" || action === "viewDraft") {
      ReviewPost(postsData[itemIdx]?.id);
    }
    else if (action === "postHistory") {
      ViewHistory(postsData[itemIdx]?.id);
    }
    else if (action === "discardDraft") {
      DiscardPost(postsData[itemIdx]);
    }
    else if (action === "published") {
      PublishPost(postsData[itemIdx]);
    }
    else if (action === "restore") {
      RestoreDraft(postsData[itemIdx]);
    }
  }

  const closeNotification = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotificationMsg('');
    setShowNotification(false);
  };

  const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
    setTablePageNo(newPage.page);
    setResetPageNo(false);
    setIsChanged(!isChanged);
    setTableRowsPerPage(newPage.rowsPerPage);
    setSortCol(newPage.orderBy);
    setSortOrder(newPage.order);
  };

  const statusValue = {
    1: "Submitted For Review",
    2: "Approved",
    3: "Rejected",
    4: "Improvement Suggested",
    5: "Deleted",
    6: "Edit With Suggestions",
    7: "Resubmitted",
    8: "Published",
    9: "Discard"
  };

  const handleChangePlatform = async (event) => {
    const platformValue = event.target.value;
    let platform = platforms.find(e => e.id === platformValue);

    if (platform === undefined) {
      platform = { id: 0, name: "all" };
    }

    setSelectedPlatform(platformValue);
    setSelectedPlatformName(platform.name);
    setSelectedListIds([]);
    setSelectedInfluencersIds([]);

    const InfId = await getAllList(campId.toString(), platform.name);
    utils.getAllInfluencers(InfId, platform.name, campId).then((data) => {
      if (data) {
        console.log("inf" + data)
        setOptionsInfluencers(data);
      }
    });
  };


  const applyFilters = () => {
    setIsSubmit(true);
    setTablePageNo(0);
  };

  const showHideAdvancedFilters = () => {
    setHideAdvancedFilters(!hideAdvancedFilters);
  };

  const handleListChange = (event) => {
    let value = event.target.value;
    let allIds = optionsLists.map(e => e.id);
    let selected = selectedListIds.length === optionsLists.length ? [] : allIds;

    if (value[value.length - 1] === -1) {
      setSelectedListIds(selected);
      getAllInfluencers(selected);
      return;
    }

    setSelectedListIds(value);
    setSelectedInfluencersIds([]);
    getAllInfluencers(value);
  };

  const getAllInfluencers = (lids) => {
    utils.getAllInfluencers(lids, selectedPlatformName, campId).then((data) => {
      if (data) {
        setOptionsInfluencers(data);
      }
    });
  };

  const handleInfluencersChange = (event) => {
    let value = event.target.value;
    let allIds = optionsInfluencers.map((e) => e.Iid);
    let selected = selectedInfluencersIds.length === optionsInfluencers.length ? [] : allIds;

    if (value[value.length - 1] === -1) {
      setSelectedInfluencersIds(selected);
      return;
    }
    setSelectedInfluencersIds(value);
  };

  const handleTasksChange = (event) => {
    let value = event.target.value;
    let allIds = optionsTasks.map((e) => e.id);
    let selected = selectedTasksIds.length === optionsTasks.length ? [] : allIds;

    if (value[value.length - 1] === -1) {
      setSelectedTasksIds(selected);
      return;
    }
    setSelectedTasksIds(value);
  };

  const isAllListSelected =
    optionsLists.length > 0 && selectedListIds.length === optionsLists.length;

  const isAllInfluencerSelected =
    optionsInfluencers.length > 0 &&
    selectedInfluencersIds.length === optionsInfluencers.length;

  const isAllTasksSelected =
    optionsTasks.length > 0 &&
    selectedTasksIds.length === optionsTasks.length;


  useEffect(() => {
    const handleClick = (event, index) => {
      const anchorEl = [];
      for (let i = 0; i < postsData.length; i++) {
        if (i === index) anchorEl.push(event.currentTarget);
        else anchorEl.push(null);
      }
      setAnchorEl(anchorEl);
    };

    const handleClose = () => {
      const anchorEl = [];
      for (let i = 0; i < postsData.length; i++) {
        anchorEl.push(null);
      }
      setAnchorEl(anchorEl);
    };

    const data = [];
    if (postsData) {
      postsData.forEach((item, index) => {
        const {
          campaign,
          cid,
          createdAt,
          currentStatus,
          email,
          id,
          iid,
          influencer,
          list,
          milestone,
          photoUrl,
          platform,
          postContent,
          profileUrl,
          taskName,
          updatedAt,
          userName,
        } = item;

        const actions = (
          <div>
            {type === 1 && (
              <>
                <MenuItem
                  onClick={() => {
                    menuItemClickHandler(index, "approvePost");
                    handleClose(index);
                  }}
                >
                  <ApprovalIcon fontSize="small" /> &nbsp;&nbsp; Review Draft
                </MenuItem>
              </>
            )}

            {type === 1 && (
              <>
                <Divider sx={{ my: 0.5 }} />
                {/* <MenuItem
                  onClick={() => {
                    menuItemClickHandler(index, "postHistory");
                    handleClose(index);
                  }}
                >
                  <ManageHistoryIcon fontSize="small" /> &nbsp;&nbsp; Manage Draft History
                </MenuItem> */}
              </>
            )}

            {type === 1 && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={() => {
                    if (window.confirm("Are you sure you want to Discard the post?")) {
                      menuItemClickHandler(index, "discardDraft");
                    }
                    handleClose(index);
                  }}
                >
                  <Delete fontSize="small" /> &nbsp;&nbsp; Discard Draft
                </MenuItem>
              </>
            )}

            {type === 2 && (
              <>
                <MenuItem
                  onClick={() => {
                    menuItemClickHandler(index, "viewDraft");
                    handleClose(index);
                  }}
                >
                  <ApprovalIcon fontSize="small" /> &nbsp;&nbsp; Review Draft
                </MenuItem>
              </>
            )}
            {type === 2 && (
              <>
                {/* <MenuItem
                  onClick={() => {
                    menuItemClickHandler(index, "postHistory");
                    handleClose(index);
                  }}
                >
                  <History fontSize="small" /> &nbsp;&nbsp; View Draft History
                </MenuItem> */}
              </>
            )}
            {type === 2 && (
              <>
                <MenuItem
                  onClick={() => {
                    if (window.confirm("Are you sure, you want to mark this post as Published?")) {
                      menuItemClickHandler(index, "published");
                    }
                    handleClose(index);
                  }}
                >
                  <Done fontSize="small" /> &nbsp;&nbsp; Mark as Published
                </MenuItem>
              </>
            )}
            {type === 3 && (
              <>
                <MenuItem
                  onClick={() => {
                    menuItemClickHandler(index, "viewDraft");
                    handleClose(index);
                  }}
                >
                  <RemoveRedEye fontSize="small" /> &nbsp;&nbsp; View Draft
                </MenuItem>
              </>
            )}
            {type === 3 && (
              <>
                {/* <MenuItem
                  onClick={() => {
                    menuItemClickHandler(index, "postHistory");
                    handleClose(index);
                  }}
                >
                  <History fontSize="small" /> &nbsp;&nbsp; View Post History
                </MenuItem> */}
              </>
            )}
            {type === 3 && (
              <>
                <MenuItem
                  onClick={() => {
                    if (window.confirm("Are you sure, you want to restore this Draft?")) {
                      menuItemClickHandler(index, "restore");
                    }
                    handleClose(index);
                  }}
                >
                  <Restore fontSize="small" /> &nbsp;&nbsp; Restore this Draft
                </MenuItem>
              </>
            )}

          </div>
        );
        const open = anchorEl.map((val) => val !== null);

        let status = statusValue[currentStatus];

        const object = {
          id: index + 1,
          influencer: (
            <Grid container spacing={2}>
              <Grid item>{influencer}</Grid>
              <Grid item>
                <Typography sx={textColor[platform]}>{snapshotHeaderIcons[platform]}</Typography>
              </Grid>
            </Grid>
          ),
          taskName: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingLeft: "2px" }}>
                {taskName || "-"}
              </Grid>
            </Grid>
          ),
          milestone: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingLeft: "2px" }}>
                {milestone || "-"}
              </Grid>
            </Grid>
          ),
          status: (
            <Grid container>
              <div>
                <Chip
                  label={status}
                  style={{
                    backgroundColor: enums.timelineDetails[status].chipColor,
                    color: enums.timelineDetails[status].iconColor,
                    width: "170px",
                    fontStyle: "italic",
                  }}
                />
                <br></br>
                <span
                  style={{
                    color: "#33CC99",
                    marginLeft: "55px",
                    fontSize: "smaller",
                  }}
                >
                  {utils.getTimePassed(
                    new Date(),
                    updatedAt.endsWith("Z")
                      ? updatedAt
                      : `${updatedAt}Z`
                  )}
                </span>
              </div>
            </Grid>
          ),
          approved: (
            <Grid container>
              <Grid
                container
                sx={{
                  marginLeft: "5px",
                }}
              >
                <Grid item xs={12}>
                  <span>
                    {utils.getTimePassed(
                      new Date(),
                      updatedAt.endsWith("Z")
                        ? updatedAt
                        : `${updatedAt}Z`
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} sx={{ fontSize: "12px", color: "gray" }}>
                  {email != "" && (
                    <i>by {email}</i>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ),
          discarded: (
            <Grid container>
              <Grid
                container
                sx={{
                  marginLeft: "5px",
                }}
              >
                <Grid item xs={12}>
                  <span>
                    {utils.getTimePassed(
                      new Date(),
                      updatedAt.endsWith("Z")
                        ? updatedAt
                        : `${updatedAt}Z`
                    )}
                  </span>
                </Grid>
                <Grid item xs={12} sx={{ fontSize: "12px", color: "gray" }}>
                  {email != "" && (
                    <i>by {email}</i>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ),
          action: (
            <Grid container>
              <Grid item xs={3}>
                <>
                  {" "}
                  <IconButton onClick={(event) => handleClick(event, index)} size="small" sx={{ ml: 2 }}>
                    <MoreVertOutlined />
                  </IconButton>
                  <Box style={{ borderRadius: "30%" }}>
                    <Menu
                      anchorEl={anchorEl[index]}
                      getContentAnchorEl={null}
                      open={open[index]}
                      onClose={handleClose}
                      className={tableStyle.menuitem}
                    >
                      {actions}
                    </Menu>
                  </Box>
                </>
              </Grid>
            </Grid>
          ),
        };

        data.push(object);
      });
      setTableData(data);
    }
  }, [postsData, anchorEl]);

  const draftsToReviesPostTableHeaders = getDraftsToReviesPostTableHeaders;
  const approvedPostsTableHeaders = getApprovedPostsTableHeaders;
  const discardedPostTableHeaders = getDiscardedPostTableHeaders;

  const renderContentHandler = () => {
    let content = null;
    if (isLoaded) {
      if (dataFound) {
        content = (
          <>
            <div id="table">
              {/* This is a custom made component, take care while changing field names */}
              <CustomTable
                tableHeaders={type === 1 ? (draftsToReviesPostTableHeaders) : (type === 2 ? (approvedPostsTableHeaders) : (discardedPostTableHeaders))}
                bodyColumns={tableData}
                sendInformation={handleInformationChange}
                totalRows={totalRecords}
                pageNo={tablePageNo}
                rowsPerPageNo={tableRowsPerPage}
                sortOrder={sortOrder}
                sortOrderBy={sortCol}
              />
            </div>
          </>
        );
      } else {
        content = (
          <MyPaper>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <p style={{ padding: "40px", textAlign: "center" }}> You don't have any Posts at the moment.</p>
              </Grid>
            </Grid>
          </MyPaper>
        );
      }
    } else content = <Loader />;

    return content;
  };

  return (
    <>


      <div style={{ marginTop: "80px" }} >
        <MyPaper>
          <CampaignFilters
            name={fields.name}
            id={campId}

            selectedPlatform={selectedPlatform}
            selectedPlatformName={selectedPlatformName}
            platforms={platforms}

            hideAdvancedFilters={hideAdvancedFilters}
            selectedListIds={selectedListIds}
            selectedInfluencersIds={selectedInfluencersIds}
            selectedTasksIds={selectedTasksIds}
            disableRefreshButton={disableRefreshButton}
            isAllListSelected={isAllListSelected}
            optionsLists={optionsLists}
            isAllInfluencerSelected={isAllInfluencerSelected}
            isAllTasksSelected={isAllTasksSelected}
            optionsInfluencers={optionsInfluencers}
            optionsTasks={optionsTasks}
            handleChangePlatform={handleChangePlatform}
            handleListChange={handleListChange}
            handleInfluencersChange={handleInfluencersChange}
            handleTasksChange={handleTasksChange}
            applyFilters={applyFilters}
            showHideAdvancedFilters={showHideAdvancedFilters}
            hideFilterBy={true}
            hidePlatform={false}
            hideInfluencers={false}
            hideTasks={false}
            hideRange={true}
          />
        </MyPaper>
      </div>


      <Box my={1}>{renderContentHandler()}</Box>

      {/* post review modal */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-post_review-label"
        aria-describedby="modal-post_review-description"
        open={postReviewModalDisplay}
        onClose={HandlePostReviewModalClose}
        closeAfterTransition
      >
        <>
          <Fade in={postReviewModalDisplay} timeout={0}>
            <PostReview
              postDetail={postDetail}
              attachmentsInFullScreen={attachmentsInFullScreen}
              HandlePostReviewModalClose={HandlePostReviewModalClose}
              HandleActionButtonClick={type === 1 ? HandleActionButtonClick : null}
              approvedDraft={type === 1 ? false : true}
              data={postsData}
            ></PostReview>
          </Fade>
        </>
      </Modal>

      {/* fullscreen attachments modal */}
      <Modal
        className="styles_expanded_modal"
        aria-labelledby="modal-expanded_attachments-label"
        aria-describedby="modal-expanded_attachments-description"
        open={expandedAttachmentsModalDisplay}
        onClose={HandleExpandedAttachmentsModalClose}
        closeAfterTransition
      >
        <>
          <Fade in={expandedAttachmentsModalDisplay} timeout={0}>
            <PostAttachmentsExpanded
              postAttachments={postDetail.Attachments}
              HandleExpandedAttachmentsModalClose={HandleExpandedAttachmentsModalClose}
            ></PostAttachmentsExpanded>
          </Fade>
        </>
      </Modal>

      {/* post history modal */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-post_history-label"
        aria-describedby="modal-post_history-description"
        open={postHistoryModalDisplay}
        onClose={HandlePostHistoryModalClose}
        closeAfterTransition
      >
        <>
          <Fade in={postHistoryModalDisplay} timeout={0}>
            <PostHistoryTimeline
              postHistory={postHistory}
              HandlePostHistoryModalClose={HandlePostHistoryModalClose}
            ></PostHistoryTimeline>
          </Fade>
        </>
      </Modal>


      {/* notification */}
      <Snackbar
        open={showNotification}
        autoHideDuration={3000}
        onClose={closeNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={closeNotification} severity={severity} sx={{ width: "100%" }}>
          {" "}
          {notificationMsg}{" "}
        </Alert>
      </Snackbar>


    </>

  )

}

function mapStateToProps(state) {
  const user = state.user;
  return { user };
}

export default connect(mapStateToProps)(Posts);
