import React, { useEffect, useState, useRef } from 'react';
import { TextField, Button, IconButton, Stack, Fade, Autocomplete, Box, Typography, Modal, Grid, FormControl, FormHelperText, Backdrop, CircularProgress, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TaskCreationForm.css'; // Importing the CSS file
import { Event, Flag, Person } from '@mui/icons-material';
import TransferList from "../../components/general/TransferList";
import axios from "axios";
import { API_HOST, UNAUTH_API_HOST, PLATFORM_NAME } from "../../config/main.json";
import { snapshotHeaderIcons, textColor } from "../../components/campaignAnalysis/snapshot";
import utils from '../../utils/utils';
import CustomDropdown from './CustomDropdown';
import EditIcon from "@mui/icons-material/Edit";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from '../../redux/features/snackbar/snackbarSlice';

function TaskForm({ cid, handleClose, open, setOpen, formType, setFormType, taskId }) {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startDateStr, setStartDateStr] = useState(new Date());
  const [endDateStr, setEndDateStr] = useState(new Date());
  const [priority, setPriority] = useState('');
  const [showTransfer, setShowTransfer] = useState(false);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [listData, setListData] = useState(null);
  const [list, setList] = useState(null);
  const [openTl, setOpenTl] = useState(false);
  const [listMsg, setListMsg] = useState("");
  const [listInfCount, setListInfCount] = useState(0);
  const [milestones, setMilestones] = useState([]);
  const [milestoneId, setMilestoneId] = useState(null);
  const [backdropShow, setBackdropShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null); // To store the selected option's ID
  const [existingIids, setExistingIids] = useState([]);
  const [editorHtml, setEditorHtml] = useState('');
  const quillRef = useRef(null);
  const [manageAssignee, setManageAssignee] = useState(false);
  const [loaderMsg, setLoaderMsg] = useState("Creating a task... please wait.");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto", // Adjust the width as needed
    backgroundColor: 'white',
    boxShadow: 24,
  };

  function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);
  
    // Extract year, month, and day components
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so we add 1
    const day = String(inputDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  
  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      return; // Do nothing if the key is Enter
    }
  
    e.preventDefault();
    setBackdropShow(true);
  
    if (editorHtml === "") {
      alert("Please add a description for the task");
      return;
    }

    const id_list = right.map((item) => item.id);

    const payload = {
      title,
      description: editorHtml,
      startdate: formatDate(startDate),
      enddate: formatDate(endDate),
      priority,
      lid: list.id,
      milestone: milestoneId,
      iid: id_list,
      notificationMetadata: {
        userId: id_list.toString(),
        sourcePlatform: PLATFORM_NAME,
        notificationTypeId: "4",
        notificationActionId: "17",
        notificationCategoryId: "5",
        generatedBy: user.attributes.name,
        priority: "medium",
        consumedBy: "gazelle",
        navigateTo: "/account/tasks"
      }
    };
    const handleAPICall = (apiFunction, successMessage) => {
      apiFunction(payload)
        .then((data) => {
          setBackdropShow(false);
          setOpen(false);
          dispatch(
            openSnackbar({
              message: successMessage,
              severity: "success",
            })
          );
        })
        .catch((error) => {
          setBackdropShow(false);
          setOpen(false);
          dispatch(
            openSnackbar({
              message: error,
              severity: "error",
            })
          );
        });
    };
    
    if (formType === "Edit") {
      payload.id = parseInt(taskId);
      setLoaderMsg("Updating task data... please wait.");
      handleAPICall(utils.updateTask, "Task updated successfully!");
    } else {
      setLoaderMsg("Creating a task... please wait.");
      handleAPICall(utils.createTask, "Task created successfully!");
    }
  };
  

  const closeTransferList = (e) => {
    setRight([]);
    setOpenTl(false);
    setManageAssignee(false);
  };

  const handleEdit = () => {
    setFormType("Edit"); 
  }

  const handleChange = (html) => {
    setEditorHtml(html);
  };


  const getInfluencers = (cid, template, list) => {

    utils.getInfluencersAnalysis(cid, "-1")

    axios.get(API_HOST + `/influencers/organic/${list}`).then((res) => {
      const influencers = res.data?.length > 0 ? res.data : [];
      const left = [];
      const right = [];
      setListInfCount(influencers.length);
      influencers.forEach((influencer) => {
        const inf = {};
          inf["id"] = influencer.iid;
          if (influencer.infName === "") {
            return;
          }
          inf["label"] = influencer.infName;
          inf["disabled"] = false;
          if(!existingIids.includes(influencer.iid)) {
            left.push(inf);
          } else {
            right.push(inf);
          }
        
      });
      setLeft(left);
      setRight(right);
      setShowTransfer(true);
      if (formType !== "View") {
        setOpenTl(true);
      }
      setIsLoaded(true);

    });
  };

  useEffect(() => {

    const getListData = async (id) => {
      try {
        const res = await axios.get(API_HOST + "/lists/camp/id/" + id);
        const listData = res.data;
        setListData(listData ? listData : []);
        return listData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (formType === "Create" || formType === "View") {
      if (formType === "View") {
        setBackdropShow(true);
        setLoaderMsg("Fetching task data... please wait.");
      }
      getListData(cid).then((listData) => {
        if (formType === "View" && listData) {
          utils.getTask(taskId).then((data) => {  
          setTitle(data[0].title);
          setEditorHtml(data[0].description);
          setMilestoneId(data[0].mid);
          setSelectedOption({ "id": data[0].mid, "label": data[0].milestone });
          setPriority(data[0].priority);
          const sDate = new Date(data[0].startdate);
          setStartDate(sDate);
          setStartDateStr(data[0].startdate);
          const eDate = new Date(data[0].enddate);

          setEndDate(eDate);
          setEndDateStr(data[0].enddate);
          const item = listData.find(item => item.id === data[0].lid);
          setList(item);
          var result = data[0].iids.split(',').map(Number);
          setExistingIids(result);
          setBackdropShow(false);
        });
        }
      });

      utils.getMilestonesByCid(cid).then((data) => {
        setMilestones(data);
      });
    };
  }, [cid, formType]);

  useEffect(() => {
    const msg = right.length > 0 ? (right.length === listInfCount ? "All influencer(s) selected" : `${right.length} influencer(s) selected`) : '';
    setListMsg(msg)
  }, [right]);

  useEffect(() => {
    if (list !== null) {
      if(openTl === true ||  manageAssignee===true){
        getInfluencers(cid, 0, list.id);
      }
    }
  }, [list, openTl, manageAssignee]);

  const isFormValid = () => {
    return title && editorHtml && startDate && endDate && list && selectedOption;
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: "column",
        }}
        open={backdropShow}
      >
        <CircularProgress color="inherit" />
        <Box sx={{ marginTop: "20px" }}>{loaderMsg}</Box>
      </Backdrop>
      <Fade in={true}>
        <div style={{
          backgroundColor: "white",
          outline: "none",
          padding: "10px",
          width: "auto",
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2 style={{ padding: "10px" }}>
            {formType} Task &nbsp;
            <Tooltip title={"Click to edit the task"}>
              <IconButton onClick={handleEdit}>
                {formType === "View" ? <EditIcon fontSize="medium" style={{ border: "1px solid #000", borderRadius: "5px",padding: "5px" }} /> : ""}
              </IconButton>
            </Tooltip>
          </h2>
            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
          </div>
          <hr />
          <div style={{ padding: "10px" }}>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Title*:</label>
                <input
                  type="text"
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={formType === "View" ? true : false}
                />
              </div>
              <div className="form-group">
                <CustomDropdown milestones={milestones} cid={cid} milestoneId={milestoneId} setMilestoneId={setMilestoneId} selectedOption={selectedOption} setSelectedOption={setSelectedOption}   disabled={formType === "View" ? true : false} setBackdropShow={setBackdropShow} />
              </div>
              <div className="form-group" style={{height:"150px"}}>
                <label>Description*:</label>
                <ReactQuill
                  ref={quillRef}
                  value={editorHtml}
                  onChange={handleChange}
                  modules={{
                    toolbar: {
                      container: [
                        [{ header: '1' }, { header: '2' }],
                        ['bold', 'italic', 'underline', 'strike'],
                        //['link', 'image', 'video'],
                        ['link', 'image'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['blockquote', 'code-block'],
                        [{ script: 'sub' }, { script: 'super' }],
                        [{ align: [] }],
                        ['clean'],
                      ],
                    },
                  }}
                  style={{ height: '100px' }}
                />
              </div>
              <div className="date-priority-row">

                <div className="form-group">
                  <label><Event />Start Date*:</label>
                  <DatePicker
                    disabled={formType === "View" ? true : false}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    minDate={new Date()} // Disable past dates
                  />
                </div>
                <div className="form-group">
                  <label><Event />End Date*:</label>
                  <DatePicker
                    disabled={formType === "View" ? true : false}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    //minDate={new Date()} // Disable past dates
                  />
                </div>
                <div className="form-group">
                  <label><Flag />Priority:</label>
                  <select required value={priority} disabled={formType === "View" ? true : false}
                    onChange={(e) => setPriority(e.target.value)}>
                    <option value="">Select Priority</option>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </select>
                </div>


              </div>
              <div>
              {formType !== 'Create'  && (
                <div className="form-group" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span> <label>
                    <Person /> Assignee: </label> {listMsg}
                  </span>
                  <span><Button
                    disabled={formType === "View" ? true : false}
                    variant="contained"
                    size="small"
                    className="postReview-btn"
                    id="postReview-approve-btn"
                    onClick={() => { setOpenTl(true);setManageAssignee(true);}}
                  >
                    Manage Assignee
                  </Button></span>
                </div>
                )}
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="combo-box-demo"
                  getOptionLabel={(option) => option.name}
                  options={listData ? listData : []}
                  value={list}
                  disabled={formType === "View" ? true : false}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      <Typography sx={textColor[option.platform]}>{snapshotHeaderIcons[option.platform]}</Typography>
                      &nbsp;
                      {option.name}
                    </Box>
                  )}
                  onChange={(e, value) => {
                    setList(value);
                    setOpenTl(true);
                  }}
                  renderInput={(params) => <TextField {...params} label="Select List" />}
                />
              </div>
              {isLoaded && showTransfer && (
                <Modal open={openTl} onClose={handleClose}>
                  <Fade in={openTl} timeout={500}>
                    <Box sx={modalStyle}>
                      <div className="form-group" style={{ padding: "10px" }}>
                        <Grid item xs={12} sm={12} style={{ marginTop: "1rem" }}>
                          <FormControl fullWidth>
                            <TransferList left={left} setLeft={setLeft} right={right} setRight={setRight} />

                          </FormControl>

                          <FormHelperText style={{ marginTop: "1rem", marginLeft: "2rem" }}>
                            <Stack
                              direction="row"
                              justifyContent="end"
                              spacing={{ xs: 1, sm: 2, md: 2 }}
                            >

                              <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                className="postReview-btn"
                                id="postReview-approve-btn"
                                onClick={() => { closeTransferList(); }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                size="large"
                                className="postReview-btn"
                                id="postReview-proideFeedback-btn"
                                onClick={() => { setOpenTl(false); setManageAssignee(false); }}
                              >
                                Apply
                              </Button>
                            </Stack><br />
                            * The transfer list does not include any direct influencers
                          </FormHelperText>
                        </Grid>
                      </div>
                    </Box>
                  </Fade>
                </Modal>

              )}
              <FormHelperText style={{ marginTop: "2rem", marginLeft: "2rem" }}>

                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >

                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className="postReview-btn"
                    id="postReview-approve-btn"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isFormValid() || formType === "View"}
                    variant="contained"
                    size="large"
                    className="postReview-btn"
                    id="postReview-proideFeedback-btn"
                    type="submit"
                  >
                      {formType === "Edit" ? "Update" : "Create"}
                  </Button>
                </Stack></FormHelperText>
            </form>
          </div>
        </div>
      </Fade></>
  );
}

export default TaskForm;