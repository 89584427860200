import React, { Component } from "react";

/* intra-project reference */
import utils from "../../utils/utils";
import enums from "../../utils/enums";

/* material-ui */
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

/* carousel */
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class PostAttachmentsExpanded extends Component {
  constructor(props) {
    super(props);
  }

  /* let parent know that user chose to close the modal */
  HandleExpandedAttachmentsModalClose = () => {
    this.props.HandleExpandedAttachmentsModalClose(true);
  };

  /* renders each of the attachment in full screen mode */
  renderAttachmentExpanded = () => {
    if (this.props.postAttachments) {
      const attachments = this.props.postAttachments;
      return attachments.map((attachment) => {
        const url = attachment.url;
        const fileExtension = url.split(".").pop();
        const allowedFileExtensions = enums.fileExtensions;
        if (allowedFileExtensions.images.includes(fileExtension)) {
          return (
            <div style={{ padding: "20px", textAlign: "center" }}>
              <img
                src={url}
                alt="attachment_image"
                onError={utils.addDefaultPostThumbnailImage}
              />
            </div>
          );
        } else if (allowedFileExtensions.video.includes(fileExtension)) {
          const type = "video/" + fileExtension;
          return (
            <div
              className="attachment_video"
              style={{ height: "90vh", position: "relative" }}
            >
              <video
                controls
                preload="metadata"
                style={{ height: "100%", width: "100%" }}
              >
                <source src={url} type={type} />
              </video>
            </div>
          );
        } else if (allowedFileExtensions.pdf.includes(fileExtension)) {
          return (
            // in case of iframe, height is required so that to maintain the ratio.
            <div
              style={{
                width: "100%",
                maxHeight: "700px",
                padding: "20px",
                textAlign: "center",
              }}
            >
              {/* tried embed and object, but the browser-supported pdf toolbar (the one with zoom, download etc options)'s postition was wrong. */}
              <iframe
                title={url}
                src={url}
                style={{
                  width: "100%",
                  height: "700px",
                  padding: "20px",
                  textAlign: "center",
                }}
              ></iframe>
            </div>
          );
        } else {
          return "";
        }
      });
    }
  };

  render() {
    const multipleImages = this.props.postAttachments.length > 1;
    // const slidePercentage = multipleImages?50:100;
    const slidePercentage = 100;
    return (
      <div
        className="syles_expanded_paper_list"
        style={{ height: "100%", width: "100%" }}
      >
        <div
          id="modal-expanded_attachments-heading"
          className="styles_expanded_modal_heading"
        >
          <IconButton
            onClick={this.HandleExpandedAttachmentsModalClose}
            style={{ float: "right", color: "white" }}
            size="large"
          >
            <CancelIcon color="white" />
          </IconButton>
        </div>
        <div id="modal-expanded_attachments-body" style={{ padding: "10px" }}>
          <div
            className="carousel-wrapper"
            style={{ width: "100%", textAlign: "center" }}
          >
            <Carousel
              fullHeightHover={false}
              className="expandedCarousel"
              infiniteLoop={multipleImages}
              showIndicators={multipleImages}
              useKeyboardArrows
              centerMode
              centerSlidePercentage={slidePercentage}
              showThumbs={false}
            >
              {this.renderAttachmentExpanded()}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}
