import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import userSlice from './features/user/userSlice';
import notificationsSlice from './features/notifications/notificationsSlice';
import snackbarSlice from './features/snackbar/snackbarSlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    notifications: notificationsSlice,
    snackbar: snackbarSlice
  },
  middleware: [thunkMiddleware]
});

export default store;
