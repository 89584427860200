import React, { Component } from "react";
import { API_HOST } from "../../../config/main.json";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Autocomplete,
  OutlinedInput,
  Snackbar,
  Alert,
  FormHelperText,
} from "@mui/material";
import { BadgeTwoTone, BusinessCenterTwoTone } from "@mui/icons-material";
import MyPaper from "../../general/MyPaper";
import axios from "axios";
import utils from "../../../utils/utils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { connect } from "react-redux";

let backdropShow = false;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class AddAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      businessTypeList: [],
      backdropShow: backdropShow,
      attributesList: [],
      primaryList: [],
      secondaryList: [],
      membershipDetails: [{ id: 0, name: "Please select a business to get membership details" }],
      notification: true,
      message: "",
      type: "",
    };
  }

  getPrimaryList() {
    utils.getPcatData().then((data) => {
      const primaryList = data;
      this.setState({ primaryList });
    });
  }

  getSecondaryList() {
    utils.getScatData().then((data) => {
      const secondaryList = data;
      this.setState({ secondaryList });
    });
  }

  loadData = () => {
    this.getBusinessTypes();
    this.getAttributesList();
    this.getPrimaryList();
    this.getSecondaryList();
    this.getAttributesList();
    this.setState({ backdropShow: false });
  };

  getBusinessTypes() {
    utils.getBtData().then((data) => {
      const businessTypeList = data;
      this.setState({ businessTypeList });
    });
  }

  getMembershipDetails(id) {
    utils.getMembershipDetails(id).then((data) => {
      const membershipDetails = data;
      this.setState({ membershipDetails });
    });
  }

  getAttributesList() {
    utils.getAttrData().then((data) => {
      const attributesList = data;
      this.setState({ attributesList });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  postData(data) {
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const { name, businessType, businessMembership, attributes, brandName, primaryCategory, secondaryCategory } = data;
    const { user } = this.props;
    // account creation payload
    const accountPayload = {
      name,
      businessType,
      membershipPlan: businessMembership,
      addedFrom: "oryx",
      addedBy: user.attributes.email,
    };

    //brand addition payload
    const brandPayload = {
      attributes,
      name: brandName,
      primaryCat: primaryCategory,
      secondaryCat: secondaryCategory,
      addedFrom: "oryx",
      addedBy: user.attributes.email,
    };

    this.setState({ backdropShow: true });
    axios.post(`${API_HOST}/account/add`, accountPayload, optionAxios).then((res) => {
      const {
        data: { id, status, newAccount },
      } = res;
      if (id > 0) {
        //after account creation , create brand
        axios.post(`${API_HOST}/brand/add`, { id, ...brandPayload }, optionAxios).then(() => {
          this.setState({
            notification: true,
            message: "Account and Brand created successfully",
            type: "success",
          });
          //redirect to accounts on successful response
          setTimeout(function () {
            window.location = "/accounts";
          }, 3000);
        });
      } else if (!newAccount) {
        this.setState({
          backdropShow: false,
          notification: true,
          message: "Account with similar name already exists",
          type: "error",
        });
      } else {
        this.setState({
          backdropShow: false,
          notification: true,
          message: status,
          type: "error",
        });
      }
    });
  }

  postAccount(e) {
    backdropShow = true;
    this.setState({ backdropShow });
    e.preventDefault();
    var body = this.state.fields;
    this.postData(body);
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    if (field === "businessType") {
      this.getMembershipDetails(e.target.value);
    }
    this.setState({ fields });
  }

  handleChangeAuto(field, e, values) {
    let fields = this.state.fields;
    fields[field] = "";
    if (values !== null) {
      if (values.length > 0) {
        var data = [];
        values.forEach(function (item, index) {
          data.push(item["id"]);
        });
        fields[field] = data.join(",");
      } else if (typeof values["id"] !== "undefined") {
        fields[field] = values["id"];
      }
    }
    this.setState({ fields });
  }

  closeNotification = () => {
    this.setState({ notification: false });
  };

  checkMandatory = () => {
    let isMandatory = false;
    const { attributes, businessType } = this.state.fields;
    if ((attributes === undefined || attributes === "") && businessType !== 2) isMandatory = true;
    if (businessType === 2)
      // Since it's an agency , whole brand attributes section is optional
      isMandatory = false;
    return isMandatory;
  };

  render() {
    return (
      <>
        <Snackbar
          open={this.state.notification}
          autoHideDuration={3000}
          onClose={this.closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={this.closeNotification} severity={this.state.type} variant="filled" sx={{ width: "100%" }}>
            {this.state.message}
          </Alert>
        </Snackbar>
        <form onSubmit={this.postAccount.bind(this)}>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              flexDirection: "column",
            }}
            open={this.state.backdropShow}
          >
            <CircularProgress color="inherit" />
            <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
          </Backdrop>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MyPaper>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                  Account Details
                </Typography>
                <Divider />
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        onChange={this.handleChange.bind(this, "name")}
                        variant="outlined"
                        label="Account Name"
                        required={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">Business Type</InputLabel>
                      <Select
                        onChange={this.handleChange.bind(this, "businessType")}
                        variant="outlined"
                        label="Business Type"
                        required
                      >
                        {this.state.businessTypeList.map((btype) => (
                          <MenuItem key={btype.id} value={btype.id}>
                            {btype.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">Plan Subscription</InputLabel>
                      <Select
                        onChange={this.handleChange.bind(this, "businessMembership")}
                        variant="outlined"
                        label="Plan Subscription"
                        required
                      >
                        {this.state.membershipDetails.map((btype) => (
                          <MenuItem key={btype.id} value={btype.id}>
                            {btype.publicName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>If no plan is selected, it will be considered a legacy account by default</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }}>
                      <BusinessCenterTwoTone sx={{ mr: 2 }} />
                      <Typography variant="h5">
                        Brand Details {this.state.fields.businessType === 2 && <span>(Optional)</span>}
                      </Typography>
                    </Box>
                    <Divider />
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            variant="outlined"
                            required={this.state.fields.businessType === 2 ? false : true}
                            type="text"
                            id="brandName"
                            refs="brandName"
                            name="brandName"
                            label="Brand Name"
                            onChange={this.handleChange.bind(this, "brandName")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            limitTags={1}
                            id="checkboxes-tags-demo"
                            options={this.state.attributesList}
                            onChange={this.handleChangeAuto.bind(this, "attributes")}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                                {option.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="Brand Attributes" required={this.checkMandatory()} />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                        <FormControl fullWidth>
                          <InputLabel id="primary-category-label">
                            Brand Primary Category {this.state.fields.businessType !== 2 ? "*" : ""}
                          </InputLabel>
                          <Select
                            required={this.state.fields.businessType === 2 ? false : true}
                            label={"Brand Primary Category" + this.state.fields.businessType !== 2 ? "*" : ""}
                            id="primaryCategory"
                            onChange={this.handleChange.bind(this, "primaryCategory")}
                            input={
                              <OutlinedInput
                                label={"Brand Primary Category" + this.state.fields.businessType !== 2 ? "*" : ""}
                                id="demo-dialog-native"
                              />
                            }
                          >
                            {this.state.primaryList.map((primaryCategory) => (
                              <MenuItem value={primaryCategory.id} key={primaryCategory.id}>
                                {primaryCategory.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="secondary-category-label">
                            Brand Secondary Category {this.state.fields.businessType !== 2 ? "*" : ""}
                          </InputLabel>
                          <Select
                            required={this.state.fields.businessType === 2 ? false : true}
                            label={"Brand Secondary Category" + this.state.fields.businessType !== 2 ? "*" : ""}
                            id="secondaryCategory"
                            onChange={this.handleChange.bind(this, "secondaryCategory")}
                            input={
                              <OutlinedInput
                                label={"Brand Secondary Category" + this.state.fields.businessType !== 2 ? "*" : ""}
                                id="demo-dialog-native"
                              />
                            }
                          >
                            {this.state.secondaryList.map((secondaryCategory) => (
                              <MenuItem value={secondaryCategory.id} key={secondaryCategory.id}>
                                {secondaryCategory.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Divider />
              </MyPaper>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Box sx={{ mt: 4 }}>
                <Button variant="contained" size="large" type="submit">
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const user = state.user;
  return { user };
};
export default connect(mapStateToProps)(AddAccount);
