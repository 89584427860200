import { Backdrop, CircularProgress, Box, Tab, Tabs, Typography, Tooltip } from "@mui/material";
import { useState } from "react";
import AddInfluencers from "../../components/account/details/AddInfluencers";
import TabPanel from "../../components/general/TabPanel";
import { Link } from "react-router-dom";
import CustomTabs from "../../common/CustomTabs/CustomTabs";

const Invites = () => {
  const [selectedTab] = useState(1);

  const tabArray = [
    {
      label: "Invite Influencers",
      id: 0,
      to: "/influencers",
      component: Link,
      tooltip: true,
      tooltipTitle:
        "Extend invitations to influencers who have been either enlisted as direct influencers or for whom you possess email contacts.",
    },
    {
      label: "Add Direct Influencers",
      id: 1,
      to: "/influencers/add",
      component: Link,
      tooltip: true,
      tooltipTitle: "Direct influencers refer to individuals who have not yet integrated their profiles with Gazelle.",
    },
  ];

  return true ? (
    <>
      <Box sx={{ width: "100%", m: 3 }}>
        <CustomTabs tabArray={tabArray} selectedTab={1} />
      </Box>
      <TabPanel index={1} name="account-details-tab" value={1}>
        <AddInfluencers from="mainMenu" />
      </TabPanel>
    </>
  ) : (
    <Backdrop
      open
      sx={{
        position: "absolute",
      }}
    >
      <CircularProgress sx={{ color: "white" }} />
    </Backdrop>
  );
};

export default Invites;
