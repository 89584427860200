import React, { Component } from "react";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Box, Stack, Button, Typography } from "@mui/material";
import utils from "../../utils/utils";
import { Link } from "react-router-dom";
import Sentiment from "../../components/sentimentAnalysis/Sentiment";
import SentimentDoughnut from "../../components/sentimentAnalysis/SentimentDoughnut";

import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";

import CampaignFilters from "./CampaignFilters";
import CommonFilters from "./CommonFilters";
import CampaignActions from "./CampaignActions";

let campId = 0;
let tabArray;
let backdropShow = true;
let disableCustomDates = true;
const styles = {
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  typography: {
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    color: "#33CC99",
    borderBottom: "2px solid #33CC99",
    marginBottom: "20px",
  },
  noData: {
    position: "relative",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontSize: "large",
    fontStyle: "italic",
  },
  centerElement: {
    position: "relative",
    left: "50%",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
  fetchingDetails: {
    marginTop: "20px",
    paddingLeft: "50px",
  },
};
let hideOnExport = ["save-sentiment-analysis"];
let dataLabel = [];
let doughnutLabel = [];

class SentimentAnalysis extends Component {
  constructor(props) {
    super(props);
    this.datePickerRef = React.createRef(Date.now());
    const search = window.location.search;
    const params = new URLSearchParams(search);
    campId = params.get("id");
    tabArray = campaignTabsArray(campId);
    this.state = {
      fields: {},
      filters: {
        startDate: moment().subtract(6, "month").startOf("month").toDate(),
        endDate: moment().toDate(),
      },
      isLoadedCampaignDetails: true,
      platforms: [],
      campId: campId,
      duration: "tMonth",
      startDate: moment().subtract(6, "month").startOf("month").toDate(),
      endDate: moment().toDate(),
      phases: "",
      filterBy: "day",
      backdropShow: backdropShow,
      disableCustomDates: disableCustomDates,
      linkToAddPost: "/campaigns/addPost?id=" + campId,
      disableRefreshButton: false,
      loading: true,
      selectedTab: "7",
      hideAdvancedFilters: true,
      data: {},
      doughnutData: {},
      data: {
        labels: dataLabel,
        datasets: [],
      },
      doughnutData: {
        labels: doughnutLabel,
        datasets: [],
      },
    };
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleCalendarChange = this.handleCalendarChange.bind(this);
    this.downloadCampaignData = this.downloadCampaignData.bind(this);
  }

  componentDidMount() {
    this.getCampaignDetails(campId);
    this.getSentimentDataset(campId, this.state.startDate, this.state.endDate);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  getSentimentDataset(campId, sd, ed) {
    utils.getSentimentAnalysisStats(campId, sd, ed).then((data) => {
      if (data) {
        let allLabels = [];
        let allKeys = [];

        let keys = Object.keys(data);
        keys.forEach((key) => {
          if (!allKeys.includes(key)) allKeys.push(key);
        });
        var positiveSet = [];
        var negativeSet = [];
        var neutralSet = [];
        allKeys.forEach((key) => {
          const date_array = new Date(key).toDateString().split(" ");
          const date = `${date_array[2]} ${date_array[1]}`;
          if (!allLabels.includes(date)) {
            allLabels.push(date);
          }
          if (key in data) {
            positiveSet.push(data[key]?.positive);
            negativeSet.push(data[key]?.negative);
            neutralSet.push(data[key]?.neutral);
          }
        });

        this.setState({
          data: {
            labels: allLabels,
            datasets: [
              {
                label: "Postitive",
                data: positiveSet,
                borderColor: "rgb(88,177,50)",
                backgroundColor: "rgba(88,177,50, 0.5)",
              },
              {
                label: "Negative",
                data: negativeSet,
                borderColor: "rgb(255,33,0)",
                backgroundColor: "rgba(255,33,0, 0.5)",
              },
              {
                label: "Neutral",
                data: neutralSet,
                borderColor: "rgb(255,191,0)",
                backgroundColor: "rgba(255,191,0, 0.5)",
              },
            ],
          },
        });
        let postitiveSum = utils.intArraySum(positiveSet);
        let negativeSum = utils.intArraySum(negativeSet);
        let neutralSum = utils.intArraySum(neutralSet);
        this.setState({
          doughnutData: {
            labels: ["Positive", "Negative", "Neutal"],
            datasets: [
              {
                label: "Sentiments",
                data: [postitiveSum, negativeSum, neutralSum],
                backgroundColor: ["rgba(88,177,50, 0.5)", "rgba(255,33,0, 0.5)", "rgba(255,191,0, 0.5)"],
                borderColor: ["rgb(88,177,50)", "rgb(255,33,0)", "rgb(255,191,0)"],
                borderWidth: 0,
              },
            ],
          },
        });
      }
      this.setState({
        loading: false,
      });
    });
  }

  getCampaignDetails(campId) {
    utils.getCampaignData(campId).then((data) => {
      const fields = data;
      this.setState({
        fields,
        isLoadedCampaignDetails: true,
      });
    });
  }

  applyFilters() {
    this.setState({
      startDate: this.state.filters.startDate,
      endDate: this.state.filters.endDate,
    });
    this.setState({
      loading: true,
    });
    this.getSentimentDataset(campId, this.state.filters.startDate, this.state.filters.endDate);
  }

  handleCalendarChange(start, end) {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      },
    }));
  }

  handleStartDate = (date) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: date,
      },
    }));
  };
  handleEndDate = (date) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        endDate: date,
      },
    }));
  };

  chartOptions = {
    maintainAspectRatio: false,
  };

  hideElementsBeforeCampaignExport() {
    for (let index in hideOnExport) {
      const elementId = hideOnExport[index];
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = "none";
      }
    }
  }

  showElementsAfterCampaignExport() {
    for (let index in hideOnExport) {
      const elementId = hideOnExport[index];
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = "block";
      }
    }
  }

  downloadCampaignData(event) {
    const downloadAs = event.target.value;
    const today = new Date();
    const filename =
      this.state.fields.name + "-" + today.getDate() + (today.getMonth() + 1) + today.getFullYear() + "." + downloadAs;
    let input = document.getElementById("campaign-analysis");

    this.hideElementsBeforeCampaignExport();

    if (downloadAs === "pdf") {
      var w = input.offsetWidth;
      var h = input.offsetHeight;
      html2canvas(input).then((canvas) => {
        var img = canvas.toDataURL("image/jpeg", 1);
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        var doc = new jsPDF("p", "px", [w, h]);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
        doc.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
        doc.save(filename);
      });
    } else if (downloadAs === "jpeg") {
      html2canvas(input).then((canvas) => {
        var a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = filename;
        a.click();
      });
    } else if (downloadAs === "png") {
      html2canvas(input).then((canvas) => {
        var a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = filename;
        a.click();
      });
    }

    this.showElementsAfterCampaignExport();
  }

  render() {
    if (!this.state.isLoadedCampaignDetails) {
      return null;
    }
    return (
      <>
        <CampaignActions name={this.state.fields.name} id={campId} />
        <br />
        <CustomTabs tabArray={tabArray} selectedTab={"6"} />
        <br />
        <div id="campaign-analysis">
          <>
            <CampaignFilters
              styles={{ float: "right" }}
              name={this.state.fields.name}
              id={this.state.campId}
              selectedPlatform={-1}
              selectedPlatformName={"all"}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              filterBy={this.state.filters.filterBy}
              platforms={[]}
              disabledFilterByWeek={this.state.disabledFilterByWeek}
              disabledFilterByMonth={this.state.disabledFilterByMonth}
              hideAdvancedFilters={true}
              selectedListIds={[]}
              selectedInfluencersIds={[]}
              disableRefreshButton={this.state.disableRefreshButton}
              isAllListSelected={false}
              optionsLists={[]}
              isAllInfluencerSelected={true}
              optionsInfluencers={[]}
              handleCalendarChange={this.handleCalendarChange}
              datePickerRef={this.datePickerRef}
              applyFilters={this.applyFilters}
              setState={this.setState}
              showHideAdvancedFilters={false}
              hideFilterBy={true}
              hidePlatform={true}
              alignWidth={"75%"}
              handleDownloadData={this.downloadCampaignData}
              currentStatus={this.state.fields.current_status}
              disableExport={true}
            />
            <Sentiment
              campId={this.state.campId}
              parentStyle={styles}
              startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
              endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
              data={this.state.data}
              chartOptions={this.chartOptions}
              loading={this.state.loading}
            />
            {this.state.doughnutData && this.state.doughnutData["datasets"] && (
              <SentimentDoughnut
                campId={this.state.campId}
                parentStyle={styles}
                startDate={moment(this.state.startDate).format("YYYY-MM-DD")}
                endDate={moment(this.state.endDate).format("YYYY-MM-DD")}
                data={this.state.doughnutData}
                chartOptions={this.chartOptions}
                loading={this.state.loading}
              />
            )}
            ;
          </>
        </div>
      </>
    );
  }
}

export default SentimentAnalysis;
