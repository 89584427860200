import {
  Backdrop, CircularProgress,
  Box, Tab, Tabs, Typography
} from '@mui/material';
import { useState } from 'react';
import AddAccount from '../../components/account/details/AddAccount';
import TabPanel from '../../components/general/TabPanel';
import Stack from "@mui/material/Stack";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';


const NewAccount = () => {
  const [selectedTab] = useState(0);
  return true ? (
    <>
      <Typography variant="h3" sx={{ mb: 2, mt: 2 }}>Account</Typography>
      <Box sx={{ width: '100%', mb: 3 }}>
        <Tabs value={selectedTab} style={{width:"200px",float:"left"}}  variant="scrollable" scrollButtons="auto">
          <Tab label="Account Details" id="account-details-tab-0" />
        </Tabs>
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" component={Link} to="../accounts/">All Accounts</Button>
        </Stack>
      </Box>
      <TabPanel index={0} name="account-details-tab" value={selectedTab}>
        <AddAccount />
      </TabPanel>

    </>
  ) : (
    <Backdrop
      open
      sx={{
        position: 'absolute'
      }}
    >
      <CircularProgress sx={{ color: 'white' }} />
    </Backdrop>
  );
};

export default NewAccount;
