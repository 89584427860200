import {
  Grid, Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import MyPaper from '../../components/general/MyPaper';

const Dashboard = () => {
  const user = useSelector((state) => state.user.attributes);
  return (
    <Grid container justifyContent="center">
      <Grid item sx={{ mt: 4 }}>
        <MyPaper>
          <Typography variant="h4">Welcome to AIFluence</Typography>
          <Typography sx={{ mt: 2 }} textAlign="center" color="text.secondary">
            {user.name}
          </Typography>
        </MyPaper>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
