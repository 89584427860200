import axios from "axios";
import { API_HOST } from "../../config/main.json";

// fetch user profile data
async function getProfileData(data) {
  try {
    const response = await axios.post(`${API_HOST}/discovery/profile/analyze`, data);
    if (response.status === 200) {
      return (response.data.data);
    }
  } catch (error) {
    console.error("Error fetching user profile's data - ", error);
  }

  return null;

}

export { getProfileData };