import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { bool, func, object, string } from "prop-types";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import { useEffect, useRef, useState } from "react";
import { Download, Downloading, LocationOn, NotificationAdd } from "@mui/icons-material";
import {
  downloadContractFromAPI,
  getContractsFromCampaign,
  remindContract,
} from "../../services/influencer/InfluencerContractDetails";
import FileSaver from "file-saver";
import utils from "../../utils/utils";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const ViewContractDialog = ({ influencerAccount, viewContract, closeViewContractDialog, platform, cid }) => {
  const [scroll, setScroll] = useState("paper");
  const [contractList, setContractList] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedContract, setSelectedContract] = useState("");
  const descriptionElementRef = useRef(null);
  const dispatch = useDispatch();

  const handleClose = () => {
    closeViewContractDialog(false);
  };

  useEffect(() => {
    if (viewContract) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      retrieveContractInformation();
    }
  }, [viewContract]);
  const retrieveContractInformation = () => {
    setLoadingData(true);
    getContractsFromCampaign(influencerAccount.id, cid).then((contracts) => {
      const contractList = [];
      const { influencerPendingContracts, influencerAcceptedContracts } = contracts;
      setLoadingData(false);
      if (influencerPendingContracts) contractList.push(...influencerPendingContracts);
      if (influencerAcceptedContracts) contractList.push(...influencerAcceptedContracts);
      setContractList(contractList);
    });
  };

  const getDateString = (contract) => {
    if (contract.acceptedOn) {
      const acceptedOn = new Date(contract.acceptedOn).toDateString();

      const dateString = `Accepted on ${acceptedOn}`;
      return dateString;
    } else if (contract.expiryDate) {
      const today = new Date();
      const lastDay = new Date(contract.expiryDate);

      const daysLeft = Math.round((lastDay - today) / (1000 * 60 * 60 * 24));

      const dateString = `Expires in ${daysLeft} days`;
      return dateString;
    }
  };

  const downloadContract = async (contractId) => {
    setLoadingDownload(true);
    setSelectedContract(contractId);
    const data = await downloadContractFromAPI(contractId);
    await FileSaver(data, `${contractId}.pdf`);
    setLoadingDownload(false);
  };

  const sendReminder = async (contractId) => {
    const data = await remindContract(contractId);
    if (data.status === "success") {
      dispatch(
        openSnackbar({
          message: `A reminder has been sent to ${influencerAccount.name} to accept invitation`,
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: `Some error occured while reminding ${influencerAccount.name}. Contact Support.`,
          severity: "error",
        })
      );
    }
  };
  return (
    <Dialog
      open={viewContract}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <CardMedia
              component="img"
              image={influencerAccount.photoUrl}
              alt={influencerAccount.name}
              onError={utils.addDefaultPostThumbnailImage}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={styles.profileHeading}>
                {influencerAccount.name}
              </Grid>
              <Grid item xs={12} className={styles.location}>
                <LocationOn /> {influencerAccount.location != 0 ? influencerAccount.location : "-"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Typography sx={textColor[platform?.toLowerCase()]}>{snapshotHeaderIcons[platform?.toLowerCase()]}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <Grid container spacing={1}>
            {loadingData ? (
              <>
                <CircularProgress color="success" className={styles.loaderElement} />
              </>
            ) : (
              <>
                {contractList.length > 0 &&
                  contractList.map((contract) => {
                    return (
                      <Grid item xs={6}>
                        <Card>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              {contract.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {contract.name}
                            </Typography>
                            {contract.acceptedOn && (
                              <Typography variant="body1" color="text.secondary">
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sx={{ fontSize: "0.8em" }}>
                                    <Typography variant="span">{getDateString(contract)}</Typography>
                                  </Grid>
                                </Grid>
                              </Typography>
                            )}
                            {contract.expiryDate && (
                              <Typography variant="body1" color="text.secondary">
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sx={{ fontSize: "0.8em" }}>
                                    <Typography variant="span">{getDateString(contract)}</Typography>
                                  </Grid>
                                </Grid>
                              </Typography>
                            )}
                          </CardContent>
                          <CardActions>
                            {contract.acceptedOn && (
                              <Button
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                variant="outlined"
                                startIcon={
                                  loadingDownload && selectedContract === contract.contractId ? <Downloading /> : <Download />
                                }
                                sx={{
                                  ml: 15,
                                }}
                                onClick={() => downloadContract(contract.contractId)}
                              >
                                {loadingDownload && selectedContract === contract.contractId ? "Loading" : "Download"}
                              </Button>
                            )}
                            {contract.expiryDate && (
                              <Button
                                variant="outlined"
                                startIcon={<NotificationAdd />}
                                sx={{
                                  ml: 11,
                                }}
                                onClick={() => sendReminder(contract.contractId)}
                              >
                                Remind again
                              </Button>
                            )}
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
                {contractList.length === 0 && (
                  <Typography gutterBottom variant="h5" component="div">
                    The influencer has no contracts at the moment.
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ViewContractDialog.propTypes = {
  influencerAccount: object.isRequired,
  viewContract: bool.isRequired,
  closeViewContractDialog: func.isRequired,
  platform: string.isRequired,
};

export default ViewContractDialog;
