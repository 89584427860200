import { CallOutlined, CancelOutlined, MailOutline } from "@material-ui/icons";
import { Backdrop } from "@mui/material";
import { Modal } from "@mui/material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { getInfluencerContactDetails } from "../../../services/influencer/Influencer";
import { Skeleton } from "@mui/material";
import { snapshotHeaderIcons, textColor } from "../snapshot";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/features/snackbar/snackbarSlice";
import { ContentCopy } from "@mui/icons-material";
import { Grid } from "@mui/material";

const ContactCard = ({ platform, influencerAccount, viewContactDialog, closeContactDialog }) => {
  const [contactData, setContactData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setIsLoading(true);
    getContactDetails();
  }, [influencerAccount]);

  const getContactDetails = async () => {
    const influencer = await getInfluencerContactDetails(influencerAccount.id);
    setIsLoading(false);
    setContactData(influencer);
  };
  const handleModalClose = () => {
    closeContactDialog(false);
  };

  async function copyTextToClipboard(text) {
    if (typeof text === 'object' && text.phone_number) {
      text = text.phone_number; // Change the text to just the phone number
  }
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      //keeping this in case of old browsers or no clipboard support
      return document.execCommand("copy", true, text);
    }
  }

  const renderContentHandler = () => {
    let content = null;
    if (isLoading) {
      content = (
        <>
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
          <Skeleton variant="rectangular" height={30} sx={{ mb: "1rem", borderRadius: "6px" }} />
        </>
      );
    } else if (
      (!isLoading && contactData.Email?.length > 0 && contactData.Email[0] !== "") ||
      (contactData.Phone?.length > 0 && contactData.Phone[0] !== "") ||
      (contactData.DirectPhones?.length > 0 && contactData.DirectPhones[0] !== "")
    ) {
      content = (
        <>
          {contactData && (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    key={`contact-link=${influencerAccount.iid}-email`}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb={"1rem"}
                  >
                    {contactData.Email?.length > 0 &&
                      contactData.Email[0] !== "" &&
                      contactData.Email.map((email, idx) => {
                        return (
                          <>
                            <Box mr={"1rem"}>
                              <MailOutline sx={{ width: "40px", height: "40px", color: "#ffffff" }} />
                            </Box>
                            <Box
                              key={`contact-email=${idx}`}
                              component={"a"}
                              fontSize={"20px"}
                              sx={{
                                textDecoration: "underline",
                                maxWidth: "350px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              href={`mailto:${email}`}
                              target="_"
                              color="#6B747A"
                            >
                              {email}
                            </Box>
                            <Box
                              sx={{ cursor: "pointer", ml: "1rem" }}
                              onClick={() =>
                                copyTextToClipboard(email).then(() =>
                                  dispatch(openSnackbar({ message: "Copied to clipboard", severity: "success" }))
                                )
                              }
                            >
                              <ContentCopy />
                            </Box>
                          </>
                        );
                      })}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    key={`contact-link=${influencerAccount.iid}-phone`}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb={"1rem"}
                  >
                    {contactData.Type === "registered" &&
                      contactData.Phone?.length > 0 &&
                      contactData.Phone[0] !== "" &&
                      contactData.Phone.map((phone, idx) => {
                        return (
                          <>
                            <Box mr={"1rem"}>
                              <CallOutlined sx={{ width: "40px", height: "40px", color: "#ffffff" }} />
                            </Box>
                            <Box
                              key={`contact-email=${idx}`}
                              component={"a"}
                              fontSize={"20px"}
                              sx={{
                                textDecoration: "underline",
                                maxWidth: "350px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              target="_"
                              color="#6B747A"
                            >
                              + {phone.dial_code} {phone.phone_number}
                            </Box>
                            <Box
                              sx={{ cursor: "pointer", ml: "1rem" }}
                              onClick={() =>
                                copyTextToClipboard(phone).then(() =>
                                  dispatch(openSnackbar({ message: "Copied to clipboard", severity: "success" }))
                                )
                              }
                            >
                              <ContentCopy />
                            </Box>
                          </>
                        );
                      })}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </>
      );
    } else {
      content = (
        <Typography fontSize={"20px"} mb={"1rem"} textAlign={"center"}>
          No contacts information available.
        </Typography>
      );
    }
    return content;
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={viewContactDialog}
      onClose={handleModalClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box
        bgcolor={"#EAEAEA"}
        border="4px solid #6B747A"
        borderRadius={"32px"}
        p={"3rem"}
        maxWidth={"740px"}
        position={"relative"}
      >
        <Box position={"absolute"} top="4%" right="4%" display={"flex"} alignItems={"center"}>
          {/* <Box mr={"1rem"}><CloudDownloadOutlinedIcon sx={{ cursor: "pointer" }} /></Box> */}
          <Box component={"div"} onClick={handleModalClose}>
            <CancelOutlined sx={{ cursor: "pointer" }} />
          </Box>
        </Box>
        <Box mx="auto" textAlign={"center"} mb={"1rem"}>
          <Box
            component={"img"}
            src={influencerAccount.photoUrl}
            sx={{ width: "90px", height: "90px", borderRadius: "32px", border: "4px solid #687076" }}
          />
          <Typography mt="12px" fontSize={"30px"} color="#687076">
            {influencerAccount.displayName}
          </Typography>
        </Box>
        {renderContentHandler()}
      </Box>
    </Modal>
  );
};

export default ContactCard;
