import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API_HOST } from "../../config/main.json";
import Datatables from "mui-datatables";
import { Download } from "@mui/icons-material";
import FileSaver from "file-saver";
import { Close, CheckCircle, Upload } from "@mui/icons-material";
import moment from "moment";
import CustomTabs from "../../common/CustomTabs/CustomTabs";

function Sentiments() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [backdropShow, setBackdropShow] = useState(true);
  const [data, setData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [jobModal, setJobModal] = useState(false);
  const [fields, setFields] = useState({});
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [inputType, setInputType] = useState("");

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = () => {
    axios.get(API_HOST + "/sentiments/list").then((res) => {
      const data = res.data;
      setData(data);
      setIsLoaded(true);
      setBackdropShow(false);
    });
  };

  const getCampaignData = async () => {
    const response = await axios({
      url: `${API_HOST}/campaign/list/type/1`,
      method: "GET",
    });
    
    setCampaignData(response.data);
  };

  const downloadInput = (link) => {
    const body = {
      filename: link,
      type: "inputFile",
    };
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      responseType: "blob",
    };
    const str = link.split("/");
    const filename = str[str.length - 1];
    axios
      .post(API_HOST + "/sentiments/file/download", body, optionAxios)
      .then((res) => {
        FileSaver(res.data, filename);
      });
  };

  const downloadReport = (value) => {
    const file =
      value.executionId !== "" ? value.executionId + ".csv" : value.fileLink;
    const type = value.executionId !== "" ? "executionId" : "outputFile";
    const body = {
      filename: type === "outputFile" ? file + ".out" : file,
      type: type,
    };
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      responseType: "blob",
    };
    const str = file.split("/");
    const filename = str[str.length - 1];
    axios
      .post(API_HOST + "/sentiments/file/download", body, optionAxios)
      .then((res) => {
        FileSaver(res.data, filename);
      });
  };

  const openJobModal = () => {
    if (campaignData.length === 0) {
      getCampaignData();
    }

    setJobModal(true);
  };

  const closeJobModal = () => {
    setJobModal(false);
  };

  const handleChange = (field, value) => {
    const newFields = { ...fields, [field]: value };
    setFields(newFields);
  };

  const handleUpload = (event) => {
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];
      setFile(file || "");
      setFileName(file.name || "");
    } else {
      setFile(null);
      setFileName("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setJobModal(false);
    setIsLoaded(false);
    setBackdropShow(true);

    const title = fields.name.replace(/ /g, "_");
    const email = fields.email;
    const campaign = fields.campaign || "";
    let finalFile = "";
    let finalFileName = "";

    if (inputType === "paste") {
      const data = fields.csv;
      const arr = data
        .split("\n")
        .map((val) => '"' + val + '"')
        .join("\n");
      const blob = new Blob([arr], { type: "text/csv" });
      finalFileName = title + ".csv";
      finalFile = new File([blob], finalFileName, { type: "text/csv" });
    } else if (inputType === "upload") {
      if (file.current && file.current.size > 5000000) {
        alert(`Please pick a file smaller than ${5000000 / 1000000} MB.`);
        return;
      }
      finalFile = file;
      const name = file.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "");
      finalFileName = title + "_" + name;
    }

    const uuid =
      Math.random().toString(36).substring(2) + moment.now().toString(36);

    const formData = new FormData();
    formData.append("file", finalFile);
    formData.append("uuid", uuid);
    formData.append("fileName", finalFileName);
    formData.append("email", email);
    formData.append("title", title);
    formData.append("campaign", campaign);

    axios.post(API_HOST + "/sentiments/start", formData);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  let tableData = [];
  tableData = data.map((row) => {
    const rowData = { ...row };
    rowData["actions"] = {
      fileLink: row.outputFile,
      executionId: row.executionId,
      status: row.jobStatus,
    };

    rowData["name"] = {
      title: row.title,
      input: row.inputFile,
    };

    rowData["createdDate"] = moment(row.createdAt, "YYYY-MM-DD h:mm:ss").format(
      "ddd, D MMM YYYY H:mm:ss [GMT]"
    );
    rowData["updatedDate"] = moment(row.updatedAt, "YYYY-MM-DD h:mm:ss").format(
      "ddd, D MMM YYYY H:mm:ss [GMT]"
    );

    rowData["cname"] = row.campaignName !== "" ? row.campaignName : "NA";

    return rowData;
  });

  const columns = [
    {
      label: "Name",
      name: "title",
      options: {
        display: false,
        viewColumns: false,
      }
    },
    {
      label: "Name",
      name: "name",
      options: {
        customBodyRender: (value, tableMeta, updatedValue) => {
          return (
            <a
              href="javascript:void(0);"
              onClick={() => downloadInput(value.input)}
            >
              {value.title}
            </a>
          );
        },
        filter: false,
        download: false,
      },
    },
    {
      label: "Email",
      name: "email",
    },
    {
      label: "Campaign Name",
      name: "cname",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "jobStatus",
    },
    {
      label: "Created At",
      name: "createdDate",
      options: {
        filter: false,
      }
    },
    {
      label: "Updated At",
      name: "updatedDate",
      options: {
        filter: false,
      }
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updatedValue) => {
          return value.fileLink !== "" && value.status !== "Failed" ? (
            <div>
              <IconButton onClick={(event) => downloadReport(value)}>
                <Tooltip title={"Download"}>
                  <Download fontSize="small" />
                </Tooltip>
              </IconButton>
            </div>
          ) : (
            <div></div>
          );
        },
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
      },
    },
  ];

  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
  };

  const tabArray = [
    {
      label: "Previous Jobs",
      id: "prev-jobs-tab-0",
      to: "/tools/sentiments",
      component: Link,
    },
  ]

  return isLoaded ? (
    <>
      <Box m={3}>
        <CustomTabs tabArray={tabArray} selectedTab={"0"} />
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" onClick={openJobModal}>
            + New Job
          </Button>
        </Stack>
      </Box>
      <Datatables data={tableData} columns={columns} options={options} />
      <Modal
        className="styles_modal"
        aria-labelledby="modal-new_job-label"
        aria-describedby="modal-new_job-description"
        open={jobModal}
        onClose={closeJobModal}
        closeAfterTransition
      >
        <Fade in={jobModal}>
          <div
            className="syles_paper_list"
            style={{ height: "auto", width: "50%" }}
          >
            <div id="modal-new_job-heading" className="styles_modal_heading">
              <h2 id="modal-new_job-heading-text" style={{ padding: "10px" }}>
                New Job
              </h2>
              <IconButton onClick={closeJobModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div
              id="modal-new_job-body"
              style={{ padding: "10px" }}
            >
              <Grid item xs={12}>
                <Box>
                  <form id="newJobForm" onSubmit={handleSubmit}>
                    <div>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{ display: "flex", maxWidth: "100%" }}
                      >
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <TextField
                            onChange={(event) =>
                              handleChange("name", event.target.value)
                            }
                            variant="outlined"
                            label="Analysis Name"
                            required={true}
                          />
                        </FormControl>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <TextField
                            onChange={(event) =>
                              handleChange("email", event.target.value)
                            }
                            variant="outlined"
                            label="Email"
                            required={true}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{ display: "flex", maxWidth: "100%" }}
                      >
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Campaign
                          </InputLabel>
                          <Select
                            onChange={(event) =>
                              handleChange("campaign", event.target.value)
                            }
                            variant="outlined"
                            label="Campaign"
                          >
                            {campaignData
                              .filter(
                                (campaign) => campaign.current_status !== 2
                              )
                              .map((campaign) => (
                                <MenuItem value={campaign.id} key={campaign.id}>
                                  {campaign.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <FormControl
                          style={{
                            width: "220px",
                            marginBottom: "15px",
                            flex: 1,
                            marginRight: "20px",
                          }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Input Type *
                          </InputLabel>
                          <Select
                            onChange={(event) =>
                              setInputType(event.target.value)
                            }
                            variant="outlined"
                            label="Category"
                            required
                          >
                            <MenuItem value="paste">Paste</MenuItem>
                            <MenuItem value="upload">Upload</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {inputType === "upload" && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ display: "flex", maxWidth: "100%" }}
                          >
                            <Button
                              variant="outlined"
                              component="label"
                              size="large"
                              label="Upload"
                            >
                              <Upload fontSize="inherit" />
                              Upload
                              <input
                                style={{
                                  width: "1px",
                                  border: "1px solid white",
                                  maxHeight: "1px",
                                }}
                                accept=".csv"
                                type="file"
                                onChange={(event) => handleUpload(event)}
                                required
                              />
                              {fileName && (
                                <>
                                  <span>&nbsp;&nbsp;</span>
                                  <Tooltip title={fileName}>
                                    <CheckCircle
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </Button>
                          </Grid>
                        </>
                      )}
                      {inputType === "paste" && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <TextField
                              onChange={(event) =>
                                handleChange("csv", event.target.value)
                              }
                              variant="outlined"
                              label="Sentiments"
                              placeholder="Paste values here with new lines... please make sure the string contains, (comma) should be enclosed by double quotes"
                              required={true}
                              multiline
                              rows={5}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </div>
                    <button
                      variant="contained"
                      className="btn btn-success float-right"
                      style={{
                        float: "right",
                        marginBottom: "15px",
                        marginRight: "20px",
                      }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
      open={backdropShow}
    >
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
}

export default Sentiments;
