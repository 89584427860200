import { Outlet, useLocation } from "react-router-dom";

const MainLayout = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/" && !location.pathname.startsWith("/accountss")}
      <Outlet />
    </>
  );
};

export default MainLayout;
