import { Box, Button, Grid, Tab, Tabs, Typography, IconButton, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import utils from "../../utils/utils";
import slides from "../../common/googleSlides.png";
import { Link } from "react-router-dom";
import GeneratePptReport from "../../components/generatePpt/GeneratePptReport";
import {
  exportFirebaseXLSX,
  getFirebaseConversions,
  getFirebaseLinkAnalysis,
  getFirebaseShortUrls,
  getFirebaseSummary,
} from "../../services/campaignAnalysis/AppLinks";
import moment from "moment";
import AppLinksFilters from "../../components/AppLinks/AppLinksFilter";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import CustomTable from "../../common/CustomTable/CustomTable";
import ClicksAndConversions from "../../components/AppLinks/ClicksAndConversions";
import enums from "../../components/linkAnalysis/enums";
import helper from "../../components/linkAnalysis/helper";
import TotalEngagement from "../../components/AppLinks/TotalEngagement";
import { snapshotHeaderIcons, textColor } from "../../components/campaignAnalysis/snapshot";
import { Analytics, ChevronRightOutlined, KeyboardArrowDown, ManageAccounts, OpenInNew } from "@mui/icons-material";
import AppInstalls from "../../components/AppLinks/AppInstalls";
import CampaignActions from "./CampaignActions";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";
import { getSingleBaseAppLinktableHeaders, getMultipleBaseAppLinktableHeader } from "../../utils/tableHeaders";

const AppLinks = () => {
  const [cid, setCid] = useState(0);
  const [campaignName, setCampaignName] = useState("");
  const [shortUrls, setShortUrls] = useState([]);
  const [selectedShortUrls, setSelectedShortUrls] = useState([]);
  const [dataFound, setDataFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(6, "months").format("YYYY-MM-DD"));
  const [selectedEndDate, setSelectedEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [linkClicksY, setLinkClicksY] = useState([]);
  const [linkClicksX, setLinkClicksX] = useState([]);
  const [conversionsData, setConversionsData] = useState([]);
  const [clickGraph, setClickGraph] = useState("daily");
  const [appInstallX, setAppInstallX] = useState([]);
  const [appInstallY, setAppInstallY] = useState([]);
  const [campaignStart, setCampaignStart] = useState("");
  const [campaignEnd, setCampaignEnd] = useState("");
  const [linkAnalysisData, setLinkAnalysisData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortCol, setSortCol] = useState("Click");
  const [sortOrder, setSortOrder] = useState("desc");
  const [totalRows, setTotalRows] = useState(0);
  const [tabArray, setTabArray] =  useState([]);

  const [pptReportId, setPptReportId] = useState(0);

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [tableHeaders, setTableHeaders] = useState([]);
  const [linksData, setLinksData] = useState([]);
  const [expand, setExpand] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const expandClick = (row, index) => {
    setSelectedRow(row);
    changeExpansionArray(index);
  };

  const changeExpansionArray = (index) => {
    const newExpandedRows = [...expand];
    newExpandedRows[index] = !newExpandedRows[index];
    setExpand(newExpandedRows);
  };

  const tableHeadersSingleBase = getSingleBaseAppLinktableHeaders;

  const tableHeaderMultipleBase = getMultipleBaseAppLinktableHeader;
  
  const subTableHeader = [
    {
      id: 1,
      label: "Influencer",
      mappedValue: "Influencer",
      field: "influencer",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 2,
      label: "URL",
      mappedValue: "URL",
      field: "url",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 3,
      label: "Clicks",
      mappedValue: "Clicks",
      field: "clicks",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 4,
      label: "Redirect",
      mappedValue: "Redirect",
      field: "redirect",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 5,
      label: "App Installs",
      mappedValue: "App Installs",
      field: "App_install",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 6,
      label: "App First Open",
      mappedValue: "App First Open",
      field: "App_first_open",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
    {
      id: 7,
      label: "App Re Open",
      mappedValue: "App Re Open",
      field: "App_re_open",
      headeralignment: "center",
      bodyalignment: "center",
      show: true,
    },
  ];

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    const tabArr = campaignTabsArray(campId);
    setCid(campId);
    setTabArray(tabArr);
    getCampaignInfo(campId);
  }, []);

  useEffect(() => {
    if (expand.length === 0 || tableData.length === 0) {
      return;
    }
    createFinalData(linksData, page, rows, sortOrder, sortCol);
  }, [expand, linksData]);

  const getCampaignInfo = (id) => {
    utils.getCampaignData(id).then((data) => {
      setCampaignName(data.name);
      setCampaignStart(data.startDate.split("T")[0]);
      setCampaignEnd(data.endDate.split("T")[0]);
      getShortUrls(id);
    });
  };

  const getShortUrls = async (id) => {
    const shortUrls = (await getFirebaseShortUrls(id)) || [];

    if (shortUrls.length > 0) {
      setShortUrls(shortUrls);
      setSelectedShortUrls(shortUrls);
      setDataFound(true);
      createGraphs(shortUrls, selectedStartDate, selectedEndDate);
      getTableData(shortUrls, selectedStartDate, selectedEndDate);
    }
  };

  const createGraphs = async (shortUrls, startDate, endDate) => {
    setLoading(true);
    const ids = shortUrls.map((shortUrl) => shortUrl.id);

    const summary = await getFirebaseSummary(ids, startDate, endDate);
    const conversions = await getFirebaseConversions(ids, startDate, endDate);

    if (summary) {
      createClickGraph(summary);
      if (conversions != null) {
        createConversionsGraph(conversions);
      }
    }

    setLoading(false);
  };

  const createClickGraph = (clickSummary) => {
    var dataArray = [];
    const acceptedValues = clickSummary[clickGraph];
    if (acceptedValues != null) {
      const keys = acceptedValues.map((value) => value.day);
      const clicksValue = acceptedValues.map((value) => value.click);
      const redirectsValue = acceptedValues.map((value) => value.redirect);
      const installsValue = acceptedValues.map((value) => value.install);
      const opensValue = acceptedValues.map((value) => value.open);
      const reopensValue = acceptedValues.map((value) => value.reopen);
      var clicks = {
        label: "Clicks",
        type: "bar",
        maxBarThickness: 20,
        data: [],
        backgroundColor: "#5368F0",
        stack: "Stack 0",
      };
      var redirect = {
        label: "Redirect",
        type: "bar",
        maxBarThickness: 20,
        data: [],
        backgroundColor: "#9D57D5",
        stack: "Stack 0",
      };
      var installs = {
        label: "Installs",
        type: "bar",
        maxBarThickness: 20,
        data: [],
        backgroundColor: "#FEAB00",
        stack: "Stack 0",
      };
      var opens = {
        label: "Open",
        type: "bar",
        maxBarThickness: 20,
        data: [],
        backgroundColor: "#E91F63",
        stack: "Stack 0",
      };
      var reopens = {
        label: "Reopen",
        type: "bar",
        maxBarThickness: 20,
        data: [],
        backgroundColor: "#92E48E",
        stack: "Stack 0",
      };

      if (Array.isArray(keys) && keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          const clickValue = clicksValue[i];
          const redirectValue = redirectsValue[i];
          const installValue = installsValue[i];
          const openValue = opensValue[i];
          const reopenValue = reopensValue[i];

          clicks.data.push(clickValue);
          redirect.data.push(redirectValue);
          installs.data.push(installValue);
          opens.data.push(openValue);
          reopens.data.push(reopenValue);
        }
      } else {
        console.log("values array is empty or invalid.");
      }
      dataArray.push(clicks);
      dataArray.push(redirect);
      dataArray.push(installs);
      dataArray.push(opens);
      dataArray.push(reopens);
      setAppInstallX(keys);
      setAppInstallY(installs.data);
      setLinkClicksX(keys);
      setLinkClicksY(dataArray);
    }
  };

  const createConversionsGraph = (conversionSummary) => {
    const keys = Object.keys(conversionSummary[0]);
    const convValuesAreZero = Object.values(conversionSummary[0]).every((value) => value === 0);
    let conversions = [];
    if (!convValuesAreZero) {
      keys.forEach((key) => {
        conversions.push({
          value: conversionSummary[0][key],
          key: key,
        });
      });
    }
    conversions = helper.restructureDataForChart(conversions, "doughnut", "Clicks & Conversions");
    setConversionsData(conversions);
  };

  const getTableData = async (shortUrls, startDate, endDate) => {
    const ids = shortUrls.map((shortUrl) => shortUrl.id);

    const linksData = (await getFirebaseLinkAnalysis(ids, startDate, endDate)) || [];
    const baseDestinationIds = [];
    linksData.forEach((row) => {
      if (!baseDestinationIds.includes(row.baseDestinationId)) {
        baseDestinationIds.push(row.baseDestinationId);
      }
    });
    if (baseDestinationIds.length <= 1) {
      const expand = Array.apply(null, Array(linksData.length)).map(function () {
        return false;
      });
      setExpand(expand);
    } else {
      const expand = Array.apply(null, Array(baseDestinationIds.length)).map(function () {
        return false;
      });
      setExpand(expand);
    }
    setLinksData(linksData);
    createFinalData(linksData, page, rows, sortOrder, sortCol);
    setLinkAnalysisData(linksData);
    setTotalRows(linksData.length);
    //createTable(linksData, page, rows, sortCol, sortOrder);
  };

  const createFinalData = (data, page, rows, sortOrder, sortCol) => {
    const final_data = [],
      baseDestinationIds = [],
      baseDestinations = [];

    data.forEach((row) => {
      if (!baseDestinationIds.includes(row.baseDestinationId)) {
        baseDestinationIds.push(row.baseDestinationId);
        baseDestinations.push(row);
      }
    });

    setTotalRows(baseDestinationIds.length > 1 ? baseDestinationIds.length : data.length);

    if (baseDestinationIds.length < 1) {
      setTableHeaders(tableHeadersSingleBase);
      setTotalRows(data.length);

      data.forEach((row, index) => {
        const isExpanded = expand[index];
        let object = {
          id: row.shortUrlId,
          selectedId: index,
          influencer: (
            <Grid container>
              <Grid item xs={2} style={{ margin: "auto" }}>
                <IconButton onClick={() => expandClick(row, index)}>
                  {isExpanded ? <KeyboardArrowDown /> : <ChevronRightOutlined />}
                </IconButton>
              </Grid>

              <Grid item xs={10} style={{ margin: "auto" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <span>{row.influencerName}</span>
                    {row.profileUrl !== "" && (
                      <a href={row.profileUrl} target="_blank" rel="noreferrer" style={{ marginLeft: "10px" }}>
                        <OpenInNew fontSize="small" />
                      </a>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ),
          group: (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography style={textColor[row.platform?.toLowerCase()]}>
                  {snapshotHeaderIcons[row.platform?.toLowerCase()]}
                </Typography>
              </Grid>
              <Grid item>{row.listName}</Grid>
            </Grid>
          ),
          url: (
            <Grid container spacing={1}>
              <Grid item xs={4}></Grid>
              <Grid item xs={3}>
                <a href={row.destinationUrl} target="_blank" rel="noreferrer">
                  {row.shortUrl}
                </a>
              </Grid>
            </Grid>
          ),
          created: (
            <Grid container spacing={1}>
              <Grid item xs={10}>
                {row.createdAt}
              </Grid>
            </Grid>
          ),
          clicks: (
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                {row.clicks}
              </Grid>
            </Grid>
          ),
          redirect: (
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                {row.redirect}
              </Grid>
            </Grid>
          ),
          sort: row.clicks,
          expand: [
            {
              colSpan: 6,
              content: (
                <>
                  <div>Destination URL:</div>
                  <div>{row.destinationUrl}</div>
                </>
              ),
            },
          ],
        };
        final_data.push(object);
      });
    } else {
      setTableHeaders(tableHeaderMultipleBase);

      baseDestinations.forEach((destination, index) => {
        const rows = data.filter((row) => row.baseDestinationId === destination.baseDestinationId);
        const subRows = [];
        let totalClicks = 0;
        let totalRedirects = 0;
        let totalApp_install = 0;
        let totalApp_first_open = 0;
        let totalApp_re_open = 0;
        rows.forEach((row) => {
          totalClicks += row.clicks;
          totalRedirects += row.Redirect;
          totalApp_install += row.App_install;
          totalApp_first_open += row.App_first_open;
          totalApp_re_open += row.App_re_open;
          const object = {
            influencer: (
              <Grid container>
                <Grid item xs={12}>
                  <span>{row.influencerName}</span>
                  {row.profileUrl !== "" && (
                    <a href={row.profileUrl} target="_blank" rel="noreferrer" style={{ marginLeft: "10px" }}>
                      <OpenInNew fontSize="small" />
                    </a>
                  )}
                </Grid>
              </Grid>
            ),
            url: (
              <Tooltip title={row.destinationUrl}>
                <a href={row.destinationUrl} rel="noreferrer" target="_blank">
                  {row.shortUrl}
                </a>
              </Tooltip>
            ),
            clicks: row.clicks,
            redirect: row.Redirect,
            App_install: row.App_install,
            App_first_open: row.App_first_open,
            App_re_open: row.App_re_open,
          };
          subRows.push(object);
        });

        subRows.sort(function (a, b) {
          if (a.clicks > b.clicks) return -1;
          if (a.clicks < b.clicks) return 1;
          return 0;
        });

        const isExpanded = expand[index];
        const object = {
          id: destination.baseDestinationId,
          selectedId: index,
          destinationUrl: (
            <Grid container>
              <Grid item xs={2} style={{ margin: "auto" }}>
                <IconButton onClick={() => expandClick(destination, index)}>
                  {isExpanded ? <KeyboardArrowDown /> : <ChevronRightOutlined />}
                </IconButton>
              </Grid>

              <Grid item xs={10} style={{ margin: "auto" }}>
                <a href={destination.baseDestinationUrl} rel="noreferrer" target="_blank">
                  {destination.baseDestinationUrl}
                </a>
              </Grid>
            </Grid>
          ),
          group: (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography style={textColor[destination.platform?.toLowerCase()]}>
                  {snapshotHeaderIcons[destination.platform?.toLowerCase()]}
                </Typography>
              </Grid>
              <Grid item>{destination.listName}</Grid>
            </Grid>
          ),
          totalClicks: (
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                {totalClicks}
              </Grid>
            </Grid>
          ),
          totalRedirects: (
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                {totalRedirects}
              </Grid>
            </Grid>
          ),
          totalApp_install: (
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                {totalApp_install}
              </Grid>
            </Grid>
          ),
          totalApp_first_open: (
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                {totalApp_first_open}
              </Grid>
            </Grid>
          ),
          totalApp_re_open: (
            <Grid container spacing={1}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                {totalApp_re_open}
              </Grid>
            </Grid>
          ),
          sort: totalClicks,
          expand: [
            {
              colSpan: 7,
              content: (
                <>
                  <CustomTable
                    tableHeaders={subTableHeader}
                    bodyColumns={subRows}
                    totalRows={subRows.length}
                    pageNo={0}
                    rowsPerPageNo={subRows.length}
                    sortOrder={"desc"}
                    sortOrderBy={"totalClicks"}
                    pagination={false}
                    nested={true}
                  />
                </>
              ),
            },
          ],
        };
        final_data.push(object);
      });
    }

    if (sortOrder === "desc") {
      final_data.sort(function (a, b) {
        if (a.sort > b.sort) return -1;
        if (a.sort < b.sort) return 1;
        return 0;
      });
    } else {
      final_data.sort(function (a, b) {
        if (a.sort < b.sort) return -1;
        if (a.sort > b.sort) return 1;
        return 0;
      });
    }

    setTableData(final_data);
  };

  const createTable = (linkAnalysisData, page, rows, sortCol, sortOrder) => {
    let tableData = linkAnalysisData;
    if (sortOrder === "desc") {
      tableData.sort(function (a, b) {
        if (a[sortCol] > b[sortCol]) return -1;
        if (a[sortCol] < b[sortCol]) return 1;
        return 0;
      });
    } else {
      tableData.sort(function (a, b) {
        if (a[sortCol] < b[sortCol]) return -1;
        if (a[sortCol] > b[sortCol]) return 1;
        return 0;
      });
    }

    tableData = tableData.slice(page * rows, (page + 1) * rows);

    tableData = tableData.map((row) => {
      row.Shortlink = (
        <a href={row.Shortlink} rel="noreferrer" target="_blank">
          {row.Shortlink}
        </a>
      );

      return row;
    });

    setTableData(tableData);
  };

  const sendFilterChange = (event) => {
    setSelectedStartDate(event.startDate);
    setSelectedEndDate(event.endDate);
    createGraphs(event.selectedShortUrls, event.startDate, event.endDate);
    getTableData(event.selectedShortUrls, event.startDate, event.endDate);
  };

  const sendInformation = ({ page, rowsPerPage, order, orderBy }) => {
    setPage(page);
    setRows(rowsPerPage);
    setSortOrder(order);
    setSortCol(orderBy);

    createTable(linkAnalysisData, page, rows, orderBy, order);
  };

  const exportXlsx = async () => {
    const ids = selectedShortUrls.map((shortUrl) => shortUrl.id);

    const response = await exportFirebaseXLSX(ids, selectedStartDate, selectedEndDate, user.attributes.email);

    if (response === "success") {
      dispatch(
        openSnackbar({
          message: "Export Triggered!",
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: "Error!",
          severity: "error",
        })
      );
    }
  };

  const tabArray2 = [
    {
      label: "Metrics",
      id: "Metrics-tab-0",
      to: `/campaigns/Applinks?id=${cid}`,
      component: Link,
      icon: <Analytics />,
      iconPosition: "start"
    },
    {
      label: "Manage app URLs",
      id: "manage-app-tab-0",
      to: `/campaigns/Applinks/manage?id=${cid}`,
      component: Link,
      icon: <ManageAccounts />,
      iconPosition: "start"
    },
  ]

  return (
    <>
      <CampaignActions name={campaignName} id={cid} />
      <br />
      <CustomTabs tabArray={tabArray} selectedTab={"7"} />

      <Box m={3}>
      <CustomTabs tabArray={tabArray2} selectedTab={"0"} />
      </Box>
      {dataFound ? (
        <>
          <AppLinksFilters
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            shortUrls={shortUrls}
            selectedShortUrls={selectedShortUrls}
            setSelectedShortUrls={setSelectedShortUrls}
            sendFilterChange={sendFilterChange}
            campaignStart={campaignStart}
            campaignEnd={campaignEnd}
          />
          {!loading ? (
            <div>
              {totalRows > 0 ? (
                <Grid container>
                  <Grid
                    sm={12}
                    style={{
                      margin: "auto",
                      padding: "20px 12px 12px 12px",
                    }}
                  >
                    <TotalEngagement
                      isToggle={false}
                      linkClicksX={linkClicksX}
                      linkClicksY={linkClicksY}
                      styles={enums.defaultStyles}
                      chartTitle="Total Engagement"
                      chartType="scatter"
                      chartOptions={{
                        legend: { display: true, position: "top" },
                      }}
                    />
                  </Grid>
                  <Grid
                    sm={6}
                    style={{
                      margin: "auto",
                      padding: "20px 12px 12px 12px",
                    }}
                  >
                    <ClicksAndConversions
                      isToggle={false}
                      data={conversionsData}
                      styles={enums.defaultStyles}
                      chartTitle="Clicks & Conversions"
                      chartType="doughnut"
                      chartOptions={{
                        legend: { display: true, position: "right" },
                      }}
                    />
                  </Grid>
                  <Grid
                    sm={6}
                    style={{
                      margin: "auto",
                      padding: "20px 12px 12px 12px",
                    }}
                  >
                    <AppInstalls
                      isToggle={false}
                      appInstallX={appInstallX}
                      appInstallY={appInstallY}
                      styles={enums.defaultStyles}
                      chartTitle="App Installs"
                      chartType="line"
                    />
                  </Grid>
                  <Grid
                    sm={12}
                    style={{
                      margin: "auto",
                      padding: "20px 12px 12px 12px",
                    }}
                  >
                    <Grid container justifyContent="space-between" mb="16px">
                      <Grid item>
                        <Typography variant="h4" sx={enums.defaultStyles}>
                          Link Details
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" onClick={exportXlsx}>
                          Export XLSX
                        </Button>
                      </Grid>
                    </Grid>
                    <CustomTable
                      tableHeaders={tableHeaders}
                      bodyColumns={tableData}
                      totalRows={totalRows}
                      pageNo={page}
                      rowsPerPageNo={rows}
                      expand={expand}
                      selectedRow={selectedRow}
                      sendInformation={sendInformation}
                    />
                  </Grid>
                </Grid>
              ) : (
                <div
                  style={{
                    position: "relative",
                    top: "100px",
                    fontSize: "large",
                    fontStyle: "italic",
                  }}
                >
                  No app links created for this campaign
                </div>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <div
          style={{
            position: "relative",
            top: "100px",
            fontSize: "large",
            fontStyle: "italic",
          }}
        >
          No app links created for this campaign
        </div>
      )}
    </>
  );
};

export default AppLinks;
