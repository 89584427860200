import React from "react";
import { FormControl, Grid } from "@mui/material";
import discoveryUtils from "../../../../utils/discoveryUtils.js";
import CustomChip from "./CustomChip";
import PropTypes from "prop-types";

const CustomSelectedFilter = (props) => {
  const { item, chipClickHandler, range, rangeHandler, isRange } = props;
  return (
    <>
      <Grid container id={item.name}>
        <Grid item xs={12} sm={isRange ? 6 : 12}>
          <CustomChip
            chipLabel={item.name}
            chipId={item.id}
            clickHandler={chipClickHandler}
          />
        </Grid>
        {isRange && (
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ width: "auto" }}>
              {discoveryUtils.getRangesFilter(range, item.id, rangeHandler)}
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};

CustomSelectedFilter.propTypes = {
  item: PropTypes.object.isRequired,
  chipClickHandler: PropTypes.func.isRequired,
  isRange: PropTypes.bool.isRequired,
  range: PropTypes.number,
  rangeHandler: PropTypes.func,
};

export default CustomSelectedFilter;
