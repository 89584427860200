import { useState, useEffect, Fragment } from "react";

import { PropTypes } from "prop-types";

import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Country, State, City } from "country-state-city";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import FAQS from "react-faqs-component";

import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Snackbar,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Fade, Modal, Select, MenuItem } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import utils from "../../utils/utils";
import { DOS, DONTS, TEMPLATE, FAQ } from "../../config/main.json";
import { addInfluencerToNewList } from "../../services/influencer/Discovery";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const AddInfToNewListDialog = ({ addInfToNewListDialog, platform, iidsSelected, modalClosed }) => {
  const [backdropShow, setbackdropShow] = useState(true);
  const [isLoaded, setisLoaded] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [platformId, setPlatformId] = useState("");
  const [newListName, setNewListName] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [timezone, setTimezone] = useState(0);
  const [timezoneLable] = useState(0);
  const [geoType, setGeoType] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [currency, setCurrency] = useState("");
  const [infBudget, setInfBudget] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [tags, setTags] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [anchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [minFollowers, setMinFollowers] = useState(0);
  const [maxFollowers, setMaxFollowers] = useState(0);
  const [displayPT, setDisplayPT] = useState("none");
  const [displayDT, setDisplayDT] = useState("none");
  const [required, setRequired] = useState(false);
  const [noofDays, setNoOfDays] = useState(0);
  const [paymentDate, setPaymentDate] = useState("");
  const [stepMessage, setStepMessage] = useState("Basic Details");
  const [displayGeo, setDisplayGeo] = useState("flex");
  const [countryLable] = useState("");
  const [stateLable] = useState("");
  const [cityLable] = useState("");
  const [convertedContent, setConvertedContent] = useState("");
  const [dos, setDos] = useState(DOS);
  const [donts, setDonts] = useState(DONTS);
  const [faqQuestion, setFaqQuestion] = useState({});
  const [faqAnswer, setFaqAnswer] = useState({});
  const [counter, setCounter] = useState(1);
  const contentBlock = htmlToDraft(TEMPLATE);
  const [editMode] = useState(false);
  const [faqListData] = useState(FAQ);
  const [faqmodalOpen, setFaqmodalOpen] = useState(false);
  const [errorAlert, setErrorAlert] = useState("Please complete all required fields.");
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks))
  );

  useEffect(() => {
    if (!platform) {
      return;
    }
    utils.getCompanyData().then((data) => {
      setCompanyList(data);
    });

    utils.getTimezoneData("all").then((data) => {
      const timezoneData = data;
      setTimezoneList(timezoneData);
    });
    setCountryList(Country.getAllCountries());
    setbackdropShow(false);
    setisLoaded(true);
  }, []);

  const handleListClose = () => {
    setCurrentStep(1);
    document.getElementById("stepForm").reset();
    modalClosed();
  };

  const newListClick = (_event) => {
    addToNewList();
    window.location = window.location.href;
  };

  const handleEditorChange = (updatedState) => {
    setEditorState(updatedState);
    setConvertedContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const addToNewList = () => {
    const payload = {
      name: newListName,
      aid: companyId,
      cid: parseInt(campaignId, 10),
      platform: parseInt(platformId, 10),
      iid: iidsSelected,
      description: description,
      tags: tags.join(" , "),
      notes: notes,
      tz: timezone,
      geoType: geoType,
      country: country,
      state: state,
      city: city,
      currency: currency,
      infBudget: infBudget,
      paymentTerm: paymentTerm,
      minFollowers: parseInt(minFollowers),
      maxFollowers: parseInt(maxFollowers),
      noOfDays: parseInt(noofDays),
      paymentDate: paymentDate,
      postContent: convertedContent,
      dos: dos,
      donts: donts,
      status: 1,
    };
    const faqData = [];
    Object.keys(faqQuestion).map((c) => {
      const obj = {
        question: faqQuestion[c],
        answer: faqAnswer[c],
      };
      faqData.push(obj);
    });
    payload["faqs"] = JSON.stringify(faqData);
    addInfluencerToNewList(payload).then((msg) => {
      dispatch(
        openSnackbar({
          message: "Influencer has been added to the list successfully",
          severity: "success",
        })
      );
      setModalOpen(false);
      handleListClose();
      window.location = "/discovery";
    });
  };

  const handleChangeAccount = (event) => {
    const cid = event.currentTarget.dataset.value;
    setCompanyId(cid);
    utils.getCampaignDataById(cid).then((data) => {
      let campaignListSelected = data;
      if (campaignListSelected == null) {
        const newCampaign = {};
        newCampaign["id"] = 0;
        newCampaign["name"] = "Account-Campaign Mapping Not Available";
        setErrorAlert("There is no campaign associated with this account, Please create campaign first to create a list.");
        campaignListSelected = [];
        campaignListSelected.push(newCampaign);
      } else {
        setErrorAlert("Please complete all required fields.");
      }
      setCampaignList(campaignListSelected);
    });
  };

  const handleChangeCampaign = (event) => {
    const cid = event.currentTarget.dataset.value;
    setCampaignId(cid);
  };

  const handleChangePlatform = (event) => {
    const pid = event.currentTarget.dataset.value;
    setPlatformId(pid);
  };

  const handleInputChange = (e) => {
    setNewListName(e.target.value);
  };

  const handleDescChange = (event) => {
    event.preventDefault();
    setDescription(event.target.value);
  };

  const handleNotesChange = (event) => {
    event.preventDefault();
    setNotes(event.target.value);
  };

  const handleTimezoneChange = (event) => {
    setTimezone(parseInt(event.id));
  };

  const handleCurrencyChange = (event) => {
    event.preventDefault();
    setCurrency(event.target.value);
  };

  const handleInfBudgetChange = (event) => {
    event.preventDefault();
    setInfBudget(event.target.value);
  };

  const handlePaymentChange = (event) => {
    event.preventDefault();
    const paymentTermSelected = event.target.value;
    setDisplayPT("none");
    setDisplayDT("none");
    if (parseInt(paymentTermSelected) === 4) {
      setDisplayDT("none");
      setDisplayPT("flex");
      setRequired(false);
    } else if (parseInt(paymentTermSelected) === 5) {
      setRequired(true);
      setDisplayPT("none");
      setDisplayDT("flex");
    }
    setPaymentTerm(event.target.value);
  };

  const handleCountryChange = (event, _value) => {
    const countryCode = event.isoCode;
    setStateList(State.getStatesOfCountry(countryCode));
    setCountry(countryCode);
    document.getElementById("stateCityDD").style.display = "flex";
    setDisplayGeo("flex");
  };

  const handleStateChange = (event) => {
    const countryCode = event.countryCode;
    const stateCode = event.isoCode;
    setCityList(City.getCitiesOfState(countryCode, stateCode));
    setState(stateCode);
  };

  const handleCityChange = (event) => {
    const cityName = event.name;
    setCity(cityName);
  };

  const handleGeoTypeChange = (event) => {
    event.preventDefault();
    setGeoType(event.target.value);
    if (event.target.value === 1) {
      setCountry("");
      setState("");
      setCityList("");
      document.getElementById("countryDD").style.display = "none";
      document.getElementById("stateCityDD").style.display = "none";
    } else {
      document.getElementById("countryDD").style.display = "flex";
    }
  };

  const handleMinFollowersChange = (event) => {
    event.preventDefault();
    setMinFollowers(event.target.value);
  };

  const handleMaxFollowersChange = (event) => {
    event.preventDefault();
    setMaxFollowers(event.target.value);
  };

  const handleNoOfDaysChange = (event) => {
    event.preventDefault();
    setPaymentDate("");
    setNoOfDays(event.target.value);
  };

  const handlePaymentDateChange = (event) => {
    event.preventDefault();
    setNoOfDays(0);
    setPaymentDate(event.target.value);
  };

  const handleKeyUp = (id, event) => {
    const keycode = event.keyCode ? event.keyCode : event.which;
    if (keycode == "13") {
      document.getElementById(id).value += "• ";
    }
    const txtval = document.getElementById(id).value;
    if (txtval.substr(txtval.length - 1) === "\n") {
      document.getElementById(id).value = txtval.substring(0, txtval.length - 1);
    }
    if (id === "dos") {
      setDos(document.getElementById(id).value);
    } else {
      setDonts(document.getElementById(id).value);
    }
  };

  const validateForm = (step) => {
    // This function deals with validation of the form fields
    let valid = true;
    if (parseInt(step) === 1) {
      if (companyId === "" || campaignId === "" || campaignId == 0 || platformId === "" || newListName === "") {
        dispatch(
          openSnackbar({
            message: errorAlert,
            severity: "error",
          })
        );
        valid = false;
      }
    } else if (parseInt(step) === 2) {
      if (country === "") {
        dispatch(
          openSnackbar({
            message: errorAlert,
            severity: "error",
          })
        );
        valid = false;
      }
    }
    return valid; // return the valid status
  };

  const handleClickFAQ = () => {
    setCounter(counter + 1);
  };

  const handleRemoveClick = (index) => (_e) => {
    let tmpFaqQ = {};
    let tmpFaqQnew = {};
    let tmpFaqAnew = {};
    tmpFaqQ = faqQuestion;
    let tmpFaqA = faqAnswer;
    delete tmpFaqQ[index];
    delete tmpFaqA[index];
    let i = 0;
    for (let j in tmpFaqQ) {
      tmpFaqQnew[i] = tmpFaqQ[j];
      tmpFaqAnew[i] = tmpFaqA[j];
      i++;
    }
    setFaqQuestion(tmpFaqQnew);
    setFaqAnswer(tmpFaqAnew);
    let count = counter - 1;
    setCounter(count);
  };

  const handleQuestionOnChange = (index) => (e) => {
    const abc = {};
    abc[index] = e.target.value;
    setFaqQuestion({ ...faqQuestion, ...abc });
  };

  const handleAnswerOnChange = (index) => (e) => {
    const abc = {};
    abc[index] = e.target.value;
    setFaqAnswer({ ...faqAnswer, ...abc });
  };

  const next = (_event) => {
    if (!validateForm(currentStep)) return false;
    if (parseInt(currentStep) === 1) {
      setStepMessage("Targeting Details");
    } else if (parseInt(currentStep) === 2) {
      setStepMessage("Payment Details");
    } else if (parseInt(currentStep) === 3) {
      setStepMessage("Guidlines");
    } else if (parseInt(currentStep) === 4) {
      setStepMessage("Faq's");
    } else if (parseInt(currentStep) === 5) {
      setStepMessage("Sample Post");
    }
    let currentStepInner = currentStep;
    currentStepInner = currentStepInner >= 5 ? 6 : parseInt(currentStepInner) + 1;
    setCurrentStep(currentStepInner);
  };

  const prev = (_event) => {
    if (parseInt(currentStep) === 2) {
      setStepMessage("Basic Details");
    } else if (parseInt(currentStep) === 3) {
      setStepMessage("Targeting Details");
    } else if (parseInt(currentStep) === 4) {
      setStepMessage("Payment Details");
    } else if (parseInt(currentStep) === 5) {
      setStepMessage("Guidlines");
    } else if (parseInt(currentStep) === 6) {
      setStepMessage("Faq's");
    }
    let currentStepInner = currentStep;
    currentStepInner = currentStepInner <= 1 ? 1 : parseInt(currentStepInner) - 1;
    setCurrentStep(currentStepInner);
  };

  const submitButton = (_event) => {
    let currentStepInner = currentStep;
    if (editMode) {
      currentStepInner = 6;
    }
    if (currentStepInner === 6) {
      return (
        <FormControl style={{ marginBottom: "15px", marginLeft: "20px", float: "right" }}>
          <button variant="contained" className="btn btn-success float-right" type="submit">
            Submit
          </button>
        </FormControl>
      );
    }
    return null;
  };

  const previousButton = (_event) => {
    let currentStepInner = currentStep;
    if (parseInt(currentStepInner) !== 1) {
      return (
        <FormControl style={{ marginBottom: "15px", marginRight: "20px" }}>
          <button variant="contained" className="btn btn-secondary float-right" type="button" onClick={prev}>
            Previous
          </button>
        </FormControl>
      );
    }
    return null;
  };

  const nextButton = (_event) => {
    let currentStepInner = currentStep;
    if (currentStepInner < 6) {
      return (
        <FormControl style={{ marginBottom: "15px" }}>
          <button variant="contained" className="btn btn-success float-right" type="button" onClick={next}>
            Next
          </button>
        </FormControl>
      );
    }
    return null;
  };

  const faqList = (_event) => {
    setFaqmodalOpen(true);
  };

  const handleFaqClose = () => {
    setFaqmodalOpen(false);
  };

  return isLoaded ? (
    <>
      {/* Modal to create new list to add selected Influencers */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-create_list-label"
        aria-describedby="modal-create_list-description"
        open={addInfToNewListDialog}
        onClose={handleListClose}
        closeAfterTransition
      >
        <Fade in={addInfToNewListDialog}>
          <div class="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-create_list-heading" className="styles_modal_heading">
              <h2 id="modal-create_list-heading-text" style={{ padding: "10px" }}>
                Add List - {stepMessage}
              </h2>
              <IconButton onClick={handleListClose}>
                {" "}
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div id="modal-create_list-body" style={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Box>
                  <Fragment>
                    <form id="stepForm" onSubmit={newListClick}>
                      {currentStep == 1 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                Account *
                              </InputLabel>
                              <Select
                                required={true}
                                label="Account *"
                                id="companyId"
                                InputLabelProps={{ shrink: false }}
                                value={companyId || ""}
                                onChange={handleChangeAccount}
                                input={<OutlinedInput label="Account *" id="demo-dialog-native" />}
                              >
                                {companyList.map((aname) => (
                                  <MenuItem value={aname.id} key={aname.id}>
                                    {aname.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                Campaign Name *
                              </InputLabel>
                              <Select
                                required={true}
                                label="Campaign Name *"
                                InputLabelProps={{ shrink: false }}
                                value={campaignId || ""}
                                id="campaignId"
                                onChange={handleChangeCampaign}
                                refs="campaignId"
                                input={<OutlinedInput label="Campaign Name *" id="demo-dialog-native" />}
                              >
                                {campaignList.map((cname) => (
                                  <MenuItem value={cname.id} key={cname.id}>
                                    {cname.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <InputLabel id="account-selection-label" InputLabelProps={{ shrink: true }}>
                                Platform *
                              </InputLabel>
                              <Select
                                required={true}
                                label="Platform *"
                                InputLabelProps={{ shrink: false }}
                                id="platformId"
                                value={platformId || ""}
                                onChange={handleChangePlatform}
                                refs="platformId"
                                input={<OutlinedInput label="Platform *" id="demo-dialog-native" />}
                              >
                                <MenuItem value={1} key={1}>
                                  Instagram
                                </MenuItem>
                                <MenuItem value={2} key={2}>
                                  Twitter
                                </MenuItem>
                                <MenuItem value={3} key={3}>
                                  TikTok
                                </MenuItem>
                                <MenuItem value={4} key={4}>
                                  YouTube
                                </MenuItem>
                                <MenuItem value={5} key={5}>
                                  LinkedIn
                                </MenuItem>
                                <MenuItem value={6} key={6}>
                                  Facebook
                                </MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <TextField
                                variant="outlined"
                                id="newListName"
                                required={true}
                                refs="newListName"
                                label="List Name"
                                value={newListName}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <TextField
                                variant="outlined"
                                required={false}
                                type="text"
                                value={description}
                                id="description"
                                refs="description"
                                name="description"
                                label="Description"
                                onChange={handleDescChange}
                              />
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <ReactTagInput
                                variant="outlined"
                                sx={{ height: "1.4375em" }}
                                tags={tags}
                                placeholder="Tags"
                                refs="tags"
                                name="tags"
                                label="Tags"
                                maxTags={3}
                                onChange={(newTags) => setTags(newTags)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <TextareaAutosize
                                variant="outlined"
                                minRows={2}
                                required={false}
                                refs="note"
                                placeholder="Notes"
                                value={notes}
                                onChange={handleNotesChange}
                                style={{ height: 56, borderRadius: "4px", borderColor: "#c4c4c4" }}
                              />
                            </FormControl>

                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <Autocomplete
                                options={timezoneList}
                                getOptionLabel={(timezoneLabel) => timezoneLabel.text}
                                defaultValue={timezoneLable || timezoneLable[0]}
                                onChange={(_event, value) => handleTimezoneChange(value)}
                                id="timezone-data"
                                renderInput={(params) => <TextField {...params} label="Timezone" />}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}></Grid>
                        </div>
                      )}
                      {currentStep == 2 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <TextField
                                variant="outlined"
                                required={false}
                                refs="minfollowers"
                                label="Min Followers"
                                type="number"
                                value={minFollowers}
                                onChange={handleMinFollowersChange}
                              />
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <TextField
                                variant="outlined"
                                required={false}
                                refs="maxfollowers"
                                label="Max Followers"
                                type="number"
                                value={maxFollowers}
                                onChange={handleMaxFollowersChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <Grid item xs={12} sm={3} style={{ display: "flex", maxWidth: "50%", marginRight: "10px" }}>
                              <FormControl style={{ width: "230px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                <InputLabel id="demo-simple-select-standard-label">Geo Target</InputLabel>
                                <Select
                                  required={false}
                                  label="Geo Target"
                                  id="geoTypeId"
                                  value={geoType}
                                  onChange={handleGeoTypeChange}
                                  refs="geoTypeId"
                                  input={<OutlinedInput label="Geo Target" id="demo-dialog-native" />}
                                  disabled
                                >
                                  <MenuItem value={1} key={1}>
                                    Gobal
                                  </MenuItem>
                                  <MenuItem value={2} key={2}>
                                    Specific
                                  </MenuItem>
                                </Select>
                              </FormControl>

                              <FormControl
                                id="countryDD"
                                style={{
                                  width: "230px",
                                  marginBottom: "15px",
                                  flex: 1,
                                  marginRight: "10px",
                                  display: displayGeo,
                                }}
                              >
                                <Autocomplete
                                  options={countryList}
                                  required={true}
                                  getOptionLabel={(countryLabel) => countryLabel.name}
                                  defaultValue={countryLable || countryLable[0]}
                                  onChange={(_event, value) => handleCountryChange(value)}
                                  id="country-data"
                                  renderInput={(params) => <TextField {...params} label="Country *" />}
                                />
                              </FormControl>
                            </Grid>
                            <Grid id="stateCityDD" item xs={12} sm={3} style={{ maxWidth: "50%", flex: 1, display: displayGeo }}>
                              <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                <Autocomplete
                                  options={stateList}
                                  getOptionLabel={(stateLabel) => stateLabel.name}
                                  defaultValue={stateLable || stateLable[0]}
                                  onChange={(_event, value) => handleStateChange(value)}
                                  id="state-data"
                                  renderInput={(params) => <TextField {...params} label="State" />}
                                />
                              </FormControl>

                              <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "10px" }}>
                                <Autocomplete
                                  options={cityList}
                                  getOptionLabel={(cityLabel) => cityLabel.name}
                                  defaultValue={cityLable || cityLable[0]}
                                  onChange={(_event, value) => handleCityChange(value)}
                                  id="state-data"
                                  renderInput={(params) => <TextField {...params} label="City" />}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}></Grid>
                        </div>
                      )}
                      {currentStep == 3 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <InputLabel id="demo-simple-select-standard-label">Currency</InputLabel>
                              <Select
                                required={false}
                                label="Currency"
                                id="currencyId"
                                value={currency}
                                onChange={handleCurrencyChange}
                                refs="currencyId"
                                input={<OutlinedInput label="Currency" id="demo-dialog-native" />}
                              >
                                <MenuItem value="$">$</MenuItem>
                                <MenuItem value="₹">₹</MenuItem>
                                <MenuItem value="€">€</MenuItem>
                                <MenuItem value="£">£</MenuItem>
                              </Select>
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1 }}>
                              <TextField
                                variant="outlined"
                                required={false}
                                refs="bugdet"
                                label="Per Influencer Budget"
                                type="number"
                                value={infBudget}
                                onChange={handleInfBudgetChange}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} style={{ display: "flex", width: "100%" }}>
                            <Grid item xs={12} sm={6} style={{ display: "flex", width: "50%" }}>
                              <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1, marginRight: "10px" }}>
                                <InputLabel id="demo-simple-select-standard-label">Payment Term</InputLabel>
                                <Select
                                  required={false}
                                  label="Payment Term"
                                  id="paymentId"
                                  value={paymentTerm}
                                  onChange={handlePaymentChange}
                                  refs="paymentId"
                                  input={<OutlinedInput label="Payment Term" id="demo-dialog-native" />}
                                >
                                  <MenuItem value={1} key={1}>
                                    Weekly
                                  </MenuItem>
                                  <MenuItem value={2} key={2}>
                                    Bi-Weekly/Fortnightly
                                  </MenuItem>
                                  <MenuItem value={3} key={3}>
                                    Monthly
                                  </MenuItem>
                                  <MenuItem value={4} key={4}>
                                    No. Days after Task Completion
                                  </MenuItem>
                                  <MenuItem value={5} key={5}>
                                    Particular Payment Date (s) - On a particular day
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid id="noofDays" item xs={12} sm={6} style={{ maxWidth: "50%", flex: 1, display: displayPT }}>
                              <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginLeft: "10px" }}>
                                <TextField
                                  variant="outlined"
                                  required={true}
                                  refs="noofDays"
                                  label="No Of Days"
                                  type="number"
                                  value={noofDays}
                                  onChange={handleNoOfDaysChange}
                                />
                              </FormControl>
                            </Grid>
                            <Grid id="onDate" item xs={12} sm={6} style={{ maxWidth: "50%", flex: 1, display: displayDT }}>
                              <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginLeft: "10px" }}>
                                <TextField
                                  clearable
                                  value={paymentDate}
                                  id="paymentDate"
                                  variant="outlined"
                                  name="paymentDate"
                                  label="Payment Date"
                                  InputLabelProps={{ shrink: true, required: true }}
                                  type="date"
                                  required={required}
                                  onChange={handlePaymentDateChange}
                                  sx={{ width: "100%" }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}></Grid>
                        </div>
                      )}
                      {currentStep == 4 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl
                              style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px", minHeight: "240px" }}
                            >
                              <TextField
                                variant="outlined"
                                id="dos"
                                name="dos"
                                label="Do's"
                                multiline
                                minRows={4}
                                required={false}
                                refs="dos"
                                placeholder="Do's"
                                onKeyUp={(event) => {
                                  handleKeyUp("dos", event);
                                }}
                                style={{ height: "240px", borderRadius: "4px", borderColor: "#c4c4c4", minHeight: "240px" }}
                                defaultValue={dos}
                              />
                            </FormControl>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <TextField
                                variant="outlined"
                                id="donts"
                                name="donts"
                                label="Dont's"
                                multiline
                                minRows={4}
                                required={false}
                                refs="Dont"
                                placeholder="Dont's"
                                onKeyUp={(event) => {
                                  handleKeyUp("donts", event);
                                }}
                                style={{ height: "auto", borderRadius: "4px", borderColor: "#c4c4c4", minHeight: "240px" }}
                                defaultValue={donts}
                              />{" "}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={3}></Grid>
                          <br />
                        </div>
                      )}
                      {currentStep == 5 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "90%", marginBottom: "15px" }}>
                              <Button
                                variant="contained"
                                className="btn btn-success float-right"
                                type="button"
                                id="demo-customized-button"
                                aria-controls={open ? "demo-customized-menu" : undefined}
                                onClick={faqList}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                style={{ float: "right", width: "100px" }}
                              >
                                {" "}
                                All FAQ
                              </Button>
                            </FormControl>
                            <FormControl style={{ marginBottom: "30px", display: "inline" }}>
                              <Button
                                variant="contained"
                                className="btn btn-success float-right"
                                type="button"
                                onClick={handleClickFAQ}
                              >
                                {" "}
                                + FAQ
                              </Button>
                            </FormControl>
                          </Grid>
                          <div style={{ maxHeight: "330px", overflow: "auto" }}>
                            {Array.from(Array(counter)).map((_c, index) => {
                              return (
                                <div>
                                  <FormControl
                                    style={{
                                      width: "100%",
                                      marginBottom: "15px",
                                      flex: 1,
                                      marginRight: "20px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <TextField
                                      variant="outlined"
                                      required={false}
                                      type="text"
                                      value={faqQuestion[index]}
                                      id="question"
                                      refs="question"
                                      name="question"
                                      label="Question"
                                      onChange={handleQuestionOnChange(index)}
                                    />
                                  </FormControl>
                                  <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                    <FormControl style={{ width: "100%", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                                      <TextField
                                        variant="outlined"
                                        required={false}
                                        type="text"
                                        value={faqAnswer[index]}
                                        id="answer"
                                        refs="answer"
                                        name="answer"
                                        label="Answer"
                                        onChange={handleAnswerOnChange(index)}
                                      />
                                    </FormControl>
                                    <FormControl style={{ marginBottom: "30px", display: "block", marginTop: "15px" }}>
                                      <RemoveCircleOutlineIcon style={{ display: "block" }} onClick={handleRemoveClick(index)} />
                                    </FormControl>
                                  </Grid>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {currentStep == 6 && (
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl style={{ width: "220px", marginBottom: "15px", flex: 1, marginRight: "20px" }}>
                              <Editor
                                editorState={editorState}
                                onEditorStateChange={handleEditorChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                toolbar={{
                                  options: ["image", "emoji", "link", "history"],
                                  image: { inDropdown: true },
                                  emoji: { inDropdown: true },
                                  link: {
                                    inDropdown: true,
                                    showOpenOptionOnHover: true,
                                    defaultTargetOption: "_self",
                                    options: ["link", "unlink"],
                                  },
                                  history: { inDropdown: true },
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={3}></Grid>
                        </div>
                      )}
                      {previousButton()}
                      {nextButton()}
                      {submitButton()}
                    </form>
                  </Fragment>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* Modal to list all FAQs */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-faqs_list-label"
        aria-describedby="modal-faqs_list-description"
        open={faqmodalOpen}
        onClose={handleFaqClose}
        closeAfterTransition
      >
        <Fade in={faqmodalOpen}>
          <div class="syles_paper_list" style={{ height: "auto", width: "50%" }}>
            <div id="modal-faqs_list-heading" className="styles_modal_heading">
              <h2 id="modal-faqs_list-heading-text" style={{ padding: "10px" }}>
                All Faqs
              </h2>
              <IconButton onClick={handleFaqClose}>
                {" "}
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div id="modal-faqs_list-body" style={{ padding: "10px" }}>
              <Grid item xs={12}>
                <Box>
                  <Grid item xs={12} sm={12} style={{ display: "block", maxWidth: "100%", maxHeight: "160px", overflow: "auto" }}>
                    <Grid item xs={12} sm={6} style={{ display: "block", maxWidth: "100%" }}>
                      <FAQS data={faqListData} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  ) : (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, flexDirection: "column" }} open={backdropShow}>
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
};

AddInfToNewListDialog.propType = {
  addInfToNewListDialog: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired,
  iidsSelected: PropTypes.array.isRequired,
  modalClosed: PropTypes.func.isRequired,
};

export default AddInfToNewListDialog;
