import axios from "axios";
import { API_HOST } from "../../config/main.json";
import { getResponseType } from "../../utils/exportData";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

/* 
  Get campaignSpecific or platform Specific list
*/
const getAllLists = async (cid, platform) => {
  return axios.post(`${API_HOST}/list/campaign/platform`, { cid, platform }, optionAxios).then((res) => {
    return res.data;
  });
};


const getAllTasks = async (cid) => {
  const response = await axios({
    url: `${API_HOST}/taskList/cid/${cid}`,
    method: "GET",
  });
  if (response) {
    return response.data;
  }
  return false;
};


/* get lists data belonging to a particular campaign, given campaign id */
const getListsByCampaign = async (id) => {
  const response = await axios({
    url: `${API_HOST}/list/acc/id/${id}`,
    method: "GET",
  });
  if (response) {
    return response.data;
  }

  return false;
};

/* get all the active lists in a particular campaign, given campaign id */
const getCampList = async (campaign_id) => {
  const response = await axios({
    url: `${API_HOST}/lists/camp/id/${campaign_id}`,
    method: "GET",
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getTopThreeGroups = async (cid, from, to, filterBy, platformIds, postTypes, lids, iids) => {
  return axios
    .post(
      `${API_HOST}/campaign/engagement/listwise`,
      { cid, from, to, filterBy, plid: platformIds, postTypes, lids, iids },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};
const getReachListwise = async (cid,from, to,filterBy, platformIds,postTypes,lids, iids) => {
  return axios
    .post(
      `${API_HOST}/campaign/engagement/reach`,
      { cid,from, to,filterBy, plid: platformIds,postTypes, lids, iids },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};
const getListData = async (
  cid,
  page,
  rows,
  SortCol,
  sortOrder,
  isExport,
  filter,
  startDate,
  endDate,
  isRefreshed,
  mimeType,
  campaignName,
  account,
  client,
  timezone,
  addedFrom,
  type,
) => {
  const response = await axios({
    url: `${API_HOST}/campaign/groups`,
    method: "POST",
    data: {
      cid,
      plid: [
        1,
        2,
        3,
        4,
        5,
        6
      ],
      page,
      rows,
      SortCol,
      sortOrder,
      isExport,
      filter,
      startDate,
      endDate,
      isRefreshed,
      mimeType,
      campaignName,
      account,
      client,
      timezone,
      addedFrom,
      status: type
    },
    headers: optionAxios.headers,
    responseType: getResponseType(isExport),
  });
  if (response) {
    return response.data;
  }

  return false;
};

export { getAllLists, getAllTasks, getListsByCampaign, getCampList, getTopThreeGroups, getListData,getReachListwise };
