import React, { Component } from "react";

/* external files */
import MyPaper from "../general/MyPaper";
import utils from "../../utils/utils";

/* mui */
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getAllLists } from "../../services/list/ListDetails";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import enums from "../../utils/enums";

const styles = (theme) => ({
  popper: {
    maxWidth: "fit-content",
  },
});

const CustomPopper = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom" />;
};

class LinkFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialTrigger: false,
      platforms: [],
      lists: [],
      campaignUrls: [],
      platformSelected: "all",
      listSelected: -1,
      campaignUrlSelected: [],
      isListsLoading: true,
      isCampaignUrlLoading: true,
      linkSelectAll: true,
    };
  }

  componentDidMount() {
    this.getAllPlatforms();
    this.getAllLists("all");
  }

  getAllPlatforms() {
    utils.getPlatforms().then((data) => {
      const platforms = data;
      this.setState({ platforms });
    });
  }

  getAllLists = async (platform) => {
    this.setState({
      isListsLoading: true,
      listSelected: -1,
      isCampaignUrlLoading: true,
      campaignUrlSelected: [],
    });
    const campId = this.props.campId.toString();
    const data = await getAllLists(campId, platform);
    let optionsLists = [];
    if (data) {
      optionsLists = data;
    }
    this.setState({
      lists: optionsLists,
      listSelected: -1,
      isListsLoading: false,
    });
    const lids = optionsLists.map((list) => list.id);
    this.getAllCampaignURLs(platform, lids);
    this.setState({ isListsLoading: false, isCampaignUrlLoading: false });
  };

  getAllCampaignURLs = (platformSelected, lids) => {
    this.setState({ isCampaignUrlLoading: true, campaignUrlSelected: [] });
    const cid = +this.props.campId;
    const data = {
      cid,
      plid: platformSelected === "all" ? [] : [enums.platformIdMapping[utils.capitalizeFirstLetter(platformSelected)]],
      lid: lids,
    };
    utils.getCampaignUrlsFilter(data).then((data) => {
      let campaignUrls = [];
      let campaignUrlSelected = [];
      if (data) {
        campaignUrls = [
          {
            url: "Select All",
            urlText: "Select All",
            urlId: -1,
          },
          ...data,
        ];
        campaignUrlSelected = data;
      }
      if (!this.state.initialTrigger) {
        this.setState(
          {
            campaignUrls: campaignUrls,
            isCampaignUrlLoading: false,
            campaignUrlSelected: campaignUrlSelected,
            initialTrigger: true,
          },
          () => {
            this.applyFilters();
          }
        );
      } else {
        this.setState({
          campaignUrls: campaignUrls,
          isCampaignUrlLoading: false,
          campaignUrlSelected: campaignUrlSelected,
        });
      }
    });
  };

  applyFilters = () => {
    let lids = [];
    const listSelected = this.state.listSelected;
    if (listSelected !== -1) {
      lids = this.state.lists
        .filter((list) => list.id === listSelected)
        .map((list) => list.id);
    } else {
      lids = this.state.lists.map((list) => list.id);
    }

    let campaignUrl = [];
    const campaignUrlSelected = this.state.campaignUrlSelected;
    if (campaignUrlSelected.length > 0) {
      campaignUrl = this.state.campaignUrls
        .filter((campaignUrl) =>
          campaignUrlSelected
            .map((val) => val.urlId)
            .includes(campaignUrl.urlId)
        )
        .map((campaignUrl) => campaignUrl.urlId);
    }

    const platform = this.state.platformSelected;

    const filterOn = {
      lids: lids,
      urlIds: campaignUrl,
      platform: platform,
    };
    this.props.handleFilterChange(filterOn);
  };

  handlePlatformChange = (event) => {
    const platformSelected = event.target.value;
    this.setState({ platformSelected });
    const platform = event.target.value;
    this.getAllLists(platform);
  };

  handleListChange = (event) => {
    const platform = this.state.platformSelected;
    const listSelected = event.target.value;
    this.setState({ listSelected });

    let lids = [];
    if (listSelected !== -1) {
      lids = this.state.lists
        .filter((list) => list.id === listSelected)
        .map((list) => list.id);
    } else {
      lids = this.state.lists.map((list) => list.id);
    }

    this.getAllCampaignURLs(platform, lids);
  };

  handleCampaignUrlChange = (value) => {
    let selectAllPresent = false;
    for (let i = 0; i < value.length; i++) {
      if (value[i].urlId === -1) {
        selectAllPresent = true;
        break;
      }
    }
    if (selectAllPresent) {
      if (this.state.linkSelectAll) {
        this.setState({ campaignUrlSelected: [], linkSelectAll: false });
      } else {
        const values = this.state.campaignUrls.filter(
          (val) => val.urlId !== -1
        );
        this.setState({ campaignUrlSelected: values, linkSelectAll: true });
      }
      return;
    }
    let selectAll = false;
    if (value.length === this.state.campaignUrls.length - 1) {
      selectAll = true;
    }
    this.setState({
      campaignUrlSelected: value,
      linkSelectAll: selectAll,
    });
  };

  showAdditionalListSelection = () => {
    if (this.state.isListsLoading) {
      return (
        <MenuItem key={-1} value={-1}>
          Loading...
        </MenuItem>
      );
    }
    if (this.state.lists && this.state.lists.length > 0) {
      return (
        <MenuItem key={-1} value={-1}>
          All Lists
        </MenuItem>
      );
    } else {
      return (
        <MenuItem key={-2} value={-1} disabled>
          No lists available
        </MenuItem>
      );
    }
  };

  render() {
    return (
      <MyPaper sx={{ marginBottom: "20px" }}>
        <Stack direction="row" justifyContent="end">
          {/* heading of the box - Filters */}
          <Typography
            variant="h5"
            sx={{
              marginRight: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            Filters
          </Typography>

          {/* select platform */}
          <FormControl size="small" sx={{ marginRight: "5px" }}>
            <InputLabel id="link-filter-select-platform-input-label">
              Platform
            </InputLabel>
            <Select
              sx={{ height: "40px", minWidth: "10vw" }}
              style={{ color: "black" }}
              labelId="link-filter-select-platform-input-label"
              id="link-filter-select-platform"
              value={this.state.platformSelected}
              label="Platform"
              onChange={this.handlePlatformChange}
              variant="outlined"
              // multiple
            >
              <MenuItem key={"all"} value={"all"}>
                All Platforms
              </MenuItem>
              {this.state.platforms.map((plat) => (
                <MenuItem key={plat.name} value={plat.name}>
                  {utils.capitalizeFirstLetter(`${plat.name}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* select list */}
          <FormControl size="small" sx={{ marginRight: "5px" }}>
            <InputLabel id="link-filter-select-list-input-label">
              List
            </InputLabel>
            <Select
              sx={{ height: "40px", minWidth: "10vw" }}
              style={{ color: "black" }}
              labelId="link-filter-select-list-input-label"
              id="link-filter-select-list"
              value={this.state.listSelected}
              label="List"
              onChange={this.handleListChange}
              variant="outlined"
              // multiple
            >
              {this.showAdditionalListSelection()}
              {this.state.lists.map((lst) => (
                <MenuItem key={lst.id} value={lst.id}>
                  {utils.capitalizeFirstLetter(`${lst.name}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* select url */}
          <FormControl size="small" sx={{ marginRight: "5px", width: "250px" }}>
            <Autocomplete
              size="small"
              multiple
              limitTags={0}
              PopperComponent={CustomPopper}
              options={this.state.campaignUrls}
              disableCloseOnSelect
              value={this.state.campaignUrlSelected}
              onChange={(e, val) => this.handleCampaignUrlChange(val)}
              getOptionLabel={(option) => option.url}
              renderOption={(props, option, { selected }) => (
                <li key={option.urlId} {...props} style={{ width: "100%" }}>
                  {option.urlId === -1 && (
                    <>
                      <Checkbox checked={this.state.linkSelectAll} />
                      &nbsp;{option.url}
                    </>
                  )}
                  {option.urlId !== -1 && (
                    <>
                      <Checkbox checked={selected} />
                      <Typography sx={textColor[option.platform]}>
                        {snapshotHeaderIcons[option.platform]}
                      </Typography>
                      &nbsp;
                      <span style={{ display: "inline-block" }}>
                        <div>{option.url}</div>
                        <div style={{ fontSize: "12px" }}>
                          {option.listName}
                        </div>
                      </span>
                    </>
                  )}
                </li>
              )}
              renderTags={(selected, _, state) =>
                state.popupOpen
                  ? ""
                  : `${selected.length} URL${
                      selected.length > 1 ? "s" : ""
                    } Selected`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Destination URL"
                  renderValue={(selected) =>
                    `${selected.length} URL${
                      selected.length > 1 ? "s" : ""
                    } Selected`
                  }
                />
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            onClick={this.applyFilters}
            endIcon={<ArrowForwardIcon />}
            disabled={this.state.campaignUrlSelected.length === 0}
          >
            GO
          </Button>
        </Stack>
      </MyPaper>
    );
  }
}

export default LinkFilter;
