import React from "react";
import AccountTable from "./AccountTable";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DeletedAccount = () => {
  const location = useLocation();
  const platform = new URLSearchParams(location.search).get("platform");

  return <AccountTable type={2} platfrom={platform} />;

}

export default DeletedAccount;