import { ToggleButton, ToggleButtonGroup, styled } from "@mui/material";
import PropTypes from "prop-types";
import utils from "../../../../../../../utils/utils";

const CustomToggleButton = styled(ToggleButton)(() => ({
  border: "none",
  transition: "border 0.5s ease-in-out",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#007DFF",
    borderBottom: "2px solid #007DFF"
  }
}));

const CustomToggleButtonGroup = (props) => {
  const { options, selectedOption, handleChange } = props;
  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedOption}
      exclusive
      onChange={handleChange}
      aria-label="InfoSection"
      sx={{
        border: 'none',
        my: "2rem"
      }}
    >
      {options.map((item) => <CustomToggleButton value={item.key} sx={{ border: "none" }}>{utils.capitalizeFirstLetter(item.value)}</CustomToggleButton>)}
    </ToggleButtonGroup>
  )
}

CustomToggleButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })),
  selectedOption: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default CustomToggleButtonGroup;