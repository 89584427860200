import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Stack,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { func, number } from "prop-types";
import { styled } from "@mui/system";
import { APP_NAME } from "../../../config/main.json";
import UserMenu from "./UserMenu";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsIcon from "@mui/icons-material/Settings";

const Logo = styled("img")({});

const AccountToolbar = ({ drawerWidth, handleDrawerToggle }) => (
  <AppBar
    elevation={0}
    position="fixed"
    sx={{
      width: { md: `calc(100% - ${drawerWidth}px)` },
      ml: { md: `${drawerWidth}px` },
      // backgroundColor: "rgba(234,249,244,0.9)",
      backgroundColor: "white",
      // color: "white",
    }}
  >
    <Stack
      direction="row"
      justifyContent="end"
      sx={{ padding: "2px 20px", display: "flex", alignItems: "center" }}
    >
      {/* <Badge badgeContent={4} color="error" sx={{ marginRight: "5%" }}>
        <MailIcon color="action" />
      </Badge>
      <Badge
        color="error"
        overlap="circular"
        badgeContent=" "
        variant="dot"
        sx={{ marginRight: "5%" }}
      >
        <NotificationsNoneIcon sx={{ color: "#414141" }} />
      </Badge>
      <SettingsIcon color="action" sx={{ marginRight: "3%" }} /> */}
      <UserMenu />
    </Stack>
    {/* <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          marginRight: (theme) => theme.spacing(2),
          display: {
            md: "none !important",
          },
        }}
      >
        <MenuIcon />
      </IconButton>
      <Logo
        src="/static/images/logo-dark.png"
        alt={APP_NAME}
        sx={{
          display: {
            md: "none",
            xs: "inline-block",
          },
          height: {
            xs: "35px !important",
            md: "initial",
          },
          margin: {
            xs: "14px 0px",
            md: "30px",
          },
        }}
      />
      <Typography component="div" sx={{ flexGrow: 1 }}>
        &nbsp;
      </Typography>
      <Grid container spacing={1} alignItems="center">
        <Grid item flexGrow={1} />
        <Grid item></Grid>
        <Grid item>
          <UserMenu />
        </Grid>
      </Grid>
    </Toolbar> */}
  </AppBar>
);

AccountToolbar.propTypes = {
  handleDrawerToggle: func.isRequired,
  drawerWidth: number.isRequired,
};

export default AccountToolbar;
