import axios from "axios";
import { API_HOST } from "../../config/main.json";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const getFirebaseAllLinks = async (cid) => {
  const response = await axios({
    url: `${API_HOST}/firebase/getAllLinks`,
    method: "POST",
    data: {
      accountId: 0,
      cid: cid,
    },
    headers: optionAxios.headers,
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getFirebaseDomains = async (cid) => {
  const response = await axios({
    url: `${API_HOST}/firebase/domains`,
    method: "POST",
    data: {
      id: cid,
    },
    headers: optionAxios.headers,
  });
  if (response) {
    return response.data;
  }

  return false;
};

const createFirebaseLinks = async (data) => {
  const response = await axios({
    url: `${API_HOST}/firebase/createLink`,
    method: "POST",
    data: data,
    headers: optionAxios.headers,
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getFirebaseShortUrls = async (cid) => {
  const response = await axios({
    url: `${API_HOST}/firebase/filter`,
    method: "POST",
    data: {
      cid: +cid,
    },
    headers: optionAxios.headers,
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getFirebaseSummary = async (shortUrlIds, startDate, endDate) => {
  const response = await axios({
    url: `${API_HOST}/firebase/analysis/summary`,
    method: "POST",
    data: {
      shortUrlIds,
      startDate,
      endDate,
    },
    headers: optionAxios.headers,
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getFirebaseConversions = async (shortUrlIds, startDate, endDate) => {
  const response = await axios({
    url: `${API_HOST}/firebase/analysis/conversions`,
    method: "POST",
    data: {
      shortUrlIds,
      startDate,
      endDate,
    },
    headers: optionAxios.headers,
  });
  if (response) {
    return response.data;
  }

  return false;
};

const getFirebaseLinkAnalysis = async (shortUrlIds, startDate, endDate) => {
  const response = await axios({
    url: `${API_HOST}/firebase/analysis/basic`,
    method: "POST",
    data: {
      shortUrlIds,
      startDate,
      endDate,
    },
    headers: optionAxios.headers,
  });
  if (response) {
    return response.data;
  }

  return false;
};

const exportFirebaseXLSX = async (shortUrlIds, startDate, endDate, email) => {
  const response = await axios({
    url: `${API_HOST}/firebase/analysis/sendxlsx`,
    method: "POST",
    data: {
      shortUrlIds,
      startDate,
      endDate,
      email,
    },
    headers: optionAxios.headers,
  });
  if (response) {
    return response.data;
  }

  return false;
};

export {
  getFirebaseAllLinks,
  getFirebaseDomains,
  createFirebaseLinks,
  getFirebaseShortUrls,
  getFirebaseSummary,
  getFirebaseConversions,
  getFirebaseLinkAnalysis,
  exportFirebaseXLSX,
};
