import { BadgeTwoTone } from "@mui/icons-material";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyPaper from "../../components/general/MyPaper";
import TabPanel from "../../components/general/TabPanel";
import { API_HOST } from "../../config/main.json";
import { CheckBoxOutlineBlank, InfoOutlined } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DraftEditor from "../../components/general/DraftEditor";
import utils from "../../utils/utils";
import CampaignTabs from "./CampaignTabs";
import CampaignActions from "./CampaignActions";

const NewTemplate = () => {
  const [cid, setCid] = useState(0);
  const [baseTemplateData, setBaseTemplateData] = useState([]);
  const [contractInputFieldsData, setContractInputFieldsData] = useState([]);
  const [templateName, setTemplateName] = useState(null);
  const [description, setDescription] = useState(null);
  const [templateSource, setTemplateSource] = useState(null);
  const [baseTemplates, setBaseTemplates] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [editor, setEditor] = useState(null);
  const [contractInputFields, setContractInputFields] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [kycRequired, setKycRequired] = useState(true);
  const [backdropShow, setBackdropShow] = useState(false);
  const [campaignName, setCampaignName] = useState("");

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    setCid(campId);
    getBaseTemplates();
    getContractInputFields();
    getCampaignInfo(campId);
  }, []);

  const getBaseTemplates = () => {
    axios.get(API_HOST + "/contract/baseTemplates").then((res) => {
      setBaseTemplateData(res.data);
    });
  };

  const getContractInputFields = () => {
    axios.get(API_HOST + "/contract/inputs").then((res) => {
      const { data } = res;
      setContractInputFieldsData(data);
      setContractInputFields(data.map((value) => value.id).join(","));
    });
  };

  const getCampaignInfo = (id) => {
    utils.getCampaignData(id).then((data) => {
      setCampaignName(data.name);
    });
  };

  const handleTemplateNameChange = (e) => {
    const value = e.target.value;
    setTemplateName(value);
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
  };

  const handleTemplateSourceChange = (e) => {
    const value = e.target.value;
    setTemplateSource(value);

    if (value === "new") {
      setShowEditor(true);
      setEditor(null);
    } else {
      setBaseTemplates(null);
      setShowEditor(false);
    }
  };

  const handleBaseTemplatesChange = (e) => {
    const value = e.target.value;
    setBaseTemplates(value);

    axios.get(API_HOST + "/contract/baseTemplateContent/" + value).then((res) => {
      const baseTemplateContent = res.data;
      setShowEditor(true);
      setEditor(baseTemplateContent[0].content);
    });
  };

  const handleInputFieldsChange = (e, value) => {
    const inputFields = value
      .map((v) => v.id)
      .sort()
      .join(",");
    setContractInputFields(inputFields);
  };

  const handleIsGlobal = (e) => {
    setIsGlobal(e.target.checked);
  };

  const handleKycRequired = (e) => {
    setKycRequired(e.target.checked);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setBackdropShow(true);
    if (editor === null || editor.search('<span style="background-color') !== -1) {
      return;
    }

    const body = {};
    if (isGlobal) {
      body["aid"] = 0;
    } else {
      body["aid"] = -1;
    }
    body["cid"] = +cid;
    body["title"] = templateName;
    body["description"] = description;
    body["content"] = editor;
    body["inputFields"] = contractInputFields;
    body["kycRequired"] = kycRequired;

    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios.post(API_HOST + "/contract/template/add", body, optionAxios).then((res) => {
      setTimeout(function () {
        window.location = "/campaigns/contracts/templates?id=" + cid;
      }, 500);
    });
  };

  const autocompleteData = contractInputFieldsData.filter((value) =>
    contractInputFields
      .split(",")
      .map((v) => +v)
      .includes(value.id)
  );

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: "column",
        }}
        open={backdropShow}
      >
        <CircularProgress color="inherit" />
        <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
      </Backdrop>

      <CampaignActions name={campaignName} id={cid} />
      <br />
      <CampaignTabs campId={cid} selectedTab={"6"} />

      <Box sx={{ m: 3 }}>
        <Tabs value={0} style={{ width: "200px", float: "left" }} variant="scrollable" scrollButtons="auto">
          <Tab label="Add Template" id="template-details-tab-0" />
        </Tabs>
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" component={Link} to={"/campaigns/contracts/templates?id=" + cid}>
            All Templates
          </Button>
        </Stack>
      </Box>
      <TabPanel index={0} name="template-imformation-tab" value={0}>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MyPaper>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                  Basic Information
                </Typography>
                <Divider />

                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={templateName}
                      onChange={handleTemplateNameChange}
                      variant="outlined"
                      label="Template Name"
                      sx={{ width: "100%" }}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={description}
                      onChange={handleDescriptionChange}
                      variant="outlined"
                      label="Description"
                      sx={{ width: "100%" }}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="template-source">Template Source</InputLabel>
                      <Select
                        required={true}
                        variant="outlined"
                        label="Template Source"
                        id="templateSource"
                        value={templateSource}
                        onChange={handleTemplateSourceChange}
                      >
                        <MenuItem value="new">Start Fresh</MenuItem>
                        <MenuItem value="base">Use Base Template</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {templateSource === "base" && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Base Templates</InputLabel>
                        <Select
                          required={true}
                          variant="outlined"
                          label="Base Templates"
                          id="baseTemplates"
                          value={baseTemplates}
                          onChange={handleBaseTemplatesChange}
                        >
                          {baseTemplateData.map((base) => (
                            <MenuItem value={base.id}>{base.title}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
                {showEditor && (
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                      {templateSource === "new" && <DraftEditor value={editor} setValue={setEditor} readOnly={false} />}
                      {templateSource === "base" && !(editor === null || editor === undefined || editor === "") && (
                        <DraftEditor value={editor} setValue={setEditor} readOnly={false} />
                      )}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} style={{ marginTop: "1rem" }}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label={
                        <>
                          <span>Make this template global for this account &nbsp;</span>
                          <IconButton>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  Selecting this will make the template available in all the campaigns of this account
                                </span>
                              }
                            >
                              <InfoOutlined fontSize="small" style={{ color: "#6b778c" }} />
                            </Tooltip>
                          </IconButton>
                        </>
                      }
                      control={<Checkbox checked={isGlobal} onChange={handleIsGlobal} />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label={
                        <>
                          <span>KYC is required before accepting the contract &nbsp;</span>
                          <IconButton>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  Selecting this will ensure that the influencer has uploaded a KYC document before proceeding
                                </span>
                              }
                            >
                              <InfoOutlined fontSize="small" style={{ color: "#6b778c" }} />
                            </Tooltip>
                          </IconButton>
                        </>
                      }
                      control={<Checkbox checked={kycRequired} onChange={handleKycRequired} />}
                    />
                  </FormControl>
                </Grid>
              </MyPaper>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Box sx={{ mt: 4 }}>
                <Button variant="contained" size="large" type="submit">
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </TabPanel>
    </>
  );
};

export default NewTemplate;
