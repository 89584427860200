import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../utils/tiktok-brands.svg";
import { Tooltip } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import utils from "../../utils/utils";
import config from "../../config/main.json";

const socialIcons = {
  instagram: <InstagramIcon sx={{ color: "#BC2A8D" }} />,
  twitter: <TwitterIcon sx={{ color: "#00ACEE" }} />,
  x: <TwitterIcon sx={{ color: "#00ACEE" }} />,
  tiktok: <TikTokIcon style={{ width: "24px", height: "24px", color: "#000000" }} />,
  youtube: <YouTubeIcon sx={{ color: "#FF0100" }} />,
  linkedin: <LinkedInIcon sx={{ color: "#0a66c2" }} />,
  facebook: <FacebookIcon sx={{ color: "#1b74e4" }} />,
};

const MAX_NAME_LENGTH = 25;

const styles = {
  cardContainer: {
    cursor: "pointer",
    maxWidth: "300px",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    textAlign: "center",
    transition: "transform 0.2s",
    position: "relative",
    paddingTop: "40px",
    margin: "10px",
  },
  profileHeader: {
    position: "relative",
    textAlign: "center",
    marginTop: "-40px",
  },
  profileImage: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    border: "3px solid #fff",
  },
  boldText: {
    fontSize: "0.9rem",
    color: "#333",
    margin: "0.3em 0",
  },
  normalText: {
    margin: "0.3em 0",
    color: "#666",
    fontSize: "0.95rem",
  },
  socialContainer: {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "#f0f0f0",
    padding: "0.8em 0",
    borderTop: "1px solid #e0e0e0",
  },
  followers: {
    textAlign: "center",
  },
  smallerText: {
    color: "#666",
    fontSize: "0.6em",
  },
};

function ProfileCard(props) {
  return (
    <div
      style={styles.cardContainer}
      onClick={() => {
        window.open(props.linkToProfile, "_blank");
      }}
    >
      <header style={styles.profileHeader}>
        <img
          style={styles.profileImage}
          src={
            props.photoUrl
              ? props.photoUrl
              : config.PROFILE_IMG
          }
          alt={props.name}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = config.PROFILE_IMG;
          }}
        />
      </header>
      <h2 style={styles.boldText}>
        <Tooltip title={props.name} placement="bottom">
          <span>
            {props.name?.length > MAX_NAME_LENGTH
              ? props.name?.slice(0, MAX_NAME_LENGTH - 1) + "..."
              : props.name}
          </span>
        </Tooltip>
        &nbsp;
        {socialIcons[props.platform]}
      </h2>
      <h2 style={styles.normalText}>
        <LocationOnIcon sx={{ color: "#03c2fc" }} />
        {props.city ? props.city : "-"}&nbsp;
      </h2>
      <div style={styles.socialContainer}>
        <div style={styles.followers}>
          <h1 style={styles.boldText}>
            {utils.convertToInternationalNumberSystem(props.followers)}
          </h1>
          <h2 style={styles.smallerText}>Followers</h2>
        </div>
        <div style={styles.followers}>
          <h1 style={styles.boldText}>
            {utils.convertToInternationalNumberSystem(props.engagement)}
          </h1>
          <h2 style={styles.smallerText}>Engagement</h2>
        </div>
        <div style={styles.followers}>
          <h1 style={styles.boldText}>
            {props.engRate.toFixed(2)}%
          </h1>
          <h2 style={styles.smallerText}>Eng Rate</h2>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
