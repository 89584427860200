import { useState, useEffect } from "react";
import MyPaper from "../../../general/MyPaper";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../../../utils/tiktok-brands.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Common from "./Common";
import axios from "axios";
import { IQHOST } from "../../../../config/main.json";
import Box from "@mui/material/Box";
import "../../FilterComponents/common/Discovery.css";
import utils from "../../../../utils/utils";

import { Select, InputLabel, FormControl, MenuItem, Grid, Typography, TextField, Autocomplete } from "@mui/material";

const Analyze = () => {
  const [selectedPlatform, setSelectedPlatform] = useState("instagram");
  const [shouldRenderUser, setShouldRenderUser] = useState(false);

  const [allFiltersData, setAllFiltersData] = useState([{ inf_hidden: [{ name: "Exclude private accounts" }] }]);
  const [allInfanalyzes, setAllInfanalyzes] = useState([]);
  const [username, setUserName] = useState("");

  const handleAnalyzeSelection = (selectedValues) => {
    let username = selectedValues["username"];
    if (username !== undefined) {
      setUserName(username);
    }
  };

  const handlerInfanalyze = (event) => {
    if (event.target.value != "") {
      getInfluencerData(event.target.value, "inf", "search");
    }
  };

  const getInfluencerData = async (searchKey, type, searchType) => {
    try {
      const res = await axios.get(
        IQHOST + "dict/users/?q=" + searchKey + "&type=" + searchType + "&platform=" + selectedPlatform + "&limit=5"
      );
      let newanalyzes = [];
      res.data.data.map((look, index) => {
        if (look["username"] != undefined || look["fullname"] != undefined) {
          let lookData = {
            username: look["username"] ? look["username"] : look["handle"],
            name: look["fullname"],
            pic: look["picture"],
            followers: look["followers"],
          };
          newanalyzes.push(lookData);
        }
      });
      setAllInfanalyzes(newanalyzes);
    } catch (err) {
      console.log(err);
    }
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const filterOptions = (options, state) => {
    sleep(500);
    return options;
  };

  const [allPlatforms] = useState(["instagram", "tiktok", "youtube"]);
  const socialIcons = {
    instagram: <InstagramIcon sx={{ color: "#BC2A8D" }} />,
    twitter: <TwitterIcon sx={{ color: "#00ACEE" }} />,
    tiktok: <TikTokIcon style={{ width: "24px", height: "24px" }} sx={{ color: "#000000" }} />,
    youtube: <YouTubeIcon sx={{ color: "#FF0100" }} />,
    linkedin: <LinkedInIcon sx={{ color: "#0a66c2" }} />,
    facebook: <FacebookIcon sx={{ color: "#1b74e4" }} />,
  };

  const handlePlatformSelection = (event) => {
    setUserName("");
    setSelectedPlatform(event.target.value);
  };

  useEffect(() => {
    if (username !== "" && selectedPlatform !== "") {
      setShouldRenderUser(true);
    } else {
      setShouldRenderUser(false);
    }
  }, [username, selectedPlatform]);

  return (
    <>
      <Typography variant="h3" sx={{ mb: 2, mt: 2 }}>
        Analyze Influencer
      </Typography>
      <MyPaper>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <FormControl fullWidth style={{ marginBottom: 10 }} size="small">
              <InputLabel id="demo-simple-select-standard-label">Platform</InputLabel>
              <Select onChange={handlePlatformSelection} variant="outlined" label="Platform" value={selectedPlatform} required>
                {allPlatforms.map((platform) => (
                  <MenuItem key={platform} value={platform}>
                    {socialIcons[platform]} {utils.capitalizeFirstLetter(platform)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <Autocomplete
              id="filter-influencer-analyze"
              size="small"
              options={allInfanalyzes}
              popupIcon={""}
              limitTags={0}
              filterOptions={filterOptions}
              getOptionLabel={(option) => option.name || ""}
              value={typeof allFiltersData[0]["inf_analyzes"] === "undefined" ? "" : allFiltersData[0]["inf_analyzes"][0]["value"]}
              onChange={(_e, v) => handleAnalyzeSelection(v)}
              renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  <span className="mat-option-text">
                    <div className="autocomplete-item">
                      <img loading="lazy" className="round-image autocomplete-item__picture" src={option.pic} srcSet={option.pic} />
                      <div className="autocomplete-item__info">
                        <div className="autocomplete-item__username">{"@" + option.username}</div>
                        <div className="autocomplete-item__fullname">{option.name}</div>
                      </div>
                      <div className="autocomplete-item__followers">
                        {utils.convertToInternationalNumberSystem(option.followers)}
                      </div>
                    </div>
                  </span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Influencer profile URL, user ID or @handle"
                  placeholder="Influencer profile URL, user ID or @handle"
                  variant="outlined"
                  onChange={handlerInfanalyze}
                />
              )}
              style={{
                minWidth: 300,
                borderRadius: ".775rem",
              }}
            />
          </Grid>
          {shouldRenderUser && <Common id={username} platform={selectedPlatform} />}
        </Grid>
      </MyPaper>
    </>
  );
};

export default Analyze;
