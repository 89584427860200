import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import FileSaver from "file-saver";

/* 
  get details of all campaigns
*/
const exportPDFFile = async (fileName, formatedData) => {
  html2canvas(formatedData).then((canvas) => {
    var img = canvas.toDataURL("image/jpeg", 1);
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    var doc = new jsPDF("p", "px", [100, 100]);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
    doc.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
    doc.save(fileName);
  });
};

const exportImageFile = async (fileName, formatedData) => {
  html2canvas(formatedData).then((canvas) => {
    var a = document.createElement("a");
    a.href = canvas
      .toDataURL("image/jpeg")
      .replace("image/jpeg", "image/octet-stream");
    a.download = fileName;
    a.click();
  });
};

const onDownloadHandle = async (fileData, file) => {
  await FileSaver(fileData, file);
};

const getResponseType = (isExport) => {
  let responseType = "text";
  if (isExport === 1) {
    responseType = "blob";
  }
  return responseType;
};

const getTimeZone = () =>{
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timeZone
}
export { exportPDFFile, exportImageFile, onDownloadHandle, getResponseType, getTimeZone };
