import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { bool, func, object, string } from "prop-types";
import { useEffect, useRef, useState } from "react";
import { getDetailedProfileInformation } from "../../services/influencer/InfluencerDetails";
import styles from "../../styles/theme/pageStyling/ProfileDetails.module.css";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import { Avatar, Box, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import utils from "../../utils/utils";
import ContactForm from "./pages/ContactForm";
import BasicInformation from "./pages/BasicInformation";
import EducationInformation from "./pages/EducationDetails";
import WorkInformation from "./pages/WorkDetails";

const ViewDetailedProfileDialog = ({ influencerAccount, platform, viewProfile, closeViewProfileDialog }) => {
  const [scroll, setScroll] = useState("paper");
  const [loading, setLoading] = useState(false);
  const [basicDetails, setBasicDetails] = useState({});
  const [socialProfileInfo, setSocialProfileInfo] = useState([]);
  const [contactInfo, setContactInfo] = useState({});
  const [educationInfo, setEducationInfo] = useState({});
  const [employmentInfo, setEmploymentInfo] = useState({});
  const [interestInfo, setInterestInfo] = useState({});
  const descriptionElementRef = useRef(null);

  const handleClose = () => {
    closeViewProfileDialog(false);
  };
  useEffect(() => {
    if (viewProfile) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      getProfileInformation();
    }
  }, [influencerAccount, platform]);

  const getProfileInformation = () => {
    setLoading(true);
    getDetailedProfileInformation(influencerAccount.id, platform, influencerAccount.type).then((result) => {
      if (result && result[0] != null) setSocialProfileInfo(result[0]);
      if (result && result[1] != null) setBasicDetails(result[1]);
      if (result && result[2] != null) setContactInfo(result[2]);
      if (result && result[3] != null) setEducationInfo(result[3]);
      if (result && result[4] != null) setEmploymentInfo(result[4]);
      if (result && result[5] != null) setInterestInfo(result[5]);
      setLoading(false);
    });
  };

  return (
    !loading && (
      <Dialog
        open={viewProfile}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Avatar
                sx={{ width: 56, height: 56 }}
                alt={socialProfileInfo.length > 0 && socialProfileInfo[0]?.name}
                src={influencerAccount.photoUrl}
              />
            </Grid>
            <Grid item xs={9} className={styles.profileHeading}>
              {influencerAccount.name}
              <br />
              <span className={styles.username}>@ {socialProfileInfo.length > 0 && socialProfileInfo[0].username}</span>
              <br />
              <span className={styles.location}>
                <LocationOn /> {influencerAccount.location === "0" ? "-" : influencerAccount.location}
              </span>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={textColor[platform?.toLowerCase()]}>{snapshotHeaderIcons[platform?.toLowerCase()]}</Typography>
            </Grid>
            <Grid item xs={12} className={styles.aboutMeHeader}>
              About Me
            </Grid>
            <Grid item xs={12} className={styles.aboutMeContent}>
              {socialProfileInfo.length > 0 && socialProfileInfo[0].bio}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                bgcolor: "background.paper",
                color: "text.secondary",
                "& svg": {
                  m: 1.5,
                },
                "& hr": {
                  mx: 0.5,
                },
              }}
            >
              <Grid container>
                <Grid item xs={3}>
                  <Card>
                    <CardContent>
                      <h6 className={styles.cardHeading}>Followers</h6>
                      <h4 className={styles.cardBody}>{utils.convertToInternationalNumberSystem(influencerAccount.followers)}</h4>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card>
                    <CardContent>
                      <h6 className={styles.cardHeading}>Friends</h6>
                      <h4 className={styles.cardBody}>
                        {utils.convertToInternationalNumberSystem(
                          socialProfileInfo.length > 0 && socialProfileInfo[0].friends_count
                        )}
                      </h4>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card>
                    <CardContent>
                      <h6 className={styles.cardHeading}>Total Eng.</h6>
                      <h4 className={styles.cardBody}>
                        {utils.convertToInternationalNumberSystem(influencerAccount.engagement)}
                      </h4>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card>
                    <CardContent>
                      <h6 className={styles.cardHeading}>Eng. Rate</h6>
                      <h4 className={styles.cardBody}>{influencerAccount.engagementrate}%</h4>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <br />
            <BasicInformation basicDetails={basicDetails} />
            <br />
            <ContactForm influencerAccount={influencerAccount} platform={platform} contactDetails={contactInfo} />
            <br />
            <EducationInformation influencerAccount={influencerAccount} platform={platform} educationInfo={educationInfo} />
            <br />
            <WorkInformation influencerAccount={influencerAccount} platform={platform} employmentInfo={employmentInfo} />
          </DialogContentText>
        </DialogContent>
        <Grid container>
          <Grid item xs={10} style={{ padding: "10px" }}>
            <i>*As shared by influencer on gazelle</i>
          </Grid>
          <Grid item xs={2}>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    )
  );
};
ViewDetailedProfileDialog.propTypes = {
  influencerAccount: object.isRequired,
  platform: string.isRequired,
  viewProfile: bool.isRequired,
  closeViewProfileDialog: func.isRequired,
};
export default ViewDetailedProfileDialog;
