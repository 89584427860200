import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import MyPaper from "../general/MyPaper";
import {
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import utils from "../../utils/utils";

let labels = [];
export class Sentiment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveAs: "",
      currentChart: null,
      data: {
        labels: labels,
        datasets: [],
      },
    };
  }

  render() {
    return (
      <MyPaper sx={{ marginTop: "20px" }}>
        {this.props.loading ? (
          <>
            <CircularProgress
              style={this.props.parentStyle.centerElement}
              color="success"
            />
            <div
              style={{
                ...this.props.parentStyle.centerElement,
                ...this.props.parentStyle.fetchingDetails,
              }}
            >
              Fetching details...
            </div>
          </>
        ) : (
          <>
            <Stack direction="row" justifyContent="end">
              <Typography variant="h4" sx={this.props.parentStyle.typography}>
                Sentiment Analysis
              </Typography>
              <div id="save-sentiment-analysis">
                <FormControl size="small" sx={{ marginRight: "5px" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Save As
                  </InputLabel>
                  <Select
                    sx={{ minWidth: "7vw" }}
                    style={{ color: "black" }}
                    labelId="demo-simple-select-label"
                    id="save-sentiment-analysis-select"
                    value={this.state.saveAs}
                    label="Save As"
                    onChange={(e) => utils.handleSaveAs(e, this.state.currentChart, "Sentiment", "sentiment-analysis")}
                    variant="outlined"
                    autoWidth
                  >
                    <MenuItem value={"png"}>PNG</MenuItem>
                    <MenuItem value={"jpeg"}>JPEG</MenuItem>
                    <MenuItem value={"pdf"}>PDF</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Stack>
            <div style={{ height: "50vh", paddingBottom: "20px" }}>
              {this.props.data.labels.length > 0 ? (
                <Line
                  id="Sentiment"
                  options={this.props.chartOptions}
                  data={this.props.data}
                  ref={(chart) => (this.state.currentChart = chart)}
                />
              ) : (
                <div style={this.props.parentStyle.noData}>
                  No Records Found
                </div>
              )}
            </div>
            
          </>
        )}
      </MyPaper>
    );
  }
}

export default Sentiment;
