import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { statusToNum } from "../../components/campaignAnalysis/snapshot";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import { changeCampaignStatus } from "../../services/campaign/CampaignDetails";
import { Edit, Archive, Visibility } from "@mui/icons-material";
import { grey } from '@mui/material/colors';

const CampaignActions = ({ name, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateCampaignStatus = async (id, status) => {
    dispatch(
      openSnackbar({
        message: "Please wait... we are refreshing your campaign statuses",
        severity: "info",
      })
    );

    const res = await changeCampaignStatus(id, status);
    navigate("/campaigns/archived?platform=oryx");
    dispatch(
      openSnackbar({
        message: "Campaign Archive Successfully",
        severity: "success",
      })
    );
  };

  return (
    <Box
      mt={5}
      ml={2}
      sx={{
        width: "100%",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={10.5}>
          <Typography variant="h2">{name}</Typography>
        </Grid>
        {/* <Grid item>
          <Tooltip title="Edit this Campaign">
            <Button
              onClick={() => navigate(`/campaigns/edit?id=${id}`)}
              sx={{
                borderRadius: '50%',
                minWidth: '40px',
                width: '40px',
                height: '40px',
                padding: '6px',
                backgroundColor: grey[400],
                '&:hover': {
                  backgroundColor: grey[700], // Darker grey on hover
                }
              }}
            >
              <Edit sx={{ fontSize: "20px", color: "white" }} />
            </Button>
          </Tooltip>
        </Grid> */}
         <Grid item >
          <Tooltip title="View Campaign Details">
            <Button
              onClick={() => navigate(`/campaigns/viewdetails?id=${id}`)}
              sx={{
                borderRadius: '50%',
                minWidth: '40px',
                width: '40px',
                height: '40px',
                padding: '6px',
                backgroundColor: grey[400],
                '&:hover': {
                  backgroundColor: grey[700], // Darker grey on hover
                }
              }}
            >
              <Visibility sx={{ fontSize: "20px", color: "white" }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item >
          <Tooltip title="Archive this Campaign">
            <Button
              onClick={() => {
                if (window.confirm("Are you sure you want to archive the campaign?\n\nThis will archive related lists too.")) {
                  updateCampaignStatus(+id, statusToNum.Archived); // to archive from Paused or Completed
                }
              }}
              sx={{
                borderRadius: '50%',
                minWidth: '40px',
                width: '40px',
                height: '40px',
                padding: '6px',
                backgroundColor: grey[400],
                '&:hover': {
                  backgroundColor: grey[700], // Darker grey on hover
                }
              }}
            >
              <Archive sx={{ fontSize: "20px", color: "white" }} />
            </Button>
          </Tooltip>
        </Grid>
       
      </Grid>
    </Box>
  );
};

export default CampaignActions;
