import {
  Button,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { socialLoginButtons as buttons } from '../../services/auth/init';

const SocialButton = styled(Button)({
  height: '50px',
  margin: '8px 0px !important',
  width: '350px',
  maxWidth: '95%',
  textTransform: 'none !important'
});

const SignIn = () => {
  const [socialMedias] = useState(null);


  return (
    <>
      <Helmet>
        <title>Sign In - AIFluence</title>
      </Helmet>
      { <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    fontSize: '20px !important',
                    margin: '30px 0px !important',
                    fontWeight: '200 !important'
                  }}
                >
                  Continue with Google
                </Typography>
      }

      {
        Object.keys(buttons).map((key) => {
          if (socialMedias === null || socialMedias.includes(key)) {
            const SocialLoginBtn = buttons[key].wrapper;
            if ('wrapper' in buttons[key]) {
              return (
                <SocialLoginBtn>
                  <SocialButton
                    variant="contained"
                    color="primary"
                    startIcon={buttons[key].icon}
                    sx={buttons[key].style}
                  >
                    {buttons[key].text}
                  </SocialButton>
                </SocialLoginBtn>
              );
            }

            return (
              <SocialButton
                variant="contained"
                color="primary"
                startIcon={buttons[key].icon}
                onClick={buttons[key].handler}
                sx={buttons[key].style}
              >
                {buttons[key].text}
              </SocialButton>
            );
          }

          return null;
        })
      }
    </>
  );
};

export default SignIn;
