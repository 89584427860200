import { Email, Phone } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { object, string } from "prop-types";
import styles from "../../../styles/theme/pageStyling/ProfileDetails.module.css";

const ContactForm = ({ influencerAccount, platform, contactDetails }) => {
  const createContactDetails = (phoneArray) => {
    let contactString = "";
    phoneArray &&
      phoneArray.forEach((number) => {
        if (number.dial_code) {
          contactString += `+${number.dial_code} ${number.phone_number}`;
        } else {
          contactString += number.phone_number;
        }
        contactString += ", ";
      });
    return contactString.length > 0 ? contactString.slice(0, contactString.length - 2) : "-";
  };

  const createEmailDetails = (emailArray) => {
    let contactString = "";
    emailArray &&
      emailArray.forEach((email) => {
        contactString += email;
        contactString += ", ";
      });
    return contactString.length > 0 ? contactString.slice(0, contactString.length - 2) : "-";
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={styles.aboutMeHeader}>
        Contact Details{" "}
      </Grid>
      <Grid item xs={12} className={styles.aboutMeContent}>
        <Grid container spacing={1}>
          <Grid item xs={1} className={styles}>
            <Phone />
          </Grid>
          <Grid item xs={11}>
            {contactDetails && createContactDetails(contactDetails.Phone)}
          </Grid>
          <Grid item xs={1} className={styles}>
            <Email />
          </Grid>
          <Grid item xs={9}>
            {contactDetails && createEmailDetails(contactDetails.Email)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
ContactForm.propTypes = {
  influencerAccount: object.isRequired,
  platform: string.isRequired,
  contactDetails: object.isRequired,
};
export default ContactForm;
