import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { array, bool, func, object, string } from "prop-types";
import { useEffect, useRef, useState } from "react";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import { CardMedia, Chip, createStyles, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { snapshotHeaderIcons, textColor } from "../campaignAnalysis/snapshot";
import {
  deleteContactForInfluencer,
  deleteEmailForInfluencer,
  getContactDetails,
  updateContactForInfluencer,
} from "../../services/influencer/InfluencerDetails";
import MuiPhoneNumber from "material-ui-phone-number";
import { PROFILE_IMG } from "../../config/main.json";
import { makeStyles } from "@mui/styles";
import { Add } from "@material-ui/icons";
import { editOnDetailsChange } from "../../services/campaign/CampaignDetails";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      height: 48,
    },
  })
);

const ViewContactDialog = ({
  influencerAccount,
  viewContactDialog,
  closeViewUpdateDialog,
  platform,
  influencerAccounts,
  setInfluencerAccountList,
}) => {
  const [scroll, setScroll] = useState("paper");
  const [email, setEmail] = useState([]);
  const [newEmail, setNewEmail] = useState([]);
  const [delEmail, setDelEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [newPhone, setNewPhone] = useState([]);
  const [delPhone, setDelPhone] = useState([]);
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState({
    phone_number: "",
    dial_code: "",
  });
  const [original, setOriginal] = useState({
    email: [],
    phone: [],
  });
  const [disable, setDisable] = useState(true);

  const descriptionElementRef = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (viewContactDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      retrieveInfluencerInformation();
    }
  }, []);

  const retrieveInfluencerInformation = () => {
    const originalData = original;
    if (influencerAccount?.email?.length > 0) {
      setEmail([influencerAccount.email]);
      originalData.email = [influencerAccount.email];
    }
    original.phone = [];
    if (influencerAccount?.phone?.length > 0) {
      setPhone([influencerAccount.phone]);
      originalData.phone = [influencerAccount.phone];
    }
    getContactDetails(influencerAccount.id).then((contact_response) => {
      if (contact_response) {
        let email = null;
        let phone = null;
        if (contact_response[0].Email) {
          email = contact_response[0].Email;
          originalData.email = contact_response[0].Email;
        }
        if (contact_response[0].Phone) {
          var contact_array = [];
          contact_response[0].Phone.forEach((phone_number) => {
            contact_array.push(phone_number);
            originalData.phone.push(phone_number);
          });
          phone = contact_array;
        }
        setOriginal(originalData);
        if (email !== null) setEmail(email);
        if (phone !== null) setPhone(phone);
      }
    });
    setOriginal(originalData);
  };

  const handleClose = () => {
    closeViewUpdateDialog(false);
  };
  const addEmail = () => {
    const emailArray = [...email];
    emailArray.push(emailValue);
    if (influencerAccount.type === "direct" && emailArray.length > 1) {
      dispatch(
        openSnackbar({
          message: "You can just add one email for direct influencer",
          severity: "error",
        })
      );
    } else {
      setEmail(emailArray);
    }
    setEmailValue("");
  };

  const addPhone = () => {
    const phoneArray = [...phone];
    phoneArray.push(phoneValue);
    if (influencerAccount.type === "direct" && phoneArray.length > 1) {
      dispatch(
        openSnackbar({
          message: "You can just add one email for direct influencer",
          severity: "error",
        })
      );
    } else {
      setPhone(phoneArray);
    }

    setPhoneValue({
      phone_number: "",
      dial_code: "",
    });
  };

  const updateProfile = () => {
    if (delEmail.length > 0 || delPhone.length > 0) {
      if (!window.confirm("Are you sure you want to edit email/phone number?")) {
        return;
      }
    }

    // Delete emails
    delEmail.forEach((email) => {
      deleteEmailForInfluencer(influencerAccount.id, [email], influencerAccount.type);
    });

    // Delete phone numbers
    delPhone.forEach((phone) => {
      deleteContactForInfluencer(influencerAccount.id, [phone], influencerAccount.type);
    });

    updateInfluencerList("email");
    updateInfluencerList("phone");

    // Update name, username and add email, phone numbers if any
    updateContactForInfluencer(influencerAccount.id, influencerAccount.type, platform?.toLowerCase(), newPhone, newEmail).then(
      (result) => {
        if (result) {
          dispatch(
            openSnackbar({
              message:
                result.failed === false ? "Updated influencer's profile successfully" : "Unable to update influencer's profile",
              severity: result.failed === false ? "success" : "error",
            })
          );
          updateInfluencerList("name");
          updateInfluencerList("profile_url");
          const updatedPaylod = {
            id: influencerAccount.id,
            contact: newPhone,
            email: newEmail,
          };
          if (original.phone.length === newPhone.length) delete updatedPaylod.contact;
          if (original.email.length === email.length) delete updatedPaylod.email;
          if (Object.keys(updatedPaylod).length > 0) editOnDetailsChange({ id: influencerAccount.id, ...updatedPaylod });
        }
      }
    );
  };

  const updateInfluencerList = (attribute) => {
    if (influencerAccounts && influencerAccounts.length > 0) {
      const accountList = [];
      influencerAccounts.forEach((account) => {
        if (account.id === influencerAccount.id) {
          if (attribute === "email") {
            account.email = "";
          } else if (attribute === "phone") {
            account.phone = "";
          }
        }
        accountList.push(account);
      });
      setInfluencerAccountList(accountList);
      handleClose();
    }
  };

  const handleEmailDelete = (event) => {
    setEmail((chips) => chips.filter((chip) => chip !== event));
  };

  const handlePhoneDelete = (event) => {
    setPhone((chips) => chips.filter((chip) => chip.phone_number !== event.phone_number));
  };

  // Function to decide if update should be enabled or not by mathing existing values to original values.
  const checkWithOriginal = () => {
    let disable = true;

    // Extracting new and deleted emails
    const newEmail = email.filter((value) => !original.email.includes(value));
    const delEmail = original.email.filter((value) => !email.includes(value));
    setNewEmail(newEmail);
    setDelEmail(delEmail);
    if (email.length !== original.email.length || newEmail.length > 0 || delEmail.length > 0) {
      disable = false;
    }

    // Extracting new and deleted phone numbers
    const comparePhone = (a, b) => a.dial_code === b.dial_code && a.phone_number === b.phone_number;
    const newPhone = phone.filter((value) => !original.phone.some((v) => comparePhone(value, v)));
    const delPhone = original.phone.filter((value) => !phone.some((v) => comparePhone(value, v)));
    setNewPhone(newPhone);
    setDelPhone(delPhone);
    if (phone.length !== original.phone.length || newPhone.length > 0 || delPhone.length > 0) {
      disable = false;
    }
    setDisable(disable);
  };

  useEffect(() => {
    checkWithOriginal();
  }, [original, email, phone]);

  return (
    <Dialog
      open={viewContactDialog}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <CardMedia
              component="img"
              image={influencerAccount.photoUrl ? influencerAccount.photoUrl : PROFILE_IMG}
              alt={influencerAccount.name}
            />
          </Grid>
          <Grid item xs={9}>
            <span className={styles.cardBody}>{influencerAccount.name}</span>
            <br />
            <span className={styles.cardHeading}>Edit Contact Details</span>
          </Grid>
          <Grid item xs={1}>
            <Typography sx={textColor[platform?.toLowerCase()]}>{snapshotHeaderIcons[platform?.toLowerCase()]}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {influencerAccount.type !== "direct" && <h6>Emails associated with the influencer</h6>}
              {influencerAccount.type === "direct" && <h6>Email associated with the direct influencer (Only 1)</h6>}
              <Grid container spacing={1}>
                {email.length > 0 &&
                  email.map((data) => {
                    return (
                      <Tooltip title={data}>
                        <Grid item xs={4}>
                          <div key={data}>
                            <Chip label={data} onDelete={() => handleEmailDelete(data)} />
                          </div>
                        </Grid>
                      </Tooltip>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={9.5}>
              <TextField
                id="outlined-basic"
                label="Add a new email of the influencer"
                variant="outlined"
                value={emailValue}
                onChange={(event) => setEmailValue(event.target.value)}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                InputProps={{
                  className: classes.input,
                }}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={addEmail}
                className={classes.input}
                startIcon={<Add />}
                disabled={influencerAccount.type === "direct" && email.length >= 1}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={12}>
              {influencerAccount.type !== "direct" && <h6>Phone numbers associated with the influencer</h6>}
              {influencerAccount.type === "direct" && <h6>Phone number associated with the direct influencer (Only 1)</h6>}
              <Grid container spacing={1}>
                {phone.length > 0 &&
                  phone.map((data) => {
                    return (
                      <Grid item xs={4}>
                        <div key={data.phone_number}>
                          <Chip
                            label={data.dial_code ? "+" + data.dial_code + "-" + data.phone_number : "+" + data}
                            onDelete={() => handlePhoneDelete(data)}
                          />
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={9.5}>
              <MuiPhoneNumber
                defaultCountry={"us"}
                variant="outlined"
                name="phone_number[]"
                label="Add a new contact number of the influencer"
                sx={{ width: "100%" }}
                value={`+${phoneValue.dial_code}${phoneValue.phone_number}`}
                onChange={(phoneNumber, country) => {
                  let phoneNr = phoneNumber.replace(/[^0-9]/g, "");
                  phoneNr = phoneNr.slice(country.dialCode.length);
                  setPhoneValue({
                    phone_number: phoneNr,
                    dial_code: country.dialCode,
                  });
                }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={addPhone}
                className={classes.input}
                startIcon={<Add />}
                disabled={influencerAccount.type === "direct" && phone.length >= 1}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} className={classes.input}>
          Cancel
        </Button>
        <Button variant="contained" onClick={updateProfile} className={classes.input} disabled={disable}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ViewContactDialog.propTypes = {
  influencerAccount: object.isRequired,
  viewContactDialog: bool.isRequired,
  closeViewUpdateDialog: func.isRequired,
  platform: string.isRequired,
  influencerAccounts: array.isRequired,
  setInfluencerAccountList: func,
};

export default ViewContactDialog;
