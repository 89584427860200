import axios from "axios";
import { IQHOST, API_HOST, PLATFORM_NAME } from "../../../../config/main.json";
import KeyEnum from "../../KeyEnum";

const sortField = {
  instagram: "engagements",
  tiktok: "followers",
  youtube: "followers",
};

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const getLocationData = async (searchKey) => {
  try {
    const res = await axios.get(
      API_HOST + "/discovery/getlocation/?location=" + searchKey
    );
    if (res?.status === 200 && res?.data?.length > 0) {
      const newLocations = res?.data?.map((loc) => {
        let locData = { id: loc["id"], name: loc["name"] };
        return locData;
      });
      return newLocations;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const getLanguageData = async (searchKey) => {
  try {
    const res = await axios.get(
      API_HOST + "/discovery/getIqLangauages?key=" + searchKey
    );
    if (res?.status === 200 && res?.data?.length > 0) {
      const newLang = res?.data?.map((lang) => {
        let langData = { id: lang["code"], name: lang["name"] };
        return langData;
      });
      return newLang;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const getBrandData = async (searchKey) => {
  try {
    const res = await axios.get(
      API_HOST + "/discovery/getBrandsInt/?key=" + searchKey + "&type=brands"
    );
    if (res?.status === 200 && res?.data?.length > 0) {
      const newBrands = res?.data?.map((brd, index) => {
        let brdData = { id: brd["id"], name: brd["name"] };
        return brdData;
      });
      return newBrands;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const getInterestData = async (searchKey) => {
  try {
    const res = await axios.get(
      API_HOST + "/discovery/getBrandsInt/?key=" + searchKey + "&type=interests"
    );
    if (res?.status === 200 && res?.data?.length > 0) {
      const newBrands = res?.data?.map((brand, index) => {
        let brandData = { id: brand["id"], name: brand["name"] };
        return brandData;
      });
      return newBrands;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const getLookalikeData = async (searchKey, platform, searchType) => {
  try {
    const res = await axios.get(
      IQHOST +
        "dict/users/?q=" +
        searchKey +
        "&type=" +
        searchType +
        "&platform=" +
        platform +
        "&limit=5"
    );
    let newLookalikes = [];
    res.data.data.map((look, index) => {
      if (look["username"] != undefined || look["fullname"] != undefined) {
        let lookData = {
          username: look["username"] ? look["username"] : look["handle"],
          name: look["fullname"],
          pic: look["picture"],
          followers: look["followers"],
        };
        newLookalikes.push(lookData);
      }
    });
    return newLookalikes;
  } catch (err) {
    console.log(err);
  }
};

const getDataByFilters = (
  payload,
  sortCol,
  selectedPlatform,
  tablePageNo,
  tableRowsPerPage,
  customCols,
  exportDetails = {},
  exportLimit
) => {
  const optionAxios = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  var reqPayload = {
    request: {
      audience_source: payload["aud_audience_type"],
      sort: {
        field: sortCol?.field
          ? KeyEnum[sortCol?.field]
          : payload["sort"] || sortField[selectedPlatform],
        ...(sortCol?.id && { id: sortCol?.id }),
        direction: "desc",
      },
      filter: {
        ads_brands: payload["inf_ads_brands"],
        age: payload["inf_age"],
        audience_age_range: payload["aud_age"],
        audience_brand: payload["aud_brand"],
        audience_brand_category: payload["aud_brand_category"],
        audience_gender: payload["aud_gender"],
        audience_geo: payload["aud_location"],
        audience_lang: payload["aud_language"],
        audience_race: payload["aud_ethinicity"],
        brand: payload["inf_brand"],
        brand_category: payload["inf_brand_category"],
        engagement_rate: {
          operator: "gte",
          value: payload["inf_er_from"],
        },
        engagements: {
          left_number: payload["inf_engagements_from"],
          right_number: payload["inf_engagements_to"],
        },
        followers: {
          left_number: payload["inf_followers_from"],
          right_number: payload["inf_followers_to"],
        },
        reels_plays: {
          left_number: payload["inf_reel_plays_from"],
          right_number: payload["inf_reel_play_to"],
        },
        gender: payload["inf_gender"],
        geo: payload["inf_location"],
        keywords: payload["inf_keyword"],
        lang: payload["inf_language"],
        last_posted: payload["aud_lastPosted"],
        relevance: payload["inf_lookalikes"],
        text: payload["inf_bio"],
        text_tags: payload["inf_mention"],
        views: {
          left_number: payload["inf_views_from"],
          right_number: payload["inf_views_to"],
        },
        with_contact: payload["aud_contact"],
        account_type: payload["inf_accountType"],
        audience_relevance: payload["aud_lookalikes"],
        followers_growth: payload["inf_followers_growth"],
        is_verified: payload["inf_verified"],
        is_hidden: payload["inf_hidden"],
        audience_credibility_class: payload["inf_credible"],
        only: payload["inf_known"],
        has_audience_data: payload["inf_has_audience_data"],
      },
      paging: {
        skip: tablePageNo * tableRowsPerPage,
        limit: exportLimit ? +exportLimit : tableRowsPerPage,
      },
    },
    custom_cols: customCols,
    ...exportDetails,
    notificationMetadata: {
      userId: payload["userId"],
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "1",
      notificationActionId: "1",
      notificationCategoryId: "3",
      generatedBy: payload["generatedBy"],
      priority: "high",
      consumedBy: "oryx"
    }
  };

  return axios.post(
    API_HOST + "/discovery/search/platform/" + selectedPlatform,
    reqPayload,
    optionAxios
  );
};

const getInfluencerContactLinks = (scid, platform) => {
  return axios.post(API_HOST + "/discovery/profile/contact/", {
    scid,
    platform,
    companyId: 0,
  });
};

const getTwitterDataByFilters = (query, tablePageNo, tableRowsPerPage) => {
  const optionAxios = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  var reqPayload = {
    keyWord: query["inf_keyword"] || "global",
    page: tablePageNo + 1,
    count: tableRowsPerPage,
  };

  return axios.post(
    API_HOST + "/discovery/search/twitter",
    reqPayload,
    optionAxios
  );
};

const exportList = (payload) =>
  axios.post(API_HOST + "/discovery/export/list", payload, optionAxios);

export {
  getLocationData,
  getLanguageData,
  getBrandData,
  getInterestData,
  getLookalikeData,
  getDataByFilters,
  getInfluencerContactLinks,
  getTwitterDataByFilters,
  exportList,
};
