const rolesData = [
  {
    value: "super-user",
    title: "Super User",
    description:
      "All Manager privileges, plus the ability to add and remove users",
  },
  {
    value: "manager",
    title: "Manager",
    description: "Managers can additionally create campaigns and assign them to members.",
  },
  {
    value: "member",
    title: "Member",
    description: "Members can view the data and manage posts and influencers.",
  },
];

export { rolesData };
