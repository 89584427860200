import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import discoveryUtils from "../../../utils/discoveryUtils.js";
import StyledMenu from "../StyledMenu";
import utils from '../../../utils/utils';
import CustomFormControlLabel from "./common/CustomFormControlLabel";

import {
  FormControl,
  Grid,
  RadioGroup,
  Tooltip,
  Radio,
  Box,
} from "@mui/material";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const Ethinicity = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
  isInfFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [range, setRange] = useState(0.3);
  const [audEthinicity] = useState("ANY");
  const [displayStyle, setDisplayStyle] = useState(false);
  const [mapEthinicity] = useState({
    Black: "African American",
    Asian: "Asian",
    White: "White / Caucasian",
    Hispanic: "Hispanic",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRange = (event) => {
    let newPayload = payload;
    newPayload["aud_ethinicity"].weight = event.target.value;
    setPayload(newPayload);
    setRange(event.target.value);
  };

  const handleChange = (event, type) => {
    let newPayload = payload;
    let ethinicity = "";
    let weight = range;
    let newEthinicity = [];
    if (event.target.value !== "ANY") {
      ethinicity = event.target.value;
      setDisplayStyle(true);
      let ethinicityPayload = { code: ethinicity, weight: weight };
      newPayload["aud_ethinicity"] = ethinicityPayload;
      newEthinicity.push(ethinicity);
      customCols = utils.addObj(customCols, "aud_ethinicity");
      customCols[0]["aud_ethinicity"] = newEthinicity;
      allFiltersData = utils.addObj(allFiltersData, "aud_ethinicity");
      let ethinicityFilter = {
        code: mapEthinicity[ethinicity],
        weight: weight,
      };
      allFiltersData[0]["aud_ethinicity"][0] = ethinicityFilter;
    } else {
      delete newPayload[type + "_ethinicity"];
      delete customCols[0][type + "_ethinicity"];
      delete allFiltersData[0][type + "_ethinicity"];
      setDisplayStyle(false);
    }
    setSelectedCol(customCols);
    setCustomCols(customCols);
    setAllFiltersData(allFiltersData);
    setPayload(newPayload);
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={FilterTooltips.ethinicity}>
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                textTransform: "none",
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              fullWidth
              size="large"
              onClick={handleClick}
              endIcon={open ? <ArrowDropUp fontSize="large" /> : <ArrowDropDown fontSize="large" />}
            >
              Ethinicity
            </Button>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1} style={{ height: "auto" }}>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControl sx={{ m: 1 }}>Ethinicity</FormControl>
                  </Grid>
                </Grid>
                <FormControl sx={{ m: 1 }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="any"
                    name="radio-buttons-group"
                    onChange={(e) => handleChange(e, "aud")}
                    size="small"
                    value={
                      typeof allFiltersData[0]["aud_ethinicity"] === "undefined"
                        ? audEthinicity
                        : utils.getKeyByValue(
                            mapEthinicity,
                            allFiltersData[0]["aud_ethinicity"][0]["code"]
                          )
                    }
                  >
                    <CustomFormControlLabel
                      value="ANY"
                      control={<Radio size="small" />}
                      label="Any"
                    />
                    <CustomFormControlLabel
                      value="Black"
                      control={<Radio size="small" />}
                      label="African Descent"
                    />
                    <CustomFormControlLabel
                      value="Asian"
                      control={<Radio size="small" />}
                      label="Asian"
                    />
                    <CustomFormControlLabel
                      value="White"
                      control={<Radio size="small" />}
                      label="White / Caucasian"
                    />
                    <CustomFormControlLabel
                      value="Hispanic"
                      control={<Radio size="small" />}
                      label="Hispanic American"
                    />
                  </RadioGroup>
                  {displayStyle && (
                    <Box sx={{ my: "1rem" }}>
                      {discoveryUtils.getRangesFilter(range, "", handleRange)}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Ethinicity.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Ethinicity;
