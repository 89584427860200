import { BadgeTwoTone, InfoOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyPaper from "../../components/general/MyPaper";
import TabPanel from "../../components/general/TabPanel";
import { API_HOST } from "../../config/main.json";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DraftEditor from "../../components/general/DraftEditor";
import utils from "../../utils/utils";
import CampaignTabs from "./CampaignTabs";
import CampaignActions from "./CampaignActions";

const EditTemplate = () => {
  const [id, setId] = useState(0);
  const [cid, setCid] = useState(0);
  const [contractInputFieldsData, setContractInputFieldsData] = useState([]);
  const [templateName, setTemplateName] = useState(null);
  const [description, setDescription] = useState(null);
  const [editor, setEditor] = useState(null);
  const [contractInputFields, setContractInputFields] = useState("");
  const [kycRequired, setKycRequired] = useState(false);
  const [backdropShow, setBackdropShow] = useState(false);
  const [campaignName, setCampaignName] = useState("");

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const templateId = params.get("id");
    const campId = params.get("cid");
    setCid(campId);
    setId(templateId);
    getTemplateData(templateId);
    getContractInputFields();
    getCampaignInfo(campId);
  }, []);

  const getTemplateData = (templateId) => {
    axios.get(API_HOST + "/contract/templateContent/" + templateId).then((res) => {
      const data = res.data[0];
      setTemplateName(data.title);
      setDescription(data.description);
      setEditor(data.content);
      setContractInputFields(data.inputFields);
      setKycRequired(data.kycRequired);
    });
  };

  const getContractInputFields = () => {
    axios.get(API_HOST + "/contract/inputs").then((res) => {
      setContractInputFieldsData(res.data);
    });
  };

  const getCampaignInfo = (id) => {
    utils.getCampaignData(id).then((data) => {
      setCampaignName(data.name);
    });
  };

  const handleTemplateNameChange = (e) => {
    const value = e.target.value;
    setTemplateName(value);
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
  };

  const handleInputFieldsChange = (e, value) => {
    const inputFields = value
      .map((v) => v.id)
      .sort()
      .join(",");
    setContractInputFields(inputFields);
  };

  const handleKycRequired = (e) => {
    setKycRequired(e.target.checked);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setBackdropShow(true);
    if (editor === null || editor.search('<span style="background-color') !== -1) {
      return;
    }

    const body = {};
    body["id"] = id;
    body["title"] = templateName;
    body["description"] = description;
    body["content"] = editor;
    body["inputFields"] = contractInputFields;
    body["kycRequired"] = kycRequired;

    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios.post(API_HOST + "/contract/template/edit", body, optionAxios).then((res) => {
      setTimeout(function () {
        window.location = "/campaigns/contracts/templates?id=" + cid;
      }, 500);
    });
  };

  const autocompleteData = contractInputFieldsData.filter((value) =>
    contractInputFields
      .split(",")
      .map((v) => +v)
      .includes(value.id)
  );

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: "column",
        }}
        open={backdropShow}
      >
        <CircularProgress color="inherit" />
        <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
      </Backdrop>

      <CampaignActions name={campaignName} id={cid} />
      <br />
      <CampaignTabs campId={cid} selectedTab={"6"} />

      <Box sx={{ m: 3 }}>
        <Tabs value={0} style={{ width: "200px", float: "left" }} variant="scrollable" scrollButtons="auto">
          <Tab label="Edit Template" id="template-details-tab-0" />
        </Tabs>
        <Stack direction="row" justifyContent="end">
          <Button variant="contained" component={Link} to={"/campaigns/contracts/templates?id=" + cid}>
            All Templates
          </Button>
        </Stack>
      </Box>
      <TabPanel index={0} name="template-imformation-tab" value={0}>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MyPaper>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} />
                  Basic Information
                </Typography>
                <Divider />

                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={templateName || ""}
                      onChange={handleTemplateNameChange}
                      input={<OutlinedInput label="Template Name *" id="demo-dialog-native" />}
                      label="Template Name"
                      sx={{ width: "100%" }}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={description || ""}
                      onChange={handleDescriptionChange}
                      input={<OutlinedInput label="Description *" id="demo-dialog-native" />}
                      label="Description"
                      sx={{ width: "100%" }}
                      required={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    {editor && <DraftEditor value={editor} setValue={setEditor} readOnly={false} />}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        label={
                          <>
                            <span>KYC is required before accepting the contract &nbsp;</span>
                            <IconButton>
                              <Tooltip
                                title={
                                  <span style={{ whiteSpace: "pre-line" }}>
                                    Selecting this will ensure that the influencer has uploaded a KYC document before proceeding
                                  </span>
                                }
                              >
                                <InfoOutlined fontSize="small" style={{ color: "#6b778c" }} />
                              </Tooltip>
                            </IconButton>
                          </>
                        }
                        control={<Checkbox checked={kycRequired} onChange={handleKycRequired} />}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </MyPaper>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Box sx={{ mt: 4 }}>
                <Button variant="contained" size="large" type="submit">
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </TabPanel>
    </>
  );
};

export default EditTemplate;
