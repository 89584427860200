/* 
based on this json
https://gist.githubusercontent.com/ssskip/5a94bfcd2835bf1dea52/raw/3b2e5355eb49336f0c6bc0060c05d927c2d1e004/ISO3166-1.alpha2.json

Read this wikipedia article for more details
https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes

*/

const countryCodes = {
  "AF": {
    name: "Afghanistan",
    color: "",
  },
  "AX": {
    name: "Aland Islands",
    color: "",
  },
  "AL": {
    name: "Albania",
    color: "",
  },
  "DZ": {
    name: "Algeria",
    color: "",
  },
  "AS": {
    name: "American Samoa",
    color: "",
  },
  "AD": {
    name: "Andorra",
    color: "",
  },
  "AO": {
    name: "Angola",
    color: "",
  },
  "AI": {
    name: "Anguilla",
    color: "",
  },
  "AQ": {
    name: "Antarctica",
    color: "",
  },
  "AG": {
    name: "Antigua And Barbuda",
    color: "",
  },
  "AR": {
    name: "Argentina",
    color: "",
  },
  "AM": {
    name: "Armenia",
    color: "",
  },
  "AW": {
    name: "Aruba",
    color: "",
  },
  "AU": {
    name: "Australia",
    color: "",
  },
  "AT": {
    name: "Austria",
    color: "",
  },
  "AZ": {
    name: "Azerbaijan",
    color: "",
  },
  "BS": {
    name: "Bahamas",
    color: "",
  },
  "BH": {
    name: "Bahrain",
    color: "",
  },
  "BD": {
    name: "Bangladesh",
    color: "",
  },
  "BB": {
    name: "Barbados",
    color: "",
  },
  "BY": {
    name: "Belarus",
    color: "",
  },
  "BE": {
    name: "Belgium",
    color: "",
  },
  "BZ": {
    name: "Belize",
    color: "",
  },
  "BJ": {
    name: "Benin",
    color: "",
  },
  "BM": {
    name: "Bermuda",
    color: "",
  },
  "BT": {
    name: "Bhutan",
    color: "",
  },
  "BO": {
    name: "Bolivia",
    color: "",
  },
  "BA": {
    name: "Bosnia And Herzegovina",
    color: "",
  },
  "BW": {
    name: "Botswana",
    color: "",
  },
  "BV": {
    name: "Bouvet Island",
    color: "",
  },
  "BR": {
    name: "Brazil",
    color: "",
  },
  "IO": {
    name: "British Indian Ocean Territory",
    color: "",
  },
  "BN": {
    name: "Brunei Darussalam",
    color: "",
  },
  "BG": {
    name: "Bulgaria",
    color: "",
  },
  "BF": {
    name: "Burkina Faso",
    color: "",
  },
  "BI": {
    name: "Burundi",
    color: "",
  },
  "KH": {
    name: "Cambodia",
    color: "",
  },
  "CM": {
    name: "Cameroon",
    color: "",
  },
  "CA": {
    name: "Canada",
    color: "",
  },
  "CV": {
    name: "Cape Verde",
    color: "",
  },
  "KY": {
    name: "Cayman Islands",
    color: "",
  },
  "CF": {
    name: "Central African Republic",
    color: "",
  },
  "TD": {
    name: "Chad",
    color: "",
  },
  "CL": {
    name: "Chile",
    color: "",
  },
  "CN": {
    name: "China",
    color: "",
  },
  "CX": {
    name: "Christmas Island",
    color: "",
  },
  "CC": {
    name: "Cocos (Keeling) Islands",
    color: "",
  },
  "CO": {
    name: "Colombia",
    color: "",
  },
  "KM": {
    name: "Comoros",
    color: "",
  },
  "CG": {
    name: "Congo",
    color: "",
  },
  "CD": {
    name: "Congo, Democratic Republic",
    color: "",
  },
  "CK": {
    name: "Cook Islands",
    color: "",
  },
  "CR": {
    name: "Costa Rica",
    color: "",
  },
  "CI": {
    name: "Cote D\"Ivoire",
    color: "",
  },
  "HR": {
    name: "Croatia",
    color: "",
  },
  "CU": {
    name: "Cuba",
    color: "",
  },
  "CY": {
    name: "Cyprus",
    color: "",
  },
  "CZ": {
    name: "Czech Republic",
    color: "",
  },
  "DK": {
    name: "Denmark",
    color: "",
  },
  "DJ": {
    name: "Djibouti",
    color: "",
  },
  "DM": {
    name: "Dominica",
    color: "",
  },
  "DO": {
    name: "Dominican Republic",
    color: "",
  },
  "EC": {
    name: "Ecuador",
    color: "",
  },
  "EG": {
    name: "Egypt",
    color: "",
  },
  "SV": {
    name: "El Salvador",
    color: "",
  },
  "GQ": {
    name: "Equatorial Guinea",
    color: "",
  },
  "ER": {
    name: "Eritrea",
    color: "",
  },
  "EE": {
    name: "Estonia",
    color: "",
  },
  "ET": {
    name: "Ethiopia",
    color: "",
  },
  "FK": {
    name: "Falkland Islands (Malvinas)",
    color: "",
  },
  "FO": {
    name: "Faroe Islands",
    color: "",
  },
  "FJ": {
    name: "Fiji",
    color: "",
  },
  "FI": {
    name: "Finland",
    color: "",
  },
  "FR": {
    name: "France",
    color: "",
  },
  "GF": {
    name: "French Guiana",
    color: "",
  },
  "PF": {
    name: "French Polynesia",
    color: "",
  },
  "TF": {
    name: "French Southern Territories",
    color: "",
  },
  "GA": {
    name: "Gabon",
    color: "",
  },
  "GM": {
    name: "Gambia",
    color: "",
  },
  "GE": {
    name: "Georgia",
    color: "",
  },
  "DE": {
    name: "Germany",
    color: "",
  },
  "GH": {
    name: "Ghana",
    color: "",
  },
  "GI": {
    name: "Gibraltar",
    color: "",
  },
  "GR": {
    name: "Greece",
    color: "",
  },
  "GL": {
    name: "Greenland",
    color: "",
  },
  "GD": {
    name: "Grenada",
    color: "",
  },
  "GP": {
    name: "Guadeloupe",
    color: "",
  },
  "GU": {
    name: "Guam",
    color: "",
  },
  "GT": {
    name: "Guatemala",
    color: "",
  },
  "GG": {
    name: "Guernsey",
    color: "",
  },
  "GN": {
    name: "Guinea",
    color: "",
  },
  "GW": {
    name: "Guinea-Bissau",
    color: "",
  },
  "GY": {
    name: "Guyana",
    color: "",
  },
  "HT": {
    name: "Haiti",
    color: "",
  },
  "HM": {
    name: "Heard Island & Mcdonald Islands",
    color: "",
  },
  "VA": {
    name: "Holy See (Vatican City State)",
    color: "",
  },
  "HN": {
    name: "Honduras",
    color: "",
  },
  "HK": {
    name: "Hong Kong",
    color: "",
  },
  "HU": {
    name: "Hungary",
    color: "",
  },
  "IS": {
    name: "Iceland",
    color: "",
  },
  "IN": {
    name: "India",
    color: "",
  },
  "ID": {
    name: "Indonesia",
    color: "",
  },
  "IR": {
    name: "Iran, Islamic Republic Of",
    color: "",
  },
  "IQ": {
    name: "Iraq",
    color: "",
  },
  "IE": {
    name: "Ireland",
    color: "",
  },
  "IM": {
    name: "Isle Of Man",
    color: "",
  },
  "IL": {
    name: "Israel",
    color: "",
  },
  "IT": {
    name: "Italy",
    color: "",
  },
  "JM": {
    name: "Jamaica",
    color: "",
  },
  "JP": {
    name: "Japan",
    color: "",
  },
  "JE": {
    name: "Jersey",
    color: "",
  },
  "JO": {
    name: "Jordan",
    color: "",
  },
  "KZ": {
    name: "Kazakhstan",
    color: "",
  },
  "KE": {
    name: "Kenya",
    color: "",
  },
  "KI": {
    name: "Kiribati",
    color: "",
  },
  "KR": {
    name: "Korea",
    color: "",
  },
  "KP": {
    name: "North Korea",
    color: "",
  },
  "KW": {
    name: "Kuwait",
    color: "",
  },
  "KG": {
    name: "Kyrgyzstan",
    color: "",
  },
  "LA": {
    name: "Lao People\"s Democratic Republic",
    color: "",
  },
  "LV": {
    name: "Latvia",
    color: "",
  },
  "LB": {
    name: "Lebanon",
    color: "",
  },
  "LS": {
    name: "Lesotho",
    color: "",
  },
  "LR": {
    name: "Liberia",
    color: "",
  },
  "LY": {
    name: "Libyan Arab Jamahiriya",
    color: "",
  },
  "LI": {
    name: "Liechtenstein",
    color: "",
  },
  "LT": {
    name: "Lithuania",
    color: "",
  },
  "LU": {
    name: "Luxembourg",
    color: "",
  },
  "MO": {
    name: "Macao",
    color: "",
  },
  "MK": {
    name: "Macedonia",
    color: "",
  },
  "MG": {
    name: "Madagascar",
    color: "",
  },
  "MW": {
    name: "Malawi",
    color: "",
  },
  "MY": {
    name: "Malaysia",
    color: "",
  },
  "MV": {
    name: "Maldives",
    color: "",
  },
  "ML": {
    name: "Mali",
    color: "",
  },
  "MT": {
    name: "Malta",
    color: "",
  },
  "MH": {
    name: "Marshall Islands",
    color: "",
  },
  "MQ": {
    name: "Martinique",
    color: "",
  },
  "MR": {
    name: "Mauritania",
    color: "",
  },
  "MU": {
    name: "Mauritius",
    color: "",
  },
  "YT": {
    name: "Mayotte",
    color: "",
  },
  "MX": {
    name: "Mexico",
    color: "",
  },
  "FM": {
    name: "Micronesia, Federated States Of",
    color: "",
  },
  "MD": {
    name: "Moldova",
    color: "",
  },
  "MC": {
    name: "Monaco",
    color: "",
  },
  "MN": {
    name: "Mongolia",
    color: "",
  },
  "ME": {
    name: "Montenegro",
    color: "",
  },
  "MS": {
    name: "Montserrat",
    color: "",
  },
  "MA": {
    name: "Morocco",
    color: "",
  },
  "MZ": {
    name: "Mozambique",
    color: "",
  },
  "MM": {
    name: "Myanmar",
    color: "",
  },
  "NA": {
    name: "Namibia",
    color: "",
  },
  "NR": {
    name: "Nauru",
    color: "",
  },
  "NP": {
    name: "Nepal",
    color: "",
  },
  "NL": {
    name: "Netherlands",
    color: "",
  },
  "AN": {
    name: "Netherlands Antilles",
    color: "",
  },
  "NC": {
    name: "New Caledonia",
    color: "",
  },
  "NZ": {
    name: "New Zealand",
    color: "",
  },
  "NI": {
    name: "Nicaragua",
    color: "",
  },
  "NE": {
    name: "Niger",
    color: "",
  },
  "NG": {
    name: "Nigeria",
    color: "",
  },
  "NU": {
    name: "Niue",
    color: "",
  },
  "NF": {
    name: "Norfolk Island",
    color: "",
  },
  "MP": {
    name: "Northern Mariana Islands",
    color: "",
  },
  "NO": {
    name: "Norway",
    color: "",
  },
  "OM": {
    name: "Oman",
    color: "",
  },
  "PK": {
    name: "Pakistan",
    color: "",
  },
  "PW": {
    name: "Palau",
    color: "",
  },
  "PS": {
    name: "Palestinian Territory, Occupied",
    color: "",
  },
  "PA": {
    name: "Panama",
    color: "",
  },
  "PG": {
    name: "Papua New Guinea",
    color: "",
  },
  "PY": {
    name: "Paraguay",
    color: "",
  },
  "PE": {
    name: "Peru",
    color: "",
  },
  "PH": {
    name: "Philippines",
    color: "",
  },
  "PN": {
    name: "Pitcairn",
    color: "",
  },
  "PL": {
    name: "Poland",
    color: "",
  },
  "PT": {
    name: "Portugal",
    color: "",
  },
  "PR": {
    name: "Puerto Rico",
    color: "",
  },
  "QA": {
    name: "Qatar",
    color: "",
  },
  "RE": {
    name: "Reunion",
    color: "",
  },
  "RO": {
    name: "Romania",
    color: "",
  },
  "RU": {
    name: "Russian Federation",
    color: "",
  },
  "RW": {
    name: "Rwanda",
    color: "",
  },
  "BL": {
    name: "Saint Barthelemy",
    color: "",
  },
  "SH": {
    name: "Saint Helena",
    color: "",
  },
  "KN": {
    name: "Saint Kitts And Nevis",
    color: "",
  },
  "LC": {
    name: "Saint Lucia",
    color: "",
  },
  "MF": {
    name: "Saint Martin",
    color: "",
  },
  "PM": {
    name: "Saint Pierre And Miquelon",
    color: "",
  },
  "VC": {
    name: "Saint Vincent And Grenadines",
    color: "",
  },
  "WS": {
    name: "Samoa",
    color: "",
  },
  "SM": {
    name: "San Marino",
    color: "",
  },
  "ST": {
    name: "Sao Tome And Principe",
    color: "",
  },
  "SA": {
    name: "Saudi Arabia",
    color: "",
  },
  "SN": {
    name: "Senegal",
    color: "",
  },
  "RS": {
    name: "Serbia",
    color: "",
  },
  "SC": {
    name: "Seychelles",
    color: "",
  },
  "SL": {
    name: "Sierra Leone",
    color: "",
  },
  "SG": {
    name: "Singapore",
    color: "",
  },
  "SK": {
    name: "Slovakia",
    color: "",
  },
  "SI": {
    name: "Slovenia",
    color: "",
  },
  "SB": {
    name: "Solomon Islands",
    color: "",
  },
  "SO": {
    name: "Somalia",
    color: "",
  },
  "ZA": {
    name: "South Africa",
    color: "",
  },
  "GS": {
    name: "South Georgia And Sandwich Isl.",
    color: "",
  },
  "ES": {
    name: "Spain",
    color: "",
  },
  "LK": {
    name: "Sri Lanka",
    color: "",
  },
  "SD": {
    name: "Sudan",
    color: "",
  },
  "SR": {
    name: "Suriname",
    color: "",
  },
  "SJ": {
    name: "Svalbard And Jan Mayen",
    color: "",
  },
  "SZ": {
    name: "Swaziland",
    color: "",
  },
  "SE": {
    name: "Sweden",
    color: "",
  },
  "CH": {
    name: "Switzerland",
    color: "",
  },
  "SY": {
    name: "Syrian Arab Republic",
    color: "",
  },
  "TW": {
    name: "Taiwan",
    color: "",
  },
  "TJ": {
    name: "Tajikistan",
    color: "",
  },
  "TZ": {
    name: "Tanzania",
    color: "",
  },
  "TH": {
    name: "Thailand",
    color: "",
  },
  "TL": {
    name: "Timor-Leste",
    color: "",
  },
  "TG": {
    name: "Togo",
    color: "",
  },
  "TK": {
    name: "Tokelau",
    color: "",
  },
  "TO": {
    name: "Tonga",
    color: "",
  },
  "TT": {
    name: "Trinidad And Tobago",
    color: "",
  },
  "TN": {
    name: "Tunisia",
    color: "",
  },
  "TR": {
    name: "Turkey",
    color: "",
  },
  "TM": {
    name: "Turkmenistan",
    color: "",
  },
  "TC": {
    name: "Turks And Caicos Islands",
    color: "",
  },
  "TV": {
    name: "Tuvalu",
    color: "",
  },
  "UG": {
    name: "Uganda",
    color: "",
  },
  "UA": {
    name: "Ukraine",
    color: "",
  },
  "AE": {
    name: "United Arab Emirates",
    color: "",
  },
  "GB": {
    name: "United Kingdom",
    color: "",
  },
  "US": {
    name: "United States",
    color: "",
  },
  "UM": {
    name: "United States Outlying Islands",
    color: "",
  },
  "UY": {
    name: "Uruguay",
    color: "",
  },
  "UZ": {
    name: "Uzbekistan",
    color: "",
  },
  "VU": {
    name: "Vanuatu",
    color: "",
  },
  "VE": {
    name: "Venezuela",
    color: "",
  },
  "VN": {
    name: "Vietnam",
    color: "",
  },
  "VG": {
    name: "Virgin Islands, British",
    color: "",
  },
  "VI": {
    name: "Virgin Islands, U.S.",
    color: "",
  },
  "WF": {
    name: "Wallis And Futuna",
    color: "",
  },
  "EH": {
    name: "Western Sahara",
    color: "",
  },
  "YE": {
    name: "Yemen",
    color: "",
  },
  "ZM": {
    name: "Zambia",
    color: "",
  },
  "ZW": {
    name: "Zimbabwe",
    color: "",
  },
}

export default countryCodes;