import axios from "axios";
import { API_HOST } from "../../config/main.json";

const optionAxios = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const addInfluencerToNewList = (payload) => {
  let msg = "";
  return axios.post(API_HOST + "/list/add", payload, optionAxios)
    .then(res => {
      if (res['data']['status'].includes('Duplicate entry')) {
        msg = "Influencer already exists in list!";
      } else if (res['data']['status'] !== "success") {
        msg = "Error...Please try again!";
      }
    })
    .catch((error) => {
      console.log(error);
      msg = "Something went wrong!!";
    })
    .finally(() => {
      return msg;
    });
}