import React, { useState, useEffect } from "react";
import TableRows from "./StoriesTableRows";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
function StoriesDynamicForm(props) {
  const [rowsData, setRowsData] = useState([]);
  useEffect(() => {
    setRowsData([]);
  }, [props.clearForm]); //this listens to changes in clear form prop

  const addTableRows = (e) => {
    e.preventDefault();
    const rowsInput = {
      url: "",
      likes: "",
      comments: "",
      shares: "",
      views: "",
      image: null,
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
    props.parentCallback(rowsData);
  };

  const handleUpload = (index, evnt) => {
    if (evnt.target.files.length !== 0) {
      const file = evnt.target.files[0];
      const rowsInput = [...rowsData];
      rowsInput[index]["image"] = file;
      setRowsData(rowsInput);
    } else {
      const rowsInput = [...rowsData];
      rowsInput[index]["image"] = null;
      setRowsData(rowsInput);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <table className="table">
            <thead>
              <tr>
                <th>Story URL</th>
                <th>Likes</th>
                <th>Comments</th>
                <th>Shares</th>
                <th>Views</th>
                <th>
                  <button className="btn" onClick={addTableRows}>
                    <AddCircleOutlineIcon color="primary" />
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRows
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
                handleUpload={handleUpload}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default StoriesDynamicForm;
