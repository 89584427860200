import React, { useState, useEffect, useRef } from 'react';
import { Stack, Button } from "@mui/material";
import { CircularProgress, Box, Tab, Tabs, Typography, FormControl, Tooltip, Snackbar, Grid } from "@mui/material";
import { getTimeZone } from "./../../utils/exportData";
import { getAccountsBrandList } from '../../services/brand/BrandDetails';
import { getAccountBrandsTableHeaders } from '../../utils/tableHeaders';
import CustomTable from "../../common/CustomTable/CustomTable";
import Loader from '../../pages/account/Loader';
import MyPaper from '../general/MyPaper';
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { Link} from "react-router-dom";
import { convertToUserTimezone, getFormattedDate } from "../../utils/momentManipulations";



const AccountBrands = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [account, setAccount] = useState(null); 
    const [type] = useState(1);
    const [isExport] = useState(0); 
    const [tablePageNo] = useState(0); 
    const [tableRowsPerPage] = useState(5); 
    const [sortCol, setSortCol] = useState("b.updatedAt");
    const [sortOrder, setSortOrder] = useState("desc"); 
    const [filter] = useState(""); 
    const [mimeType] = useState("csv");
    const [brandPlatform] = useState("oryx"); 
    const [brandData, setBrandData] = useState([]);
    const [dataFound, setDataFound] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);

    const timeZone = getTimeZone();


    const fetchData = () => {
        setIsLoaded(() => false);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const account = Number(params.get("id"));
        setAccount(account)
        getBrandsList(
            account,
            type,
            isExport,
            tablePageNo,
            tableRowsPerPage,
            sortCol,
            sortOrder,
            filter,
            mimeType,
            brandPlatform,
            timeZone,
        );
    };

    useEffect(() => {
        fetchData();
    }, [
        account,
        sortCol,
        sortOrder,
    ]);

    const getBrandsList = async (
        account,
        type,
        isExport,
        tablePageNo,
        tableRowsPerPage,
        sortCol,
        sortOrder,
        filter,
        mimeType,
        brandPlatform,
        timeZone,
    ) => {
        setIsLoaded(false);
        // API used to get the details of the campaigns
        const res = await getAccountsBrandList(
            account,
            type,
            isExport,
            tablePageNo + 1,
            tableRowsPerPage,
            sortCol,
            sortOrder,
            filter,
            mimeType,
            brandPlatform,
            timeZone,
        );
        const { total_records, accountList, active, inactive, deleted } = res;


        if (total_records > 0 && accountList) {
            setBrandData(accountList);
            setDataFound(true);
        } else {
            setDataFound(false);
        }

        setTotalRecords(total_records);
        setIsLoaded(true);
    };

    const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
        setIsChanged(!isChanged);
        setSortCol(newPage.orderBy);
        setSortOrder(newPage.order);
    };

    useEffect(() => {
        const data = [];
        if (brandData) {
            brandData.forEach((item, index) => {
                const {
                    id,// 159
                    name, // "Brand creation 001"
                    accId, // 172
                    accName, // "Atlmails"
                    campaignCount, //2
                    campaignStatus, // "Live:1,Draft:1"
                    category,
                    createdOn,
                } = item;

                let liveCampaigns = 0;
                const statusBreakup = campaignCount > 0 ? campaignStatus.split(",") : [];
                statusBreakup.filter((statusValue) => {
                    const [status, statusCount] = statusValue.split(":");
                    if (status.toLowerCase() === "live") liveCampaigns = parseInt(statusCount);
                });

                const formattedCreatedOn = getFormattedDate(createdOn, "MMMM Do YYYY");

                const object = {
                    id: index + 1,
                    name: (
                        <>
                            <Grid container spacing={1} sx={{ justifyContent: "flex-start" }}>
                                <Grid item md={8} sm={5}>
                                    <Box
                                    component={"a"}
                                        sx={{
                                            cursor: "pointer",
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 800,
                                            fontSize: "1.1rem",
                                            lineHeight: "1rem",
                                            color: "#1461cc",
                                        }}
                                        href={"../brand/details?id=" + id}
                                        target="_blank"          
                                        rel="noopener noreferrer"
                                    >
                                        {name}
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    ),
                    created: (
                        <Grid container >
                            <Grid item xs={12} style={{fontSize: "0.95em",fontStyle: "italic"}}>
                                {formattedCreatedOn}
                            </Grid>
                        </Grid>
                    ),
                    campaigns: (
                        <Grid container sx={{ paddingTop: "10px", paddingLeft: "20px" }}>
                            <Grid item xs={2}>
                                {campaignCount > 0 ? (
                                    <p>{campaignCount}</p>
                                ) : (
                                    <p>
                                        <span style={{ paddingLeft: "20px" }}>-</span>
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Tooltip
                                    title={
                                        campaignCount > 0 ? (
                                            <>
                                                <Typography color="inherit">Campaign Breakup</Typography>
                                                <hr />
                                                {statusBreakup.map((statusValue) => {
                                                    const [status, statusCount] = statusValue.split(":");
                                                    return (
                                                        <>
                                                            <span
                                                                style={{ fontSize: "14px", marginBottom: "15px" }}
                                                            >{`${status} Campaigns : ${statusCount}`}</span>
                                                            <br />
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }
                                >
                                    <InfoIcon fontSize="small" style={{ opacity: campaignCount > 0 ? "0.5" : "0" }} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    category: (
                        <Grid container >
                            <Grid item xs={12}>
                            {category}
                            </Grid>
                        </Grid>
                    ),
                    edit: (
                        <Grid container >
                            <Grid item xs={12}>
                                <Tooltip title="Edit the brand">
                                    <Link to={`../brands/edit?id=${id}&accId=${accId}`} id={`Edit-${id}`} style={{ color: 'inherit' }}>
                                        <EditIcon sx={{ color: "#757575" }} />
                                    </Link>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                };
                data.push(object);
            });
            setTableData(data);
        }
    }, [brandData]);

    const tableHeaders = getAccountBrandsTableHeaders;

    const renderContentHandler = () => {
        let content = null;
        if (isLoaded) {
            if (dataFound) {
                content = (
                    <>
                        <div id="table">
                            {/* This is a custom made component, take care while changing field names */}
                            <CustomTable
                                tableHeaders={tableHeaders}
                                bodyColumns={tableData}
                                sendInformation={handleInformationChange}
                                sortOrder={sortOrder}
                                sortOrderBy={sortCol}
                                Pagination={false}
                            />
                        </div>
                    </>
                );
            } else {
                content = (
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <p style={{ padding: "40px", textAlign: "center" }}> You don't have any Brands at the moment.</p>
                        </Grid>
                    </Grid>
                );
            }
        } else content = <Loader />;

        return content;
    };

    return (
        <>
            <MyPaper sx={{ marginTop: "60px" }}>

                <Stack direction="row" justifyContent="start">
                    {/* <Typography variant="h4" sx={props.parentStyle.typography}> */}
                    <Typography variant="h3">
                        Brands
                    </Typography>
                </Stack>

                <Box my={1}>{renderContentHandler()}</Box>

                {totalRecords > 5 && (
                    <div style={{ textAlign: "center" }}>
                        <a href={`/brands?accId=${account}`} style={{ textDecoration: "none" }}>
                            <button
                                style={{ width: "160px", height: "40px", color: "#757575", backgroundColor: "#FFFFFF", border: "1px solid #474747", borderRadius: "10px", fontSize: "20px", fontWeight: "bold" }}
                            > View All </button>
                        </a>
                    </div>
                )}

            </MyPaper>
        </>
    )
}


export default AccountBrands;