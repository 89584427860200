import React from "react";
import Info from "@material-ui/icons/Info";
import { Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function CampaignInfluencerMap(props) {
  return (
    <span>
      <Tooltip
        title={
          props.data ? (
            <>
              <Typography color="inherit">Associated Campaign(s)</Typography>
              <ul>
                {props.data.map((elem) => {
                  return (
                    <li>
                      <Link
                        target="_blank"
                        to={"../campaigns/analysis?id=" + elem.cid}
                        style={{
                          color: "#FFF",
                          textDecorationLine: "underline",
                        }}
                      >
                        {elem.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <>
              <Typography color="inherit">No campaigns found</Typography>
            </>
          )
        }
      >
        <Info style={{ fontSize: "16px", opacity: "0.5" }} />
      </Tooltip>
    </span>
  );
}
