import React, { Component } from "react";
import { API_HOST } from "../../../config/main.json";
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Backdrop,
  Box,
  Modal,
  Fade,
  IconButton,
  OutlinedInput,
  MenuItem,
  Autocomplete,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { BadgeTwoTone, Close } from "@mui/icons-material";
import MyPaper from "../../general/MyPaper";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import utils from "../../../utils/utils";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import AddBrand from "../../brand/details/AddBrand";

let accId = 0;
let backdropShow = false;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
class EditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandList: [],
      fields: {},
      editFields: {},
      openAdd: false,
      openEdit: false,
      editDetails: {},
      backdropShow: backdropShow,
      attributesList: [],
      primaryList: [],
      secondaryList: [],
      attributeValues: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
  }

  handleClickOpen(id, event) {
    if (id === -1) {
      let openAdd = true;
      this.setState({ openAdd });
    } else {
      let openEdit = true; 
      let editDetails = this.state.brandList[id];
      let attributeValues = [];
      if (editDetails?.attributes?.length) {
        editDetails.attributes.split(",").forEach((key) => {
          attributeValues.push(this.state.attributesList[+key - 1]);
        });
      }
      editDetails["attributeValues"] = attributeValues;
      let editFields = {
        id: editDetails.id,
        brandName: editDetails.name,
        attributes: editDetails.attributes,
        primaryCategory: editDetails.primaryCat,
        secondaryCategory: editDetails.secondaryCat,
      };
      this.setState({ openEdit, editDetails, editFields });
    }
  }

  handleClose() {
    const openAdd = false;
    const openEdit = false;
    this.setState({ openAdd, openEdit });
  }


  handleSaveEdit(e) {
    e.preventDefault();
    const openEdit = false;
    this.setState({ openEdit });
    backdropShow = true;
    this.setState({ backdropShow });
    delete this.state.editFields["name"];
    var body = {};
    body["id"] = this.state.editFields["id"].toString();
    body["name"] = this.state.editFields["brandName"];
    body["attributes"] = this.state.editFields["attributes"];
    body["primaryCat"] = this.state.editFields["primaryCategory"];
    body["secondaryCat"] = this.state.editFields["secondaryCategory"];
    this.postData(body, "edit");
  }

  getAccountDetails(accId) {
    utils.getAccountData(accId).then((data) => {
      const accountData = data;
      const fields = {};
      fields["id"] = accountData["id"];
      fields["name"] = accountData["name"];
      this.setState({ fields });
    });
  }

  getBrands(accId) {
    utils.getBrandData(accId).then((data) => {
      if (data != null) {
        const brandList = data.map((brand, index) => {
          brand["sl"] = index + 1;
          return brand;
        });
        this.setState({ brandList });
      }
    });
  }

  getAttributesList() {
    utils.getAttrData().then((data) => {
      const attributesList = data;
      this.setState({ attributesList });
    });
  }

  getPrimaryList() {
    utils.getPcatData().then((data) => {
      const primaryList = data;
      this.setState({ primaryList });
    });
  }

  getSecondaryList() {
    utils.getScatData().then((data) => {
      const secondaryList = data;
      this.setState({ secondaryList });
    });
  }

  loadAccountData = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    accId = params.get("id");
    if (typeof accId !== "undefined") {
      this.getAccountDetails(accId);
      this.getBrands(accId);
      this.getAttributesList();
      this.getPrimaryList();
      this.getSecondaryList();
      this.setState({ backdropShow: false });
    }
  };

  componentDidMount() {
    this.loadAccountData();
  }

  postData(data, type) {
    var optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  
    let url = "/brand/edit/" + accId;
    axios.post(API_HOST + url, data, optionAxios).then((res) => {
      this.loadAccountData();
    });
  }

  saveTheAddedBrand = () => {
    this.setState({ openAdd: false });
    this.loadAccountData();
  }


  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleChangeAuto(field, e, values) {
    let fields = this.state.fields;
    fields[field] = "";
    if (values !== null) {
      if (values.length > 0) {
        var data = [];
        values.forEach(function (item, index) {
          data.push(item["id"]);
        });
        fields[field] = data.join(",");
      } else if (typeof values["id"] !== "undefined") {
        fields[field] = values["id"];
      }
    }
    this.setState({ fields });
  }

  handleChangeEdit(field, e) {
    let editFields = this.state.editFields;
    editFields[field] = e.target.value;
    this.setState({ editFields });
  }

  handleChangeAutoEdit(field, e, values) {
    let editFields = this.state.editFields;
    editFields[field] = "";
    if (values !== null) {
      if (values.length > 0) {
        var data = [];
        values.forEach(function (item, index) {
          data.push(item["id"]);
        });
        editFields[field] = data.join(",");
      } else if (typeof values["id"] !== "undefined") {
        editFields[field] = values["id"];
      }
    }
    this.setState({ editFields });
  }

  render() {
    const attributesList = this.state.attributesList;
    const primaryList = this.state.primaryList;
    const secondaryList = this.state.secondaryList;
    const brandList = this.state.brandList;

    const cols = [
      {
        label: "SR NO.",
        name: "sl",
        options: {
          filter: false,
        },
      },
      {
        label: "Brand Name",
        name: "name",
      },
      {
        label: "Brand Attributes",
        name: "attributes",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const attrKeys = value.split(",").map((key) => +key);
            let res = [];
            if (attributesList.length > 0 && attrKeys.length > 0) {
              attrKeys?.forEach((key) => {
                if (key > 0) {
                  res.push(this.state.attributesList[key - 1].name);
                }
              });
            }

            return <div>{res.join(", ")}</div>;
          },
        },
      },
      {
        label: "Primary Category",
        name: "primaryCat",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let res = "";
            if (primaryList.length > 0) {
              res = primaryList[value - 1].category;
            }

            return <div>{res}</div>;
          },
        },
      },
      {
        label: "Secondary Category",
        name: "secondaryCat",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let res = "";
            if (secondaryList.length > 0) {
              res = secondaryList[value - 1].category;
            }

            return <div>{res}</div>;
          },
        },
      },
      {
        label: "Actions",
        name: "Actions",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <IconButton
                  id={"Edit-" + tableMeta.rowData[0]}
                  onClick={this.handleClickOpen.bind(this, tableMeta.rowData[0] - 1)}
                >
                  <Tooltip title={"Edit"}>
                    <EditIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              </div>
            );
          },
          filter: false,
        },
      },
    ];

    const options = {
      textLabels: {
        pagination: {
          next: "Next Page",
          previous: "Previous Page",
          rowsPerPage: "Rows per page:",
          displayRows: "of",
        },
        toolbar: {
          search: "Search",
          downloadCsv: "Download CSV",
          print: "Print",
          viewColumns: "View Columns",
          filterTable: "Filter Table",
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },
        viewColumns: {
          title: "Show Columns",
          titleAria: "Show/Hide Table Columns",
        },
      },
      responsive: "simple",
      selectableRows: "none",
      searchAlwaysOpen: true,
      searchPlaceholder: "Search by all visible columns",
    };

    return (
      <>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: "column",
          }}
          open={this.state.backdropShow}
        >
          <CircularProgress color="inherit" />
          <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
        </Backdrop>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MyPaper>
              <div style={{ width: "100%", textAlign: "center" }}>
                <div style={{ float: "left" }}>
                  <Typography variant="h5">
                    <BadgeTwoTone sx={{ verticalAlign: "middle", mr: 2 }} align="right" />
                    {this.state.fields["name"]}
                  </Typography>
                </div>
                <div style={{ float: "right" }}>
                  <Button variant="contained" align="right" onClick={this.handleClickOpen.bind(this, -1)}>
                    Add Brand
                  </Button>
                </div>
              </div>
              <br />
              <br />
              <Divider />
              <br />
              <MUIDataTable data={brandList} columns={cols} options={options} />
              <br />
              <Divider />
            </MyPaper>
          </Grid>
        </Grid>


        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openAdd}
          // closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={this.state.openAdd}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 800,
                // minWidth: 400,
                bgcolor: "background.paper",
                borderRadius: "15px",
                boxShadow: 24,
                p: 4,
              }}
            >

              <div id="modal-add_user-heading" className="styles_modal_heading">
                <h2 id="modal-add_user-heading-text" style={{ padding: "10px" }}>
                  Add Brand
                </h2>
                <IconButton onClick={this.handleClose.bind(this)}>
                  <Close />
                </IconButton>
              </div>

              <AddBrand
                accountName={this.state.fields["name"] ? this.state.fields["name"] : ""}
                accountId={this.state.fields["id"]}
                type={"editAccount"}
                saveTheAddedBrand={this.saveTheAddedBrand}
              />
            </Box>
          </Fade>
        </Modal>




        <Modal
          className="styles_modal"
          aria-labelledby="modal-edit_brand_to_account-label"
          aria-describedby="modal-edit_brand_to_account-description"
          open={this.state.openEdit}
          onClose={this.handleClose.bind(this)}
          closeAfterTransition
        >
          <Fade in={this.state.openEdit}>
            <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
              <div id="modal-edit_brand_to_account-heading" className="styles_modal_heading">
                <h2 id="modal-edit_brand_to_account-heading-text" style={{ padding: "10px" }}>
                  Edit Brand
                </h2>
                <IconButton onClick={this.handleClose.bind(this)}>
                  <CloseIcon />
                </IconButton>
              </div>
              <hr />
              <div id="modal-edit_brand_to_account-body" style={{ padding: "10px" }}>
                <Grid item xs={12}>
                  <Box>
                    <React.Fragment>
                      <form id="editBrandForm" onSubmit={this.handleSaveEdit.bind(this)}>
                        <div>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl
                              style={{
                                width: "220px",
                                marginBottom: "15px",
                                flex: 1,
                                marginRight: "20px",
                              }}
                            >
                              <TextField
                                variant="outlined"
                                required={true}
                                type="text"
                                id="brandName"
                                refs="brandName"
                                name="brandName"
                                label="Brand Name"
                                defaultValue={this.state.editDetails.name}
                                onChange={this.handleChangeEdit.bind(this, "brandName")}
                              />
                            </FormControl>
                            <FormControl
                              style={{
                                width: "220px",
                                marginBottom: "15px",
                                flex: 1,
                                marginRight: "20px",
                              }}
                            >
                              <Autocomplete
                                multiple
                                limitTags={1}
                                id="checkboxes-tags-demo"
                                options={this.state.attributesList}
                                onChange={this.handleChangeAutoEdit.bind(this, "attributes")}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                defaultValue={this.state.editDetails.attributeValues}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                                    {option.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Brand Attributes"
                                    required={
                                      this.state.editFields.attributes == undefined || this.state.editFields.attributes == ""
                                    }
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                            <FormControl
                              style={{
                                width: "220px",
                                marginBottom: "15px",
                                flex: 1,
                                marginRight: "20px",
                              }}
                            >
                              <InputLabel id="primary-category-label">Brand Primary Category *</InputLabel>
                              <Select
                                required={true}
                                label="Brand Primary Category *"
                                id="primaryCategory"
                                onChange={this.handleChangeEdit.bind(this, "primaryCategory")}
                                defaultValue={this.state.editDetails.primaryCat}
                                input={<OutlinedInput label="Brand Primary Category *" id="demo-dialog-native" />}
                              >
                                {this.state.primaryList.map((primaryCategory) => (
                                  <MenuItem value={primaryCategory.id} key={primaryCategory.id}>
                                    {primaryCategory.category}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl
                              style={{
                                width: "220px",
                                marginBottom: "15px",
                                flex: 1,
                                marginRight: "20px",
                              }}
                            >
                              <InputLabel id="secondary-category-label">Brand Secondary Category *</InputLabel>
                              <Select
                                required={true}
                                label="Brand Secondary Category *"
                                id="secondaryCategory"
                                onChange={this.handleChangeEdit.bind(this, "secondaryCategory")}
                                defaultValue={this.state.editDetails.secondaryCat}
                                input={<OutlinedInput label="Brand Secondary Category *" id="demo-dialog-native" />}
                              >
                                {this.state.secondaryList.map((secondaryCategory) => (
                                  <MenuItem value={secondaryCategory.id} key={secondaryCategory.id}>
                                    {secondaryCategory.category}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </div>
                        <button
                          variant="contained"
                          className="btn btn-success float-right"
                          style={{
                            float: "right",
                            marginBottom: "15px",
                            marginRight: "20px",
                          }}
                          type="submit"
                        >
                          Submit
                        </button>
                      </form>
                    </React.Fragment>
                  </Box>
                </Grid>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }
}
export default EditAccount;
