import * as React from "react";
import { Backdrop, CircularProgress, Box, Tab, Tabs, Typography, Grid } from "@mui/material";
import { useState } from "react";
import TabPanel from "../../components/general/TabPanel";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import utils from "../../utils/utils";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import MyPaper from "../../components/general/MyPaper";
import { width } from "@mui/system";

const BrandDetails = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const brandId = params.get("id");
  const [selectedTab] = useState(0);
  const [state, setState] = useState({
    backdropShow: true,
    isLoaded: false,
    brandList: [],
  });
  React.useEffect(() => {
    getBrandList(brandId);
  }, []);

  const getBrandList = (brandId) => {
    setState({ ...state, backdropShow: true, isLoaded: false });
    utils.getBrandDetails(brandId).then((data) => {
      const brandList = data;
      console.log(brandList)
      setState({ ...state, brandList, backdropShow: false, isLoaded: true });
    });
  };

  const formatAttributes = (attributes) => {
    if (!attributes) return '';

    // Split the string by commas and group them into chunks of 10
    const attributesArray = attributes.split(',');
    let formattedAttributes = '';
    for (let i = 0; i < attributesArray.length; i += 10) {
      const chunk = attributesArray.slice(i, i + 10);
      formattedAttributes += chunk.join(',') + '<br/>';
    }

    return formattedAttributes;
  };

  const tabArray = [
    {
      label: "Brand Details",
      id: "brand-details-tab-0",
    }
  ]
  return true ? (
    <>
      <Typography variant="h3" sx={{ mb: 2, mt: 2 }}>
      {state.brandList?.name}
      </Typography>
      <Box sx={{ width: "100%", mb: 3 }}>
        <CustomTabs tabArray={tabArray} selectedTab={"0"} />

        <Stack direction="row" justifyContent="end">
          <Button variant="contained" component={Link} to="../brands/">
            All Brands
          </Button>
        </Stack>
      </Box>
      <MyPaper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span>
              <b>Brand Name:</b> {state.brandList?.name}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span>
              <b>Brand Attributes:</b>{' '}
              <span dangerouslySetInnerHTML={{ __html: formatAttributes(state.brandList?.attributes) }} />
            </span>
          </Grid>
          <Grid item xs={12}>
            <span>
              <b>Primary Category:</b> {state.brandList?.primaryCat}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span>
              <b>Secondary Category:</b> {state.brandList?.secondaryCat}
            </span>
          </Grid>
        </Grid>
      </MyPaper>
    </>
  ) : (
    <Backdrop
      open
      sx={{
        position: "absolute",
      }}
    >
      <CircularProgress sx={{ color: "white" }} />
    </Backdrop>
  );
};

export default BrandDetails;
