import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import StyledMenu from "../StyledMenu";

import {
  FormControl,
  Grid,
  MenuItem,
  Tooltip,
  InputLabel,
  Select,
} from "@mui/material";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const Views = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
}) => {
  const [infFromViewsSel, setInfFromViewsSel] = useState("");
  const [infToViewsSel, setInfToViewsSel] = useState("");
  const [toViews] = useState([
    { value: "1000", name: "1k" },
    { value: "5000", name: "5k" },
    { value: "10000", name: "10k" },
    { value: "25000", name: "25k" },
    { value: "50000", name: "50k" },
    { value: "100000", name: "100k" },
    { value: "250000", name: "250k" },
    { value: "500000", name: "500k" },
    { value: "1000000", name: ">1m" },
  ]);
  const [fromViews] = useState([
    { value: "1000", name: "1k" },
    { value: "5000", name: "5k" },
    { value: "10000", name: "10k" },
    { value: "25000", name: "25k" },
    { value: "50000", name: "50k" },
    { value: "100000", name: "100k" },
    { value: "250000", name: "250k" },
    { value: "500000", name: "500k" },
    { value: "1000000", name: ">1m" },
  ]);

  const handleFromChange = (event, type) => {
    let newPayload = payload;
    let views = event.target.value;
    if (views === "To" || views === "From") {
      views = "";
    }
    if (type === "inf_views_from") {
      setInfFromViewsSel(views);
    } else {
      setInfToViewsSel(views);
    }
    newPayload[type] = views;
    setPayload(newPayload);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={FilterTooltips.views}>
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                textTransform: "none",
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              size="large"
              disableElevation
              onClick={handleClick}
              fullWidth
              endIcon={
                open ? (
                  <ArrowDropUp fontSize="large" />
                ) : (
                  <ArrowDropDown fontSize="large" />
                )
              }
            >
              Views
            </Button>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={2} style={{ height: "auto" }}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControl sx={{ m: 1 }}>Views</FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        From
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="infFromViews"
                        value={infFromViewsSel}
                        label="From"
                        onChange={(e) => handleFromChange(e, "inf_views_from")}
                        defaultValue={infFromViewsSel}
                      >
                        {fromViews.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        To
                      </InputLabel>
                      <Select
                        value={infToViewsSel}
                        onChange={(e) => handleFromChange(e, "inf_views_to")}
                        label="To"
                        defaultValue={infToViewsSel}
                      >
                        {toViews.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Views.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Views;
