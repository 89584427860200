import PropTypes from "prop-types";
import utils from '../../../utils/utils';

import { Grid, Typography } from "@mui/material";
import {
  People,
  RecordVoiceOver,
  WarningAmberRounded,
} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const DiscoveryAppliedFilters = ({
  appliedFilters,
  payload,
  setPayload,
  customCols,
  setCustomCols,
  isChanged,
  setIsChanged,
  allFiltersData,
  setAllFiltersData,
  setSelectedCol,
}) => {
  let total = 0;

  const filterKeys = {
    audience_type: "Audience Type",
    ads_brands: "Partnership",
    brand_category: "Interests",
    reel_plays: "Reel Plays",
    lastPosted: "Last Posted",
  };
  const labelWithoutKeys = [
    "contact",
    "verified",
    "credible",
    "known",
    "hidden",
    "has_audience_data",
  ];

  const handleDelete = (
    filterType,
    appliedFilters,
    label,
    id,
    name,
    filtername
  ) => {
    let newPayload = payload;
    let newcustCol = customCols;
    let newFilters = allFiltersData;
    if (filtername == "language") {
      newPayload[appliedFilters] = {};
      if (filterType === "aud") {
        delete newcustCol[0][appliedFilters];
      }
      delete newFilters[0][appliedFilters];
    } else if (
      (filtername == "brand" || filtername == "ads_brands") &&
      filterType === "inf"
    ) {
      let index = newPayload[appliedFilters].indexOf(id);
      if (index !== -1) {
        newPayload[appliedFilters].splice(index, 1);
      }
      const skippedFilter = allFiltersData[0][appliedFilters].filter(
        (_, idx) => idx !== index
      );
      newFilters[0][appliedFilters] = skippedFilter;
    } else if (
      filtername == "gender" ||
      filtername == "ethinicity" ||
      filtername == "age" ||
      filtername == "audience_type" ||
      filtername == "lookalikes" ||
      filtername == "bio" ||
      filtername == "keyword" ||
      filtername == "followers" ||
      filtername == "engagements" ||
      filtername === "er" ||
      filtername === "reel_plays"
    ) {
      if (filterType === "aud") {
        delete newcustCol[0][appliedFilters];
      }
      if (
        filtername === "followers" ||
        filtername === "engagements" ||
        filtername === "reel_plays"
      ) {
        delete newPayload["inf_" + filtername + "_from"];
        delete newPayload["inf_" + filtername + "_to"];
        delete newFilters[0]["inf_" + filtername + "_from"];
        delete newFilters[0]["inf_" + filtername + "_to"];
      }
      if (filtername === "er") {
        delete newPayload["inf_er_from"];
        delete newFilters[0]["inf_er"];
      }

      delete newPayload[appliedFilters];
      delete newFilters[0][appliedFilters];

      if (filtername === "lookalikes") {
        if (allFiltersData[0] === undefined) {
          newPayload["sort"] = "engagements";
        } else if (
          filterType === "inf" &&
          allFiltersData[0]["aud_lookalikes"] != undefined
        ) {
          newPayload["sort"] = "audience_relevance";
        } else if (
          filterType === "aud" &&
          allFiltersData[0]["inf_lookalikes"] != undefined
        ) {
          newPayload["sort"] = "relevance";
        } else {
          newPayload["sort"] = "engagements";
        }
      }
    } else if (filtername === "contact") {
      if (allFiltersData[0][appliedFilters].length !== 1) {
        const skippedFilter = allFiltersData[0][appliedFilters].filter(
          (item) => item.name !== name
        );
        newFilters[0][appliedFilters] = skippedFilter ?? [];
        if (skippedFilter?.length > 0) {
          const payload = newPayload[appliedFilters].filter(
            (item) => item.type === skippedFilter[0]?.value
          );
          newPayload[appliedFilters] = payload;
        }
      } else {
        delete newFilters[0][appliedFilters];
        delete newPayload[appliedFilters];
      }
    } else if (
      filtername === "lastPosted" ||
      filtername === "followers_growth" ||
      filtername === "credible" ||
      filtername === "has_audience_data" ||
      filtername === "verified" ||
      filtername === "hidden" ||
      filtername === "known"
    ) {
      delete newFilters[0][appliedFilters];
      delete newPayload[appliedFilters];
    } else if (filtername === "accountType") {
      if (allFiltersData[0][appliedFilters].length !== 1) {
        const skippedFilter = allFiltersData[0][appliedFilters].filter(
          (item) => item.name !== name
        );
        newFilters[0][appliedFilters] = skippedFilter ?? [];
        if (skippedFilter?.length > 0) {
          const payload = newPayload[appliedFilters].filter(
            (item) => item.toString() === skippedFilter[0]?.value.toString()
          );
          newPayload[appliedFilters] = payload;
        }
      } else {
        delete newFilters[0][appliedFilters];
        delete newPayload[appliedFilters];
      }
    } else {
      const index = payload[appliedFilters].map((item) => item.id).indexOf(id);
      if (index !== -1) {
        const filteredLocations = payload[appliedFilters].filter(
          (_, idx) => idx !== index
        );
        newPayload[appliedFilters] = filteredLocations;
        if (filterType === "aud") {
          let newcustCol = customCols;
          newcustCol[0][appliedFilters] = utils.removeElement(
            customCols[0][appliedFilters],
            name
          );
        }
        const skippedFilter = allFiltersData[0][appliedFilters].filter(
          (_, idx) => idx !== index
        );
        newFilters[0][appliedFilters] = skippedFilter;
      }
    }
    setCustomCols(newcustCol);
    setSelectedCol(newcustCol);
    setPayload(newPayload);
    setAllFiltersData(newFilters);
    setIsChanged(!isChanged);
  };

  const getFilterKey = (label) => {
    if (filterKeys[label] !== undefined && filterKeys[label] !== "") {
      return filterKeys[label];
    }
    return utils.capitalizeFirstLetter(label);
  };

  const chipForSingleFilter = (value) => {
    if (!value) return null;

    total += 1;
    return Object.keys(value).flatMap((appliedFilter) => {
      if (typeof value[appliedFilter] === "object") {
        return Object.keys(value[appliedFilter]).map((index) => {
          let FilterData = appliedFilter.split("_");
          let filterType = FilterData[0];
          let label = FilterData.splice(1).join("_");
          let filterValue = "";
          if (label == "age") {
            filterValue =
              value[appliedFilter][index].left +
              "-" +
              value[appliedFilter][index].right +
              " Y.O";
          } else if (
            label == "followers" ||
            label == "engagements" ||
            label === "reel_plays"
          ) {
            let followersRange =
              value[appliedFilter][index].left +
              "-" +
              value[appliedFilter][index].right;
            if (
              value[appliedFilter][index].right === undefined ||
              value[appliedFilter][index].right == ""
            ) {
              followersRange = value[appliedFilter][index].left + "+";
            }
            if (
              value[appliedFilter][index].left === undefined ||
              value[appliedFilter][index].left == ""
            ) {
              followersRange = "up to " + value[appliedFilter][index].right;
            }
            filterValue = followersRange;
          } else if (
            label === "audience_type" ||
            label === "bio" ||
            label === "keyword" ||
            label === "er"
          ) {
            filterValue = value[appliedFilter][index];
          } else if (label === "lookalikes" || label === "mention") {
            filterValue = value[appliedFilter][index].value;
          } else {
            filterValue =
              value[appliedFilter][index].name ||
              value[appliedFilter][index].code;
          }
          let icon = "";
          if (labelWithoutKeys.includes(label)) {
            icon = <CampaignIcon />;
          } else if (filterType === "aud") {
            icon = <People />;
          } else {
            icon = <RecordVoiceOver />;
          }
          const filterLabel = labelWithoutKeys.includes(label)
            ? `${filterValue}`
            : `${getFilterKey(label)}: ${filterValue}`;
          return (
            <Chip
              key={`${appliedFilter}-${label}`}
              icon={icon}
              onDelete={(e) =>
                handleDelete(
                  filterType,
                  appliedFilter,
                  label,
                  value[appliedFilter][index].id,
                  filterValue,
                  label
                )
              }
              label={filterLabel}
              style={{ backgroundColor: "white", margin: "2px 5px" }}
              deleteIcon={<CloseRoundedIcon />}
            />
          );
        });
      }
    });
  };

  const createFilterChips = (appliedFilters) => {
    return Object.keys(appliedFilters).map((appliedFilterName) => {
      const values = appliedFilters[appliedFilterName];
      if (Array.isArray(values)) {
        // multiple values
        return values.map((value) => {
          return chipForSingleFilter(value);
        });
      } else {
        // single values only
        return chipForSingleFilter(values);
      }
    });
  };

  const renderFilters = () => {
    total = 0;
    if (!appliedFilters || Object.keys(appliedFilters[0]).length === 0) {
      return <></>;
    }
    return (
      <Grid
        container
        columns={{ xs: 4, sm: 6, md: 12 }}
        style={{ marginTop: "1rem" }}
      >
        <Grid
          item
          xs={10}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Typography
            className="appliedFilters-label"
            id="appliedFilters-searchParams-label"
            p
            sx={{ p: 0, pr: "1rem", display: "inline-flex", my: "5px" }}
          >
            Filters:
          </Typography>
          {createFilterChips(appliedFilters)}
        </Grid>
        <Grid item xs={2} textAlign="right">
          <WarningAmberRounded sx={{ color: "#FED4A3" }} /> There are active
          filters
        </Grid>
      </Grid>
    );
  };

  const render = renderFilters();

  return total > 0 ? (
    <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
      <Grid item xs={4} sm={6} md={12}>
        {render}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

DiscoveryAppliedFilters.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
};
export default DiscoveryAppliedFilters;
