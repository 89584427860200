import { func, string } from "prop-types";
import InviteByList from "./InviteByList";
import InviteByEmail from "./InviteByEmail";

const InviteLayout = ({ typeOfInvite, goback,presetData }) => {
  const setNavigateBack = (event) => {
    goback(event);
  };
  return (
    <>
      {typeOfInvite === "list" && (
        <InviteByList setNavigateBack={setNavigateBack} presetData={presetData} />
      )}
      {typeOfInvite === "email" && (
        <InviteByEmail setNavigateBack={setNavigateBack} presetData={presetData} />
      )}
    </>
  );
};

InviteLayout.propTypes = {
  typeOfInvite: string.isRequired,
  goback: func.isRequired,
};
export default InviteLayout;
