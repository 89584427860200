const gradientColours = [ "#2c9dfa", "#3fa5fa", "#55b1fd", "#6fbefe", "#7fc5ff", "#94cefe", "#a8d8ff", "#b9dfff", "#c8e6fe", "#d9eefe", "#e6f3fe" ];

const backgroundColours = [ "#5368F0", "#9D57D5", "#FEAB00", "#4FD1C5", "#E91F63", "#92E48E" ];

const helper = {
  /* checkMe: done trying this. remove true condition for 'individualDatasets' */
  // data in the format: [{<key1>: <value1>} ... ]
  // colouringRoutine: {0: gradient , 1: random} // can be overshadowed by color
  restructureDataForChart: (data, chartType, label, colouringRoutine=1, colour="") => {

    if(!data || data.length === 0) {
      return data;
    }

    /* segregate axis values */
    const xAxisValues = [];
    const yAxisValues = [];
    data.forEach((obj) => {
      xAxisValues.push(obj.key);
      yAxisValues.push(obj.value);
    });

    /* create chart data */
    let bgColour = [];
    if(colour !== "") {
      bgColour = colour
    } else if (colouringRoutine === 0) {
      bgColour = gradientColours
    } else {
      bgColour = backgroundColours
    }

    return {
      labels: xAxisValues,
      datasets: [
        {
          "type": chartType,
          "label": label,
          "labels": xAxisValues,
          "maxBarThickness": 20,
          "backgroundColor": bgColour,
          // "borderColor": "rgb(255, 99, 132)",
          // "borderRadius": 9,
          "categoryPercentage": 0.5,
          "data": yAxisValues
        }
      ],
    }

  },
  restructureCountryDataForMap: (data) => {
    if(!data) {
      return [];
    }
    return data.map((item) => {
      return {
        "country": item.value,
        "value": item.clicks,
        "percentage": item.percentage + "%",
      }
    });
  },
  restructureCityDataForMap: (data) => {
    if(!data) {
      return [];
    }
    return data.map((item) => {
      return {
        "city": item.city,
        "value": item.clicks,
        "percentage": item.percentage + "%",
      }
    });
  },
  remapCountryLocationDataForTable: (locationData, countryCodes) => {
    if(!locationData) {
      return [];
    }
    let countries = [];
    let unknownCountry_clicks = 0;
    let unknownCountry_percentage = 0;
    locationData.forEach((item) => {
      if (countryCodes[item.country]) {
        const country = countryCodes[item.country].name;
        countries.push({
          "country": country,
          "clicks": item.value,
          "percentage": item.percentage,
        });
      } else {
        unknownCountry_clicks += item.value;
        const percentage_str = item.percentage;
        const percentage_num = percentage_str.slice(0, percentage_str.length - 1);
        unknownCountry_percentage += parseFloat(percentage_num);
      }
    });
    if (unknownCountry_clicks>0) {
      countries.push({
        "country": "Other",
        "clicks": unknownCountry_clicks,
        "percentage": unknownCountry_percentage + "%",
      });
    }
    return countries;
  },
  remapCityLocationDataForTable: (locationData, countryCodes) => {
    if(!locationData) {
      return [];
    }
    let cities = [];
    let otherClicks = 0;
    let otherPercentage = 0;
    locationData.forEach((item) => {
      const city = item.city.split(",")[0]
      const country = item.city.split(",")[1]
      if (country !== "NONE" && countryCodes[country]) {
        cities.push({
          "city": city,
          "country": countryCodes[country].name,
          "clicks": item.value,
          "percentage": item.percentage,
        });
      } else {
        otherClicks += item.value;
        const percentage_str = item.percentage;
        const percentage_num = percentage_str.slice(0, percentage_str.length - 1);
        otherPercentage += parseFloat(percentage_num);
      }
    });
    if (otherClicks>0) {
      cities.push({
        "city": "Other",
        "country": "-",
        "clicks": otherClicks,
        "percentage": otherPercentage + "%",
      });
    }
    return cities;
  }
}

export default helper;