/* types
  select: always searchable, multiple is options 
*/

const FilterEnum = {
  filterData: {
    instagram: [
      "location",
      "language",
      "brand",
      "partership",
      "interest",
      "gender",
      
      "ethinicity",
      "age",
      "audienceType",
      "lookalikes",
      "bio",
      "keywords",
      "followers",
      "engagements",
      "reelPlays",
      "contact",
      "lastPost",
      "accountType",
      "growth",
      "verified",
    ],
    tiktok: [
      "location",
      "language",
      "gender",
      "age",
      "lookalikes",
      "bio",
      "keywords",
      "followers",
      "views",
      "engagements",
      "contact",
      "lastPost",
      "growth",
      "verified",
    ],
    youtube: [
      "location",
      "language",
      "gender",
      "age",
      "lookalikes",
      "audienceType",
      "keywords",
      "followers",
      "views",
      "engagements",
      "contact",
      "lastPost",
      "growth",
      "verified",
    ],
    twitter: ["keywords"],
  },
  analyze: {
    instagram: ["search"],
    tiktok: ["search"],
    youtube: ["search"],
    twitter: ["search"],
  },
  headers: [
    {
      id: 1,
      label: "Account",
      sortParameter: "profile",
      headerAssignment: "profile",
      show: true,
    },
    {
      id: 2,
      label: "Followers",
      headerAssignment: "following",
      sortParameter: "following",
      sortCol: "followers",
      show: true,
      sortable: true,
    },
    {
      id: 3,
      label: "Engagements",
      sortParameter: "engagements",
      headerAssignment: "engagements",
      sortCol: "engagements",
      show: true,
      sortable: true,
    },
    {
      id: 4,
      label: "Engagements Rate",
      sortParameter: "engagement_rate",
      headerAssignment: "engagement_rate",
      sortCol: "er",
      show: true,
      sortable: true,
    },
  ],
};

export default FilterEnum;
