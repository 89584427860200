import { Close, OpenWith } from "@mui/icons-material";
import { Button, Box, Fade, Grid, IconButton, LinearProgress, Modal, Paper, Stack, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import styles from "./ProgressChart.module.css";
import utils from "../../../../../../../utils/utils";

const ProgressChart = ({ data, chartTitle, backgroundColor, expandButtonVisible, wrapperElevation = 1, barSxProps, wrapperSxProps, topValuesCount = 4 }) => {
  const [expandTable, setExpandTable] = useState(false);

  const renderData = (expandFlag) => {
    const topValues = expandFlag ? data : data.slice(0, topValuesCount);

    return topValues.map((data) => {
      const title = data?.title || data?.name;
      const percentage = parseInt(data.percentage);
      const color = data.color;
      const tooltip = data.tooltip;
      return (
        <>
          <Paper elevation={wrapperElevation} sx={{ width: "100%", ...wrapperSxProps }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" justifyContent="center" alignItems="center">
                <span class="progress_chart-title">{utils.capitalizeFirstLetter(title)}</span>
                {tooltip &&
                  <Tooltip title={tooltip} sx={{ ml: "8px", fontSize: "14px" }}>
                    <IconButton>
                      <HelpOutlineIcon fontSize='14px' />
                    </IconButton>
                  </Tooltip>
                }
              </Stack>
              <span class="progress_chart-value">
                {percentage}
                {"%"}
              </span>
            </Stack>
            <Box style={{ color: color || "#4299E1" }}>
              <LinearProgress
                sx={{
                  height: 12,
                  ...barSxProps
                }}
                variant="determinate"
                key={{ title }}
                value={percentage}
                color="inherit"
              />
            </Box>
          </Paper>
        </>
      );
    });
  };

  const expandButton = () => {
    return (
      <Stack direction="row" justifyContent="end">
        <Button style={{ backgroundColor: "#BDBDBD" }} variant="contained" onClick={expandChart} endIcon={<OpenWith />}>
          Expand
        </Button>
      </Stack>
    );
  };

  const expandChart = () => {
    setExpandTable(true);
  };

  const handleTableModalClose = () => {
    setExpandTable(false);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, md: 12 }}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        {/* {chartTitle &&
          <Grid item xs={4} md={12}>
            <h4>
              <b className={styles.title}>{chartTitle}</b>
            </h4>
          </Grid>
        } */}
        <Grid item xs={4} md={12}>
          {renderData(false)}
          {expandButtonVisible && expandButton()}
        </Grid>
      </Grid>
      <Modal className={styles.stylesModal} open={expandTable} onClose={handleTableModalClose} closeAfterTransition>
        <Fade in={expandTable}>
          <div className={styles.sylesPaper} style={{ width: "50%", overflowY: "scroll", maxHeight: "500px" }}>
            <div id="modal-expanded_progress" className={styles.stylesModalHeading}>
              <h4>
                <b className={styles.title}>{chartTitle}</b>
              </h4>
              <IconButton onClick={handleTableModalClose}>
                <Close />
              </IconButton>
            </div>
            <hr />
            {renderData(true)}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

ProgressChart.propTypes = {
  data: PropTypes.array.isRequired,
  chartTitle: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  expandButtonVisible: PropTypes.bool.isRequired,
};
export default ProgressChart;
