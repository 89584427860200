import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import StyledMenu from "../StyledMenu";
import utils from "../../../utils/utils";

import {
  FormControl,
  Grid,
  MenuItem,
  Tooltip,
  InputLabel,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const Followers = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const [infFromFollowersSel, setInfFromFollowersSel] = useState("");
  const [infToFollowersSel, setInfToFollowersSel] = useState("");
  const [toFollowers] = useState([
    { value: "1000", name: "1k" },
    { value: "5000", name: "5k" },
    { value: "10000", name: "10k" },
    { value: "25000", name: "25k" },
    { value: "50000", name: "50k" },
    { value: "100000", name: "100k" },
    { value: "250000", name: "250k" },
    { value: "500000", name: "500k" },
    { value: "1000000", name: ">1m" },
  ]);
  const [fromFollowers] = useState([
    { value: "1000", name: "1k" },
    { value: "5000", name: "5k" },
    { value: "10000", name: "10k" },
    { value: "25000", name: "25k" },
    { value: "50000", name: "50k" },
    { value: "100000", name: "100k" },
    { value: "250000", name: "250k" },
    { value: "500000", name: "500k" },
    { value: "1000000", name: ">1m" },
  ]);
  const [radio, setRadio] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) {
      if (typeof allFiltersData[0]["inf_followers"] === "undefined") {
        setRadio("");
      } else {
        const left = utils.getValueByKey(
          toFollowers,
          allFiltersData[0]["inf_followers"][0]["left"],
          "name",
          "value"
        );

        const right = utils.getValueByKey(
          toFollowers,
          allFiltersData[0]["inf_followers"][0]["right"],
          "name",
          "value"
        );

        if (left === "1000" && right === "10000") {
          setRadio("Nano");
        } else if (left === "10000" && right === "50000") {
          setRadio("Micro");
        } else if (left === "50000" && right === "500000") {
          setRadio("Mid");
        } else if (left === "500000" && right === "1000000") {
          setRadio("Macro");
        } else if (left === "1000000") {
          setRadio("Mega");
        }
      }
    }
  }, [open]);

  const handleFromChange = (followers, type, clear = false) => {
    let newPayload = payload;
    if (type === "inf_followers_from") {
      setInfFromFollowersSel(followers);
    } else {
      setInfToFollowersSel(followers);
    }
    newPayload[type] = followers;
    setPayload(newPayload);
    setFollowerFilter();

    if (clear) {
      setRadio("");
    }
  };

  const setFollowerFilter = () => {
    let fromFollow = payload["inf_followers_from"];
    let toFollow = payload["inf_followers_to"];
    allFiltersData = utils.addObj(allFiltersData, "inf_followers");

    allFiltersData[0]["inf_followers"][0] = {
      left: utils.getValueByKey(toFollowers, fromFollow, "value", "name"),
      right: utils.getValueByKey(toFollowers, toFollow, "value", "name"),
    };
    setAllFiltersData(allFiltersData);
  };

  const handleRadio = (event) => {
    const value = event.target.value;
    setRadio(value);

    if (value === "Nano") {
      handleFromChange("1000", "inf_followers_from");
      handleFromChange("10000", "inf_followers_to");
    } else if (value === "Micro") {
      handleFromChange("10000", "inf_followers_from");
      handleFromChange("50000", "inf_followers_to");
    } else if (value === "Mid") {
      handleFromChange("50000", "inf_followers_from");
      handleFromChange("500000", "inf_followers_to");
    } else if (value === "Macro") {
      handleFromChange("500000", "inf_followers_from");
      handleFromChange("1000000", "inf_followers_to");
    } else if (value === "Mega") {
      handleFromChange("1000000", "inf_followers_from");
      handleFromChange("", "inf_followers_to");
    }
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={FilterTooltips.follower}>
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                textTransform: "none",
              }}
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              fullWidth
              size="large"
              endIcon={
                open ? (
                  <ArrowDropUp fontSize="large" />
                ) : (
                  <ArrowDropDown fontSize="large" />
                )
              }
            >
              Influencer Size
            </Button>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={2} style={{ height: "auto" }}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControl sx={{ m: 1 }}>Followers</FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        From
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="infFromFollowers"
                        value={
                          typeof allFiltersData[0]["inf_followers"] ===
                          "undefined"
                            ? infFromFollowersSel
                            : utils.getValueByKey(
                                toFollowers,
                                allFiltersData[0]["inf_followers"][0]["left"],
                                "name",
                                "value"
                              )
                        }
                        label="From"
                        onChange={(e) =>
                          handleFromChange(
                            e.target.value,
                            "inf_followers_from",
                            true
                          )
                        }
                      >
                        {fromFollowers.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-simple-select-helper-label">
                        To
                      </InputLabel>
                      <Select
                        onChange={(e) =>
                          handleFromChange(
                            e.target.value,
                            "inf_followers_to",
                            true
                          )
                        }
                        label="To"
                        value={
                          typeof allFiltersData[0]["inf_followers"] ===
                          "undefined"
                            ? infToFollowersSel
                            : utils.getValueByKey(
                                toFollowers,
                                allFiltersData[0]["inf_followers"][0]["right"],
                                "name",
                                "value"
                              )
                        }
                      >
                        {toFollowers.map((data) => (
                          <MenuItem key={data.value} value={data.value}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl fullWidth sx={{ padding: "0 1rem" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={handleRadio}
                    value={radio}
                  >
                    <FormControlLabel
                      value="Nano"
                      control={<Radio />}
                      label={
                        <Typography sx={{ width: "100%" }}>
                          Nano
                          <Typography sx={{ float: "right", color: "gray" }}>
                            1K-10K
                          </Typography>
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Micro"
                      control={<Radio />}
                      label={
                        <Typography sx={{ width: "100%" }}>
                          Micro
                          <Typography sx={{ float: "right", color: "gray" }}>
                            10K-50K
                          </Typography>
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Mid"
                      control={<Radio />}
                      label={
                        <Typography sx={{ width: "100%" }}>
                          Mid
                          <Typography sx={{ float: "right", color: "gray" }}>
                            50K-500K
                          </Typography>
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Macro"
                      control={<Radio />}
                      label={
                        <Typography sx={{ width: "100%" }}>
                          Macro
                          <Typography sx={{ float: "right", color: "gray" }}>
                            500K-1M
                          </Typography>
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Mega"
                      control={<Radio />}
                      label={
                        <Typography sx={{ width: "100%" }}>
                          Mega
                          <Typography sx={{ float: "right", color: "gray" }}>
                            1M+
                          </Typography>
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Followers.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Followers;
