import { Paper } from '@mui/material';
import {
  bool, node, number, object
} from 'prop-types';

// this MyPaper component provides a convenient way to create Paper components with consistent styles and behavior

/**
children: This prop represents the content that will be rendered inside the Paper component.
elevation: This prop controls the elevation (drop shadow) of the Paper component.
fitHeight: This prop is a boolean flag that determines whether the height of the Paper component should be set to 100% (true) 
or should adjust automatically based on its content (false).
*/
const MyPaper = ({
  children, elevation, fitHeight, sx
}) => (
  <Paper
    elevation={elevation}
    sx={{
      p: 3,
      borderRadius: 2,
      border: 1,
      borderColor: '#fdfdfd',
      boxShadow:
        'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
      height: fitHeight ? '100%' : 'auto',
      ...sx
    }}
  >
    {children}
  </Paper>
);

MyPaper.propTypes = {
  children: node,
  elevation: number,
  fitHeight: bool,
  sx: object
};

MyPaper.defaultProps = {
  elevation: 2
};

export default MyPaper;
