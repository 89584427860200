import React from "react";
import ReactEcharts from "echarts-for-react";
import { useLocation } from "react-router-dom";
import { getReachListwise } from "../../services/list/ListDetails";
import { Card, Grid, Typography } from "@mui/material";
import { BarChart } from "../../common/chart";

const ReachByListwise = (props) => {
  const {
    startDate,
    endDate,
    filterBy,
    selectedPlatformName,
    platforms,
    selectedListIds,
    selectedInfluencersIds,
    parentStyle,
  } = props;

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const campaignId = params.get("id");

  const [listEngagementTime, setListEngagementTime] = React.useState([]);
  const [listEngagement, setListEngagement] = React.useState([]);
  const [lists, setLists] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, [
    startDate,
    endDate,
    filterBy,
    selectedPlatformName,
    platforms,
    selectedListIds,
    selectedInfluencersIds,
  ]);

  const fetchData = async () => {
    const platformIds =
      selectedPlatformName === "all"
        ? platforms.map((value) => value.id)
        : [platforms.find((obj) => obj.name === selectedPlatformName).id];
    const response = await getReachListwise(
      campaignId,
      startDate,
      endDate,
      filterBy,
      platformIds,
      [-1],
      selectedListIds.length > 0 ? selectedListIds : [-1],
      selectedInfluencersIds.length > 0 ? selectedInfluencersIds : [-1]
    );

    if (response && response.ListReach && response.ListReach.length > 0) {
      createListWisengagementSummaryArray(response.ListReach);
    } else {
      setListEngagementTime([]);
      setLists([]);
      setListEngagement([]);
    }
  };

  const createListWisengagementSummaryArray = (listReach) => {
    let dateArray = [];
    let valueArray = [];

    listReach.forEach((item) => {
      dateArray.push(item.Key);
      valueArray.push(item.Value);
    });

    setListEngagementTime(dateArray);
    setLists(["List Reach"]);
    setListEngagement([{ name: "List Reach", type: "bar", data: valueArray }]);
  };

  const prepareOption = () => {
    const seriesData = listEngagement.map((data) => ({
      name: data.name,
      type: 'bar',
      data: data.data,
    }));

    return BarChart(listEngagementTime, true, seriesData, lists, ["line", "bar", "stack"]);
  };

  return (
    <Card style={{ padding: "20px", marginTop: "20px", boxShadow: "none" }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Typography
            variant="h4"
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#1E2022",
              marginBottom: "20px",
            }}
          >
            Reach by List
          </Typography>
        </Grid>
      </Grid>
      {listEngagementTime && listEngagementTime.length > 0 ? (
        <ReactEcharts
          option={prepareOption()}
          style={{ height: "400px", width: "100%" }}
        />
      ) : (
        <p style={parentStyle.noData}>No Graph found</p>
      )}
    </Card>
  );
};

export default ReachByListwise;
