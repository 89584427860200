// import styles from "./CustomTabs.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { Tab, Tabs, Tooltip } from "@mui/material";

const CustomTabs = (props) => {
  return (
    <Tabs
      ml={4}
      variant="scrollable"
      scrollButtons="auto"
      value={props.selectedTab}
      sx={{
        ".MuiTab-root": {
          textTransform: "none",
          // fontSize: "14px"
        },
      }}
    >
      {props.tabArray.map((link, index) =>
        link.tooltip ? (
          <Tooltip title={link.tooltipTitle}>
            <Tab
              label={link.label}
              id={link.id}
              value={index.toString()}
              to={link.to}
              component={link.component}
              icon={link.icon}
              iconPosition={link.iconPosition}
            />
          </Tooltip>
        ) : (
          <Tab
            label={link.label}
            id={link.id}
            value={index.toString()}
            to={link.to}
            component={link.component}
            icon={link.icon}
            iconPosition={link.iconPosition}
          />
        )
      )}
    </Tabs>
  );
};

export default CustomTabs;
