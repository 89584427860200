import React, { Component } from "react";

/* intra-project reference */
import utils from '../../utils/utils';
import enums from '../../utils/enums';

/* mui */
import { IconButton, Button, Box, Typography } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';

/* post history timeline */
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'; // Import opposite content component
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

export default class PostHistoryTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postHistory: this.props.postHistory
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.postHistory !== this.props.postHistory) {
        this.setState({ postHistory: this.props.postHistory });
    }
}

  drawTimeline = () => {
    if (this.state.postHistory && this.state.postHistory.length > 0) {
      return (
        <Timeline>
          {this.state.postHistory.map((historyItem, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent sx={{ py: 1, px: 2 }}> {/* Adjusted for left side content */}
                <Typography variant="body2" color="textSecondary" sx={{ fontSize: '0.875rem' }}>
                  {new Date(historyItem.createdAt).toLocaleString('en-US', {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true
                    })}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {enums.statusMapping[historyItem.status]}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                {index < this.state.postHistory.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ py: 1, px: 2 }}> {/* Adjusted for right side content */}
                {historyItem.comment && (
                  <Typography variant="body2">
                    {historyItem.comment}
                  </Typography>
                )}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      );
    } else {
      return (
        <Typography align="center" color="textSecondary">
          Nothing to show here!!
        </Typography>
      );
    }
  }

  HandlePostHistoryModalClose = () => {
    this.props.HandlePostReviewModalClose(true);
  }

  render() {
    return (
      <div className="syles_paper_list" style={{ height: "auto", width: "100%", backgroundColor: "#fff", borderRadius: "20px", padding: "20px", border: "1px solid #ccc", marginTop: "20px" }}>
        <div id="modal-post_history-body" style={{ padding: "10px" }}>
          {this.drawTimeline()}
        </div>
      </div>
    );
  }
}
