import axios from "axios";

const accountApi = process.env.REACT_APP_ACCOUNT_API;
const awsEnv = process.env.REACT_APP_AWS_ENV;

const sendEmailVerification = async (id, email) =>
  axios({
    url: `${accountApi}${awsEnv}/otp/send-email`,
    method: "POST",
    data: {
      influencerContactId: id,
      email,
    },
  });

const verifyEmail = async (id, code) =>
  axios({
    url: `${accountApi}${awsEnv}/otp/verify-email`,
    method: "POST",
    data: {
      influencerContactId: id,
      code,
    },
  });

const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

const validateBusinessEmails = (email) => {
  const listOfDomains = ["gmail", "yahoo", "hotmail", "rediffmail"];
  const emailParts = email?.split("@");
  if (emailParts.length !== 2) {
    return false; // Invalid email format
  }
  const domainFull = emailParts[1]?.toLowerCase();
  const domain = domainFull?.split(".")[0];
  // Check if the domain is in the listOfDomains array
  return listOfDomains.includes(domain);
};

const validateAiFluenceEmails = (email, agency) => {
  const emailParts = email?.split("@");
  const domainFull = emailParts[1]?.toLowerCase();
  const domain = domainFull?.split(".")[0];
  if ((domain?.toLowerCase() === "ai-fluence" && agency?.name?.toLowerCase() !== "aifluence")) return true;
  else {
    return false;
  }
};

const sendPhoneVerification = async (id, dialCode, phoneNumber) =>
  axios({
    url: `${accountApi}${awsEnv}/otp/send-sms`,
    method: "POST",
    data: {
      influencerContactId: id,
      phone: `+${dialCode}${phoneNumber}`,
    },
  });

const verifyPhone = async (id, code) =>
  axios({
    url: `${accountApi}${awsEnv}/otp/verify-phone`,
    method: "POST",
    data: {
      influencerContactId: id,
      code,
    },
  });

const validatePhone = (dialCode, phoneNumber) => {
  const regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  return regex.test(`+${dialCode}${phoneNumber}`);
};

export {
  sendEmailVerification,
  verifyEmail,
  validateEmail,
  sendPhoneVerification,
  verifyPhone,
  validatePhone,
  validateBusinessEmails,
  validateAiFluenceEmails,
};
