import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import StyledMenu from "../StyledMenu";
import utils from '../../../utils/utils';

import { FormControl, Grid, RadioGroup, Tooltip, Radio } from "@mui/material";
import CustomFormControlLabel from "./common/CustomFormControlLabel";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const AudienceType = ({
  showFilter,
  customCols,
  setCustomCols,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [audType] = useState("any");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    let newPayload = payload;
    let aud_type = event.target.value;
    if (event.target.value !== "any") {
      newPayload["aud_audience_type"] = aud_type;
      allFiltersData = utils.addObj(allFiltersData, "aud_audience_type");
      allFiltersData[0]["aud_audience_type"][0] = aud_type;
    } else {
      delete newPayload["aud_audience_type"];
      delete allFiltersData[0]["aud_audience_type"];
    }
    setAllFiltersData(allFiltersData);
    setPayload(newPayload);
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={FilterTooltips.audienceType}>
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                textTransform: "none",
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              fullWidth
              size="large"
              endIcon={open ? <ArrowDropUp fontSize="large" /> : <ArrowDropDown fontSize="large" />}
            >
              Audience Type
            </Button>
          </Tooltip>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1} style={{ height: "auto" }}>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControl sx={{ m: 1 }}>Audience Type</FormControl>
                  </Grid>
                </Grid>
                <FormControl sx={{ m: 1 }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="any"
                    name="radio-buttons-group"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    value={
                      typeof allFiltersData[0]["aud_audience_type"] ===
                      "undefined"
                        ? audType
                        : allFiltersData[0]["aud_audience_type"][0]
                    }
                  >
                    <CustomFormControlLabel
                      value="any"
                      control={<Radio size="small" />}
                      label="Any"
                    />
                    <CustomFormControlLabel
                      value="likers"
                      control={<Radio size="small" />}
                      label="Likers"
                    />
                    <CustomFormControlLabel
                      value="followers"
                      control={<Radio size="small" />}
                      label="Followers"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

AudienceType.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default AudienceType;
