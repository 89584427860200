import { createTheme } from '@mui/material';
import typography from './typography';
import palette from './palette';
import MuiMenuItem from './components/MuiMenuItem';

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiMenuItem,
    MuiCssBaseline: {
      styleOverrides: `
          .pac-container {
            z-index: 1500 !important;
          }
        `,
    },
  }
});

export default theme;
