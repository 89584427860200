import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import utils from "../../../utils/utils";
import PublishedPostAnalysis from "./PublishedPostAnalysis";
import DraftPostAnalysis from "./DraftPostAnalysis";
import TabPanel from "../../general/TabPanel";
import { Delete, Drafts, Publish } from "@mui/icons-material";
import CampaignActions from "../../../pages/account/CampaignActions";
import CustomTabs from "../../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../../utils/campaignTabsArray";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PostAnalysis = () => {
  const [value, setValue] = React.useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [state, setState] = React.useState({
    campId: parseInt(params.get("id")),
    accId: parseInt(params.get("accId")),
    name: "",
  });
  const tabArray = campaignTabsArray(state.campId, state.accId);

  React.useEffect(() => {
    getCampaignInfo();
  }, [params.get("id")]);

  const getCampaignInfo = () => {
    utils.getCampaignData(state.campId).then((data) => {
      setState({ ...state, name: data.name, campId: parseInt(params.get("id")) });
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {" "}
      <CampaignActions name={state.name} id={state.campId} />
      <br />
      <CustomTabs tabArray={tabArray} selectedTab={"2"} />
      <Box
        sx={{
          width: "100%",
          paddingRight: "10px",
          paddingTop: "15px"
        }}
        ml={2}
      >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Published" {...a11yProps(0)} />
          <Tab label="Draft" {...a11yProps(1)} />
          <Tab label="Deleted" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <PublishedPostAnalysis deleted={"0"} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DraftPostAnalysis />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PublishedPostAnalysis deleted={"1"} />
        </TabPanel>
      </Box>
    </>
  );
};
export default PostAnalysis;
