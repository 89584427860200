import { Face, Flag, Lightbulb, PermIdentity, School, Synagogue } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { object, string } from "prop-types";
import styles from "../../../styles/theme/pageStyling/ProfileDetails.module.css";

const BasicInformation = ({ basicDetails }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={styles.aboutMeHeader}>
        Basic Information
      </Grid>
      <Grid item xs={12} className={styles.aboutMeContent}>
        <Grid container spacing={1}>
          <Grid item xs={1} className={styles}>
            <Face />
          </Grid>
          <Grid item xs={5}>
            {basicDetails.gender.length > 0 ? basicDetails.gender : "-"}
          </Grid>
          <Grid item xs={1} className={styles}>
            <PermIdentity />
          </Grid>
          <Grid item xs={5}>
            {basicDetails.age > 0 ? `${basicDetails.age} years old` : "-"}
          </Grid>
          <Grid item xs={1} className={styles}>
            <Flag />
          </Grid>
          <Grid item xs={5}>
            {basicDetails.nationality.length > 0 ? `${basicDetails.nationality} Nationality` : "-"}
          </Grid>
          <Grid item xs={1} className={styles}>
            <Lightbulb />
          </Grid>
          <Grid item xs={5}>
            {basicDetails.political_ideology.length > 0 ? `${basicDetails.political_ideology} Political Ideology` : "-"}
          </Grid>

          <Grid item xs={1} className={styles}>
            <Synagogue />
          </Grid>
          <Grid item xs={5}>
            {basicDetails.religion.length > 0 ? `${basicDetails.religion} Religion` : "-"}
          </Grid>
          <Grid item xs={1} className={styles}>
            <School />
          </Grid>
          <Grid item xs={5}>
            {basicDetails.education_level.length > 0 ? `${basicDetails.education_level} Education Level` : "-"}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
BasicInformation.propTypes = {
  basicDetails: object.isRequired,
};
export default BasicInformation;
