import { Link, useLocation, useNavigate } from "react-router-dom";
const campaignTabsArray = (campId, accId) => {
    return [
        { label: 'Analytics', id: "0", to: `/campaigns/analysis?id=${campId}`, component: Link },
        { label: 'Influencers', id: "1", to: `/campaigns/influencers?id=${campId}`, component: Link },
        { label: 'Posts', id: "2", to: `/campaigns/postAnalysis?id=${campId}`, component: Link },
        { label: 'Lists', id: "3", to: `/campaigns/listAnalysis?id=${campId}`, component: Link },
        { label: 'Links', id: "4", to: `/campaigns/linkAnalysis?id=${campId}`, component: Link },
        { label: 'Contracts', id: "5", to: `/campaigns/contracts?id=${campId}`, component: Link },
        { label: 'Sentiment', id: "6", to: `/campaigns/sentiment/analysis?id=${campId}`, component: Link },
        { label: 'Firebase', id: "7", to: `/campaigns/Applinks?id=${campId}`, component: Link },
        { label: 'Tasks', id: "8", to: `/campaigns/tasks?id=${campId}`, component: Link },
        { label: 'Reports', id: "9", to: `/campaigns/reports?id=${campId}`, component: Link },
        {/* <Tab label="Audience" id="1" value="1" to={linkToAudienceTab} component={Link} /> */ }
    ];
};

export default campaignTabsArray;