import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Box } from "@mui/material";
import utils from '../../utils/utils';

function CustomDropdown({milestones, cid,  milestoneId, setMilestoneId, selectedOption, setSelectedOption, disabled, setBackdropShow}) {
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState([]); // Default options
    useEffect(() => {
        if (milestones !== undefined && milestones !== null && milestones.length > 0) {
            setOptions(milestones);
        }
      }, [milestones, selectedOption]);
    
    
    const handleInputChange = (event, newValue) => {
        if (typeof newValue === 'string') {
            // When the input value is a string, set it as the new option
            setValue(newValue);
            // Check if the entered value is already in options by label
            const selectedOption = options.find((option) => option.label === newValue);
            if (selectedOption) {
                setSelectedOption(selectedOption.label); // Set the ID as selected if it exists
                setMilestoneId(selectedOption.id);
            }
        } else if (newValue && newValue.inputValue) {
            // When the inputValue property exists in newValue, it means a new option is entered
            // You can add the new option to your list of options and set its ID as the value
            const newOption = { label: newValue.inputValue, id: options?.length + 1 }; // Assign a unique ID
            setOptions([...options, newOption]);
            setSelectedOption(newOption.label);
            setMilestoneId(newOption.id);
        } else if (newValue) {
            setSelectedOption(newValue.label); // Set the ID of the selected option
            setMilestoneId(newValue.id);
        } else {
            setSelectedOption(null);
            setMilestoneId(null)

        }
    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter' && value) {
            setBackdropShow(true);
            // Check if the value is already in options by label
            const selectedOption = options.find((option) => option.label === value);
            if (!selectedOption) {
                const payload = {
                    "cid": parseInt(cid),
                    "name": value
                }
                utils.createMilestone(payload).then((data) => {
                    if(data.status==="success") {
                        const newOption = { label: value, id: data.id};
                        setOptions([...options, newOption]);
                        setSelectedOption(newOption.label);
                        setMilestoneId(data.id);
                        setBackdropShow(false);
                    }
                });
            }
        }
    };

    const noOptionsText = `Press Enter to create new milestone: "${value}"`;
    return (
        <div>

        <Autocomplete
            disableClearable
            id="combo-box-demo"
            value={selectedOption}
            disabled={disabled}
            onChange={(event, newValue) => {
                setSelectedOption(newValue);
            }}
            onInputChange={handleInputChange}
            options={options}
            renderInput={(params) => <TextField {...params} label="Select Milestone" onKeyPress={handleInputKeyPress}
            sx={{ border: 'none' }} // Remove the inner border
            />}
            renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                </Box>
            )}
            noOptionsText={noOptionsText} // Customize the "No Options" text
        />  
            </div>
    );
}

export default CustomDropdown;
