import { Grid } from "@mui/material";
import { string } from "prop-types";
import styles from "../styles/theme/pageStyling/widget.module.css";
const Widget = ({ name, count, completeString }) => {
  return (
    <Grid container className={styles.widgetBackground}>
      <Grid item xs={12} className={styles.heading}>
        {name}
      </Grid>
      <Grid item xs={12} className={styles.count} title={completeString}>
        {count}
      </Grid>
    </Grid>
  );
};
Widget.propTypes = {
  name: string.isRequired,
  count: string.isRequired,
  completeString: string.isRequired,
};

export default Widget;
