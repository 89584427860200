import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as HeartIcon } from "../../utils/heart.svg";
import { ReactComponent as BrandsIcon } from "../../utils/brands.svg";
import { ReactComponent as WorkIcon } from "../../utils/work.svg";
import { CircularProgress, Box, Tab, Tabs, Typography, FormControl, Tooltip, Snackbar } from "@mui/material";
import utils from "../../utils/utils";
import Grid from "@mui/material/Grid";
import MuiAlert from "@mui/material/Alert";
import "../../styles/internal/CampaignAnalytics.css";
import Widget from "../../utils/Widget";
import AccountCampigns from '../../components/accountsDashboard/AccountCampigns';
import AccountBrands from '../../components/accountsDashboard/AccontsBrands';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const chartOptions = {
  maintainAspectRatio: false,
};

const AccountDashbord = () => {
  const datePickerRef = useRef(Date.now())
  const [accId, setAccId] = useState(null);
  const [fields, setFields] = useState({});
  const [widgetArray, setWidgetArray] = useState([]);
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);


  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const accId = params.get("id");
    setAccId(accId)
    getAccountDetails(accId);
  }, []);

  const getAccountDetails = (accId) => {
    utils.getAccountSumary(accId).then((data) => {
      setFields(data);

      const widgetObject = [
        {
          icon: <HeartIcon fontSize="small" />,
          value: data.liveCampaignCount || 0,
          name: "Live Campaigns",
          info: false,
        },
        {
          icon: <BrandsIcon fontSize="small" />,
          value: data.allBrandCount || 0,
          name: "Active Brands",
          info: false,
        },
        {
          icon: <WorkIcon fontSize="small" />,
          value: data.allUserCount || 0,
          name: "Total Users",
          info: false,
        },
        {
          icon: <WorkIcon fontSize="small" />,
          value: data.allPublicationCount || 0,
          name: "Total Publications",
          info: false,
        },
      ];
      setWidgetArray(widgetObject);
    })
  }

  const closeNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification(false);
  };

  return (
    <>
      <Grid item xs={10.5}>
        <Typography variant="h2">{fields.accountName}</Typography>
      </Grid>

      <div id="campaign-analysis">
            <>
              <Grid container sx={{ paddingRight: "20px", paddingTop: "20px" }}>
                <Grid item>

                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 8, md: 12 }}>
                    {widgetArray.map((widget, index) => (
                      <Grid item key={index} fontSize={12}>
                        <Widget widget={widget} />
                      </Grid>
                    ))}
                  </Grid>

                </Grid>
              </Grid>
            </>

        <br />

        <div id="account-campaign">
          <>
            <AccountCampigns
              accId={accId}
            />
          </>
        </div>

        <div id="account-campaign">
          <>
            <AccountBrands
              accId={accId}
            />
          </>
        </div>

        <Snackbar
          open={notification}
          autoHideDuration={3000}
          onClose={closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={closeNotification} severity="success" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

export default AccountDashbord;
