import React, { useState, useEffect } from "react";
import ReactEcharts from 'echarts-for-react';
import utils from "../../utils/utils";
import MyPaper from "../general/MyPaper";
import { Typography } from "@mui/material";
import { BarChart } from "../../common/chart";

const colorMap = {
  Post: '#7244AE',
  Story: '#4299E1',
  Reel: '#d63384'
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = String(date.getFullYear()).slice(-2);
  return `${day} ${month} ${year}`;
};

const EngReachByPostType = (props) => {
  const { campId, startDate, endDate, filterBy, selectedPlatform, selectedPlatformName, selectedListIds, selectedInfluencersIds, type, parentStyle } = props;

  const [caption, setCaption] = useState(type === "reach" ? "Reach by Post Type" : "Engagement by Post Type");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ labels: [], datasets: { post: [], story: [], reel: [] } });
  const [dataFound, setDataFound] = useState(false);

  useEffect(() => {
    getEngReachByPostTypeDataset();
  }, [campId, startDate, endDate, filterBy, selectedPlatform, selectedListIds, selectedInfluencersIds]);

  const getEngReachByPostTypeDataset = async () => {
    setLoading(true);
    try {
      const response = await utils.getEngReachByPostType(
        campId,
        startDate,
        endDate,
        filterBy,
        selectedPlatform,
        [-1],
        selectedListIds.length > 0 ? selectedListIds : [-1],
        selectedInfluencersIds.length > 0 ? selectedInfluencersIds : [-1]
      );

      let aggregatedData = { labels: [], datasets: { post: [], story: [], reel: [] } };
      if (response && response[type]) {
        if (selectedPlatformName === "all") {
          const allPlatforms = Object.keys(response[type]);
          let tempData = {};
          allPlatforms.forEach(platform => {
            const dataByDay = response[type][platform]?.day || {};
            Object.keys(dataByDay).forEach(date => {
              if (!tempData[date]) {
                tempData[date] = { post: 0, story: 0, reel: 0 };
              }
              tempData[date].post += dataByDay[date].post;
              tempData[date].story += dataByDay[date].story;
              tempData[date].reel += dataByDay[date].reel;
            });
          });

          aggregatedData.labels = Object.keys(tempData).sort((a, b) => new Date(a) - new Date(b));
          aggregatedData.datasets.post = aggregatedData.labels.map(date => tempData[date].post);
          aggregatedData.datasets.story = aggregatedData.labels.map(date => tempData[date].story);
          aggregatedData.datasets.reel = aggregatedData.labels.map(date => tempData[date].reel);
        } else if (response[type][selectedPlatformName]) {
          const dataByDay = response[type][selectedPlatformName].day;
          aggregatedData.labels = Object.keys(dataByDay).sort((a, b) => new Date(a) - new Date(b));
          aggregatedData.datasets.post = aggregatedData.labels.map(date => dataByDay[date].post);
          aggregatedData.datasets.story = aggregatedData.labels.map(date => dataByDay[date].story);
          aggregatedData.datasets.reel = aggregatedData.labels.map(date => dataByDay[date].reel);
        }

        // Format the dates here
        aggregatedData.labels = aggregatedData.labels.map(formatDate);

        setData(aggregatedData);
        setLoading(false);
        setDataFound(aggregatedData.labels.length > 0);
      } else {
        setData({ labels: [], datasets: { post: [], story: [], reel: [] } });
        setLoading(false);
        setDataFound(false);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setData({ labels: [], datasets: { post: [], story: [], reel: [] } });
      setLoading(false);
      setDataFound(false);
    }
  };

  const renderEchartOptions = () => {
    if (!data.labels.length) return {};
  
    const maxValue = Math.max(...data.datasets.post, ...data.datasets.story, ...data.datasets.reel) * 1.1;
    const totalPost = data.datasets.post.reduce((acc, val) => acc + val, 0);
    const totalStory = data.datasets.story.reduce((acc, val) => acc + val, 0);
    const totalReel = data.datasets.reel.reduce((acc, val) => acc + val, 0);
    const totalAll = totalPost + totalStory + totalReel;
  
    const legendData = [];
    if (totalPost > 0) legendData.push('Post');
    if (totalStory > 0) legendData.push('Story');
    if (totalReel > 0) legendData.push('Reel');
  
    const percentages = {
      'Post': ((totalPost / totalAll) * 100).toFixed(2) + '%',
      'Story': ((totalStory / totalAll) * 100).toFixed(2) + '%',
      'Reel': ((totalReel / totalAll) * 100).toFixed(2) + '%'
    };
  
    const seriesData = legendData.flatMap(type => [
      {
        name: type,
        type: 'bar',
        stack: type,
        data: data.datasets[type.toLowerCase()],
        itemStyle: { color: colorMap[type], borderRadius: [10, 10, 10, 10] },
        barWidth: 5,
        z: 1
      },
      {
        name: `Shadow ${type}`,
        type: 'bar',
        stack: type,
        data: data.datasets[type.toLowerCase()].map(value => Math.min(value, maxValue)),
        itemStyle: { color: '#f7f7f7', borderRadius: [10, 10, 0, 0] },
        barGap: '10%',
        z: 0
      }
    ]);
  
    return BarChart(data.labels, true, seriesData, legendData, ['bar'], {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      legend: {
        data: legendData,
        top: '0',
        right: 'center',
        orient: 'horizontal',
        icon: 'circle',
        textStyle: { fontSize: 16 },
        formatter: function (name) { return `${name} - ${percentages[name]}`; }
      },
      grid: { left: '3%', right: '4%', bottom: '10%', containLabel: true },
      xAxis: { type: 'category', data: data.labels, axisTick: { alignWithLabel: true } },
      yAxis: { type: 'value' }
    });
  };
  

  return (
    <MyPaper sx={{ marginTop: "20px", boxShadow: 'none' }}>
      <Typography variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#1E2022', marginBottom: "20px" }}>
        {caption}
      </Typography>
      {dataFound ? (
        <div style={{ height: "50vh" }}>
          <ReactEcharts option={renderEchartOptions()} style={{ height: '100%', width: '100%' }} />
        </div>
      ) : (
        <p style={parentStyle.noData}>No data available</p>
      )}
    </MyPaper>
  );
};

export default EngReachByPostType;
