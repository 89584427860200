import React, { Component } from "react";
import { Box, Typography, Stack } from "@mui/material";
import MyPaper from "../general/MyPaper";
import Grid from "@mui/material/Grid";
import utils from "../../utils/utils";
import FetchingDetailsLoader from "../general/FetchingDetailsLoader";
import { boxStyles, headerStyle, textColor, snapshotHeaderIcons } from "./snapshot";
import GroupIcon from "@mui/icons-material/Group";
import ImageIcon from "@mui/icons-material/Image";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import PeopleIcon from "@mui/icons-material/People";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { FaInfinity } from "react-icons/fa";
import { AllInclusive } from "@material-ui/icons";


let campId = 0;
let accId;
class CampaignSnapshot extends Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    campId = params.get("id");
    accId = params.get("accId");
    this.state = {
      showSnapshot: true,
      loading: true,
      snapshotData: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate ||
      prevProps.selectedPlatform !== this.props.selectedPlatform ||
      prevProps.selectedListIds !== this.props.selectedListIds ||
      prevProps.selectedInfluencersIds !== this.props.selectedInfluencersIds
    ) {
      this.setState({
        loading: true,
        snapshotData: [],
      });

      this.getSnapShotStats(
        this.props.campId,
        this.props.startDate,
        this.props.endDate,
        this.props.filterBy,
        this.props.selectedPlatform,
        this.props.selectedListIds,
        this.props.selectedInfluencersIds
      );
    }
  }

  componentDidMount() {
    this.getSnapShotStats(
      this.props.campId,
      this.props.startDate,
      this.props.endDate,
      this.props.filterBy,
      this.props.selectedPlatform,
      this.props.selectedListIds,
      this.props.selectedInfluencersIds
    );
  }

  getSnapShotStats(campId, sd, ed, filter, platformId, lids, iids) {
    if (lids.length == 0) lids = [-1];
    if (iids.length == 0) iids = [-1];
    utils.getCampaignSnapshotStats(campId, sd, ed, filter, platformId, [-1], lids, iids).then((res) => {
      if (res.Status && res.Data) {
        let temp = [];
        let total = {};
        let pushTotal = false;
        for (let i in res.Data) {
          if (i === "total") {
            total[i] = res.Data[i];
            pushTotal = true;
          } else {
            let obj = {};
            obj[i] = res.Data[i];
            temp.push(obj);
          }
        }
        if (pushTotal) temp.push(total);
        this.setState({
          showSnapshot: true,
          snapshotData: temp,
        });
      } else {
        this.setState({
          showSnapshot: false,
        });
      }
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    if (this.state.showSnapshot) {
      if (this.state.loading) {
        return <FetchingDetailsLoader />;
      } else {
        return (
          <>
            <MyPaper sx={{ marginTop: "20px", marginBottom: "5px", backgroundColor: "#E2EDFC", boxShadow: "none" }}>
              {/* <Stack direction="row" justifyContent="start">
            <Typography variant="h4" sx={this.props.parentStyle.typography}>
              Campaign Snapshot
            </Typography>
          </Stack> */}

              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 5, sm: 12, md: 14 }}>
                {this.state.snapshotData.map((snap) => {
                  const platform = Object.keys(snap)[0];
                  return (
                    <>
                      <Grid key={"header"} item xs={1} sm={2} md={2}>
                        <div style={headerStyle[platform]}>
                          <Typography sx={textColor[platform]}>
                            {snapshotHeaderIcons[platform]}
                            &nbsp;
                            {platform === "total" ? "All Platforms" : utils.capitalizeFirstLetter(platform)}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid key={"influencers"} item xs={1} sm={2} md={2}>
                        <Box
                          sx={{ ...boxStyles[platform], borderRadius: "9px", display: "flex", alignItems: "center", gap: "10px" }}
                        >
                          <GroupIcon fontSize="small" />
                          <Typography sx={{ fontSize: "15px", lineHeight: "1" }}>
                            {utils.convertToInternationalNumberSystem(snap[platform].influencers)} Influencers
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid key={"posts"} item xs={1} sm={2} md={2}>
                        <Box
                          sx={{ ...boxStyles[platform], borderRadius: "9px", display: "flex", alignItems: "center", gap: "10px" }}
                        >
                          <ImageIcon fontSize="small" />
                          <Typography sx={{ fontSize: "15px", lineHeight: "1" }}>
                            {utils.convertToInternationalNumberSystem(snap[platform].posts)} Posts
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid key={"infReach"} item xs={1} sm={2} md={2}>
                        <Box
                          sx={{
                            ...boxStyles[platform],
                            borderRadius: "9px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <VolumeUpIcon fontSize="small" />
                          <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                            <Typography sx={{ fontSize: "15px", lineHeight: "1" }}>
                              {utils.convertToInternationalNumberSystem(snap[platform].followers)}
                              &nbsp;
                              <br />
                              Followers
                            </Typography>
                          </div>
                        </Box>
                      </Grid>

                      <Grid key={"postReach"} item xs={1} sm={2} md={2}>
                        <Box
                          sx={{ ...boxStyles[platform], borderRadius: "9px", display: "flex", alignItems: "center", gap: "10px" }}
                        >
                          <PeopleIcon fontSize="small" />
                          <Typography sx={{ fontSize: "15px", lineHeight: "1" }}>
                          {utils.convertToInternationalNumberSystem(snap[platform].views)}
                            <br /> 
                            Reach
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid key={"engagement"} item xs={1} sm={2} md={2}>
                        <Box
                          sx={{ ...boxStyles[platform], borderRadius: "9px", display: "flex", alignItems: "center", gap: "10px" }}
                        >
                          <FavoriteIcon fontSize="small" />
                          <div style={{ textAlign: "center" }}>
                            <Typography sx={{ fontSize: "15px", lineHeight: "1" }}>
                              {utils.convertToInternationalNumberSystem(snap[platform].engagement)}
                              <br />
                              Engagement
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid key={"engRate"} item xs={1} sm={2} md={2}>
                        <Box
                          sx={{ ...boxStyles[platform], borderRadius: "9px", display: "flex", alignItems: "center", gap: "7px" }}
                        >
                          <AllInclusive fontSize="small" />
                          <div style={{ textAlign: "center" }}>
                            <Typography sx={{ fontSize: "15px", lineHeight: "1" }}>
                              {snap[platform].engRate.toFixed(2)} % ERR
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </MyPaper>
            <a href={`/campaigns/postAnalysis?id=${campId}&unresolved`}  style={{ fontSize: "14px", paddingLeft: "10px" }}>* does not include unresolved posts</a>
          </>
        );
      }
    } else {
      return <></>;
    }
  }
}

export default CampaignSnapshot;
