import React, { Component } from "react";

import ChartVisualization from "../visualization/ChartVisualization";

/*
chartOptions can have multiple options
____________________________________________________________________________________________________
key             | value                                             | default
----------------------------------------------------------------------------------------------------
indexAxis       | 'x' / 'y'                                         | 'x'
legend.display  | true / false                                      | false
legend.position | 'top' / 'left' / 'bottom' / 'right' / 'chartArea' | 'top'
____________________________________________________________________________________________________
*/
class LinkAnalysisChart extends Component {
  constructor(props) {
    super(props);
  }

  renderChart() {
    if(!this.props.data) {
      return null;
    }
    let defaultChartOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        }
      },
      // doing anything to x won't draw the chart
      // scales: {
      //   x: {
      //     grid: {
      //       display: false
      //     }
      //   },
      //   y: {
      //     grid: {
      //       display: false
      //     }
      //   },
      // }
    }
    if(this.props.chartOptions) {
      defaultChartOptions = {...this.props.chartOptions, ...defaultChartOptions};
      if(this.props.chartOptions.legend) {
        defaultChartOptions.plugins.legend = this.props.chartOptions.legend;
      }
    }
    return(
      <ChartVisualization
        isToggle={this.props.isToggle}
        data={this.props.data}
        additionalData={(this.props.additionalData)?(this.props.additionalData):{}}
        parentStyle={this.props.styles}
        chartOptions={defaultChartOptions}
        chartTitle={this.props.chartTitle}
        chartType={this.props.chartType}
        chartMenu={this.props.chartMenu}
        defaultMenu={this.props.defaultMenu}
      />
    )
  }

  render() {
    return(
      <>
        {this.renderChart()}
      </>
    )
  }
}

export default LinkAnalysisChart;