import { Close, Download, Info, Preview } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_HOST } from "../../config/main.json";
import utils from "../../utils/utils";
import Datatables from "mui-datatables";
import FileSaver from "file-saver";
import CustomTabs from "../../common/CustomTabs/CustomTabs";

function InboundLeads() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [backdropShow, setBackdropShow] = useState(true);
  const [data, setData] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = () => {
    axios.get(API_HOST + "/requestdemo/all").then((res) => {
      let data = [];
      if (res.data) {
        data = res.data;
      }
      setData(data);
      setIsLoaded(true);
      setBackdropShow(false);
    });
  };

  const openPreviewModal = (uuid) => {
    axios.get(API_HOST + "/requestdemo/response/" + uuid).then((res) => {
      const responseData = res.data[0];
      setResponseData(responseData);
      setPreviewModal(true);
    });
  };

  const downloadRequestDemoResponse = async (uuid) => {
    const response = await axios({
      method: "GET",
      url: `${API_HOST}/requestdemo/download/${uuid}`,
      responseType: "blob",
    });
    await FileSaver(response.data, `${uuid}.pdf`);
  };

  const closeModal = () => {
    setPreviewModal(false);
  };

  let tableData = [];
  tableData = data.map((response) => {
    const row = { ...response };
    row.nameColumn = (
      <Grid container>
        <Grid item xs={2}>
          <Tooltip title={"Email: " + row.email}>
            <Info fontSize="small" />
          </Tooltip>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={9}>
          {row.fullName}
        </Grid>
      </Grid>
    );
    row.submissionTimeColumn =
      utils.convertDate(row.createdAt, "withtime") + " GMT";
    row.actions = (
      <>
        <IconButton onClick={() => openPreviewModal(row.uuid)}>
          <Tooltip title={"View Response"}>
            <Preview fontSize="small" />
          </Tooltip>
        </IconButton>
        <IconButton onClick={() => downloadRequestDemoResponse(row.uuid)}>
          <Tooltip title={"Download Response"}>
            <Download fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
    return row;
  });

  const columns = [
    {
      label: "Name",
      name: "nameColumn",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value) => {
          return value;
        },
      },
    },
    {
      label: "Name",
      name: "fullName",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      label: "Organisation",
      name: "organisation",
    },
    {
      label: "Type",
      name: "businessType",
    },
    {
      label: "Submitted At",
      name: "submissionTimeColumn",
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
        sort: false,
        download: false,
      },
    },
  ];

  const options = {
    textLabels: {
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    responsive: "simple",
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchPlaceholder: "Search by all visible columns",
  };

  const tabArray = [
    {
      label: "Inbound Leads",
      id: "inbound-leads-tab-0",
      to: "/bdTools/inboundLeads",
      component: Link,
    },
  ]

  return isLoaded ? (
    <>
      <Box m={3} style={{ paddingBottom: "35px" }}>
        <CustomTabs tabArray={tabArray} selectedTab={"0"} />
      </Box>
      <Datatables data={tableData} columns={columns} options={options} />
      <Modal
        className="styles_modal"
        aria-labelledby="modal-preview-label"
        aria-describedby="modal-preview-description"
        open={previewModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <Fade in={previewModal}>
          <div
            className="syles_paper"
            style={{ maxHeight: "80vh", width: "50%" }}
          >
            <div id="modal-preview-heading" className="styles_modal_heading">
              <h2 id="modal-preview-heading-text" style={{ padding: "10px" }}>
                Response
              </h2>
              <IconButton onClick={closeModal}>
                <Close />
              </IconButton>
            </div>
            <hr />
            <div id="modal-preview-body" style={{ padding: "10px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Full Name"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.fullName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Work Email"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Organisation"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.accountName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Which best describes you?"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.businessType}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Country"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.country}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Phone Number"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={`+${responseData.dialCode} ${responseData.phoneNumber}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={3}
                    variant="outlined"
                    label="Anything else we should know?"
                    style={{ width: "100%" }}
                    inputProps={{
                      readOnly: true,
                    }}
                    value={responseData.description}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
      open={backdropShow}
    >
      <CircularProgress color="inherit" />
      <Box sx={{ marginTop: "20px" }}>Fetching data... please wait.</Box>
    </Backdrop>
  );
}

export default InboundLeads;
