import { useEffect, useState } from "react";
import { Select, InputLabel, FormControl, MenuItem, Grid, Button } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../utils/tiktok-brands.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import utils from "../../utils/utils";
import CustomFilter from "./FilterComponents/common/CustomFilter";
import { FilterTooltips } from "./FilterComponents/static/FiltersTooltips";
import FilterEnum from "./FilterEnum";
import { getLocationData } from "./FilterComponents/service/fetchDataByFilter";
import Followers from "./FilterComponents/Followers";
import Age from "./FilterComponents/Age";
import Genders from "./FilterComponents/Gender";
import TuneIcon from "@mui/icons-material/Tune";
import Bio from "./FilterComponents/Bio";
import Keywords from "./FilterComponents/Keywords";

const socialIcons = {
  instagram: <InstagramIcon sx={{ color: "#BC2A8D" }} />,
  twitter: <TwitterIcon sx={{ color: "#00ACEE" }} />,
  tiktok: <TikTokIcon style={{ width: "24px", height: "24px" }} sx={{ color: "#000000" }} />,
  youtube: <YouTubeIcon sx={{ color: "#FF0100" }} />,
  linkedin: <LinkedInIcon sx={{ color: "#0a66c2" }} />,
  facebook: <FacebookIcon sx={{ color: "#1b74e4" }} />,
};

const contentOptions = ["bio", "keywords", "hashtags / mentions"];

const Platforms = ({
  setSelectedData,
  setTableDataUpdated,
  customCols,
  setCustomCols,
  setTableDataLoaded,
  tablePageChanged,
  tableRowsPerPageChanged,
  tableRowsPerPage,
  tablePageNo,
  isChanged,
  setIsChanged,
  resetPageNo,
  setTablePageNo,
  payload,
  setPayload,
  allFiltersData,
  setAllFiltersData,
  selectedCol,
  setSelectedCol,
  sortCol,
  selectedPlatform,
  setSelectedPlatform,
  clearAllFilter,
  expandMoreFilters,
  isMoreFiltersExpanded,
  appliedFilters,
}) => {
  const [allPlatforms] = useState(["instagram", "tiktok", "youtube"]);
  const contentOptions = ["bio", "keywords", "hashtags / mentions"];
  const socialIcons = {
    instagram: <InstagramIcon sx={{ color: "#BC2A8D" }} />,
    twitter: <TwitterIcon sx={{ color: "#00ACEE" }} />,
    tiktok: (
      <TikTokIcon
        style={{ width: "24px", height: "24px" }}
        sx={{ color: "#000000" }}
      />
    ),
    youtube: <YouTubeIcon sx={{ color: "#FF0100" }} />,
    linkedin: <LinkedInIcon sx={{ color: "#0a66c2" }} />,
    facebook: <FacebookIcon sx={{ color: "#1b74e4" }} />,
  };
  const [selectedItem, setSelectedItem] = useState(null);
  const [isData, setIsData] = useState(false);
  const [selectedContentType, setSelectedContentType] = useState(contentOptions[0]);

  const handlePlatformSelection = (event) => {
    setSelectedPlatform(event.target.value);
    clearAllFilter();
  };

  const handleContentSelection = (event) => {
    setSelectedContentType(event.target.value);
  };

  useEffect(() => {
    console.log(appliedFilters.length > 0 && Object.keys(appliedFilters[0]))
  }, [appliedFilters]);

  const renderContentType = () => {
    let content = null;
    if (FilterEnum["filterData"]?.[selectedPlatform]) {
      switch (selectedContentType) {
        case "bio": {
          content = (
            <Bio
              setSelectedItem={setSelectedItem}
              showFilter={FilterEnum["filterData"]?.[selectedPlatform]?.includes("bio")}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
            />
          );
          break;
        }
        case "keywords": {
          content = (
            <Keywords
              setSelectedItem={setSelectedItem}
              showFilter={FilterEnum["filterData"][selectedPlatform]?.includes("keywords")}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
            />
          );
          break;
        }
        case "hashtags / mentions": {
          content = (
            <Keywords
              isHashtag
              setSelectedItem={setSelectedItem}
              showFilter={FilterEnum["filterData"]?.[selectedPlatform]?.includes("keywords")}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
            />
          );
          break;
        }

        default:
          break;
      }
    }

    return content;
  };
  // const [selectedItem] = useState(null);
  // const [isData] = useState(false);

  return allPlatforms && allPlatforms.length > 0 ? (
    <>
      {setSelectedData(selectedItem)}
      {setTableDataUpdated(isData)}
      {setCustomCols(customCols)}
      {setAllFiltersData(allFiltersData)}
      <Grid key={"platform-" + JSON.stringify(payload)} container spacing={2}>
        <Grid item container columns={25} spacing={2}>
          <Grid item xs={4}>
            <FormControl style={{ marginBottom: 10 }} sx={{ width: "100%" }} size="medium">
              <InputLabel id="demo-simple-select-standard-label">Platform</InputLabel>
              <Select
                onChange={handlePlatformSelection}
                variant="outlined"
                label="Platform"
                value={selectedPlatform}
                required
                size="small"
              >
                {allPlatforms.map((platform) => (
                  <MenuItem key={platform} value={platform}>
                    {socialIcons[platform]} {utils.capitalizeFirstLetter(platform)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CustomFilter
              audFilterKey="aud_location"
              infFilterKey="inf_location"
              filterType="location"
              fetchDataByFilterHandler={getLocationData}
              setSelectedItem={setSelectedItem}
              showFilter={FilterEnum["filterData"]?.[selectedPlatform]?.includes("location")}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              audReqKey="id"
              infReqKey="id"
              weight={0.05}
              isMulti={true}
              audTooltipMsg={FilterTooltips.location.audTooltipMsg}
              infTooltipMsg={FilterTooltips.location.infTooltipMsg}
              label="Audience Location"
            />
          </Grid>
          <Grid item xs={4}>
            <Followers
              setSelectedItem={setSelectedItem}
              showFilter={FilterEnum["filterData"]?.[selectedPlatform]?.includes("followers")}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
            />
          </Grid>
          <Grid item xs={4}>
            <Age
              showFilter={FilterEnum["filterData"]?.[selectedPlatform]?.includes("age")}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
            />
          </Grid>
          <Grid item xs={4}>
            <Genders
              showFilter={FilterEnum["filterData"]?.[selectedPlatform]?.includes("gender")}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={() => {
                setIsChanged(!isChanged);
                expandMoreFilters("panel1")(false);
              }}
              fullWidth
              sx={{ textTransform: "none" }}
              size="large"
              disabled={appliedFilters.length > 0 && Object.keys(appliedFilters[0])?.length <= 1 && Object.keys(appliedFilters[0]).includes("inf_hidden")}
            >
              Show
            </Button>
          </Grid>
        </Grid>
        <Grid item container columns={25} spacing={2}>
          <Grid item xs={4}>
            <FormControl style={{ marginBottom: 10 }} sx={{ width: "100%" }} size="medium">
              <InputLabel id="demo-simple-select-standard-label">Content</InputLabel>
              <Select
                onChange={handleContentSelection}
                variant="outlined"
                label="Content"
                value={selectedContentType}
                required
                size="small"
              >
                {contentOptions.map((option, idx) => (
                  <MenuItem key={`content-option-${idx}`} value={option} selected={option === selectedContentType}>
                    In {utils.capitalizeFirstLetter(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={18}>
            {renderContentType()}
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{ whiteSpace: "nowrap", textTransform: "none" }}
              variant={isMoreFiltersExpanded ? "contained" : "outlined"}
              onClick={(e) => expandMoreFilters("panel1")(!isMoreFiltersExpanded)}
              fullWidth
              startIcon={<TuneIcon />}
              size="large"
            >
              More Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <p>Loading ...</p>
  );
};

export default Platforms;
