import { Card, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import {InfoToolTip} from "./toolTip";

const Widget = ({ widget }) => {
  return (
    <Card
      sx={{
        minWidth: 230,
        padding: "10px",
        borderRadius: 3,
        boxShadow: "1px",
      }}
    >
      <Grid container spacing={2} direction="row">
        <Grid
          item
          xs={3}
          columns={8}
          direction="column"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#1461CC",
          }}
        >
          {widget.icon}
        </Grid>
        <Grid item xs container spacing={2} columns={4} direction="column">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "bold",
                fontWeight: 1200,
                fontSize: "22px",
                color: "#000000",
                opacity: 0.7,
              }}
            >
              <b>{widget.value}</b>
            </Typography>

            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                color: "#757575",
              }}
            >
              {widget.name}{" "}
              {widget.info && <InfoToolTip text={widget.text} />}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
Widget.propTypes = {
  widget: PropTypes.object.isRequired,
};

export default Widget;
