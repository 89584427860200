import React, { Component } from "react";

import { Scatter, Doughnut, Line } from "react-chartjs-2";

/* external files */
import MyPaper from "../general/MyPaper";
import utils from "../../utils/utils";

/* mui */
import {
  Button,
  CircularProgress,
  FormControl,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";

class ChartVisualization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveAs: "",
      loading: false,
      currentChart: null,
      dataToDisplay: {},
      chartTitle: "Chart",
      toggleValue: true,
      open: false,
      anchorEl: null,
      chartType: "",
      chartTypeUpdated: false,
    };
  }

  saveChartAs = (val) => {
    if (val === "png") {
      utils.downloadImage("png", "png", "chart", this.state.currentChart);
    } else if (val === "jpeg") {
      utils.downloadImage(
        "octet-stream",
        "jpeg",
        "chart",
        this.state.currentChart
      );
    } else if (val === "pdf") {
      utils.div2PDF("totalEngaementAnalysis", "chart");
    } else {
      window.alert("that seems to be a wrong input for us!");
    }
  };

  saveChartAsPng = () => {
    this.saveChartAs("png");
  };
  saveChartAsJpeg = () => {
    this.saveChartAs("jpeg");
  };
  saveChartAsPdf = () => {
    this.saveChartAs("pdf");
  };

  viewChartAs = (chartType) => {
    if (
      chartType === "bar" ||
      chartType == "line" ||
      chartType === "doughnut"
    ) {
      if (this.state.chartType != chartType) {
        this.setState({ chartType, chartTypeUpdated: true });
      }
    } else {
      window.alert("that seems to be a wrong input for us!");
    }
  };

  viewChartAsBar = () => {
    this.viewChartAs("bar");
  };
  viewChartAsLine = () => {
    this.viewChartAs("line");
  };
  viewChartAsDoughnut = () => {
    this.viewChartAs("doughnut");
  };

  handleChartMenuClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleChartMenuClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  expandChart = () => {
    alert("still under construction...");
  };

  updateDatasetForChartType = (data, chartType) => {
    const datasets = data.datasets;
    datasets.forEach((dataset) => {
      dataset.type = chartType;
    });
    data.datasets = datasets;
    return data;
  };

  fetchChartData = () => {
    if (
      !this.props.data ||
      !this.props.data.labels ||
      !this.props.data.labels.length
    ) {
      return <div style={this.props.parentStyle.noData}>No records found</div>;
    }
    /* get the chart type */
    let chartType = this.state.chartType;
    if (!chartType) {
      chartType = this.props.chartType;
      if (chartType != this.state.chartType) {
        this.setState({ chartType });
      }
    }

    /* get (and update, if required) the data to disply */
    let dataToDisplay = this.state.toggleValue
      ? this.props.data
      : this.props.additionalData;
    if (this.props.chartType && this.props.chartType != chartType) {
      dataToDisplay = this.updateDatasetForChartType(dataToDisplay, chartType);
    }

    if (chartType) {
      if (chartType === "doughnut") {
        return (
          <Doughnut
            options={this.props.chartOptions}
            data={dataToDisplay}
            ref={(chart) => (this.state.currentChart = chart)}
          />
        );
      } else if (chartType === "line") {
        return (
          <Line
            options={this.props.chartOptions}
            data={dataToDisplay}
            ref={(chart) => (this.state.currentChart = chart)}
          />
        );
      }
    }
    return (
      <Scatter
        id="totalEngaementAnalysis"
        options={this.props.chartOptions}
        data={dataToDisplay}
        ref={(chart) => (this.state.currentChart = chart)}
      />
    );
  };

  expandButton = () => {
    if (
      !this.props.data ||
      this.props.data.length == 0 ||
      !this.props.isExpensionRequired
    ) {
      return null;
    }
    return (
      <Stack direction="row" justifyContent="end">
        <Button
          variant="contained"
          onClick={this.expandChart}
          endIcon={<OpenWithIcon />}
        >
          Expand
        </Button>
      </Stack>
    );
  };

  handleToggleSwitchChange = () => {
    const toggleValue = !this.state.toggleValue;
    this.setState({ toggleValue });
  };

  showToggleSwitch = () => {
    if (!this.props.isToggle) {
      return null;
    }
    return (
      <FormControl size="small" sx={{ marginRight: "10px" }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Cumulative</Typography>
          <Switch defaultChecked onChange={this.handleToggleSwitchChange} />
          <Typography>Daily</Typography>
        </Stack>
      </FormControl>
    );
  };

  showChartTitle = () => {
    if (!this.props.chartTitle) {
      return "Chart";
    }
    return this.props.chartTitle;
  };

  showChartMenu = () => {
    if (!this.props.data.labels || !this.props.data.labels.length) {
      return <div id="chart_menu"></div>;
    }
    return (
      <div id="chart_menu">
        <FormControl size="small" sx={{ marginRight: "5px" }}>
          {this.showToggleSwitch()}
        </FormControl>
        <IconButton
          aria-label="more"
          id="three_dot_menu-button"
          aria-controls={this.state.open ? "chart-menu" : undefined}
          aria-expanded={this.state.open ? "true" : undefined}
          aria-haspopup="true"
          onClick={this.handleChartMenuClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="chart-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleChartMenuClose}
          MenuListProps={{
            "aria-labelledby": "three_dot_menu-button",
          }}
        >
          <MenuItem disabled>Save As</MenuItem>
          <MenuItem value={"png"} onClick={this.saveChartAsPng}>
            PNG
          </MenuItem>
          <MenuItem value={"jpeg"} onClick={this.saveChartAsJpeg}>
            JPEG
          </MenuItem>
          <MenuItem value={"pdf"} onClick={this.saveChartAsPdf}>
            PDF
          </MenuItem>
          {/* <MenuItem disabled>View As</MenuItem>
          <MenuItem value={"bar"} onClick={this.viewChartAsBar}>Bar Chart</MenuItem>
          <MenuItem value={"line"} onClick={this.viewChartAsLine}>Line Chart</MenuItem>
          <MenuItem value={"doughnut"} onClick={this.viewChartAsDoughnut}>Doughnut Chart</MenuItem> */}
        </Menu>
      </div>
    );
  };

  render() {
    return (
      <MyPaper sx={{ marginTop: "20px", height: "40vh" }}>
        {!this.props.data || this.props.data.length == 0 ? (
          <>
            <CircularProgress
              style={{
                position: "relative",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
              color="success"
            />
            <div
              style={{
                ...this.props.parentStyle.centerElement,
                ...this.props.parentStyle.fetchingDetails,
              }}
            >
              Fetching details...
            </div>
          </>
        ) : (
          <>
            <Stack direction="row" justifyContent="end">
              <Typography variant="h4" sx={this.props.parentStyle.typography}>
                {this.showChartTitle()}
              </Typography>
              {this.showChartMenu()}
            </Stack>
            {/* <div style={{ height: "45vh", paddingBottom: "20px" }}> */}
            <div style={{ height: "30vh", paddingBottom: "10px" }}>
              {this.fetchChartData()}
            </div>
            {this.expandButton()}
          </>
        )}
      </MyPaper>
    );
  }
}

export default ChartVisualization;