import React, { Component } from "react";
import TopNInfluencers from "./TopNInfluencers";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  InputLabel,
  Typography,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Box,
  Button,
  Modal,
  Fade,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import MyPaper from "../../general/MyPaper";
import utils from "../../../utils/utils";
import InfluencerList from "./InfluencerList";
import CampaignActions from "../../../pages/account/CampaignActions";
import AddInfluencers from "../../../components/account/details/AddInfluencers";
import { Close } from "@mui/icons-material";
import CustomTabs from "../../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../../utils/campaignTabsArray";
import AddInvites from "../../../components/account/details/AddInfluencers";

let campId = 0;
let accId;
let tabArray;
const styles = {
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  typography: {
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    color: "#33CC99",
    borderBottom: "2px solid #33CC99",
    marginBottom: "20px",
  },
  noData: {
    position: "relative",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontSize: "large",
    fontStyle: "italic",
  },
  centerElement: {
    position: "relative",
    left: "50%",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
  },
  fetchingDetails: {
    marginTop: "20px",
    paddingLeft: "50px",
  },
};

let hideOnExport = [];

class InfluencerAnalysis extends Component {
  constructor(props) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    campId = params.get("id");
    accId = params.get("accId");
    tabArray = campaignTabsArray(campId, accId);
    super(props);
    this.state = {
      fields: {},
      campId: campId,
      selectedPlatform: -1,
      selectedPlatformName: "all",
      platforms: [],
      linkToAddPost: "/campaigns/addPost" + "?id=" + campId,
      pptReportId: 0,
      addInfluencersToCampaign: false,
      notification: false,
      message: "",
      severity: "",
    };
    this.handleChangePlatform = this.handleChangePlatform.bind(this);
    this.addInfluencer = this.addInfluencer.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.callbackHandler = this.callbackHandler.bind(this);
  }

  componentDidMount() {
    this.getAllPlatforms();
    this.getCampaignDetails(campId);
  }
  handleChangePlatform(event) {
    let platform = this.state.platforms.find((e) => e.id === event.target.value);
    if (platform === undefined) {
      platform = { id: -1, name: "all" };
    }
    this.setState({
      selectedPlatform: event.target.value,
      selectedPlatformName: platform.name,
    });
  }

  getAllPlatforms() {
    utils.getPlatforms().then((data) => {
      const platforms = data;
      this.setState({ platforms });
    });
  }

  getCampaignDetails(campId) {
    utils.getCampaignData(campId).then((data) => {
      const fields = data;
      this.setState({
        fields,
      });
    });
  }

  addInfluencer(event) {
    this.setState({ addInfluencersToCampaign: true });
  }

  hideElementsBeforeCampaignExport() {
    for (let index in hideOnExport) {
      const elementId = hideOnExport[index];
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = "none";
      }
    }
  }

  showElementsAfterCampaignExport() {
    for (let index in hideOnExport) {
      const elementId = hideOnExport[index];
      const element = document.getElementById(elementId);
      if (element) {
        element.style.display = "block";
      }
    }
  }

  closeModal() {
    this.setState({ addInfluencersToCampaign: false });
  }

  callbackHandler() {
    this.closeModal();
    window.location.reload();
  }

  onInfluencerAdd = (event) => {
    const { notification, message } = event;
    console.log(notification, message);
    this.setState({ notification, message, severity: "success" });
    this.closeModal();
  };
  closeNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    let notification = false;
    this.setState({ notification });
  };

  render() {
    return (
      <>
        <CampaignActions name={this.state.fields.name} id={campId} />
        <br />
        <CustomTabs tabArray={tabArray} selectedTab={"1"} />
        <Stack
          direction="row"
          justifyContent="flex-end"
          style={{
            width: "100%",
            marginTop: "30px",
          }}
        >
          <Box>
            <Button variant="contained" sx={{ marginRight: "30px" }} onClick={this.addInfluencer}>
              Add Influencer
            </Button>
          </Box>
        </Stack>
        <br />
        <div id="influencer-analysis">
          <MyPaper sx={{ marginBottom: "20px" }}>
            <Stack direction="row" justifyContent="end">
              <Typography
                variant="h5"
                sx={{
                  marginRight: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Filters
              </Typography>
              <FormControl sx={{ marginRight: "5px" }}>
                <InputLabel id="demo-simple-select-standard-label">Platform</InputLabel>
                <Select
                  sx={{ height: "40px", minWidth: "10vw" }}
                  style={{ color: "black" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.selectedPlatform}
                  label="Platform"
                  onChange={this.handleChangePlatform}
                  variant="outlined"
                >
                  <MenuItem key={0} value={-1}>
                    All Platforms
                  </MenuItem>
                  {this.state.platforms.map((plat) => (
                    <MenuItem key={plat.id} value={plat.id}>
                      {utils.capitalizeFirstLetter(`${plat.name}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </MyPaper>
          {/* <InfluencerSnapshot
            campId={this.state.campId}
            parentStyle={styles}
            platforms={this.state.platforms}
            selectedPlatform={this.state.selectedPlatform}
            selectedPlatformName={this.state.selectedPlatformName}
          /> */}
          <InfluencerList
            campId={this.state.campId}
            parentStyle={styles}
            platforms={this.state.platforms}
            selectedPlatform={this.state.selectedPlatform}
            selectedPlatformName={this.state.selectedPlatformName}
          />
          <TopNInfluencers campId={this.state.campId} parentStyle={styles} platforms={this.state.platforms} />

          <Modal
            className="styles_modal"
            aria-labelledby="modal-add-inf-to-list-label"
            aria-describedby="modal-add-brand-to-account-description"
            open={this.state.addInfluencersToCampaign}
            onClose={this.closeModal}
            id
            style={{ padding: "10px" }}
            closeAfterTransition
          >
            <Fade in={this.state.addInfluencersToCampaign}>
              <div className="syles_paper_list" style={{ height: "auto", width: "50%", padding: "10px" }}>
                <div id="modal-add-inf-to-list-heading" className="styles_modal_heading">
                  <h2 id="modal-add-inf-to-list-heading-text">Add Influencer</h2>
                  <IconButton onClick={this.closeModal}>
                    <Close />
                  </IconButton>
                </div>
                <hr />
                {/* <SearchInfluencers
                  listInfo={{
                    campaignId: this.state.campId,
                    campaignName: this.state.fields.name,
                    listId: 0,
                    listName: "",
                    companyName: "",
                    platform: "",
                  }}
                  callbackHandler={this.callbackHandler}
                /> */}
                <AddInvites from="campaign" campaignName={this.state.fields.name} onInfluencerAdd={this.onInfluencerAdd} />
              </div>
            </Fade>
          </Modal>
          <Snackbar
            open={this.state.notification}
            autoHideDuration={3000}
            onClose={this.closeNotification}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={this.closeNotification} severity={this.state.severity} sx={{ width: "100%" }}>
              {" "}
              {this.state.message}{" "}
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  }
}

export default InfluencerAnalysis;
