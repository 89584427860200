import PropTypes from "prop-types";
import utils from "../../../utils/utils";
import { FormControl, Grid, Tooltip, IconButton } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Bio = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const handleInfBioelection = (latestBio) => {
    if (latestBio !== undefined) {
      let newPayload = {...payload};
      newPayload["inf_bio"] = latestBio;
      allFiltersData = utils.addObj(allFiltersData, "inf_bio");
      allFiltersData[0]["inf_bio"][0] = latestBio;
      setAllFiltersData(allFiltersData);
      setPayload(newPayload);
    }
  };

  const handleKeyDown = (e) => {
    handleInfBioelection(e.target.value);
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <div style={{ display: 'flex', alignItems: 'center', border: "1px solid lightgrey", borderRadius: "6px", padding: '7px 12px' }}>
              <input
                type="text"
                onBlur={handleKeyDown}
                placeholder="Search in Bio"
                style={{ flex: 1, border: 'none', outline: 'none', color: "#6B778C" }}
              />
              <Tooltip title="Input text and click outside the box">
                <IconButton size="small" style={{ margin: '0 5px' }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </FormControl>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Bio.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Bio;
