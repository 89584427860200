import React from "react";
import ChartVisualization from "../visualization/ChartVisualization";

const TotalEngagement = ({
  isToggle,
  linkClicksX,
  linkClicksY,
  additionalData,
  styles,
  chartTitle,
  chartType,
  chartMenu,
  defaultMenu,
  chartOptions,
}) => {
  const renderChart = () => {
    let data = {
      labels: linkClicksX,
      datasets: linkClicksY,
    };
    let defaultChartOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };
    if (chartOptions) {
      defaultChartOptions = {
        ...chartOptions,
        ...defaultChartOptions,
      };
      if (chartOptions.legend) {
        defaultChartOptions.plugins.legend = chartOptions.legend;
      }
    }

    return (
      <ChartVisualization
        isToggle={isToggle}
        data={data}
        additionalData={additionalData ? additionalData : {}}
        parentStyle={styles}
        chartOptions={defaultChartOptions}
        chartTitle={chartTitle}
        chartType={chartType}
        chartMenu={chartMenu}
        defaultMenu={defaultMenu}
      />
    );
  };

  return renderChart();
};

export default TotalEngagement;
