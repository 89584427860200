import utils from "../utils/utils";

const BarChart = (xAxisData, showyAxis, yAcisData, legend, magicType,showToolbox = true, showXAxis = true, barWidth = 30,showXAxisLine=true,yAxisConfig={}) => {
  const option = {
    tooltip: {},
    legend: {
      data: legend ? legend : [],
    },
    xAxis: {
      show: showXAxis,
      type: "category",
      data: xAxisData,
      axisLine: {
        show: showXAxisLine,
      },
      axisTick: {
        show: showXAxisLine
      }
    },
    yAxis: {
      type: "value",
      show: showyAxis,
      ...yAxisConfig
    },
    toolbox: {
      show: showToolbox,
      feature: {
        magicType: { show: true, type: magicType },
        saveAsImage: { show: true },
      },
    },
    barMaxWidth: barWidth,
    series: yAcisData,
  };

  return option;
};

const DoughnutChart = (title, color, legendOrient, left, top, data, radius,showLabel = false, showToolbox = false, tooltipConfig = {}, seriesConfig = {}) => {
  const option = {
    tooltip: {
      trigger: "item",
      ...tooltipConfig
    },
    legend: {
      show: !legendOrient ? false : true,
      type: "scroll",
      orient: legendOrient,
      left: left,
      top: top,
    },
    toolbox: {
      show: showToolbox,
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: title,
        type: "pie",
        radius: radius,
        avoidLabelOverlap: false,
        color: color,
        label: {
          show: showLabel,
          formatter: "{c}%",
          position: "inner",
          fontSize: 16,
        },
        labelLine: {
          show: false,
        },
        data: data,
        ...seriesConfig
      },

    ],
  };

  return option;
};

const MixedBarLineChart = (title, legendArray, xAxisData, yAxisData, yAcisData) => {
  const option = {
    title: {
      text: title,
      textStyle: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 24,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: legendArray,
      bottom: 10,
      left: 70,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
      },
    ],
    yAxis: yAxisData,
    series: yAcisData,
  };
  return option;
};

const RaceLineChart = (title, xAxisData, yAxisData, ...chartOptions) => {
  const option = {
    animationDuration: 5000,
    title: {
      show: !!title,
      text: title,
    },
    tooltip: {
      order: 'valueDesc',
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        alignWithLabel: true
      }
    },
    yAxis: {
      type: "value",
      position: "right",
      splitNumber: 4,
      axisLabel: {
        formatter: function (value) {
          return utils.convertToInternationalNumberSystem(value, 0);
        }
      }
    },
    series: [{
      data: yAxisData,
      type: 'line',
      lineStyle: {
        color: "#EE6002"
      },
      showSymbol: false,

    }],
    ...chartOptions,
  };
  return option;
}

export { BarChart, DoughnutChart, MixedBarLineChart, RaceLineChart };
