import axios from "axios";
import { API_HOST, INF_API_HOST } from "../../config/main.json";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
const removeInfluencerFromList = (iidsSelected, listId) => {
  return axios
    .post(
      `${API_HOST}/list/influencers/id/remove`,
      { iid: iidsSelected, lid: Number(listId) },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const removeInfluencerEmailFromList = (emailsSelected, listId) => {
  return axios
    .post(
      `${API_HOST}/list/influencers/email/remove`,
      { email: emailsSelected, lid: Number(listId) },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const influencerAsLeader = (lid, iid, isLeader) => {
  return axios
    .post(
      `${API_HOST}/list/assignLeader`,
      { iid, lid: Number(lid), isLeader },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const getInfluencerIdByList = (lid) => {
  return axios.get(`${API_HOST}/list/influencers/id/${lid}`).then((res) => {
    return res.data;
  });
};

const getInfluencerWithInvitationStatus = (iids, lid, platform) => {
  return axios
    .post(
      `${API_HOST}/campaign/influencer/invitaion`,
      { iids, lid, platform },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const getListInfluencers = (
  id,
  platform,
  refresh,
  page,
  rows,
  sortCol,
  sortOrder,
  filter
) => {
  const data = {
    id,
    platform,
    refresh,
    page,
    rows,
    sortCol,
    sortOrder,
    filter,
  };
  return axios
    .post(
      `${API_HOST}/list/influencers`,
      data,
      optionAxios
    )
    .then((res) => res.data);
};

const addNoteForInfluencer = (lid, iid, note, cid) => {
  return axios
    .post(
      `${API_HOST}/list/influencers/note/add`,
      { lid, iid, note, cid: +cid },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const getInfluencerDataByList = (organicIds, directIds, platform) => {
  return axios
    .post(
      `${INF_API_HOST}/influencer/specific/list`,
      { organicIds, directIds, platform },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const generateCertificate = (iidsSelected, cname, infName, lid, notificationMetadata) => {
  return axios
    .post(
      `${API_HOST}/influencer/certificate`,
      { iid: iidsSelected, infName: infName, campaignName: cname, lid, notificationMetadata },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const optionAxios1 = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  responseType: "blob",
};

const downloadCertificate = (link) => {
  return axios
    .post(
      `${API_HOST}/influencer/downloadCertificate`,
      { s3Link: link },
      optionAxios1
    )
    .then((res) => {
      return res.data;
    });
};
export {
  removeInfluencerFromList,
  getInfluencerIdByList,
  influencerAsLeader,
  addNoteForInfluencer,
  getInfluencerDataByList,
  getInfluencerWithInvitationStatus,
  getListInfluencers,
  generateCertificate,
  downloadCertificate,
  removeInfluencerEmailFromList
};
