import React, { useState } from "react";
import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";
const StatusDropdown = ({ value, rowId, tiId, onChange }) => {
    const [selectedStatus, setSelectedStatus] = useState(value);
  const handleStatusChange = (event) => {
    const newValue = event.target.value;
    setSelectedStatus(newValue);
    onChange(newValue, rowId, tiId);
  };

  return (
      <FormControl>
          <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
          <Select label="Status" variant="outlined" value={selectedStatus} onChange={handleStatusChange}>
              <MenuItem value="Assigned">Assigned</MenuItem>
              <MenuItem value="In-Progress">In-Progress</MenuItem>
              <MenuItem value="Submitted">Submitted</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Reassigned">Reassigned</MenuItem>
          </Select>
      </FormControl>
  );

};
export default StatusDropdown;
