import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import "../../styles/internal/CampaignAnalytics.css";


const CampaignScore = ({ activated_score, contracted_score, dob_score,age_score,location_score,location_country_score,kyc_score,username_score,profile_score,post_score,post_tag_score,list_post_match,avg_post_score,avg_post_bonus }) => {

  return (
    <Table style={{ background: "white", border: "1px solid black" }}  className="table">
    <TableHead>
      <TableRow style={{ background: "white", border: "1px solid black" }}>
        <TableCell className="tableHeader">Sr.No</TableCell>
        <TableCell className="tableHeader">Data Points</TableCell>
        <TableCell className="tableHeader">Score</TableCell>
      </TableRow>
    </TableHead>
    <TableBody style={{ background: "white", border: "1px solid black" }}>
      <TableRow>
        <TableCell className="tableCol">{"1"}</TableCell>
        <TableCell className="tableCol">{"Activated Influencer Ratio"}</TableCell>
        <TableCell className="tableCol">{Number(activated_score).toFixed(1)}{"/10"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"2"}</TableCell>
        <TableCell className="tableCol">{"Contracted Influencer Ratio"}</TableCell>
        <TableCell className="tableCol">{Number(contracted_score).toFixed(1)}{"/10"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"3"}</TableCell>
        <TableCell className="tableCol">{"Average Channel per Influencer"}</TableCell>
        <TableCell className="tableCol">{Number(avg_post_score).toFixed(1)}{"/5"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"4"}</TableCell>
        <TableCell className="tableCol">{"DOB Coverage"}</TableCell>
        <TableCell className="tableCol">{Number(dob_score).toFixed(1)}{"/5"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"5"}</TableCell>
        <TableCell className="tableCol">{"Age Coverage"}</TableCell>
        <TableCell className="tableCol">{Number(age_score).toFixed(1)}{"/10"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"6"}</TableCell>
        <TableCell className="tableCol">{"Gender Coverage"}</TableCell>
        <TableCell className="tableCol">{Number(age_score).toFixed(1)}{"/5"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"7"}</TableCell>
        <TableCell className="tableCol">{"Location Coverage City"}</TableCell>
        <TableCell className="tableCol">{Number(location_score).toFixed(1)}{"/10"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"8"}</TableCell>
        <TableCell className="tableCol">{"Location Coverage Country"}</TableCell>
        <TableCell className="tableCol">{Number(location_country_score).toFixed(1)}{"/10"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"9"}</TableCell>
        <TableCell className="tableCol">{"KYC Coverage"}</TableCell>
        <TableCell className="tableCol">{Number(kyc_score).toFixed(1)}{"/10"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"10"}</TableCell>
        <TableCell className="tableCol">{"Username Coverage"}</TableCell>
        <TableCell className="tableCol">{Number(username_score).toFixed(1)}{"/5"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"11"}</TableCell>
        <TableCell className="tableCol">{"Profile Link Coverage"}</TableCell>
        <TableCell className="tableCol">{Number(profile_score).toFixed(1)}{"/5"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"12"}</TableCell>
        <TableCell className="tableCol">{"Post Url Resolve Coverage"}</TableCell>
        <TableCell className="tableCol">{Number(post_score).toFixed(1)}{"/5"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"13"}</TableCell>
        <TableCell className="tableCol">{"Tagged Posts"}</TableCell>
        <TableCell className="tableCol">{Number(post_tag_score).toFixed(1)}{"/5"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="tableCol">{"14"}</TableCell>
        <TableCell className="tableCol">{"Post List Mismatch"}</TableCell>
        <TableCell className="tableCol">{Number(list_post_match).toFixed(1)}{"/5"}</TableCell>
      </TableRow>
    </TableBody>
  </Table>
  );
};

export default CampaignScore;
