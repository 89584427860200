import React from 'react';
import { Avatar, Box, Grid, IconButton, Paper, Tooltip, Typography, styled } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InsightNumbers from './components/InsightNumbers/InsightNumbers';
import ReactEcharts from "echarts-for-react";
import { BarChart, DoughnutChart, RaceLineChart } from "../../../../../../common/chart";
import ProgressChart from '../common/ProgressChart/ProgressChart';
import CardHeader from '../common/CardHeader/CardHeader';
import { object, string } from 'prop-types';
import utils from "../../../../../../utils/utils";
import FallbackWrapper from '../common/FallbackWrapper/FallbackWrapper';
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import CustomPaperCard from '../common/CustomPaperCard/CustomPaperCard';

const CustomAvatar = styled(Avatar)(() => ({
  "& .MuiAvatar-img": {
    objectFit: "contain"
  }
}))

const monthEnum = Object.freeze({
  "01": "January",
  "02": "Feburary",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December",
});


const InfluencerInfo = (props) => {
  const { data, platform } = props;

  const {
    overall_insights,
    followers_info: followersInfo,
  } = data;

  const monthsData = followersInfo?.months_data;

  const followersBreakdownDataHandler = () => {
    const followersData = followersInfo?.breakdown?.line?.map((item, idx) => {
      item.title = item.name;
      item.color = FOLLOWERS_COLORS[idx];
      // item.tooltip = idx !== 0 && "help";
      return item;
    });

    return followersData;
  }


  //get cards tooltips
  const tooltipHandler = (insightKey) => {
    switch(insightKey) {
      case "credibility": 
        return "To establish the followers credibility score for historical followers we take into account factors such as an account’s avatar and bio description, number of posts, number of accounts followed vs following ratio. Influencers with a genuine audience will attain scores of 80 or above.";
      case "engagementRate" : return "Average likes divided by followers";
      case "likes": return "The average sum of likes on the last 30 posts";
      default : return null;
    }
  }

  // render the type of information specific to social platform of influencer
  const renderSectionForPlatform = () => {
    let content = null;
    switch (platform) {

      case "instagram":
        content = (
          <Grid container spacing={3} sx={{ my: "1rem" }}>
            <Grid item xs sm={6} md={5}>
              <CustomPaperCard cardSxProps={{ my: 0 }}>
                <FallbackWrapper isError={utils.checkIsObjectEmpty(monthsData)}>
                  <CardHeader title={<RaceLineHeading growthRate={followersInfo?.growth} />} hideToolTip />
                  <ReactEcharts option={RaceLineChart(null, monthsData?.months.slice(0, 5).map(item => monthEnum[item.split("-")[1]].slice(0, 3)), monthsData?.followersData.slice(0, 5))} style={{ height: "250px", width: "100%" }} />
                </FallbackWrapper>
              </CustomPaperCard>
            </Grid>
            <Grid item xs sm={6} md={7}>
              <CustomPaperCard cardSxProps={{ minHeight: "260px", my: 0 }}>
                <CardHeader title={"Followers Breakdown"} hideToolTip />
                <FallbackWrapper isError={utils.checkIsObjectEmpty(followersInfo?.breakdown)}>
                  <Grid container item>
                    <Grid item xs={4} position="relative">
                      <>
                        <ReactEcharts
                          option={DoughnutChart("Followers", FOLLOWERS_COLORS, false, 0, 0, followersInfo?.breakdown?.pie, [
                            "80%",
                            "90%",
                          ])}
                          style={{ height: "245px", width: "100%" }}
                        />
                        <Box position="absolute" left="50%" top="50%" sx={{ transform: "translate(-50%,-50%)" }}>
                          <Typography color="#000000" fontWeight="bold">
                            {(overall_insights["followers"]).toLocaleString()}
                          </Typography>
                          <Typography textAlign="center">
                            Followers
                          </Typography>
                        </Box>
                      </>
                    </Grid>
                    <Grid item xs={8}>
                      <ProgressChart data={followersBreakdownDataHandler()} expandButtonVisible={false} wrapperElevation={0} barSxProps={{ height: 6, borderRadius: 12 }} wrapperSxProps={{ mb: "26px" }} />
                    </Grid>
                  </Grid>
                </FallbackWrapper>
              </CustomPaperCard>
            </Grid>
          </Grid>
        );
        break;

      case "tiktok":
        content = (
          <Grid container spacing={3} sx={{ my: "1rem" }}>
            <Grid item xs sm={4} >
              <CustomPaperCard cardSxProps={{ my: 0 }}>
                <FallbackWrapper isError={utils.checkIsObjectEmpty(monthsData)}>
                  <CardHeader title={<RaceLineHeading growthRate={followersInfo?.followerGrowth} />} hideToolTip />
                  <ReactEcharts option={RaceLineChart(null, monthsData?.months.slice(0, 5).map(item => monthEnum[item.split("-")[1]].slice(0, 3)), monthsData?.followersData.slice(0, 5))} style={{ height: "250px", width: "100%" }} />
                </FallbackWrapper>
              </CustomPaperCard>
            </Grid>
            <Grid item xs sm={4} >
              {/* <Paper elevation={4} sx={{ borderRadius: "12px", minHeight: "225px", pt: "12px", pb: "8px", px: "24px" }}> */}
              <CustomPaperCard cardSxProps={{ my: 0 }}>
                <FallbackWrapper isError={utils.checkIsObjectEmpty(monthsData)}>
                  <CardHeader title={<RaceLineHeading dataText='Likes' growthRate={followersInfo?.likersGrowth} />} hideToolTip />
                  <ReactEcharts option={RaceLineChart(null, monthsData?.months.slice(0, 5).map(item => monthEnum[item.split("-")[1]].slice(0, 3)), monthsData?.followersData.slice(0, 5))} style={{ height: "250px", width: "100%" }} />
                </FallbackWrapper>
              </CustomPaperCard>
              {/* </Paper> */}
            </Grid>
            <Grid item xs sm={4} >
              <Paper elevation={4} sx={{ borderRadius: "12px", minHeight: "225px", pt: "12px", pb: "8px", px: "24px" }}>
                <FallbackWrapper isError={utils.checkIsObjectEmpty(monthsData)}>
                  <CardHeader title={<RaceLineHeading dataText='Comments' growthRate={followersInfo?.commentersGrowth} />} hideToolTip />
                  <ReactEcharts option={RaceLineChart(null, monthsData?.months.slice(0, 5).map(item => monthEnum[item.split("-")[1]].slice(0, 3)), monthsData?.followersData.slice(0, 5))} style={{ height: "250px", width: "100%" }} />
                </FallbackWrapper>
              </Paper>
            </Grid>
          </Grid>
        );
        break;

      case "youtube":
        content = (
          <Grid container spacing={3} sx={{ my: "1rem" }}>
            <Grid item xs sm={6} >
              <CustomPaperCard cardSxProps={{ my: 0 }}>
                <FallbackWrapper isError={utils.checkIsObjectEmpty(monthsData)}>
                  <CardHeader title={<RaceLineHeading growthRate={followersInfo?.followerGrowth} />} hideToolTip />
                  <ReactEcharts option={RaceLineChart(null, monthsData?.months.slice(0, 5).map(item => monthEnum[item.split("-")[1]].slice(0, 3)), monthsData?.followersData.slice(0, 5))} style={{ height: "250px", width: "100%" }} />
                </FallbackWrapper>
              </CustomPaperCard>
            </Grid>
            <Grid item xs sm={6}>
              <CustomPaperCard cardSxProps={{ my: 0 }}>
                <FallbackWrapper isError={utils.checkIsObjectEmpty(monthsData)}>
                  <CardHeader title={<RaceLineHeading dataText='Views' growthRate={followersInfo?.viewsGrowth} />} hideToolTip />
                  <ReactEcharts option={RaceLineChart(null, monthsData?.months.slice(0, 5).map(item => monthEnum[item.split("-")[1]].slice(0, 3)), monthsData?.followersData.slice(0, 5))} style={{ height: "250px", width: "100%" }} />
                </FallbackWrapper>
              </CustomPaperCard>
            </Grid>
          </Grid>
        );
        break;
      default: content = <>Unable to show information</>; break;
    }
    return content;
  }
  // MAIN COMPONENT
  return (
    <>
      <Grid container spacing={3}>
        <FallbackWrapper isError={utils.checkIsObjectEmpty(overall_insights)}>
          {Object.keys(overall_insights).filter(key => Boolean(overall_insights[key])).map((key, idx) => (
            <Grid item xs key={`card-${idx}`}>
              <CustomPaperCard cardSxProps={{ my: 0, minHeight: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography component={"h1"} variant='h1'>
                    {typeof (overall_insights[key]) === "number" ? utils.convertToInternationalNumberSystem(overall_insights[key], 1) : overall_insights[key]}
                  </Typography>
                  {idx === 0 && followersInfo?.growth && <TrendArrow growthRate={followersInfo?.growth} />}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>
                    {utils.capitalizeFirstLetter(key, false)}
                  </Typography>
                  {tooltipHandler(key) &&
                   <Tooltip title={tooltipHandler(key)} sx={{ ml: "10px", fontSize: '12px' }}>
                    <IconButton>
                      <HelpOutlineIcon fontSize='12px' />
                    </IconButton>
                  </Tooltip>
                  }
                </Box>
              </CustomPaperCard>
            </Grid>
          ))}
        </FallbackWrapper>
      </Grid>
      {renderSectionForPlatform()}
      <Grid container spacing={3} sx={{ my: "1rem" }}>
        <Grid item xs sm={6} md={4}>
          <CustomPaperCard cardSxProps={{ minHeight: "295px", display: "flex", flexDirection: "column", alignItems: "center", my: 0 }}>
            <CardHeader title={"Insights"} hideToolTip wrapperSxProps={{ pb: "35px" }} />
            <FallbackWrapper isError={utils.checkIsObjectEmpty(followersInfo?.breakdown?.insights)}>
              <Box>
                <InsightNumbers data={followersInfo?.breakdown?.insights} />
              </Box>
            </FallbackWrapper>
          </CustomPaperCard>
        </Grid>
        <Grid item xs sm={6} md={8}>
          <CustomPaperCard cardSxProps={{ my: 0 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <CardHeader title={"Average Engagement Over Time"} hideToolTip wrapperSxProps={{ mb: 0 }} />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <BarLegendText color="#7244AE" text="Likes" />
                {followersInfo?.breakdown?.avg_engagement?.comments?.length > 0 && <BarLegendText color="#4299E1" text="Comments" />}
              </Box>
            </Box>
            <Grid item xs>
              <FallbackWrapper isError={utils.checkIsObjectEmpty(followersInfo?.breakdown?.avg_engagement)} >
                <ReactEcharts
                  option={BarChart(followersInfo?.breakdown?.avg_engagement?.months.map(item => monthEnum[item.split("-")[1]].slice(0, 3)), false, [
                    {
                      name: "Likes",
                      type: "bar",
                      showBackground: true,
                      backgroundStyle: {
                        borderRadius: [100, 100, 0, 0],
                      },
                      color: ["#7244AE"],
                      itemStyle: {
                        borderRadius: [100, 100, 0, 0],
                      },
                      tooltip: {
                        valueFormatter: function (value) {
                          return value;
                        },
                      },
                      data: followersInfo?.breakdown?.avg_engagement?.likes,
                    },
                    followersInfo?.breakdown?.avg_engagement?.comments?.length > 0 && {
                      name: "Comments",
                      type: "bar",
                      showBackground: true,
                      backgroundStyle: {
                        borderRadius: [100, 100, 0, 0],
                      },
                      color: ["#4299E1"],
                      itemStyle: {
                        borderRadius: [100, 100, 0, 0],
                      },
                      tooltip: {
                        valueFormatter: function (value) {
                          return value;
                        },
                      },
                      data: followersInfo?.breakdown?.avg_engagement?.comments,
                    },
                  ], null, null, false, true, "17")}
                  style={{ height: "250px", width: "100%" }}
                />
              </FallbackWrapper>
            </Grid>
          </CustomPaperCard>
        </Grid>
      </Grid>
      {platform === "instagram" &&
        <Grid container spacing={3} sx={{ my: "1rem" }}>
          <Grid item xs sm={6} md={4}>
            <CustomPaperCard cardSxProps={{ my: 0 }}>
              <CardHeader title={"Brand Affinity"} hideToolTip wrapperSxProps={{ justifyContent: "center" }} />
              <FallbackWrapper isError={!(followersInfo?.brand_affinity?.length)}>
                <Box sx={{ display: "grid", gridTemplateColumns: `repeat(${followersInfo?.brand_affinity?.length > 3 ? 3 : followersInfo?.brand_affinity?.length},1fr)`, columnGap: "8px",rowGap:"8px", mt: "12px", placeItems: "center" }}>
                  {followersInfo?.brand_affinity?.map((item, idx) => (
                    <Tooltip title={item.name} key={`aff-${item.name}`}>
                    <Box display="flex" alignItems="center">
                      <CustomAvatar
                        alt={item.name}
                        src={item.logo}
                        sx={{ mr: "6px" }}
                        variant='rounded'
                        />

                    </Box>
                        </Tooltip>
                  ))}
                </Box>
              </FallbackWrapper>
            </CustomPaperCard>
          </Grid>
          <Grid item xs sm={6} md={8}>
            <CustomPaperCard cardSxProps={{ my: 0 }}>
              <CardHeader title={"Top Topics"} hideToolTip wrapperSxProps={{ justifyContent: "center" }} />
              <FallbackWrapper isError={!followersInfo?.top_topics?.length}>
                <Box sx={{ display: "flex", alignItems:"center", flexWrap:"wrap", mt: "12px" }}>
                  {followersInfo?.top_topics?.map((item, idx) => <Typography sx={{mx:"12px"}} key={`#${item}`}>#{item}</Typography>)}
                </Box>
              </FallbackWrapper>
            </CustomPaperCard>
          </Grid>
        </Grid>
      }
    </>
  )
}


InfluencerInfo.propTypes = {
  data: object.isRequired,
  platform: string.isRequired
}

export default InfluencerInfo;

// Small common components

const BarLegendText = (props) => {
  const { color, text } = props;
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" ml="8px">
      <Box height="18px" width="18px" borderRadius="50%" bgcolor={color} />
      <Typography fontSize="14px" color="#77838F" pl="4px">
        {text}
      </Typography>
    </Box>
  )
}

const TrendArrow = (props) => {
  const { growthRate } = props;
  const isPositive = growthRate[0] === "+";
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" justifyContent="center" alignItems="center" borderRadius="50%" p="4px" ml="6px" bgcolor={isPositive ? "rgba(0,128,0,0.1)" : "rgba(255,0,0,0.1)"}>
        {isPositive ? <TrendingUp sx={{ fill: "#13B558", stroke: "#13B558" }} fontSize='small' /> : <TrendingDown sx={{ fill: "#E53E3E", stroke: "#E53E3E" }} fontSize='small' />}
      </Box>
      <Typography component="p" color={isPositive ? "#13B558" : "#E53E3E"} fontSize="12px" ml="6px">{growthRate}</Typography>
      <Tooltip title={"Compared to last month"} sx={{ ml: "4px", fontSize: "12px" }}>
        <IconButton>
          <HelpOutlineIcon fontSize='inherit' />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const RaceLineHeading = ({ growthRate, dataText = "Followers" }) => {
  const isPositive = growthRate?.length ? growthRate[0] === "+" : null;
  return <>{dataText} {isPositive ? isPositive ? "up" : "down" : null} by <Box component={"span"} sx={{ color: isPositive ? "green" : "red" }}>{growthRate}</Box> this month</>
}

const FOLLOWERS_COLORS = ["#1DA1F2", "#F087B1", "#4B6188", "#D6D7D6"];