import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import discoveryUtils from "../../../utils/discoveryUtils.js";
import StyledMenu from "../StyledMenu";
import utils from '../../../utils/utils';

import {
  FormControl,
  Grid,
  MenuItem,
  Tooltip,
  InputLabel,
  Select,
  Box,
} from "@mui/material";
import { FilterTooltips } from "./static/FiltersTooltips";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const Age = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
  isInfFilter,
}) => {
  const [audFromAge] = useState("");
  const [audToAge] = useState("");
  const [infFromAgeSel] = useState("");
  const [infToAgeSel] = useState("");
  const [audienceFromAge] = useState(["13", "18", "25", "35", "45", "65"]);
  const [audienceToAge] = useState(["18", "25", "35", "45", "65", "65+"]);
  const [infFromAge] = useState(["18", "25", "35", "45", "65"]);
  const [infToAge] = useState(["25", "35", "45", "65", "65+"]);
  const [range, setRange] = useState(0.01);

  const handleAudFromChange = (event, ageType) => {
    let newPayload = payload;
    newPayload[ageType] = event.target.value;
    newPayload["aud_age"] = {
      operator: "gte",
      weight: range,
      right_number: "",
      left_number: "",
    };
    if (
      allFiltersData !== "undefined" &&
      allFiltersData[0]["aud_age"] !== undefined
    ) {
      if (allFiltersData[0]["aud_age"][0]["right"] === "65+") {
        allFiltersData[0]["aud_age"][0]["right"] = "";
      }
      newPayload["aud_age"]["right_number"] =
        allFiltersData[0]["aud_age"][0]["right"].trim();
      newPayload["aud_age"]["left_number"] =
        allFiltersData[0]["aud_age"][0]["left"];
    }
    if (ageType === "aud_age_from") {
      newPayload["aud_age"]["left_number"] = event.target.value;
    } else {
      if (event.target.value === "65+") {
        event.target.value = "";
      }
      newPayload["aud_age"]["right_number"] = event.target.value;
    }
    setAllFiltersData(allFiltersData);
    setPayload(newPayload);
    setAgeFilter("aud");
  };

  const handleInfFromChange = (event, ageType) => {
    let newPayload = payload;
    newPayload[ageType] = event.target.value;
    if (newPayload["aud_age_from"] === "" && newPayload["aud_age_to"] === "") {
      delete newPayload["audience_age_range"];
    }
    newPayload["inf_age"] = { right_number: "", left_number: "" };

    if (
      allFiltersData !== "undefined" &&
      allFiltersData[0]["inf_age"] !== undefined
    ) {
      if (allFiltersData[0]["inf_age"][0]["right"] === "65+") {
        allFiltersData[0]["inf_age"][0]["right"] = "";
      }
      newPayload["inf_age"]["right_number"] =
        allFiltersData[0]["inf_age"][0]["right"].trim();
      newPayload["inf_age"]["left_number"] =
        allFiltersData[0]["inf_age"][0]["left"];
    }
    if (ageType === "inf_age_from") {
      newPayload["inf_age"]["left_number"] = event.target.value;
    } else {
      if (event.target.value === "65+") {
        event.target.value = "";
      }
      newPayload["inf_age"]["right_number"] = event.target.value;
    }
    setAllFiltersData(allFiltersData);
    setPayload(newPayload);
    setAgeFilter("inf");
  };
  const setAgeFilter = (type) => {
    let newPayload = payload;
    let fromAge = payload[type + "_age_from"];
    let toAge = payload[type + "_age_to"];
    if (payload[type + "_age_to"] === undefined) {
      toAge = "65+";
    }
    if (payload[type + "_age_from"] === undefined && type === "aud") {
      fromAge = "13";
    }
    if (payload[type + "_age_from"] === undefined && type === "inf") {
      fromAge = "18";
    }
    allFiltersData = utils.addObj(allFiltersData, type + "_age");
    allFiltersData[0][type + "_age"][0] = {
      left: fromAge,
      right: toAge,
      weight: newPayload[type + "_age_weight"],
    };
    setAllFiltersData(allFiltersData);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRange = (event) => {
    let newPayload = payload;
    newPayload["aud_age"]["weight"] = event.target.value;
    setPayload(newPayload);
    setRange(event.target.value);
  };
  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tooltip
            title={
              isInfFilter
                ? FilterTooltips.age.infTooltipMsg
                : FilterTooltips.age.audTooltipMsg
            }
          >
            <Button
              sx={{
                color: "#6B778C",
                background: "unset !important",
                border: "1px solid lightgrey",
                p: "7px 12px",
                justifyContent: "space-between",
                textTransform: "none",
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              fullWidth
              size="large"
              endIcon={
                open ? (
                  <ArrowDropUp fontSize="large" />
                ) : (
                  <ArrowDropDown fontSize="large" />
                )
              }
            >
              {isInfFilter ? "Age" : "Audience Age"}
            </Button>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1} sx={{ height: "auto", mt: 0 }}>
              {!isInfFilter && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FormControl sx={{ m: 1 }}>Age</FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">
                          From
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="audFromAge"
                          value={
                            typeof allFiltersData[0]["aud_age"] === "undefined"
                              ? audFromAge
                              : allFiltersData[0]["aud_age"][0]["left"]
                          }
                          label="From"
                          onChange={(e) =>
                            handleAudFromChange(e, "aud_age_from")
                          }
                        >
                          {audienceFromAge.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">
                          To
                        </InputLabel>
                        <Select
                          value={
                            typeof allFiltersData[0]["aud_age"] === "undefined"
                              ? audToAge
                              : allFiltersData[0]["aud_age"][0]["right"]
                          }
                          onChange={(e) => handleAudFromChange(e, "aud_age_to")}
                          label="To"
                        >
                          {audienceToAge.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ margin: "8px" }}>
                      {discoveryUtils.getRangesFilter(range, "", handleRange)}
                    </Box>
                  </Grid>
                </Grid>
              )}
              {isInfFilter && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FormControl sx={{ m: 1 }}>Age</FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">
                          From
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="infFromAge"
                          value={
                            typeof allFiltersData[0]["inf_age"] === "undefined"
                              ? infFromAgeSel
                              : allFiltersData[0]["inf_age"][0]["left"]
                          }
                          label="From"
                          onChange={(e) =>
                            handleInfFromChange(e, "inf_age_from")
                          }
                        >
                          {infFromAge.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-helper-label">
                          To
                        </InputLabel>
                        <Select
                          value={
                            typeof allFiltersData[0]["inf_age"] === "undefined"
                              ? infToAgeSel
                              : allFiltersData[0]["inf_age"][0]["right"]
                          }
                          onChange={(e) => handleInfFromChange(e, "inf_age_to")}
                          label="To"
                        >
                          {infToAge.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>{" "}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

Age.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default Age;
