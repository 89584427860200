import axios from "axios";
import { API_HOST } from "../../config/main.json";
const getPendingContracts = (influencer_id) => {
  return axios
    .get(`${API_HOST}/contract/influencer/pending/${influencer_id}`)
    .then((res) => {
      return res.data;
    });
};

const getAcceptedContracts = (influencer_id) => {
  return axios
    .get(`${API_HOST}/contract/influencer/accepted/${influencer_id}`)
    .then((res) => {
      return res.data;
    });
};

const getContractsFromCampaign = (influencer_id, campaign_id) => {
  return axios
  .get(`${API_HOST}/contract/list/${campaign_id}/${influencer_id}`)
  .then((res) => {
    return res.data;
  });
}

const downloadContractFromAPI = async (contractId) => {
  const response = await axios({
    method: "GET",
    url: `${API_HOST}/contract/download/${contractId}`,
    responseType: "blob",
  });

  if (response.status === 200) {
    return response.data;
  }

  return null;
};

const resendContract = async (contractId) => {
  const response = await axios({
    method: "GET",
    url: `${API_HOST}/contract/resend/${contractId}`,
  });

  if (response.status === 200) {
    return response.data;
  }

  return null;
};

const remindContract = async (contractId) => {
  const response = await axios({
    method: "GET",
    url: `${API_HOST}/contract/remind/${contractId}`,
  });

  if (response.status === 200) {
    return response.data;
  }

  return null;
};

export {
  getPendingContracts,
  getAcceptedContracts,
  downloadContractFromAPI,
  resendContract,
  remindContract,
  getContractsFromCampaign
};
