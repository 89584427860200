import { Box, Paper, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TopNPosts from '../../../../../../pages/account/TopNPosts'
import { object, string } from 'prop-types';
import CardHeader from '../common/CardHeader/CardHeader';
import { ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material';
import Carousel from 'react-bootstrap/Carousel';

const ContentInfo = (props) => {

  const { data, platform } = props;

  const [itemIndex, setItemIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(1);

  useEffect(() => {
    if (data?.length > 7)
      setTotalSlides(3);
    else if (data?.length > 4)
      setTotalSlides(2);
  }, [data?.length])


  const handleBackClick = () => {
    if (itemIndex === 0) {
      return;
    }
    setItemIndex(itemIndex - 1);
  }
  const handleForwardClick = () => {
    if (itemIndex + 1 >= totalSlides) {
      return;
    }
    setItemIndex(itemIndex + 1);
  }

  return (
    <Paper sx={{ minHeight: "200px", px: "24px", py: "12px", position: "relative" }} >
      <CardHeader title="Top Posts" hideToolTip />
      <Carousel activeIndex={itemIndex} indicators={false} controls={false} intetval={null} style={{ marginBottom: "6px" }}>
        <Carousel.Item>
          <Stack
            direction="row"
            className="align-items-center"
            gap={7}
          >
            {data.slice(0, 3).map((item, idx) => (
              <Box key={`post-${idx}`} >
                <TopNPosts
                  PostUrl={item}
                  Platform={platform}
                />
              </Box>
            ))}
          </Stack>
        </Carousel.Item>
        {data?.length > 4 &&
          <Carousel.Item>
            <Stack
              direction="row"
              className="align-items-center"
              gap={7}
            >
              {data.slice(3, 6).map((item, idx) => (
                <Box key={`post-${idx}`} >
                  <TopNPosts
                    PostUrl={item}
                    Platform={platform}
                  />
                </Box>
              ))}
            </Stack>
          </Carousel.Item>
        }
        {data?.length > 7 &&
          <Carousel.Item>
            <Stack
              direction="row"
              className="align-items-center"
              gap={7}
            >
              {data.slice(6, 9).map((item, idx) => (
                <Box key={`post-${idx}`} >
                  <TopNPosts
                    PostUrl={item}
                    Platform={platform}
                  />
                </Box>
              ))}
            </Stack>
          </Carousel.Item>
        }
      </Carousel>
      <Box display="flex" alignItems="center" justifyContent="end">
        <Box mr="10px">
          {(3 * itemIndex) + 1} - {((itemIndex + 1) * totalSlides)} of {data.slice(0, 9).length}
        </Box>
        <ArrowBackIosNew onClick={handleBackClick} fontSize='small' sx={{ mr: "6px", cursor: "pointer" }} />
        <ArrowForwardIos onClick={handleForwardClick} fontSize='small' sx={{ cursor: "pointer" }} />
      </Box>
    </Paper>
  )
}

ContentInfo.propTypes = {
  data: object.isRequired,
  platform: string.isRequired
}

export default ContentInfo