import {
  Facebook,
  Google,
  Twitter,
  YouTube,
  Instagram,
  LinkedIn
} from '@mui/icons-material';
import { IoLogoTiktok } from 'react-icons/io5';

const platforms = {
  facebook: {
    icon: Facebook,
    color: '#4267B2'
  },
  google: {
    icon: Google,
    color: '#DB4437'
  },
  youtube: {
    icon: YouTube,
    color: '#FF0000'
  },
  twitter: {
    icon: Twitter,
    color: '#1DA1F2'
  },
  tiktok: {
    icon: IoLogoTiktok,
    color: '#010101'
  },
  instagram: {
    icon: Instagram,
    color: '#cd486b'
  },
  linkedin: {
    icon: LinkedIn,
    color: '#0077b5'
  }
};

const getIcon = (platform) => ((platforms[platform] !== undefined) ? platforms[platform].icon : undefined);

const getColor = (platform) => ((platforms[platform] !== undefined) ? platforms[platform].color : '#282828');

export {
  platforms,
  getIcon, getColor
};
