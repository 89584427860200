import PropTypes from "prop-types";
import FilterEnum from "./FilterEnum";
import PartnerShips from "./FilterComponents/Partnerships";
import Genders from "./FilterComponents/Gender";
import Ethnicity from "./FilterComponents/Ethnicity";
import AudienceType from "./FilterComponents/Audiencetype";
import Age from "./FilterComponents/Age";
import Engagements from "./FilterComponents/Engagements";
import ReelPlays from "./FilterComponents/ReelPlays";
import Contact from "./FilterComponents/Contact";
import LastPost from "./FilterComponents/LastPost";
import AccountType from "./FilterComponents/AccountType";
import FollowerGrowth from "./FilterComponents/FollowersGrowth";
import Views from "./FilterComponents/Views";
import Verified from "./FilterComponents/Verified";
import { Grid } from "@mui/material";
import CustomFilter from "./FilterComponents/common/CustomFilter";
import {
  getBrandData,
  getLanguageData,
  getLocationData,
  getInterestData,
  getLookalikeData,
} from "./FilterComponents/service/fetchDataByFilter";
import { FilterTooltips } from "./FilterComponents/static/FiltersTooltips";

const Filters = ({
  setSelectedData,
  setTableDataUpdated,
  selectedPlatform,
  customCols,
  setCustomCols,
  setTableDataLoaded,
  tablePageChanged,
  tableRowsPerPageChanged,
  tableRowsPerPage,
  tablePageNo,
  isChanged,
  setIsChanged,
  resetPageNo,
  setTablePageNo,
  payload,
  setPayload,
  allFiltersData,
  setAllFiltersData,
  selectedCol,
  setSelectedCol,
  sortCol,
  isInfFilter,
  setSelectedItem,
  setIsData,
}) => {
  return (
    <>
      {setCustomCols(selectedCol)}
      <Grid
        key={"filters-" + JSON.stringify(payload)}
        container
        spacing={2}
        columns={5}
      >
        {FilterEnum["filterData"][selectedPlatform].includes("location") &&
          isInfFilter && (
            <Grid item md={1}>
              <CustomFilter
                audFilterKey="aud_location"
                infFilterKey="inf_location"
                filterType="location"
                fetchDataByFilterHandler={getLocationData}
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "location"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                audReqKey="id"
                infReqKey="id"
                weight={0.05}
                isMulti={true}
                audTooltipMsg={FilterTooltips.location.audTooltipMsg}
                infTooltipMsg={FilterTooltips.location.infTooltipMsg}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("language") && (
          <Grid item md={1}>
            <CustomFilter
              audFilterKey="aud_language"
              infFilterKey="inf_language"
              filterType="language"
              fetchDataByFilterHandler={getLanguageData}
              setSelectedItem={setSelectedItem}
              setIsData={setIsData}
              showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                "language"
              )}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              audReqKey="code"
              infReqKey="code"
              weight={0.05}
              isMulti={false}
              audTooltipMsg={FilterTooltips.language.audTooltipMsg}
              infTooltipMsg={FilterTooltips.language.infTooltipMsg}
              isInfFilter={isInfFilter}
            />
          </Grid>
        )}
        {FilterEnum["filterData"][selectedPlatform].includes("brand") && (
          <Grid item md={1}>
            <CustomFilter
              audFilterKey="aud_brand"
              infFilterKey="inf_brand"
              filterType="brand"
              fetchDataByFilterHandler={getBrandData}
              setSelectedItem={setSelectedItem}
              setIsData={setIsData}
              showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                "brand"
              )}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              audReqKey="id"
              infReqKey=""
              weight={0.05}
              isMulti={true}
              audTooltipMsg={FilterTooltips.brand.audTooltipMsg}
              infTooltipMsg={FilterTooltips.brand.infTooltipMsg}
              isInfFilter={isInfFilter}
            />
          </Grid>
        )}
        {FilterEnum["filterData"][selectedPlatform].includes("partership") &&
          isInfFilter && (
            <Grid item md={1}>
              <PartnerShips
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "partership"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                infTooltipMsg={FilterTooltips.parntership.audTooltipMsg}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("interest") && (
          <Grid item md={1}>
            <CustomFilter
              audFilterKey="aud_brand_category"
              infFilterKey="inf_brand_category"
              filterType="interests"
              fetchDataByFilterHandler={getInterestData}
              setSelectedItem={setSelectedItem}
              setIsData={setIsData}
              showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                "interest"
              )}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              audReqKey="id"
              infReqKey=""
              weight={0.05}
              isMulti={true}
              audTooltipMsg={FilterTooltips.interest.audTooltipMsg}
              infTooltipMsg={FilterTooltips.interest.infTooltipMsg}
              isInfFilter={isInfFilter}
            />
          </Grid>
        )}
        {FilterEnum["filterData"][selectedPlatform].includes("gender") &&
          isInfFilter && (
            <Grid item md={1}>
              <Genders
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "gender"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("ethinicity") &&
          !isInfFilter && (
            <Grid item md={1}>
              <Ethnicity
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "ethinicity"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("age") &&
          isInfFilter && (
            <Grid item md={1}>
              <Age
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "age"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("audienceType") &&
          !isInfFilter && (
            <Grid item md={1}>
              <AudienceType
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "audienceType"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("lookalikes") && (
          <Grid item md={1}>
            {/* <Lookalikes setSelectedItem={setSelectedItem} setIsData={setIsData}
              showFilter={FilterEnum['filterData'][selectedPlatform].includes("lookalikes")} customCols={customCols} setCustomCols={setCustomCols} setSelectedCol={setSelectedCol} payload={payload} setPayload={setPayload} selectedPlatform={selectedPlatform} allFiltersData={allFiltersData} setAllFiltersData={setAllFiltersData} isInfFilter={isInfFilter}
            /> */}
            <CustomFilter
              audFilterKey="aud_lookalikes"
              infFilterKey="inf_lookalikes"
              filterType="lookalikes"
              fetchDataByFilterHandler={getLookalikeData}
              setSelectedItem={setSelectedItem}
              setIsData={setIsData}
              showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                "lookalikes"
              )}
              customCols={customCols}
              setCustomCols={setCustomCols}
              setSelectedCol={setSelectedCol}
              payload={payload}
              setPayload={setPayload}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              selectedPlatform={selectedPlatform}
              allFiltersData={allFiltersData}
              setAllFiltersData={setAllFiltersData}
              audReqKey="id"
              infReqKey=""
              weight={0.05}
              isMulti={false}
              audTooltipMsg={FilterTooltips.lookalike.audTooltipMsg}
              infTooltipMsg={FilterTooltips.lookalike.infTooltipMsg}
              isInfFilter={isInfFilter}
            />
          </Grid>
        )}
        {/* {FilterEnum['filterData'][selectedPlatform].includes("bio") && (
          <Grid item md={1}>
            <Bio setSelectedItem={setSelectedItem} setIsData={setIsData}
              showFilter={FilterEnum['filterData'][selectedPlatform].includes("bio")} customCols={customCols} setCustomCols={setCustomCols} setSelectedCol={setSelectedCol} payload={payload} setPayload={setPayload} selectedPlatform={selectedPlatform} allFiltersData={allFiltersData} setAllFiltersData={setAllFiltersData}
            />
          </Grid>
        )}
        {FilterEnum['filterData'][selectedPlatform].includes("keywords") && (
          <Grid item md={1}>
            <Keywords setSelectedItem={setSelectedItem} setIsData={setIsData}
              showFilter={FilterEnum['filterData'][selectedPlatform].includes("keywords")} customCols={customCols} setCustomCols={setCustomCols} setSelectedCol={setSelectedCol} payload={payload} setPayload={setPayload} selectedPlatform={selectedPlatform} allFiltersData={allFiltersData} setAllFiltersData={setAllFiltersData}
            />
          </Grid>
        )} */}
        {/* {FilterEnum['filterData'][selectedPlatform].includes("followers") && (
          <Grid item md={1}>
            <Followers setSelectedItem={setSelectedItem} setIsData={setIsData}
              showFilter={FilterEnum['filterData'][selectedPlatform].includes("followers")} customCols={customCols} setCustomCols={setCustomCols} setSelectedCol={setSelectedCol} payload={payload} setPayload={setPayload} selectedPlatform={selectedPlatform} allFiltersData={allFiltersData} setAllFiltersData={setAllFiltersData} isInfFilter={isInfFilter}
            />
          </Grid>
        )} */}
        {FilterEnum["filterData"][selectedPlatform].includes("views") &&
          !isInfFilter && (
            <Grid item md={1}>
              <Views
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "views"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("engagements") &&
          isInfFilter && (
            <Grid item md={1}>
              <Engagements
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "engagements"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("reelPlays") &&
          isInfFilter && (
            <Grid item md={1}>
              <ReelPlays
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "reelPlays"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("contact") &&
          isInfFilter && (
            <Grid item md={1}>
              <Contact
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "contact"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("lastPost") &&
          isInfFilter && (
            <Grid item md={1}>
              <LastPost
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "lastPost"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("accountType") &&
          isInfFilter && (
            <Grid item md={1}>
              <AccountType
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "accountType"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("growth") &&
          isInfFilter && (
            <Grid item md={1}>
              <FollowerGrowth
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "growth"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
        {FilterEnum["filterData"][selectedPlatform].includes("verified") &&
          isInfFilter && (
            <Grid
              item
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Verified
                setSelectedItem={setSelectedItem}
                setIsData={setIsData}
                showFilter={FilterEnum["filterData"][selectedPlatform].includes(
                  "verified"
                )}
                customCols={customCols}
                setCustomCols={setCustomCols}
                setSelectedCol={setSelectedCol}
                payload={payload}
                setPayload={setPayload}
                selectedPlatform={selectedPlatform}
                allFiltersData={allFiltersData}
                setAllFiltersData={setAllFiltersData}
                isInfFilter={isInfFilter}
              />
            </Grid>
          )}
      </Grid>
    </>
  );
};

Filters.propTypes = {
  selectedPlatform: PropTypes.string.isRequired,
};

export default Filters;
