import axios from "axios";
import { API_HOST } from "../../config/main.json";
const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
const inviteInfluencerByList = (campaign_id, audience_id, influencer_id, cname, medias, action, notificationMetadata) => {
  return axios
    .post(
      `${API_HOST}/list/influencer/invite`,
      { campaign_id, audience_id, influencer_id, cname, medias, action, notificationMetadata },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

const inviteInfluencerByEmail = (campaign_id, audience_id, emails, cname, medias, action, notificationMetadata) => {
  return axios
    .post(
      `${API_HOST}/campaign/influencers/invite`,
      { campaign_id, audience_id, emails, cname, medias,action, notificationMetadata },
      optionAxios
    )
    .then((res) => {
      return res.data;
    });
};

export { inviteInfluencerByList, inviteInfluencerByEmail };
