import React, { useState, useEffect } from "react";
import ReactEcharts from 'echarts-for-react';
import { CircularProgress, Card, Grid, Typography } from "@mui/material";
import utils from "../../utils/utils";
import * as echarts from "echarts";
import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingUp from '@mui/icons-material/TrendingUp';
import TrendingFlat from '@mui/icons-material/TrendingFlat';
import styles from '../../styles/theme/CampaignList.module.css';

const trendLine = {
  low: (
    <span className={styles.dotRed}>
      <TrendingDown style={{ color: "#E53E3E" }} />
    </span>
  ),
  high: (
    <span className={styles.dotGreen}>
      <TrendingUp style={{ color: "#38A169" }} />
    </span>
  ),
  equal: (
    <span className={styles.dotBlue}>
      <TrendingFlat style={{ color: "#0f9af4" }} />
    </span>
  ),
};

const PerformanceOverTimeGraph = (props) => {
  const { campId, startDate, endDate, selectedPlatformIds, filterBy } = props;

  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [error, setError] = useState(null);
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisPostData, setYAxisPostData] = useState([]);
  const [yAxisEngagementData, setYAxisEngagementData] = useState([]);

  useEffect(() => {
    fetchGraphData();
  }, [campId, startDate, endDate, selectedPlatformIds]);

  const fetchGraphData = async () => {
    setLoading(true);
    try {
      const response = await utils.getPerformanceGraph(
        campId,
        startDate,
        endDate,
        selectedPlatformIds
      );
      if (response) {
        setGraphData(response);
        const { campaignSplit } = response;
        const xAxisData = Object.keys(campaignSplit);
        const yAxisPostData = xAxisData.map(key => campaignSplit[key].posts);
        const yAxisEngagementData = xAxisData.map(key => campaignSplit[key].engagement);

        setXAxisData(xAxisData);
        setYAxisPostData(yAxisPostData);
        setYAxisEngagementData(yAxisEngagementData);
        setLoading(false);
      } else {
        handleErrorResponse(response);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleErrorResponse = (error) => {
    let errorMessage = 'Failed to fetch data';
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if (error.message) {
      errorMessage = error.message;
    }
    setError(errorMessage);
    setLoading(false);
  };

  const renderEchartOptions = () => {
    if (!xAxisData.length) return {};

    return {
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      legend: {
        data: ['Publications', 'Engagement'],
        top: '0',
        right: 'center',
        orient: 'horizontal',
        icon: 'circle',
        textStyle: { fontSize: 16 },
      },
      grid: { left: '3%', right: '4%', bottom: '10%', containLabel: true },
      xAxis: { type: 'category', data: xAxisData, axisTick: { alignWithLabel: true } },
      yAxis: [
        { type: 'value', name: 'Publications' },
        { type: 'value', name: 'Engagement' },
      ],
      series: [
        {
          name: 'Publications',
          type: 'bar',
          color: ['#DDE3EE'],
          data: yAxisPostData,
          barWidth: 30,
          z: 1,
        },
        {
          name: 'Engagement',
          type: 'line',
          smooth: true,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgb(190, 227, 248)' },
              { offset: 1, color: 'rgb(252, 254, 255)' },
            ]),
          },
          yAxisIndex: 1,
          data: yAxisEngagementData,
        },
      ],
    };
  };

  if (loading) {
    return <CircularProgress style={{ display: 'block', margin: 'auto' }} />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const hasData = graphData && xAxisData.length > 0 && yAxisPostData.length > 0 && yAxisEngagementData.length > 0;

  return (
    <Card style={{ padding: "20px", background: "white", borderRadius: 2, marginTop: '20px', overflow: 'visible', minHeight: '600px', boxShadow: 'none' }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Typography variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#1E2022', marginBottom: "20px" }}>
            Performance Over Time 
          </Typography>
        </Grid>
      </Grid>
      <div style={{ position: 'relative', height: '450px', width: '100%' }}>
        {hasData ? (
          <>
            <ReactEcharts
              option={renderEchartOptions()}
              style={{ height: '100%', width: '100%' }}
            />
            <br></br>
              <p style={{ position: "absolute", right: 20, top: '420px', fontSize:'14px' }}>
              *Engagement is cumulative
              {filterBy !== 'month' && (
              <p style={{ fontSize:'14px' }}>
                *Engagement data is shown on a monthly basis. Daily and yearly data is not available.
              </p>
            )}
            </p>           
          </>
        ) : (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            width: '100%'
          }}>
            No records found
          </div>
        )}
      </div>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Grid item xs={3}>
          <h5 className={styles.subtextCardTitle}>Engagement This Week</h5>
          <h5 className={styles.subtextCardContent}>{graphData.engagementsThisWeek.value}</h5>
          {trendLine[graphData.engagementsThisWeek.trend]}
        </Grid>
        <Grid item xs={3}>
          <h5 className={styles.subtextCardTitle}>Engagement Last Week</h5>
          <h5 className={styles.subtextCardContent}>{graphData.engagementsLastWeek.value}</h5>
          {trendLine[graphData.engagementsLastWeek.trend]}
        </Grid>
        <Grid item xs={3}>
          <h5 className={styles.subtextCardTitle}>Publications This Week</h5>
          <h5 className={styles.subtextCardContent}>{graphData.postThisWeek.value}</h5>
          {trendLine[graphData.postThisWeek.trend]}
        </Grid>
        <Grid item xs={3}>
          <h5 className={styles.subtextCardTitle}>Lifetime Publications</h5>
          <h5 className={styles.subtextCardContent}>{graphData.totalPosts.value}</h5>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PerformanceOverTimeGraph;
