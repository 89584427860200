import React, { Component } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    TextField,
    Typography,
    CircularProgress,
    Backdrop,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Checkbox,
    Autocomplete,
    OutlinedInput,
    Snackbar,
    Alert,
} from "@mui/material";
import { BusinessCenterTwoTone } from "@mui/icons-material";
import MyPaper from "../../general/MyPaper";
import utils from "../../../utils/utils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader from "../../../pages/account/Loader";

let brandId = 0;
let accId = 0;
let backdropShow = false;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class EditBrand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            backdropShow: backdropShow,
            attributesList: [],
            primaryList: [],
            secondaryList: [],
            accountData: [],
            notification: true,
            message: "",
            type: "",
            editFields: {},
            editDetails: {},
            isLoading: true,
            brandData: {}
        };
    }

    getAttributesList() {
        return utils.getAttrData().then((data) => {
            const attributesList = data;
            this.setState({ attributesList });
            return data;
        }).catch(error => {
            console.error('Error fetching attributes data:', error);
            throw error;
        });
    }

    getPrimaryList() {
        utils.getPcatData().then((data) => {
            const primaryList = data;
            this.setState({ primaryList });
        });
    }

    getSecondaryList() {
        utils.getScatData().then((data) => {
            const secondaryList = data;
            this.setState({ secondaryList });
        });
    }


    getBrands(accId) {
        utils.getBrandData(accId).then((data) => {
            const brandData = data.filter((item) => item.id == brandId);
            let editDetails = brandData[0];
            let attributeValues = [];
            if (editDetails?.attributes?.length) {
                editDetails.attributes.split(",").forEach((key) => {
                    attributeValues.push(this.state.attributesList[+key - 1]);
                });
            }
            let editFields = {
                id: editDetails.id,
                brandName: editDetails.name,
                attributes: editDetails.attributes,
                primaryCategory: editDetails.primaryCat,
                secondaryCategory: editDetails.secondaryCat,
            };
            editDetails["attributeValues"] = attributeValues;
            this.setState({ editDetails, editFields });
            this.setState({ isLoading: false })
        });
    }

    loadData = async () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        brandId = params.get("id");
        accId = params.get("accId")
        if (typeof brandId !== "undefined") {
            await this.getAttributesList();
            this.getPrimaryList();
            this.getSecondaryList();
            this.getBrands(accId)
            this.setState({ backdropShow: false });
        }
    };

    componentDidMount() {
        this.loadData();
    }

    postData(data) {
        return utils.getPostData(data, accId).then((data) => {
            this.setState({
                notification: true,
                message: "Brand Edited successfully",
                type: "success",
            });
            //redirect to accounts on successful response
            setTimeout(function () {
                window.location = "/brands";
            }, 3000);
        }).catch(error => {
            console.error('Failed to post data:', error);
            this.setState({
                notification: true,
                message: "Failed to edit brand",
                type: "error",
            });
            //redirect to accounts on successful response
            setTimeout(function () {
                window.location = "/brands";
            }, 3000);
        });
    }

    handleChange(field, e) {
        let editFields = this.state.editFields;
        editFields[field] = e.target.value;
        this.setState({ editFields });
    }

    handleSaveEdit(e) {
        backdropShow = true;
        this.setState({ backdropShow });
        e.preventDefault();
        delete this.state.editFields["name"];
        var body = {};
        body["id"] = brandId.toString();
        body["name"] = this.state.editFields["brandName"];
        body["attributes"] = this.state.editFields["attributes"];
        body["primaryCat"] = this.state.editFields["primaryCategory"];
        body["secondaryCat"] = this.state.editFields["secondaryCategory"];
        this.postData(body);
    }

    handleChangeAuto(field, e, values) {
        let editFields = this.state.editFields;
        editFields[field] = "";
        if (values !== null) {
            if (values.length > 0) {
                var data = [];
                values.forEach(function (item, index) {
                    data.push(item["id"]);
                });
                editFields[field] = data.join(",");
            } else if (typeof values["id"] !== "undefined") {
                editFields[field] = values["id"];
            }
        }
        this.setState({ editFields });
    }

    closeNotification = () => {
        this.setState({ notification: false });
    };


    handleChangeAccount(values, field) {
        let editFields = this.state.editFields;
        editFields[field] = undefined;
        editFields["accountName"] = "";
        if (values !== null) {
            editFields[field] = values.id;
            editFields["accountName"] = values.name;
        }
    }

    render() {
        return this.state.isLoading ? (
            <>
                <Loader />
            </>
        ) : (

            <>
                <Snackbar
                    open={this.state.notification}
                    autoHideDuration={3000}
                    onClose={this.closeNotification}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert onClose={this.closeNotification} severity={this.state.type} variant="filled" sx={{ width: "100%" }}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <form onSubmit={this.handleSaveEdit.bind(this)}>
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            flexDirection: "column",
                        }}
                        open={this.state.backdropShow}
                    >
                        <CircularProgress color="inherit" />
                        <Box sx={{ marginTop: "20px" }}>Submitting data... please wait.</Box>
                    </Backdrop>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MyPaper>
                                <Grid container spacing={2} sx={{ mt: 1 }}>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }}>
                                            <BusinessCenterTwoTone sx={{ mr: 2 }} />
                                            <Typography variant="h5">Brand Details</Typography>
                                        </Box>
                                        <Divider />
                                        <Grid container spacing={2} sx={{ mt: 1 }}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        variant="outlined"
                                                        required={true}
                                                        type="text"
                                                        id="brandName"
                                                        refs="brandName"
                                                        name="brandName"
                                                        label="Brand Name"
                                                        defaultValue={this.state.editDetails.name}
                                                        onChange={this.handleChange.bind(this, "brandName")}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        multiple
                                                        limitTags={1}
                                                        id="checkboxes-tags-demo"
                                                        options={this.state.attributesList}
                                                        onChange={this.handleChangeAuto.bind(this, "attributes")}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option) => option?.name}
                                                        defaultValue={this.state.editDetails.attributeValues}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                                                                {option.name}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Brand Attributes"
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} style={{ display: "flex", maxWidth: "100%" }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="primary-category-label">Brand Primary Category *</InputLabel>
                                                    <Select
                                                        required={true}
                                                        label={"Brand Primary Category *"}
                                                        id="primaryCategory"
                                                        onChange={this.handleChange.bind(this, "primaryCategory")}
                                                        defaultValue={this.state.editDetails.primaryCat}
                                                        input={<OutlinedInput label={"Brand Primary Category *"} id="demo-dialog-native" />}
                                                    >
                                                        {this.state.primaryList.map((primaryCategory) => (
                                                            <MenuItem value={primaryCategory.id} key={primaryCategory.id}>
                                                                {primaryCategory.category}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="secondary-category-label">Brand Secondary Category *</InputLabel>
                                                    <Select
                                                        required={true}
                                                        label={"Brand Secondary Category *"}
                                                        id="secondaryCategory"
                                                        onChange={this.handleChange.bind(this, "secondaryCategory")}
                                                        defaultValue={this.state.editDetails.secondaryCat}
                                                        input={<OutlinedInput label={"Brand Secondary Category *"} id="demo-dialog-native" />}
                                                    >
                                                        {this.state.secondaryList.map((secondaryCategory) => (
                                                            <MenuItem value={secondaryCategory.id} key={secondaryCategory.id}>
                                                                {secondaryCategory.category}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <br />
                                <Divider />
                            </MyPaper>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "right" }}>
                            <Box sx={{ mt: 4 }}>
                                <Button variant="contained" size="large" type="submit">
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }
}
export default EditBrand;
