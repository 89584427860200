import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ExportDropdown from './common/ExportDropdown';
import { Box, IconButton, Pagination, Skeleton, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { exportList } from './service/fetchDataByFilter';
import { openSnackbar } from '../../../redux/features/snackbar/snackbarSlice';
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { ReactComponent as TikTokIcon } from "../../../utils/tiktok-brands.svg";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import utils from '../../../utils/utils';


const platforms = ["all", "instagram", "titkok", "youtube"];
const exportTypes = ["all", "regular", "contact"];
const sortTypes = ["Newest", "Oldest", "Account: High - Low", "Account: Low - High"];

const socialIcons = {
  "instagram": <InstagramIcon sx={{ fontSize: "16px", color: "#BC2A8D" }} />,
  "youtube": <YouTubeIcon sx={{ color: "#FF0100", fontSize: "16px" }} />,
  "titkok": <TikTokIcon style={{ width: "16px", height: "16px" }}
    sx={{ color: "#000000" }} />
}



const ExportList = () => {


  const [socialNetwork, setSocialNetwork] = useState("all");
  const [exportType, setExportType] = useState("all");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [exportData, setExportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageChangeHandler = (event, value) => {
    setPage(value);
  };

  const exportListHandler = async () => {
    setIsLoading(true);
    try {
      const { data, status } = await exportList(
        {
          "companyId": 0,
          "page": page,
          "rows": rowsPerPage,
          "sortcol": sortBy,
          "sortorder": sortOrder,
          "platform": socialNetwork,
          "exportType": exportType

        }
      );
      if (status === 200) {
        setExportData(data);
      }
    } catch (error) {
      dispatch(openSnackbar({
        message: "Unable to fetch data",
        type: "error",
      }));
    }
    setIsLoading(false);
  }


  useEffect(() => {
    exportListHandler();
  }, [socialNetwork, exportType, sortBy, sortOrder, page, rowsPerPage]);

  const sortByHandler = (value) => {
    if (value === "Newest" || value === "Oldest") {
      setSortBy("createdAt");
      if (value === "Newest")
        setSortOrder("desc");
      else
        setSortOrder("asc");
    } else if (value === "Account: High - Low" || value === "Account: Low - High") {
      setSortBy("count");
      if (value === "Account: High - Low")
        setSortOrder("desc");
      else
        setSortOrder("asc");
    }
  }

  const sortBySelectedValue = () => {
    if (sortBy === "count") {
      if (sortOrder === "desc")
        return "Newest";
      else
        return "Oldest";
    } else if (sortBy === "createdAt") {
      if (sortOrder === "desc")
        return "Account: High - Low";
      else
        return "Account: Low - High";
    }
  }


  const renderContentHandler = () => {
    let content = null;
    if (isLoading) {
      content = (
        <Box width={"100%"}>
          {new Array(8).fill(0).map((item, index) => (<Skeleton key={index} variant="rectangular" height={50} width={"100%"} sx={{ mb: "1rem", borderRadius: "6px" }} />))}
        </Box>
      )
    } else if (exportData?.exportList?.length) {
      content = (
        <Box>
          {exportData?.exportList?.map((item, index) => (<DataRow key={`export-data-row-${index}`} data={item} />))}
        </Box>
      )
    } else {
      content = (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>No Records Found</Typography>
        </Box>
      )
    }
    return content;
  }


  return (
    <>
      <Box sx={{ display: "flex", alignItems: "start" }} maxWidth={"1200px"} mx="auto">
        <Tooltip title="Go Back" placement="right">
          <IconButton sx={{
            color: "black",
            background: "white",
            border: "1px solid lightgrey",
            borderRadius: "9999px",
            "&:hover": { boxShadow: 2, background: "white" },
            whiteSpace: "nowrap",
            p: 1,
            textTransform: "none",
            justifyContent: "space-between",
            mr: "2rem",
            my: 1
          }}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Box p={1} width={"100%"} >
          <Box >
            <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>My Exports {exportData?.exportList?.length && `(${exportData?.exportList?.length})`}</Typography>
          </Box>
          <Box sx={{ display: "flex", aligItems: "center" }}>
            <ExportDropdown dropdownData={platforms} selectedValue={socialNetwork} setSelectedValue={setSocialNetwork} title={"Social Networks"} />
            <ExportDropdown dropdownData={exportTypes} selectedValue={exportType} setSelectedValue={setExportType} title={"Export Type"} />
            <ExportDropdown dropdownData={sortTypes} selectedValue={sortBySelectedValue()} setSelectedValue={sortByHandler} title={"Sort By"} />
          </Box>
          <Box my={3}>
            {renderContentHandler()}
            {exportData?.exportList?.length &&
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'end' }}>
                <Pagination count={exportData?.total_pages} shape="rounded" page={page} onChange={pageChangeHandler} />
              </Box>
            }
          </Box>
        </Box>
      </Box>

    </>
  )
}

export default ExportList;

const DataRow = ({ data }) => {

  // download a file with s3 filepath
  const downloadFileHandler = (url) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 2, pb: 1, borderBottom: "1px solid black" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box width={"120px"}>
          <Typography sx={{ mr: 2, color: "#000000", whiteSpace: "nowrap" }}>{utils.formatDateTime(data?.createdAt)}</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ mr: 1, color: "#888", whiteSpace: "nowrap", fontSize: "12px" }}>{data?.count} out of {data?.totalRecords} accounts</Typography>
            <Box ml="2px" fontSize={"10px"}>
              {socialIcons[data?.platform]}
            </Box>
            {data?.isContact === 1 &&
              <Box ml="2px" fontSize={"16px"}>
                <AccountCircleRoundedIcon sx={{ fontSize: "18px", pb: "2px" }} />
              </Box>
            }
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", maxWidth: "45vw", ml: 14 }}>
          {data?.FiterText &&
            <>
              {data?.FiterText.split(";").filter(item => item !== " ").map((item, index) => (
                <>
                  <Box sx={{ mr: 1, background: "#ffffff", borderRadius: "9999px", p: "8px", my: "4px", boxShadow: 2 }} key={`filter-text-${index}`}>
                    <Typography sx={{ color: "#000000", whiteSpace: "nowrap", fontSize: "12px" }}>{item}</Typography>
                  </Box>
                </>
              ))}
            </>
          }
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Download File" placement="right">
          <IconButton sx={{
            color: "black",
            border: "1px solid lightgrey",
            borderRadius: "12px",
            "&:hover": { bgcolor: "lightgray" },
            whiteSpace: "nowrap",
            px: "12px",
            textTransform: "none",
            justifyContent: "space-between",
            mr: "1rem"
          }}
            onClick={() => downloadFileHandler(data?.filePath)}
          >
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
