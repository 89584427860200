import { useState, useEffect, React } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";

import { TextField, Button, Stack, Snackbar, Alert } from "@mui/material";
import { Fade, Modal } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { API_HOST } from "../../config/main.json";
import utils from "../../utils/utils";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

const AddInfToListDialog = ({ iidsSelected, platform, addToListDialog, modalClosed, newListCreation }) => {
  const [listData, setListData] = useState([]);
  const [anchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!platform) {
      return;
    }
    const url = API_HOST + "/lists/type/2/" + platform?.toLowerCase();
    axios.get(url).then((res) => {
      const lists = res.data;
      let newlistData = [];
      if (lists !== null) {
        Object(lists).forEach((item, index) => {
          newlistData.push({ sl: index + 1, ...item });
          newlistData[index]["name"] =
            newlistData[index]["aname"] +
            " - " +
            newlistData[index]["name"] +
            " - " +
            utils.capitalizeFirstLetter(newlistData[index]["platform"]) +
            " - " +
            newlistData[index]["totalInf"];
        });
        setListData(newlistData);
      }
    });
  }, [platform]);

  const createNewList = (event) => {
    newListCreation(true);
  };

  const searchList = (e) => {
    let input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    ul = document.getElementById("searchUL");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  const listClick = (event) => {
    const lid = event.currentTarget.value;
    addToList(lid);
  };

  const addToList = (lid) => {
    const optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    let successfullyAddedToList = false;
    axios
      .post(API_HOST + "/list/influencers/add", { iid: iidsSelected, lid: lid, group: "" }, optionAxios)
      .then((res) => {
        if (res["data"]["status"].includes("Duplicate entry")) {
          dispatch(
            openSnackbar({
              message: "Influencer already exists in list!",
              severity: "error",
            })
          );
        } else if (res["data"]["status"] !== "success") {
          dispatch(
            openSnackbar({
              message: "Error...Please try again!",
              severity: "error",
            })
          );
        } else {
          successfullyAddedToList = true;
          dispatch(
            openSnackbar({
              message: "Influencer added to list!",
              severity: "success",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            message: "Something went wrong!!",
            severity: "error",
          })
        );
      })
      .finally(() => {
        modalClosed(successfullyAddedToList);
      });
  };

  const handleClose = () => {
    modalClosed(false);
  };

  return (
    <>
      {/* Modal to choose one of existing lists or creating new list to add selected Influencers */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-add_influencer_to_list-label"
        aria-describedby="modal-add_influencer_to_list-description"
        open={addToListDialog}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={addToListDialog}>
          <div className="syles_paper">
            <div id="modal-add_influencer_to_list-heading" className="styles_modal_heading">
              <h2 id="modal-add_influencer_to_list-heading-text" style={{ padding: "10px" }}>
                Add To List
              </h2>
              <IconButton onClick={handleClose}>
                {" "}
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div
              id="modal-add_influencer_to_list-body"
              style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", paddingTop: "0px !important" }}
            >
              <Stack direction="row" justifyContent="center">
                <Button
                  variant="contained"
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  onClick={createNewList}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  style={{ width: "100%" }}
                >
                  {" "}
                  + New List
                </Button>
              </Stack>

              <div className="ui vertical divider" style={{ height: "100px" }}>
                <div className="ortext">
                  <span className="orSpan">OR</span>
                </div>
              </div>

              <div className="verticaDiv">
                <div>
                  <TextField
                    variant="outlined"
                    id="searchInput"
                    className="searchBox"
                    label="Search for list..."
                    style={{ width: "100%  !important", marginLeft: "0px !important" }}
                    refs="searchInput"
                    onKeyDown={searchList}
                    placeholder="Search for list..."
                  />
                  <div id="listOfLists">
                    <ul id="searchUL">
                      {listData.map((x) => (
                        <li onClick={listClick} key={x.name} value={x.id}>
                          {x.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

AddInfToListDialog.propType = {
  addToListDialog: PropTypes.bool.isRequired,
  platform: PropTypes.string.isRequired,
  iidsSelected: PropTypes.array.isRequired,
  modalClosed: PropTypes.func.isRequired,
  newListCreation: PropTypes.func.isRequired,
};

export default AddInfToListDialog;
