import { Close } from "@mui/icons-material";
import {
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { rolesData } from "./enum";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import { API_HOST, HEIMDALL_HOST } from "../../config/main.json";
import { validateAiFluenceEmails, validateBusinessEmails, validateEmail } from "../../services/account/verification";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

function NewUserModal({ newUserModal, setNewUserModal, setRefreshTable }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("member");

  const dispatch = useDispatch();

  const closeModal = () => {
    setNewUserModal(false);
  };

  const handleAifluenceSubmit = (event) => {
    event.preventDefault();
    closeModal();

    if (!email.toLowerCase().endsWith("@ai-fluence.com")) {
      dispatch(
        openSnackbar({
          message: "Please invite only Aifluence Users",
          severity: "error",
        })
      );
      return;
    }

    const data = {
      name: firstName.trim() + " " + lastName.trim(),
      email: email.trim().toLowerCase(),
      role: role,
    };

    axios.post(API_HOST + "/access/adduser", data, optionAxios).then((res) => {
      if (res.status === 200) {
        if (res.data === "success") {
          setRefreshTable(true);
          dispatch(
            openSnackbar({
              message: `User ${data.email} has been invited.`,
              severity: "success",
            })
          );
        } else if (res.data === "email already exist") {
          dispatch(
            openSnackbar({
              message: `User ${data.email} already exist!`,
              severity: "error",
            })
          );
        } else {
          dispatch(
            openSnackbar({
              message: `User ${data.email} already exist!`,
              severity: "error",
            })
          );
        }
        return;
      }
    });
  };

  return (
    <Modal
      className="styles_modal"
      aria-labelledby="modal-new_user-label"
      aria-describedby="modal-new_user-description"
      open={newUserModal}
      onClose={closeModal}
      closeAfterTransition
    >
      <Fade in={newUserModal}>
        <div className="syles_paper_list" style={{ height: "auto", width: "50%" }}>
          <div id="modal-new_user-heading" className="styles_modal_heading">
            <h2 id="modal-new_user-heading-text" style={{ padding: "10px" }}>
              Invite New AiFluence User
            </h2>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </div>
          <hr />
          <div id="modal-new_user-body" style={{ padding: "10px" }}>
            <form onSubmit={handleAifluenceSubmit} style={{ paddingTop: "10px" }}>
              <Grid container spacing={2} style={{ width: "100%" }}>
                <Grid item xs={6}>
                  <TextField
                    onChange={(event) => setFirstName(event.target.value)}
                    variant="outlined"
                    label="First Name"
                    style={{ width: "100%" }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={(event) => setLastName(event.target.value)}
                    variant="outlined"
                    label="Last Name"
                    style={{ width: "100%" }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={(event) => setEmail(event.target.value)}
                    variant="outlined"
                    label="Email"
                    style={{ width: "100%" }}
                    required={true}
                  />
                  {validateBusinessEmails(email) && (
                    <p style={{ padding: "10px", color: "red" }}>Please use business email only</p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel>Role *</InputLabel>
                    <Select
                      onChange={(event) => setRole(event.target.value)}
                      variant="outlined"
                      label="Role"
                      defaultValue="member"
                      renderValue={(value) => rolesData.filter((role) => role.value === value)[0].title}
                      required
                    >
                      {rolesData.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                          <div style={{ maxWidth: "100%" }}>
                            <div>{role.title}</div>
                            <div style={{ fontSize: "12px" }}>{role.description}</div>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <button
                variant="contained"
                className="btn btn-success"
                style={{
                  float: "right",
                  margin: "20px",
                }}
                type="submit"
              >
                + Invite User
              </button>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default NewUserModal;
