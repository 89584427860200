import axios from "axios";
import { API_HOST } from "../../config/main.json";
import { getResponseType } from "../../utils/exportData";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const getAccountList = async (
  account,
  type,
  isExport,
  page,
  rows,
  SortCol,
  SortOrder,
  filter,
  mimeType,
  accountPlatform,
  timeZone,
  plan
) => {
  plan = plan.toString()
  const response = await axios({
    url: `${API_HOST}/accounts`,
    method: "POST",
    data: {
      account,
      type,
      isExport,
      page,
      rows,
      SortCol,
      SortOrder,
      filter,
      mimeType,
      addedFrom: accountPlatform,
      timeZone,
      status: "",
      platform: accountPlatform,
      secondaryFilter: {
        plan: plan 
      }

    },
    headers: optionAxios.headers,
    responseType: getResponseType(isExport),
  });
  if (response) {
    return response.data;
  }

  return false;
};





export { getAccountList };
