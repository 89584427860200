import React from "react";
import CampaignTable from "./CampaignTable";
import { Link, useLocation, useNavigate } from "react-router-dom";


const DeletededCampaigns = () => {
  const location = useLocation();
  const platform = new URLSearchParams(location.search).get("platform")

  return <CampaignTable type={2} platfrom={platform} />;
}

export default DeletededCampaigns;
