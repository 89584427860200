import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import StyledMenu from "../StyledMenu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import utils from '../../../utils/utils';

import {
  FormControl,
  Grid,
  Autocomplete,
  Tooltip,
  Chip,
  MenuItem,
} from "@mui/material";
import AutocompleteSideMenu from "./common/AutocompleteSideMenu";

const FollowerGrowth = ({
  showFilter,
  customCols,
  setCustomCols,
  setSelectedCol,
  payload,
  setPayload,
  isChanged,
  setIsChanged,
  sendSelectedPlatform,
  allFiltersData,
  setAllFiltersData,
}) => {
  const [allFollowerGrowth] = useState([
    { value: "i1month", name: "1 month" },
    { value: "i2months", name: "2 months" },
    { value: "i3months", name: "3 months" },
    { value: "i4months", name: "4 months" },
    { value: "i5months", name: "5 months" },
    { value: "i6months", name: "6 months" },
  ]);
  const [percentage] = useState([
    { value: ">-0.1", name: ">10%" },
    { value: ">-0.2", name: ">20%" },
    { value: ">-0.3", name: ">30%" },
    { value: ">-0.4", name: ">40%" },
    { value: ">-0.5", name: ">50%" },
    { value: ">-0.6", name: ">60%" },
    { value: ">-0.7", name: ">70%" },
    { value: ">-0.8", name: ">80%" },
    { value: ">-0.9", name: ">90%" },
    { value: ">-1", name: ">100%" },
    { value: ">-1.1", name: ">110%" },
    { value: ">-1.2", name: ">120%" },
    { value: ">-1.3", name: ">130%" },
    { value: ">-1.4", name: ">140%" },
    { value: ">-1.5", name: ">150%" },
    { value: ">-1.6", name: ">160%" },
    { value: ">-1.7", name: ">170%" },
    { value: ">-1.8", name: ">180%" },
    { value: ">-1.9", name: ">190%" },
    { value: ">-1", name: ">200%" },
    { value: ">-2.1", name: ">210%" },
    { value: ">-2.2", name: ">220%" },
    { value: ">-2.3", name: ">230%" },
    { value: ">-2.4", name: ">240%" },
    { value: ">-2.5", name: ">250%" },
    { value: ">-2.6", name: ">260%" },
    { value: ">-2.7", name: ">270%" },
    { value: ">-2.8", name: ">280%" },
    { value: ">-2.9", name: ">290%" },
    { value: ">-3", name: ">300%" },
    { value: ">-3.1", name: ">310%" },
    { value: ">-3.2", name: ">320%" },
    { value: ">-3.3", name: ">330%" },
    { value: ">-3.4", name: ">340%" },
    { value: ">-3.5", name: ">350%" },
    { value: ">-3.6", name: ">360%" },
    { value: ">-3.7", name: ">370%" },
    { value: ">-3.8", name: ">380%" },
    { value: ">-3.9", name: ">390%" },
    { value: ">-4", name: ">400%" },
    { value: "<-1.1", name: "<110%" },
    { value: "<-1.2", name: "<120%" },
    { value: "<-1.3", name: "<130%" },
    { value: "<-1.4", name: "<140%" },
    { value: "<-1.5", name: "<150%" },
    { value: "<-1.6", name: "<160%" },
    { value: "<-1.7", name: "<170%" },
    { value: "<-1.8", name: "<180%" },
    { value: "<-1.9", name: "<190%" },
    { value: "<-2", name: "<200%" },
    { value: "<-2.1", name: "<210%" },
    { value: "<-2.2", name: "<220%" },
    { value: "<-2.3", name: "<230%" },
    { value: "<-2.4", name: "<240%" },
    { value: "<-2.5", name: "<250%" },
    { value: "<-2.6", name: "<260%" },
    { value: "<-2.7", name: "<270%" },
    { value: "<-2.8", name: "<280%" },
    { value: "<-2.9", name: "<290%" },
    { value: "<-3", name: "<300%" },
    { value: "<-3.1", name: "<310%" },
    { value: "<-3.2", name: "<320%" },
    { value: "<-3.3", name: "<330%" },
    { value: "<-3.4", name: "<340%" },
    { value: "<-3.5", name: "<350%" },
    { value: "<-3.6", name: "<360%" },
    { value: "<-3.7", name: "<370%" },
    { value: "<-3.8", name: "<380%" },
    { value: "<-3.9", name: "<390%" },
    { value: "<-4", name: "<400%" },
    { value: "<-4.1", name: "<410%" },
    { value: "<-4.2", name: "<420%" },
    { value: "<-4.3", name: "<430%" },
    { value: "<-4.4", name: "<440%" },
    { value: "<-4.5", name: "<450%" },
    { value: "<-4.6", name: "<460%" },
    { value: "<-4.7", name: "<470%" },
    { value: "<-4.8", name: "<480%" },
    { value: "<-4.9", name: "<490%" },
    { value: "<-5", name: "<500%" },
  ]);
  const [range, setRange] = useState(">-1.5");
  const [operator, setOperator] = useState("gte");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [FollowerGrowthSelected, setFollowerGrowthSelected] = useState();

  const [paragraphs, setParagraphs] = useState([]);
  const handleInfFollowerGrowthelection = (selectedValues) => {
    let latestFollowerGrowth = selectedValues["name"];
    let filterSet = { name: latestFollowerGrowth };
    allFiltersData = utils.addObj(allFiltersData, "inf_followers_growth");
    allFiltersData[0]["inf_followers_growth"][0] = filterSet;
    if (latestFollowerGrowth !== undefined) {
      let newParaghaph = [];
      newParaghaph.push(
        <Grid
          container
          id={latestFollowerGrowth}
          spacing={1}
          style={{ marginTop: "10px" }}
        >
          <Grid item xs={12} sm={6}>
            {
              <Chip
                key={latestFollowerGrowth}
                icon={
                  <CancelRoundedIcon
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => {
                      handleCancleFollowerGrowth(latestFollowerGrowth);
                    }}
                  />
                }
                label={latestFollowerGrowth}
                style={{
                  backgroundColor: "white",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              />
            }
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      );
      let newPayload = payload;
      let followersGrowth = "";
      if (latestFollowerGrowth === "Choose the interval") {
        newPayload["inf_followers_growth"] = {};
        followersGrowth = "";
      } else {
        let growth = {
          interval: selectedValues["value"],
          value: range.substring(2),
          operator: operator,
        };
        newPayload["inf_followers_growth"] = growth;
        followersGrowth = "followersGrowth";
      }
      customCols = utils.addObj(customCols, "inf_followers_growth");
      customCols[0]["inf_followers_growth"][0] = followersGrowth;
      setSelectedCol(customCols);
      setCustomCols(customCols);
      setPayload(newPayload);
      setAllFiltersData(allFiltersData);
      setParagraphs(newParaghaph);
    } else if (latestFollowerGrowth !== undefined) {
      handleCancleFollowerGrowth(latestFollowerGrowth);
    } else {
      setParagraphs();
    }
    setFollowerGrowthSelected(selectedValues);
  };

  const handleRange = (event) => {
    const dataArray = event.target.value.split("-");
    let operator = dataArray[0];
    let value = dataArray[1];
    let operatorString = "lte";
    if (operator === ">") {
      operatorString = "gte";
    }
    setRange(event.target.value);
    setOperator(operator);
    let newPayload = payload;
    newPayload["inf_followers_growth"].value = value;
    newPayload["inf_followers_growth"].operator = operatorString;
    setPayload(newPayload);
    setRange(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancleFollowerGrowth = () => {
    const filteredData = [];
    let newPayload = payload;
    newPayload["inf_followers_growth"] = filteredData;
    setPayload(newPayload);
    let newFilters = allFiltersData;
    newFilters[0]["inf_followers_growth"] = filteredData;
    setAllFiltersData(newFilters);
    setIsChanged(!isChanged);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const sideMenuAttachment = () => {
    if (
      allFiltersData !== undefined &&
      allFiltersData[0]["inf_followers_growth"] !== undefined &&
      allFiltersData[0]["inf_followers_growth"].length > 0
    ) {
      let value = range;
      const filter = payload["inf_followers_growth"];
      if (filter !== undefined) {
        let operator = "<";
        if (filter.operator === "gte") {
          operator = ">";
        }
        value = operator + "-" + filter.value;
      }
      return (
        <AutocompleteSideMenu
          options={Object.keys(allFiltersData[0]["inf_followers_growth"]).map(
            (index) => {
              let item = allFiltersData[0]["inf_followers_growth"][index];
              return (
                <>
                  <Grid
                    container
                    id={item.name}
                    spacing={1}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item xs={12} sm={6}>
                      {
                        <Chip
                          key={item.name}
                          icon={
                            <CloseRoundedIcon
                              style={{ width: "20px", height: "20px" }}
                              onClick={() => {
                                handleCancleFollowerGrowth();
                              }}
                            />
                          }
                          label={item.name}
                          style={{
                            backgroundColor: "white",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        label="Range"
                        select
                        value={value}
                        fullWidth
                        onChange={(e) => handleRange(e)}
                      >
                        {percentage.map((items) => (
                          <MenuItem
                            key={items["value"]}
                            value={items["value"]}
                            primaryText={items["name"]}
                          >
                            {items["name"]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </>
              );
            }
          )}
        />
      );
    }
  };

  return showFilter ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple={false}
              disableClearable
              id="filter-FollowerGrowth"
              size="small"
              popupIcon={""}
              options={allFollowerGrowth}
              forcePopupIcon={false}
              getOptionLabel={(option) => option.name}
              onChange={(_e, v) => handleInfFollowerGrowthelection(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Follower Growth"
                  placeholder="Choose the interval"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: sideMenuAttachment(),
                  }}
                />
              )}
              style={{
                borderRadius: ".775rem",
              }}
            />
          </FormControl>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl sx={{ m: 1 }}>Influencer</FormControl>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  title={
                    "Identify influencers by having sponsored posts with FollowerGrowth. We detect sponsored posts either by usage of commercial hashtags or official paid FollowerGrowth tag. You can add several FollowerGrowth and your search results will be refined with influencers that have sponsored posts for ANY of the specified FollowerGrowth."
                  }
                >
                  <HelpRoundedIcon fontSize="small" style={{ opacity: 0.3 }} />
                </Tooltip>
              </Grid>
            </Grid>
          </StyledMenu>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
};

FollowerGrowth.propTypes = {
  showFilter: PropTypes.bool.isRequired,
};

export default FollowerGrowth;
