import axios from "axios";
import React, { useState, useEffect } from "react";
import { API_HOST } from "../../config/main.json";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import utils from "../../utils/utils";

function UserLogsFilter({
  start,
  end,
  selectedUsers,
  selectedActions,
  handleFilter,
}) {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(selectedUsers);
  const [actions, setActions] = useState([]);
  const [filteredActions, setFilteredActions] = useState(selectedActions);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  useEffect(() => {
    getUsers();
    getActions();
  }, []);

  const getUsers = () => {
    axios.get(API_HOST + "/logs/users").then((res) => {
      const users = res.data || [];
      setUsers(users);
    });
  };

  const getActions = () => {
    axios.get(`${API_HOST}/logs/actions`).then((res) => {
      const actions = res.data || [];
      setActions(actions);
    });
  };

  const handleCalendarChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const filter = () => {
    handleFilter(startDate, endDate, filteredUsers, filteredActions);
  };

  return (
    <Box sx={{ p: "20px", mt: "10px 0", bgcolor: "white" }}>
      <Grid container spacing={1}>
        <Grid item md={1}>
          <Typography>Filters</Typography>
        </Grid>
        <Grid item md={3} alignItems="center">
          <DateRangePicker
            initialSettings={{
              startDate: startDate,
              endDate: endDate,
              ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment().subtract(1, "days").toDate(),
                  moment().subtract(1, "days").toDate(),
                ],
                "This Week": [
                  moment().startOf("week").toDate(),
                  moment().toDate(),
                ],
                "Last Week": [
                  moment().subtract(1, "weeks").startOf("week").toDate(),
                  moment().subtract(1, "weeks").endOf("week").toDate(),
                ],
                "Last 7 Days": [
                  moment().subtract(6, "days").toDate(),
                  moment().toDate(),
                ],
                "Last 30 Days": [
                  moment().subtract(29, "days").toDate(),
                  moment().toDate(),
                ],
                "This Month": [
                  moment().startOf("month").toDate(),
                  moment().endOf("month").toDate(),
                ],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month").toDate(),
                  moment().subtract(1, "month").endOf("month").toDate(),
                ],
                "Last 6 Months": [
                  moment().subtract(6, "month").startOf("month").toDate(),
                  moment().toDate(),
                ],
              },
            }}
            onCallback={handleCalendarChange}
          >
            <input
              style={{ lineHeight: 1.6 }}
              type="text"
              className="form-control"
            />
          </DateRangePicker>
        </Grid>
        <Grid item md={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel size="small">User Emails</InputLabel>
            <Select
              size="small"
              style={{ width: "100%" }}
              label="User Emails"
              multiple
              value={filteredUsers}
              onChange={(event) => setFilteredUsers(event.target.value)}
              renderValue={(selected) => {
                if (selected.length === 1) {
                  return selected.length + " User Selected";
                } else {
                  return selected.length + " Users Selected";
                }
              }}
            >
              {users.map((user) => (
                <MenuItem key={user} value={user}>
                  <Checkbox checked={filteredUsers.indexOf(user) > -1} />
                  {user}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel size="small">Actions</InputLabel>
            <Select
              size="small"
              style={{ width: "100%" }}
              label="Actions"
              multiple
              value={filteredActions}
              onChange={(event) => setFilteredActions(event.target.value)}
              renderValue={(selected) => {
                if (selected.length === 1) {
                  return selected.length + " Action Selected";
                } else {
                  return selected.length + " Actions Selected";
                }
              }}
            >
              {actions.map((action) => (
                <MenuItem key={action.action} value={action.action}>
                  <Checkbox
                    checked={filteredActions.indexOf(action.action) > -1}
                  />
                  {utils.capitalizeFirstLetter(
                    action.action.split("-").join(" - ").split("_").join(" ")
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={1} />
        <Grid item md={1}>
          <Button
            variant="contained"
            onClick={filter}
            endIcon={<ArrowForward />}
          >
            Go
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

UserLogsFilter.propTypes = {
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  selectedActions: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default UserLogsFilter;
