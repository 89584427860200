import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import "../../styles/internal/CampaignAnalytics.css";


const CampaignScoreChart = () => {
  const data = [
    { scoreRange: '96-100', grade: 'A+', quality: 'Excellent' },
    { scoreRange: '91-95', grade: 'A', quality: 'Excellent' },
    { scoreRange: '86-90', grade: 'B+', quality: 'Good' },
    { scoreRange: '71-85', grade: 'B', quality: 'Good' },
    { scoreRange: '61-70', grade: 'C+', quality: 'Fair' },
    { scoreRange: '51-60', grade: 'C', quality: 'Fair' },
    { scoreRange: '41-50', grade: 'D+', quality: 'Poor' },
    { scoreRange: '31-40', grade: 'D', quality: 'Poor' },
    { scoreRange: '21-30', grade: 'E+', quality: 'Bad' },
    { scoreRange: '0-20', grade: 'E', quality: 'Bad' },
  ];


  return (
    <Table className="table">
      <TableHead>
        <TableRow style={{ background: "white", border: "1px solid black" }}>
          <TableCell className="tableHeader">Score Range</TableCell>
          <TableCell className="tableHeader">Grade</TableCell>
          <TableCell className="tableHeader">Quality of Campaign</TableCell>
        </TableRow>

      </TableHead>
      <TableBody style={{ background: "white", border: "1px solid black" }}>

        {data.map((item, index) => (
          <TableRow key={index}>
            <TableCell className="tableCol">{item.scoreRange}</TableCell>
            <TableCell className="tableCol">{item.grade}</TableCell>
            <TableCell className="tableCol">{item.quality}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CampaignScoreChart;
